define('webapp/pods/alerta/model', ['exports'], function (exports) {

	'use strict';

	exports['default'] = Ember.Object.extend({
		id: null,
		titulo: '',
		dataAlerta: '',
		dataEnvio: null,

		findUrlFn: function findUrlFn(id) {
			return 'alerta/' + id.toString();
		}

	});

});
define('webapp/models/fundamento-legal', ['exports', 'ember'], function (exports, Ember) {

	'use strict';

	exports['default'] = Ember['default'].Object.extend({
			id: null,
			descricao: '',
			ativo: ''
	});

});
define('webapp/pods/requisicao/item/orcamento/controller', ['exports', 'ember', 'webapp/utils/util', 'webapp/mixins/crud-mixin'], function (exports, Ember, Util, CrudMixin) {

	'use strict';

	exports['default'] = Ember['default'].Controller.extend(CrudMixin['default'], {

		needs: ['requisicao/item'],

		breadCrumbConfig: {
			fnNomeApresentacao: 'fnNomeApresentacao'
		},

		fnNomeApresentacao: function fnNomeApresentacao() {
			return 'Orçamento ' + (this.model.version ? this.model.numeroApresentacao : '(Novo)');
		},

		formataCpfCnpj: function formataCpfCnpj(orcamento) {
			var identificadorFornecedor = orcamento.identificadorFornecedor;
			if (!identificadorFornecedor) {
				return;
			} else if (identificadorFornecedor.length === 11) {
				return Util['default'].incluiPontuacaoCpf(identificadorFornecedor);
			} else {
				return Util['default'].incluiPontuacaoCnpj(identificadorFornecedor);
			}
		},

		crudConfig: {
			criar: {
				urlFn: function urlFn() {
					return 'itemRequisicao/' + this.get("controllers.requisicao/item").model.id + '/orcamentoItem';
				},
				okFn: function okFn(opcao) {
					this.get('controllers.requisicao/item').send('ler');
					if (opcao === 'CRIAR_E_PROXIMO') {
						//ação "borbulhará" para a rota
						this.send('refreshRota');
						return;
					}
					this.transitionToRoute('requisicao/item');
				}
			},
			atualizar: {
				okFn: function okFn() {
					this.get('controllers.requisicao/item').send('ler');
					this.transitionToRoute('requisicao/item');
				}
			},
			excluir: {
				okFn: function okFn() {
					this.get('controllers.requisicao/item').send('ler');
				}
			}
		},

		ehOrcamentoNovo: (function () {
			return !this.model.version;
		}).property('model.version'),

		sugerirDataAtualParaOrcamento: (function () {
			if (!this.get('ehOrcamentoNovo')) {
				return;
			}
			this.set('model.dataEmissao', new Date());
		}).observes('model')

	});

});
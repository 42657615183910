define('webapp/pods/aquisicao/compra/empenho/model', ['exports', 'ember'], function (exports, Ember) {

	'use strict';

	exports['default'] = Ember['default'].Object.extend({
		id: null,
		numeroFisico: null,
		numeroApresentacao: null,
		prazoEfetivo: '',
		prazoLimite: '',
		aceite: '', //data
		entregaObjeto: '', //data
		diferenca: null, //entre prazoLimite e (prazoEfetivo ou entregaObjeto ou aceite ??)

		findUrlFn: function findUrlFn(id) {
			return 'compra/empenho/' + id.toString();
		}
	});

});
define('webapp/instance-initializers/modifica-componente-base', ['exports', 'ember', 'webapp/utils/id'], function (exports, Ember, UtilID) {

  'use strict';

  /* Exemplo de uso:
  {{<nome da tag>
    controlePermissao=<true | false (default) - indica se deve ser controlada
                        a permissão de acesso ao componente>
    textoAlternativo=<texto que deve substituir o componente quando não for
                      permitido acessá-lo>
    classeElementoInterno=classe da div
    cssElementoInterno=style da div
    destaqueTextoAlternativo=<true | false (default) - indica se o texto
                              alternativo deve aparecer com em destaque>
    baseID=<caso o mesmo tipo de componente, com determinado id, seja utilizado
            várias vezes na mesma tela - ex. num bloco each - haveria componentes
            com id repetido na tela. Assim, para diferenciá-los, é possível
            definir uma string para servir de base para o id - que seria igual em
            todas as repetições do componente na tela - e uma porção variável -
            que diferenciaria os compoentes na tela. A propriedade baseID é
            a string que serve como base.>
    variadorID=<Porção variável do id>
  }}

  */

  exports['default'] = {
    initialize: function initialize() {

      Ember['default'].Component.reopen({

        usuarioLogado: Ember['default'].inject.service('usuario-logado'),

        controlePermissao: false,
        destaqueTextoAlternativo: false,

        _montaID: (function () {
          if (!this.baseID) {
            return;
          }
          if (!this.variadorID && this.variadorID !== 0) {
            Ember['default'].warn('Está sendo definido um ID composto (baseID + variadorID) ' + ('sem ser definido um variador válido. (baseID: ' + this.baseID + ')'));
            this.set('id', this.baseID);
          }

          this.set('id', UtilID['default'].incluiSeccaoOpcional(this.baseID, this.variadorID));

          //"this.id" guarda o id do componente; "this.elementId" guarda o id do elemento
          //html correspondente. Nesse ponto da execução é possível que o id do elemento
          //html já tenha sido definido. Então é preciso definir ambos.
          this.set('elementId', this.id);
        }).on('init').observes('baseID', 'variadorID'),

        _insereClasseElementoInterno: (function () {
          if (this.get('classeElementoInterno')) {
            this.$().addClass(this.get('classeElementoInterno'));
          }
        }).on('willInsertElement'),

        _insereCSSElementoInterno: (function () {
          if (this.get('cssElementoInterno')) {
            this.$().attr("style", this.$().attr("style") ? this.$().attr("style") + ";" + this.get('cssElementoInterno') : this.get('cssElementoInterno'));
          }
        }).on('willInsertElement'),

        _insereToolTip: (function () {
          if (this.dica) {
            this.$().attr('title', this.dica);
          }
        }).on('willInsertElement'),

        _verificaID: (function () {

          if (this.controlePermissao && !this.id) {
            Ember['default'].warn('ID NÃO DEFINIDO PARA COMPONENTE COM CONTROLE DE PERMISSÃO. \n' + ('ID: ' + this.id + ' \n') + ('Texto: ' + this.texto + ' - Valor: ' + this.valor + ' \n') + ('Context: ' + this.context + ' \n') + ('Element: ' + this.element + ' \n') + ('Title: ' + this.title + ' - Template: ' + this.templateName + ' \n') + ('ToString: ' + this.toString() + ' \n') + ('ValueOf: ' + this.valueOf() + ' \n') + '-------------------------------------------', this.id);
            return;
          }

          Ember['default'].warn('ID COM FORMATO INVÁLIDO: ' + this.id, UtilID['default'].ehValido(this.id));
        }).on('willInsertElement'),

        // Caso o usuário não tenha permissão, esconde o componente e retorna false;
        // Se o usuário tiver permissão ou o componente não estiver sob controle
        // de permissão, simplesmente retorna true e nada mais
        _verificaPermissao: (function () {
          //Se não estiver no controle de permissão, apresenta o componente
          if (!this.controlePermissao) {
            return true;
          }

          if (!this.get('usuarioLogado')) {
            this._escondeComponente();
            return false;
          }

          if (!this.get('usuarioLogado').idPermitido(this.id)) {
            this._escondeComponente();
            return false; //
          }

          return true;
        }).on('didInsertElement'),

        _escondeComponente: function _escondeComponente() {
          //Para funcionar o hide deste modo, o componente tem que ter algum
          //tag-name definido. Vai ser usado span. Implementar um modo de o id
          //que ficar no span ser diferente do id do button (diferenciando o id
          //do _idInterno)

          if (this.textoAlternativo) {
            var elementoHtmlAlternativo;

            if (this.destaqueTextoAlternativo) {
              elementoHtmlAlternativo = document.createElement('p');
              elementoHtmlAlternativo.className = 'alert-danger';
              elementoHtmlAlternativo.setAttribute('style', 'text-align: center');
            } else {
              elementoHtmlAlternativo = document.createElement('span');
            }

            elementoHtmlAlternativo.appendChild(document.createTextNode(this.textoAlternativo));

            this.element.parentNode.insertBefore(elementoHtmlAlternativo, this.element);
            this.element.parentNode.removeChild(this.element);
          } else {
            //this.$().html('');
            this.$().hide();
          }
        }

      });
    }
  };

});
define('webapp/pods/fornecedor/dado-bancario/route', ['exports', 'ember', 'webapp/utils/util'], function (exports, Ember, Util) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({
    //sempre vem do controller fornecedor.
    setupController: function setupController(controller, model) {
      controller.set('model', Util['default'].clona(model));

      this.get('comunicador').leParaPropriedade(controller, 'banco', 'bancos', "Não foi possível obter a lista de bancos!");
    }
  });

});
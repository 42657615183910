define('webapp/pods/fornecedor/prova-regularidade/route', ['exports', 'ember', 'webapp/utils/util'], function (exports, Ember, Util) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({
    //sempre vem do controller fornecedor.
    setupController: function setupController(controller, model) {
      controller.set('model', Util['default'].clona(model));

      this.get('comunicador').leParaPropriedade(controller, 'classificacaoItem', 'classificacoesProdutos', 'Não foi possível obter as classificações financeiras dos produtos');
    }
  });

});
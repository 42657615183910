define('webapp/pods/papel/listagem/controller', ['exports', 'ember', 'webapp/mixins/crud-mixin'], function (exports, Ember, CrudMixin) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend(CrudMixin['default'], {
    breadCrumbConfig: {
      nomeApresentacao: 'Papéis'
    },

    buscarRolesExistentes: function buscarRolesExistentes() {
      this.get('comunicador').leParaPropriedade(this, 'role', 'roles', 'Não foi possível obter as entidades existentes', true);
    },

    actions: {
      listar: function listar() {
        this.buscarRolesExistentes();
      }
    }
  });

});
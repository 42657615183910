define('webapp/pods/adm-outras-entidades/municipio/controller', ['exports', 'ember', 'webapp/mixins/crud-mixin'], function (exports, Ember, CrudMixin) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend(CrudMixin['default'], {

    needs: ['adm-outras-entidades'],

    ufsDisponiveis: [],
    paisSelecionado: '',
    ufSelecionada: '',
    mostraModalCadastro: false,

    crudConfig: {
      criar: {
        urlFn: function urlFn() {
          return 'uf/' + this.ufSelecionada.id + '/municipio';
        },
        modelFn: function modelFn() {
          return this.get('model');
        },
        okFn: function okFn(opcao) {
          this.get('controllers.adm-outras-entidades').preencheLocalidadesModel();
          this.set('model', {});
          this.set('mostraModalCadastro', opcao === 'CRIAR_E_PROXIMO');
        }
      },
      excluir: {
        urlFn: function urlFn(idParaConcatenarNaUrl) {
          return 'uf/municipio/' + idParaConcatenarNaUrl;
        },
        okFn: function okFn() {
          this.get('controllers.adm-outras-entidades').preencheLocalidadesModel();
        }
      }
    },

    paises: (function () {
      return this.get('controllers.adm-outras-entidades').model.paises;
    }).property('controllers.adm-outras-entidades.model.paises'),

    filtraUFsParaSelecao: (function () {
      if (!this.paisSelecionado) {
        return;
      }
      this.get('comunicador').leParaPropriedade(this, 'pais/' + this.paisSelecionado.id.toString() + '/uf', 'ufsDisponiveis');
    }).observes('paisSelecionado'),

    actions: {
      adicionar: function adicionar() {
        this.set('model', {});
        this.set('mostraModalCadastro', true);
      },
      cancelarModal: function cancelarModal() {
        this.set('model', {});
        this.set('mostraModalCadastro', false);
      }
    }
  });

});
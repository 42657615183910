define('webapp/pods/aquisicao/contrato/efeito-financeiro/model', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Object.extend({
    id: null,
    version: '',
    numeroApresentacao: '',
    dataInicio: '',
    valorInicialAtualizadoMensal: '',
    valorInicialAtualizadoUnico: '',
    valorInicialAtualizadoTotal: '',
    valorAtualMensal: '',
    valorAtualUnico: '',
    valorAtualTotal: '',
    apostilamento: null,
    aditivo: null,
    observacao: '',

    findUrlFn: function findUrlFn(id) {
      return 'contrato/efeitoFinanceiro/' + id.toString();
    }
  });

});
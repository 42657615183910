define('webapp/pods/login/controller', ['exports', 'ember', 'webapp/utils/util'], function (exports, Ember, Util) {

	'use strict';

	exports['default'] = Ember['default'].Controller.extend({

		needs: ['application'],

		usuarioLogado: Ember['default'].inject.service('usuario-logado'),

		actions: {
			entrar: function entrar() {
				var _this = this;

				this.get('comunicador').servico('post', 'auth/login', { 'usuario': this.usuario ? this.usuario.trim() : '', 'senha': this.senha }).then(function (conteudo) {
					var usuarioInfo = conteudo;
					_this.get('usuarioLogado').entrar(usuarioInfo);
					if (_this.prod()) {
						_this.set('senha', null);
					}
					_this.transitionToRoute('application');
				}, function (resultado) {
					Util['default'].alertaErro("Não foi possível entrar no sistema.", resultado.responseText);
				});
			},

			logout: function logout() {
				this.get('comunicador').servico('get', 'auth/logout');
				this.get('usuarioLogado').sair();
				this.transitionToRoute('login');
			}
		},

		prod: function prod() {
			return this.get('controllers.application').PRD() || this.get('controllers.application').PRDLocal();
		}

	});

});
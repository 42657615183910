define('webapp/helpers/chama-funcao', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    exports['default'] = Ember['default'].Handlebars.makeBoundHelper(function (linha, _ref) {
        var opcoes = _ref.hash;

        var nomeFuncao = opcoes.nomeFuncao;
        var controller = opcoes.controllerReferencia;
        return controller[nomeFuncao](linha);
    });

});
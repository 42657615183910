define('webapp/pods/aquisicao/contrato/evento/criar/route', ['exports', 'ember', 'webapp/pods/aquisicao/contrato/evento/model'], function (exports, Ember, Evento) {

	'use strict';

	exports['default'] = Ember['default'].Route.extend({
		controllerName: 'aquisicao/contrato/evento',
		setupController: function setupController(controller) {
			controller.set('model', Evento['default'].create({}));
		},

		model: function model() {
			return Evento['default'].create();
		}
	});

});
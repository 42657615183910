define('webapp/pods/publico/compras/route', ['exports', 'ember', 'webapp/utils/util'], function (exports, Ember, Util) {

    'use strict';

    exports['default'] = Ember['default'].Route.extend({
        setupController: function setupController(controller) {
            // Configura informações da tela
            this.get('comunicador').servico('get', 'parametroExibicao').then(function (resultado) {
                controller.set('infoTela', Util['default'].converteParametrosDeExibicao(resultado).TRANSP_INFO_COMPRA);
                controller.set('exibeCabecalho', Util['default'].converteParametrosDeExibicao(resultado).TRANSP_EXIBE_CABECALHOS == "S");
                controller.set('urlLogo', Util['default'].converteParametrosDeExibicao(resultado).TRANSP_URL_LOGO);
                controller.set('retroagir', Util['default'].converteParametrosDeExibicao(resultado).TRANSP_QTDE_ANOS_RETROAGIR);
            });

            // Parâmetros de URL
            var queryParams = controller.get('queryParams');
            var temFiltroQuery = false;
            queryParams.forEach(function (param) {
                if (controller.get(param)) {
                    temFiltroQuery = true;
                }
            });
            // seta filtro padrão, caso não tenha sido filtrado pela query string
            if (!temFiltroQuery) {
                var dataAtual = new Date();
                var mesAnterior = dataAtual.getMonth();
                if (mesAnterior == 0) {
                    // Casos de janeiro, daí o filtro padrão deve ser dezembro do ano anterior
                    controller.set('ano', dataAtual.getFullYear() - 1);
                    controller.set('mes', "12");
                } else {
                    controller.set('ano', dataAtual.getFullYear());
                    controller.set('mes', mesAnterior < 10 ? "0" + mesAnterior : mesAnterior); // mês anterior propositalmente
                }
            }

            controller.send('listar');
        }
    });

});
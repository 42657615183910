define('webapp/pods/aquisicao/ata/adesao/item/controller', ['exports', 'ember', 'webapp/mixins/crud-mixin'], function (exports, Ember, CrudMixin) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend(CrudMixin['default'], {

    needs: ['aquisicao/ata/adesao', 'aquisicao/ata'],

    breadCrumbConfig: {
      fnNomeApresentacao: 'fnNomeApresentacao'
    },

    fnNomeApresentacao: function fnNomeApresentacao() {
      return 'Item ' + (this.model.version ? this.model.numeroApresentacao : '(Novo)');
    },

    itensAta: (function () {
      return this.get("controllers.aquisicao/ata").get('model.itens');
    }).property("controllers.aquisicao/ata.model.itens"), //PRECISA DE UM EACH??

    crudConfig: {
      criar: {
        urlFn: function urlFn() {
          return 'adesaoExterna/' + this.get("controllers.aquisicao/ata/adesao").model.id + '/itemAdesaoExterna';
        },
        okFn: function okFn(opcao) {
          this.get('controllers.aquisicao/ata/adesao').send('ler');
          this.get('controllers.aquisicao/ata').send('ler');
          if (opcao === 'CRIAR_E_PROXIMO') {
            //ação "borbulhará" para a rota
            this.send('refreshRota');
            return;
          }
          this.transitionToRoute('aquisicao/ata/adesao', this.get("controllers.aquisicao/ata/adesao").model.id);
        }
      },
      atualizar: {
        okFn: function okFn() {
          this.get('controllers.aquisicao/ata/adesao').send('ler');
          this.get('controllers.aquisicao/ata').send('ler');
          this.transitionToRoute('aquisicao/ata/adesao', this.get("controllers.aquisicao/ata/adesao").model.id);
        }
      },
      excluir: {
        okFn: function okFn() {
          this.get('controllers.aquisicao/ata/adesao').send('ler');
          this.get('controllers.aquisicao/ata').send('ler');
        }
      }
    }

  });

});
define('webapp/pods/aquisicao/compra/route', ['exports', 'ember', 'webapp/utils/util', 'webapp/mixins/suprimir-templates-superiores-mixin'], function (exports, Ember, Util, SuprimirTemplatesSuperioresMixin) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(SuprimirTemplatesSuperioresMixin['default'], {
    setupController: function setupController(controller, model) {
      controller.set('model', Util['default'].clona(model));
      if (model.idContrato) {
        this.get('comunicador').leParaPropriedade(controller, 'aquisicao/contrato/' + model.idContrato + '/semAlertas', 'contratoDaCompra', 'Não foi possível obter o contrato da compra');
      }
    }
  });

});
define('webapp/pods/papel/listagem/route', ['exports', 'ember'], function (exports, Ember) {

	'use strict';

	exports['default'] = Ember['default'].Route.extend({
		setupController: function setupController(controller) {
			controller.send('listar');
		}
	});

});
define('webapp/pods/fornecedor/prova-regularidade/controller', ['exports', 'ember', 'webapp/mixins/crud-mixin'], function (exports, Ember, CrudMixin) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend(CrudMixin['default'], {

    needs: ['fornecedor'],

    breadCrumbConfig: {
      fnNomeApresentacao: 'fnNomeApresentacao'
    },

    fnNomeApresentacao: function fnNomeApresentacao() {
      return 'Prova de Regularidade ' + (this.model.version ? this.model.numeroApresentacao : '(Novo)');
    },

    crudConfig: {
      criar: {
        urlFn: function urlFn() {
          return 'fornecedor/' + this.get("controllers.fornecedor").model.id + '/provaRegularidade';
        },
        okFn: function okFn(opcao) {
          this.get("controllers.fornecedor").send("ler");
          if (opcao === 'CRIAR_E_PROXIMO') {
            //ação "borbulhará" para a rota
            this.send('refreshRota');
            return;
          }
          this.transitionToRoute('fornecedor');
        }
      },
      atualizar: {
        okFn: function okFn() {
          this.get("controllers.fornecedor").send("ler");
          this.transitionToRoute('fornecedor');
        }
      },
      excluir: {
        okFn: function okFn() {
          this.get("controllers.fornecedor").send('ler');
          this.transitionToRoute('fornecedor');
        }
      }
    },

    ehBalancoPatrimonial: (function () {
      return this.model.tipo === 'BALANCO_PATRIMONIAL';
    }).property('model.tipo'),

    ehAtestadoCapacidadeTecnica: (function () {
      return this.model.tipo === 'ATESTADO_CAPACIDADE_TECNICA';
    }).property('model.tipo'),

    hashIdTipoProvaVsNome: {
      'FAZENDA_FEDERAL': 'Fazenda Federal',
      'FAZENDA_ESTADUAL': 'Fazenda Estadual',
      'FAZENDA_MUNICIPAL': 'Fazenda Municipal',
      'FGTS': 'FGTS',
      'CNDT': 'CNDT',
      'INCISO_XXXII': 'Declaração de cumprimento do inciso XXXIII, art. 7º da CF',
      'ENQUADRAMENTO_ME_EPP': 'Enquadramento como ME ou EPP',
      'NEGATIVA_FALENCIA': 'Negativa de Falência',
      'BALANCO_PATRIMONIAL': 'Balanço Patrimonial',
      'ENTIDADE_PROFISSIONAL': 'Registro em Entidade Profissional',
      'NEGATIVA_INIDONEIDADE': 'Negativa de Cadastro como Empresa Inidônea ou Suspensa (CEIS)',
      'ATESTADO_CAPACIDADE_TECNICA': 'Atestado de Capacidade Técnica'
    },

    tiposProvaRegularidade: (function () {
      var _this = this;

      var tiposProva = [];
      Object.keys(this.hashIdTipoProvaVsNome).forEach(function (idTipo) {
        tiposProva.push({
          id: idTipo,
          nome: _this.hashIdTipoProvaVsNome[idTipo]
        });
      });
      return tiposProva;
    }).property(),

    getNomeTipo: function getNomeTipo(prova) {
      var nomeTipo = this.hashIdTipoProvaVsNome[prova.tipo];
      //Se o tipo for Atestado de Capacidade Técnica, a classificação do produto
      //para o qual a capacidade foi atestada faz parte do nome do tipo
      // if(prova.tipo === 'ATESTADO_CAPACIDADE_TECNICA') {
      //   nomeTipo += ` [${prova.classificacaoProduto.nome}]`;
      // }
      return nomeTipo;
    },

    defineCorProvaRegularidadeExpirada: function defineCorProvaRegularidadeExpirada(provaRegularidade) {
      if (!provaRegularidade.dataExpiracao) {
        return '';
      }

      //data atual com o horário zerado, assim como são as datas das provas de regularidade
      var dataAtualHorarioZero = new Date().setHours(0, 0, 0, 0);

      if (provaRegularidade.dataExpiracao < dataAtualHorarioZero) {
        return 'red';
      }

      return '';
    },

    limpaCamposBalancoPatrimonial: (function () {
      if (this.get('ehBalancoPatrimonial')) {
        return;
      }

      this.set('model.capitalCirculanteLiquido', null);
      this.set('model.patrimonioLiquido', null);
      this.set('model.liquidezGeral', null);
      this.set('model.liquidezCorrente', null);
      this.set('model.solvenciaGeral', null);
    }).observes('ehBalancoPatrimonial'),

    limpaCamposAtestadoCapacidadeTecnica: (function () {
      if (this.get('ehAtestadoCapacidadeTecnica')) {
        return;
      }

      //this.set('model.classificacaoProduto', null);
      this.set('model.quantitativosCapacidadeTecnica', null);
    }).observes('ehAtestadoCapacidadeTecnica')

  });

});
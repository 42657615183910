define('webapp/pods/aquisicao/ata/criar/route', ['exports', 'ember', 'webapp/pods/aquisicao/ata/model', 'webapp/mixins/suprimir-templates-superiores-mixin'], function (exports, Ember, Ata, SuprimirTemplatesSuperioresMixin) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(SuprimirTemplatesSuperioresMixin['default'], {
    controllerName: 'aquisicao/ata',

    setupController: function setupController(controller) {
      controller.set('model', Ata['default'].create());

      this.get('comunicador').leParaPropriedade(controller, 'aquisicao/' + controller.get('controllers.aquisicao').model.id + '/adjudicado', 'fornecedoresAdjudicadosAquisicao', "Não foi possível obter os Fornecedores!");
    },

    model: function model() {
      return Ata['default'].create();
    }

  });

});
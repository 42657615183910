define('webapp/models/cbo', ['exports', 'ember'], function (exports, Ember) {

	'use strict';

	exports['default'] = Ember['default'].Object.extend({
		id: null,
		codigo: '',
		nome: ''
	});

});
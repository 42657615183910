define('webapp/components/tag-relatorio', ['exports', 'ember', 'webapp/mixins/ajusta-id-interno-mixin', 'webapp/utils/id'], function (exports, Ember, AjustaIdInternoMixin, UtilID) {

  'use strict';

  /*
    Exemplo de uso:

    Colocar a seguinte tag no template

    {{tag-relatorio
      descricaoRelatorios=<propriedade do controller com a descrição dos relatórios>
      controlePermissao=<true | false (default) - controla a permissão do componente
                          como um todo (ou seja, se vai aparecer o dropdown com a
                          lista de relatórios disponíveis)>
      posicaoLivre=<true | false (default) - define se o componente ficará livre
                    para posicionar (true) ou se ficará posicionado no canto direito
                    superior da tela (false)>
      id="componente|gerar|relatorios|..."}}

    E criar, no controller, uma propriedade que retorne um array com
    a descrição de cada relatório, como abaixo:

    [
      {
        textoMenu: 'Relação de Fornecimentos',
        servico: 'relatorio/relacaoDeFornecimentos/' + this.get("model.id"),
        nomeArquivo: 'relacao_de_fornecimento',
        nomeParaID: 'relacao-fornecimento', //irá para o id do link para este relatório,
                                            //na seção de informação, sendo prefixado com
                                            //"relatorio-" (ex. relatorio-relacao-fornecimento)
        controlePermissao: true //se deve ser controlada, na lista de opções de relatório,
                                //a permissão relativa ao link para este relatório
      },
      {
        textoMenu: 'Certificado de Registro Cadastral',
        servico: 'relatorio/certificadoDeRegistroCadastral/' + this.get("model.id"),
        nomeArquivo: 'fornecedor_certificado_de_registro_cadastral',
        nomeParaID: 'certificado-registro-cadastral',
        controlePermissao: true
      },
      ...
    ]

    Caso o menu dropdown do relatório deva aparecer, mas ficar desabilitado devido a alguma
    condição qualquer, tipo após uma pesquisa que não retornou dados - portanto, não haveria o que
    imprimir -, podemos utilizar uma propriedade extra na descrição do relatório, que deve se
    chamar testaHabilitado e que deve ser uma function que retorna um boolean, vide exemplo abaixo:
       {
        textoMenu: 'Certificado de Registro Cadastral',
        servico: 'relatorio/certificadoDeRegistroCadastral/' + this.get("model.id"),
        nomeArquivo: 'fornecedor_certificado_de_registro_cadastral',
        nomeParaID: 'certificado-registro-cadastral',
        controlePermissao: true,
  			testaHabilitado: () => {
  					return !this.get('mostraMensagemPesquisaSemResultado') && this.get('mostraTabelaResultadoPesquisa');
  			},
      },

    Caso para gerar o relatório seja necessário que o usuário informe algum parâmetro
    (ex. no caso de relatório de penalidades é preciso que o usuário informe o ano
     sobre o qual deseja o relatório), a URL do relatório não deve mais ser definida
     no atributo "servico" da descrição do relatório, e a descrição deve incluir o atributo
     "configChamada", ficando como abaixo:
     [...

  		{
  			textoMenu:'Relatório Anual de Penalidades',
  			configChamada: {
  				parametros: [{nome: 'ano', nomeLegivel: 'Ano'}],
  				getUrlServico: (objetoParametros) => { return 'relatorio/penalidadesPorAno/' + objetoParametros.ano; }
  			},
  			nomeArquivo:'penalidades_por_ano',
  			nomeParaID: 'penalidades-por-ano',
  			controlePermissao: false
  		}

     ...]

    Esse atributo adicional deve ser um objeto com um atributo "parametros" e uma função
    getUrlServico(objetoParametros). "parametros" deve conter um array com um objeto para
    parâmetro, indicando o nome do parâmetro e o nome legível que deve ser apresentado
    ao usuário (ex. [{nome: "mes", nomeLegivel: "Mês"}, {nome: "ano", nomeLegivel: "Ano"}]).
    A funcao getUrlServico deve receber como parâmetro um objeto com atributos correspondendo
    aos parâmetros informados (ex. {mes: ..., ano: ...}) e, a partir disso, definir a URL
    que deve ser chamada para acessar o relatório (podendo usar para isso tanto objetoParametros
    quanto atributos do controller de origem da função - tais como "model" e suas propriedades).

  */

  exports['default'] = Ember['default'].Component.extend(AjustaIdInternoMixin['default'], {
    classNameBindings: ['classText'],
    classText: 'paddingZero',
    topoTela: true,

    comunicador: Ember['default'].inject.service(),

    definePosicionamento: (function () {
      if (this.posicaoLivre) {
        return;
      }

      this.set('classText', this.classText + ' col-md-3 sac_relatorios');
    }).on('init'),

    _idDropdown: (function () {
      var contexto = UtilID['default'].getSeccao(this.id, 'contexto');
      var local = UtilID['default'].getSeccao(this.id, 'local');
      return 'link|abrir-fechar|opcoes-relatorios|' + contexto + '|' + local;
    }).property('id'),

    _descricaoRelatoriosComplementada: (function () {
      var _this = this;

      return this.descricaoRelatorios.map(function (relatorio) {
        _this._acrescentaIdDescricaoRelatorio(relatorio);
        _this._acrescentaFlagRelatorioHabilitado(relatorio);
        return relatorio;
      });
    }).property('id', 'descricaoRelatorios'),

    _acrescentaIdDescricaoRelatorio: function _acrescentaIdDescricaoRelatorio(relatorio) {
      var informacao = 'relatorio-' + relatorio.nomeParaID;
      var contexto = UtilID['default'].getSeccao(this.id, 'contexto');
      var local = UtilID['default'].getSeccao(this.id, 'local');
      relatorio.id = 'link|gerar|' + informacao + '|' + contexto + '|' + local;
    },

    _acrescentaFlagRelatorioHabilitado: function _acrescentaFlagRelatorioHabilitado(relatorio) {
      relatorio.habilitado = true;
      if (relatorio.testaHabilitado) {
        relatorio.habilitado = false;
        if (relatorio.testaHabilitado()) {
          //relatorio.habilitado = true;
          Ember['default'].set(relatorio, "habilitado", true);
        }
      }
    },

    //---------- DEFINIÇÃO DA CHAMADA DO RELATÓRIO - COM PARÂMETROS DEFINIDOS PELO USUÁRIO OU SEM

    arrayParametros: [],
    configRelatorioSelecionado: {},
    mostrarModalParametrosRelatorio: false,

    //A partir de this.arrayParametros, no formato
    //[{nome: 'p1', nomeLegivel: 'P1', valor: 'aaa'},
    // {nome: 'p2', nomeLegivel: 'P2', valor: 'bbb'},
    // ...]
    //Criar um objeto no formato
    //{p1: 'aaa', p2: 'bbb', ...}
    _getObjetoComParametros: function _getObjetoComParametros() {
      var obj = {};

      this.arrayParametros.forEach(function (parametro) {
        obj[parametro.nome] = parametro.valor;
      });

      return obj;
    },

    _limpaSelecaoRelatorio: function _limpaSelecaoRelatorio() {
      this.arrayParametros.forEach(function (parametro) {
        Ember['default'].set(parametro, 'valor', undefined);
      });
      this.set('arrayParametros', []);
      this.set('configRelatorioSelecionado', {});
      this.set('mostrarModalParametrosRelatorio', false);
    },

    actions: {
      selecionarRelatorio: function selecionarRelatorio(relatorio) {
        if (relatorio.configChamada) {
          this.set('configRelatorioSelecionado', relatorio);
          this.set('arrayParametros', this.configRelatorioSelecionado.configChamada.parametros);
          this.set('mostrarModalParametrosRelatorio', true);
        } else {
          this.send('acessarRelatorio', relatorio.servico, relatorio.nomeArquivo, relatorio.metodo, Ember['default'].typeOf(relatorio.dados) === "function" ? relatorio.dados() : relatorio.dados);
        }
      },

      cancelarRelatorio: function cancelarRelatorio() {
        this._limpaSelecaoRelatorio();
      },

      acessarRelatorioComParametros: function acessarRelatorioComParametros() {
        var objetoParametros = this._getObjetoComParametros();
        var url = this.configRelatorioSelecionado.configChamada.getUrlServico(objetoParametros);
        this.send('acessarRelatorio', url, this.configRelatorioSelecionado.nomeArquivo, this.configRelatorioSelecionado.metodo, this.configRelatorioSelecionado.dados);
        this._limpaSelecaoRelatorio();
      },

      acessarRelatorio: function acessarRelatorio(url, nomeArquivo, metodo, dados) {
        var comunicador = this.get('comunicador');

        if (!metodo) {
          metodo = "get";
        }

        comunicador.servicoBlob(metodo, url, dados, "BAIXAR_ARQUIVO").then(function (resultado) {
          var a = document.createElement("a");
          document.body.appendChild(a);
          a.style = "display: none";
          var blob = new Blob([resultado], { type: "application/pdf" }),
              url = window.URL.createObjectURL(blob);
          a.href = url;
          a.download = nomeArquivo;
          a.click();
          window.URL.revokeObjectURL(url);
        });
      }
    }
  });

});
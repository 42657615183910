define('webapp/pods/requisicao/item/orcamento/listagem/controller', ['exports', 'ember'], function (exports, Ember) {

	'use strict';

	exports['default'] = Ember['default'].Controller.extend({

		orcamentosParaApresentar: (function () {
			return this.model.map(this.copiaOrcamentoAdicionandoIdentificadorFornecedor);
		}).property('model'),

		copiaOrcamentoAdicionandoIdentificadorFornecedor: function copiaOrcamentoAdicionandoIdentificadorFornecedor(orcamento) {
			var orcamentoComIndentificador = {};
			Object.assign(orcamentoComIndentificador, orcamento);
			orcamentoComIndentificador.identificadorFornecedor = orcamento.fornecedor.cpf ? orcamento.fornecedor.cpf : orcamento.fornecedor.cnpj;
			return orcamentoComIndentificador;
		}

	});

});
define('webapp/pods/requisicao/model', ['exports', 'ember', 'webapp/models/unidade-organizacional', 'webapp/models/programa-trabalho'], function (exports, Ember, UnidadeOrganizacional, ProgramaTrabalho) {

	'use strict';

	exports['default'] = Ember['default'].Object.extend({
		id: null,
		numeroApresentacao: '',
		dataCriacao: '',
		programaDeTrabalho: ProgramaTrabalho['default'].create({}),
		objeto: '',
		numeroProcessoAdministrativo: '',
		unidadeRequisitante: UnidadeOrganizacional['default'].create({}),
		localEntrega: '',
		prazoEntrega: '',
		idAquisicoes: [], //ids das aquisições para as quais a requisição sirva como base
		contatosEntrega: [], //Contato
		itens: [], //ItemRequisicao
		valorTotal: null, //Efetivamente calculado e devolvido pelo servidor mas
		//sugerido pelo cliente durante o preenchimento das informações
		observacao: '',

		inicializar: (function () {
			this.set('programaDeTrabalho', ProgramaTrabalho['default'].create({}));
			this.set('unidadeRequisitante', UnidadeOrganizacional['default'].create({}));
			this.set('idAquisicoes', []);
			this.set('contatosEntrega', []);
			this.set('itens', []);
		}).on('init'),

		findUrlFn: function findUrlFn(id) {
			return 'requisicao/' + id.toString();
		}
	});

});
define('webapp/pods/aquisicao/contrato/aditivo/alteracao-teor-clausula/criar/route', ['exports', 'ember', 'webapp/pods/aquisicao/contrato/aditivo/alteracao-teor-clausula/model'], function (exports, Ember, AlteracaoTeorClausula) {

	'use strict';

	exports['default'] = Ember['default'].Route.extend({
		controllerName: 'aquisicao/contrato/aditivo/alteracao-teor-clausula',
		setupController: function setupController(controller) {
			controller.set('model', AlteracaoTeorClausula['default'].create({}));
		},

		model: function model() {
			return AlteracaoTeorClausula['default'].create();
		}
	});

});
define('webapp/pods/adm-outras-entidades/fundamento-legal/controller', ['exports', 'ember', 'webapp/mixins/crud-mixin', 'webapp/utils/util'], function (exports, Ember, CrudMixin, Util) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend(CrudMixin['default'], {

    needs: ['adm-outras-entidades'],

    mostraModalCadastro: false,
    adicionando: false,

    crudConfig: {
      criar: {
        urlFn: function urlFn() {
          return 'fundamentoLegal';
        },
        modelFn: function modelFn() {
          return this.get('model');
        },
        okFn: function okFn(opcao) {
          this.get('controllers.adm-outras-entidades').buscarFundamentosLegais();
          this.set('model', {});
          this.set('mostraModalCadastro', opcao === 'CRIAR_E_PROXIMO');
        }
      },
      atualizar: {
        urlFn: function urlFn() {
          return 'fundamentoLegal';
        },
        okFn: function okFn() {
          this.get('controllers.adm-outras-entidades').buscarFundamentosLegais();
        }
      },
      excluir: {
        urlFn: function urlFn(idParaConcatenarNaUrl) {
          return 'fundamentoLegal/' + idParaConcatenarNaUrl;
        },
        okFn: function okFn() {
          this.get('controllers.adm-outras-entidades').buscarFundamentosLegais();
        }
      }
    },

    amparosLegais: (function () {
      return this.get('controllers.adm-outras-entidades').amparosLegais;
    }).property('controllers.adm-outras-entidades'),

    amparosLegaisAtivos: (function () {
      return this.get('controllers.adm-outras-entidades').amparosLegais.filter(function (amp) {
        return amp.ativo === "S";
      });
    }).property('controllers.adm-outras-entidades'),

    tipos: (function () {
      return [Ember['default'].Object.create({ id: 'PENALIDADE', nome: "Penalidade" }), Ember['default'].Object.create({ id: 'AQUISICAO', nome: "Aquisição" }), Ember['default'].Object.create({ id: 'ADESAO', nome: "Adesão" }), Ember['default'].Object.create({ id: 'LICITACAO_PREGAO_ELETRONICO', nome: "Pregão Eletrônico" }), Ember['default'].Object.create({ id: 'LICITACAO_PREGAO_PRESENCIAL', nome: "Pregão Presencial" }), Ember['default'].Object.create({ id: 'LICITACAO_CONVITE', nome: "Convite" }), Ember['default'].Object.create({ id: 'LICITACAO_TOMADA_PRECOS', nome: "Tomada de preços" }), Ember['default'].Object.create({ id: 'LICITACAO_CONCORRENCIA', nome: "Concorrência Presencial" }), Ember['default'].Object.create({ id: 'LICITACAO_CONCORRENCIA_ELETRONICA', nome: "Concorrência Eletrônica" }), Ember['default'].Object.create({ id: 'PARTICIPACAO_REGISTRO_PRECOS', nome: "Participação em registro de preços" }), Ember['default'].Object.create({ id: 'INEXIGIBILIDADE', nome: "Inexigibilidade" }), Ember['default'].Object.create({ id: 'DISPENSA', nome: "Dispensa" }), Ember['default'].Object.create({ id: 'REGISTRO_PRECOS', nome: "Registro de preços" }), Ember['default'].Object.create({ id: 'SUPRIMENTO_FUNDOS', nome: "Suprimento de fundos" }), Ember['default'].Object.create({ id: 'CONCURSO', nome: "Concurso" }), Ember['default'].Object.create({ id: 'OUTROS', nome: "Outros" })];
    }).property(),

    getNomeLegivelTipo: function getNomeLegivelTipo(fundamento) {
      if (!fundamento.tipo) {
        return "";
      }
      return this.get('tipos').findBy('id', fundamento.tipo).get('nome');
    },

    getNomeLegivelAtivo: function getNomeLegivelAtivo(fundamento) {
      return fundamento.ativo == 'S' ? 'Sim' : 'Não';
    },

    getDescricaoAmparoLegal: function getDescricaoAmparoLegal(fundamento) {
      if (fundamento.pncpAmparoLegal) {
        var _amparo = this.get('controllers.adm-outras-entidades').amparosLegais.find(function (amp) {
          return amp.id === fundamento.pncpAmparoLegal.id;
        });
        if (_amparo) {
          return _amparo.descricao;
        }
      }
      return "";
    },

    fundamentoAtivoBoolean: Ember['default'].computed('model.ativo', {
      get: function get(key) {
        return this.get('model.ativo') == 'S';
      },
      set: function set(key, booleanValue) {
        if (booleanValue) {
          this.set('model.ativo', 'S');
        } else {
          this.set('model.ativo', 'N');
        }
      }
    }),

    actions: {
      adicionar: function adicionar() {
        this.set('adicionando', true);
        this.set('model', {});
        this.set('mostraModalCadastro', true);
      },
      acessar: function acessar(fundamento) {
        this.set('adicionando', false);
        this.set('model', Ember['default'].Object.create(Util['default'].clona(fundamento)));
        this.set('mostraModalCadastro', true);
      },
      cancelarModal: function cancelarModal() {
        this.set('adicionando', false);
        this.set('model', {});
        this.set('mostraModalCadastro', false);
      }
    }
  });

});
define('webapp/pods/aquisicao/contrato/garantia/controller', ['exports', 'ember', 'webapp/mixins/crud-mixin'], function (exports, Ember, CrudMixin) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend(CrudMixin['default'], {

    needs: ['aquisicao/contrato'],

    breadCrumbConfig: {
      fnNomeApresentacao: 'fnNomeApresentacao'
    },

    fnNomeApresentacao: function fnNomeApresentacao() {
      return 'Garantia ' + (this.model.version ? 'Nº ' + this.model.numeroApresentacao : '(Nova)');
    },

    crudConfig: {
      criar: {
        urlFn: function urlFn() {
          return 'contrato/' + this.get("controllers.aquisicao/contrato").model.id + '/garantia';
        },
        okFn: function okFn(opcao) {
          this.get('controllers.aquisicao/contrato').send("ler");
          if (opcao === 'CRIAR_E_PROXIMO') {
            //ação "borbulhará" para a rota
            this.send('refreshRota');
            return;
          }
          this.get('controllers.aquisicao/contrato').send("ler");
          this.transitionToRoute('aquisicao/contrato');
        }
      },
      atualizar: {
        okFn: function okFn() {
          this.get('controllers.aquisicao/contrato').send("ler");
          this.transitionToRoute('aquisicao/contrato');
        }
      },
      excluir: {
        okFn: function okFn() {
          this.get('controllers.aquisicao/contrato').send('ler');
          this.transitionToRoute('aquisicao/contrato');
        }
      }
    },

    getNomeLegivelModalidade: function getNomeLegivelModalidade(modalidade) {
      var mod = this.modalidades.find(function (cadamodalidade) {
        return modalidade.modalidade === cadamodalidade.id;
      });
      return mod.nome;
    },

    modalidades: [{ id: "FIANCA", nome: "Fiança Bancária" }, { id: "CAUCAO", nome: "Caução" }, { id: "SEGURO", nome: "Seguro Garantia" }]

  });

});
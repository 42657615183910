define('webapp/pods/transparencia/documento/route', ['exports', 'ember', 'webapp/utils/util', 'webapp/mixins/suprimir-templates-superiores-mixin'], function (exports, Ember, Util, SuprimirTemplatesSuperioresMixin) {

      'use strict';

      exports['default'] = Ember['default'].Route.extend(SuprimirTemplatesSuperioresMixin['default'], {

            setupController: function setupController(controller, model) {
                  controller.set('model', Util['default'].clona(model));

                  /*this.get('comunicador').leParaPropriedade(controller, 'programaTrabalho', 'programasDeTrabalho',
                        "Não foi possível obter os programas de trabalho.");
                   this.get('comunicador').leParaPropriedade(controller, 'unidadeOrcamentaria/selecionavel/unidadeOrganizacionalCorrespondente', 'unidadesOrganizacionais',
                        "Não foi possível obter as unidades organizacionais.");*/
            },

            actions: {
                  willTransition: function willTransition(transition) {
                        this._preparaParametrosTransicaoRotaPesquisa(transition);
                  }
            },

            //Se a transição for para a rota de pesquisa, envia um parâmetro indicando que é
            //necessário manter os dados da pesquisa anterior na tela
            _preparaParametrosTransicaoRotaPesquisa: function _preparaParametrosTransicaoRotaPesquisa(transition) {
                  if (transition.targetName !== 'transparencia/documento/listagem') {
                        return true;
                  }

                  var parametros = transition.queryParams ? Object.keys(transition.queryParams) : [];
                  if (parametros.includes('manterDadosPesquisaAnterior')) {
                        return true;
                  }

                  transition.abort();
                  this.transitionTo('transparencia/documento/listagem', { queryParams: { manterDadosPesquisaAnterior: 'true' } });
            }

      });

});
define('webapp/templates/components/tag-endereco', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        isHTMLBars: true,
        revision: "Ember@1.12.1",
        blockParams: 0,
        cachedFragment: null,
        hasRendered: false,
        build: function build(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        render: function render(context, env, contextualElement) {
          var dom = env.dom;
          var hooks = env.hooks, get = hooks.get, inline = hooks.inline;
          dom.detectNamespace(contextualElement);
          var fragment;
          if (env.useFragmentCache && dom.canClone) {
            if (this.cachedFragment === null) {
              fragment = this.build(dom);
              if (this.hasRendered) {
                this.cachedFragment = fragment;
              } else {
                this.hasRendered = true;
              }
            }
            if (this.cachedFragment) {
              fragment = dom.cloneNode(this.cachedFragment, true);
            }
          } else {
            fragment = this.build(dom);
          }
          var morph0 = dom.createMorphAt(fragment,1,1,contextualElement);
          inline(env, morph0, context, "botao-geral", [], {"acao": "buscarCEP", "labelAcessivel": get(env, context, "labelAcessivelBotaoPesquisar"), "classe": "btn btn-default input-sm", "classesCSSAdicionais": "botaoExtremidadeCampo", "iconeUiIcon": "search", "desabilitar": get(env, context, "desabilitarBotaoCEP")});
          return fragment;
        }
      };
    }());
    var child1 = (function() {
      return {
        isHTMLBars: true,
        revision: "Ember@1.12.1",
        blockParams: 0,
        cachedFragment: null,
        hasRendered: false,
        build: function build(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        render: function render(context, env, contextualElement) {
          var dom = env.dom;
          var hooks = env.hooks, get = hooks.get, inline = hooks.inline;
          dom.detectNamespace(contextualElement);
          var fragment;
          if (env.useFragmentCache && dom.canClone) {
            if (this.cachedFragment === null) {
              fragment = this.build(dom);
              if (this.hasRendered) {
                this.cachedFragment = fragment;
              } else {
                this.hasRendered = true;
              }
            }
            if (this.cachedFragment) {
              fragment = dom.cloneNode(this.cachedFragment, true);
            }
          } else {
            fragment = this.build(dom);
          }
          var morph0 = dom.createMorphAt(fragment,1,1,contextualElement);
          inline(env, morph0, context, "tag-input", [], {"texto": get(env, context, "labelLogradouro"), "valor": get(env, context, "endereco.logradouro"), "obrigatorio": get(env, context, "obrigatorio"), "somenteLeitura": get(env, context, "somenteLeitura"), "labelAcessivel": get(env, context, "labelAcessivelLogradouro"), "aria-labelledby": "Logradouro", "id": get(env, context, "idTagLogradouro")});
          return fragment;
        }
      };
    }());
    var child2 = (function() {
      return {
        isHTMLBars: true,
        revision: "Ember@1.12.1",
        blockParams: 0,
        cachedFragment: null,
        hasRendered: false,
        build: function build(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        render: function render(context, env, contextualElement) {
          var dom = env.dom;
          var hooks = env.hooks, get = hooks.get, inline = hooks.inline;
          dom.detectNamespace(contextualElement);
          var fragment;
          if (env.useFragmentCache && dom.canClone) {
            if (this.cachedFragment === null) {
              fragment = this.build(dom);
              if (this.hasRendered) {
                this.cachedFragment = fragment;
              } else {
                this.hasRendered = true;
              }
            }
            if (this.cachedFragment) {
              fragment = dom.cloneNode(this.cachedFragment, true);
            }
          } else {
            fragment = this.build(dom);
          }
          var morph0 = dom.createMorphAt(fragment,1,1,contextualElement);
          inline(env, morph0, context, "tag-input", [], {"texto": "Logradouro", "valor": get(env, context, "endereco.logradouro"), "obrigatorio": get(env, context, "obrigatorio"), "somenteLeitura": get(env, context, "somenteLeitura"), "labelAcessivel": get(env, context, "labelAcessivelLogradouro"), "aria-labelledby": "Logradouro", "id": get(env, context, "idTagLogradouro")});
          return fragment;
        }
      };
    }());
    return {
      isHTMLBars: true,
      revision: "Ember@1.12.1",
      blockParams: 0,
      cachedFragment: null,
      hasRendered: false,
      build: function build(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createTextNode("          \n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      render: function render(context, env, contextualElement) {
        var dom = env.dom;
        var hooks = env.hooks, get = hooks.get, block = hooks.block, inline = hooks.inline;
        dom.detectNamespace(contextualElement);
        var fragment;
        if (env.useFragmentCache && dom.canClone) {
          if (this.cachedFragment === null) {
            fragment = this.build(dom);
            if (this.hasRendered) {
              this.cachedFragment = fragment;
            } else {
              this.hasRendered = true;
            }
          }
          if (this.cachedFragment) {
            fragment = dom.cloneNode(this.cachedFragment, true);
          }
        } else {
          fragment = this.build(dom);
        }
        var morph0 = dom.createMorphAt(fragment,1,1,contextualElement);
        var morph1 = dom.createMorphAt(fragment,3,3,contextualElement);
        var morph2 = dom.createMorphAt(fragment,5,5,contextualElement);
        var morph3 = dom.createMorphAt(fragment,7,7,contextualElement);
        var morph4 = dom.createMorphAt(fragment,9,9,contextualElement);
        var morph5 = dom.createMorphAt(fragment,11,11,contextualElement);
        block(env, morph0, context, "tag-input", [], {"texto": "CEP", "mascara": "cep", "labelAcessivel": get(env, context, "labelAcessivelCEP"), "comentario": get(env, context, "instrucoesAdicionaisCampo"), "valor": get(env, context, "endereco.cep"), "obrigatorio": get(env, context, "obrigatorio"), "somenteLeitura": get(env, context, "somenteLeitura"), "possuiBotoes": true, "tamanho": 6, "id": get(env, context, "idTagCep")}, child0, null);
        inline(env, morph1, context, "tag-select", [], {"opcaoLabel": "nome", "conteudo": get(env, context, "this.paises"), "valor": get(env, context, "endereco.pais"), "textopadrao": "Selecione o país", "permiteLimpar": true, "obrigatorio": get(env, context, "obrigatorio"), "somenteLeitura": get(env, context, "somenteLeitura"), "id": get(env, context, "idSelectPais"), "tamanho": 6, "texto": "País", "labelAcessivel": get(env, context, "labelAcessivelPais")});
        inline(env, morph2, context, "tag-select", [], {"opcaoLabel": "nome", "conteudo": get(env, context, "this.ufs"), "valor": get(env, context, "endereco.uf"), "textopadrao": "Selecione a UF", "permiteLimpar": true, "obrigatorio": get(env, context, "campoObrigatorioUF"), "somenteLeitura": get(env, context, "somenteLeitura"), "id": get(env, context, "idSelectUF"), "tamanho": 6, "texto": "UF", "labelAcessivel": get(env, context, "labelAcessivelUF")});
        inline(env, morph3, context, "tag-select", [], {"opcaoLabel": "nome", "conteudo": get(env, context, "this.municipios"), "valor": get(env, context, "endereco.municipio"), "textopadrao": "Selecione o Município", "permiteLimpar": true, "obrigatorio": get(env, context, "campoObrigatorioMunicipio"), "somenteLeitura": get(env, context, "somenteLeitura"), "id": get(env, context, "idSelectMunicipio"), "tamanho": 6, "texto": "Município", "labelAcessivel": get(env, context, "labelAcessivelMunicipio")});
        block(env, morph4, context, "if", [get(env, context, "labelLogradouro")], {}, child1, child2);
        inline(env, morph5, context, "tag-input", [], {"texto": "Bairro", "valor": get(env, context, "endereco.bairro"), "comprimentoMaximo": "25", "obrigatorio": get(env, context, "obrigatorio"), "somenteLeitura": get(env, context, "somenteLeitura"), "labelAcessivel": get(env, context, "labelAcessivelBairro"), "id": get(env, context, "idTagBairro")});
        return fragment;
      }
    };
  }()));

});
define('webapp/pods/pncp/listagem/controller', ['exports', 'ember', 'webapp/mixins/crud-mixin', 'webapp/defs', 'webapp/utils/util'], function (exports, Ember, CrudMixin, Defs, Util) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend(CrudMixin['default'], {

    mostraModalErros: false,
    naoEnviados: null,
    filtro: "",
    arrayRadioButtons: [],
    arrayTitulosDaLista: [],

    breadCrumbConfig: {
      nomeApresentacao: 'PNCP'
    },

    inicializacao: (function () {
      this.arrayRadioButtons = [{ texto: "Não Enviados", valor: Defs['default'].arrayFiltrosPNCP[0] }, { texto: "Rejeitados", valor: Defs['default'].arrayFiltrosPNCP[1] }, { texto: "Todos", valor: Defs['default'].arrayFiltrosPNCP[2] }];

      this.arrayTitulosDaLista = [{ filtro: Defs['default'].arrayFiltrosPNCP[0], titulo: "Registros ainda Não Enviados" }, { filtro: Defs['default'].arrayFiltrosPNCP[1], titulo: "Registros Rejeitados" }, { filtro: Defs['default'].arrayFiltrosPNCP[2], titulo: "Registros a Enviar ao PNCP" }];

      this.filtro = this.arrayRadioButtons[2].valor;
    }).on('init'),

    tituloDaLista: (function () {
      var _this = this;

      return this.arrayTitulosDaLista.find(function (tituloDaLista) {
        return tituloDaLista.filtro == _this.filtro;
      }).titulo;
    }).property('filtro'),

    buscarDadosAEnviarAoPNCP: function buscarDadosAEnviarAoPNCP() {
      this.get('comunicador').leParaPropriedade(this, 'pncp', 'naoEnviados', 'Não foi possível obter os registros de envio ao PNCP existentes', true);
    },

    tituloJanelaModal: (function () {
      return "Erros de Validação e de Envio ao PNCP: " + this.get("model.descricaoDoTipoDaEntidade");
    }).property('model.descricaoDoTipoDaEntidade'),

    descricaoDaParteDaEntidade: (function () {
      return this.get('model.descricaoDaParteDaEntidade');
    }).property('model.descricaoDaParteDaEntidade'),

    parteEntidadeId: (function () {
      return this.get('model.parteEntidadeId');
    }).property('model.parteEntidadeId'),

    descricaoDaOperacao: (function () {
      return this.getDescricaoDaOperacao(this.get('model'));
    }).property('model.descricaoDaOperacao'),

    dataHoraDoErro: (function () {
      return this.getDataHoraDoErro(this.get('model'));
    }).property('model.errosEnvio'),

    mensagemDoErro: (function () {
      return this.getMensagemDoErro(this.get('model'));
    }).property('model.errosEnvio'),

    getOrdemDeExibicao: function getOrdemDeExibicao(registroDeEnvio) {
      return registroDeEnvio.id;
    },

    getNumeroDaEntidade: function getNumeroDaEntidade(registroDeEnvio) {
      return registroDeEnvio.numeroDaEntidade;
    },

    getDescricaoDaParteDaEntidade: function getDescricaoDaParteDaEntidade(registroDeEnvio) {
      return registroDeEnvio.descricaoDaParteDaEntidade;
    },

    getDescricaoDaOperacao: function getDescricaoDaOperacao(registroDeEnvio) {
      return registroDeEnvio.descricaoDaOperacao;
    },

    getDataHoraDoErro: function getDataHoraDoErro(registroDeEnvio) {
      if (registroDeEnvio.errosEnvio != null) {
        return registroDeEnvio.errosEnvio.dataHoraTentativa;
      }
    },

    getMensagemDoErro: function getMensagemDoErro(registroDeEnvio) {
      if (registroDeEnvio.errosEnvio != null) {
        return registroDeEnvio.errosEnvio.mensagensDeValidacao;
      }
    },

    // Link para o documento
    getLink: function getLink(registroDeEnvio) {
      return Defs['default'].enderecoCliente + "#/aquisicao/" + registroDeEnvio.entidadeId;
    },

    naoEnviadosFiltrados: (function () {
      if (this.filtro == "NaoEnviados") {
        return this.naoEnviados.filter(function (registroDeEnvio) {
          return registroDeEnvio.errosEnvio.dataHoraTentativa == null;
        });
      } else if (this.filtro == "Rejeitados") {
        return this.naoEnviados.filter(function (registroDeEnvio) {
          return registroDeEnvio.errosEnvio.dataHoraTentativa != null;
        });
      }
      return this.naoEnviados;
    }).property('naoEnviados', 'filtro'),

    actions: {
      listar: function listar() {
        this.buscarDadosAEnviarAoPNCP();
      },
      acessar: function acessar(envio) {
        this.set('model', Ember['default'].Object.create(Util['default'].clona(envio)));
        this.set('mostraModalErros', true);
      },
      cancelarModal: function cancelarModal() {
        this.set('model', {});
        this.set('mostraModalErros', false);
      }
    }
  });

});
define('webapp/mixins/campos-excludentes-mixin', ['exports', 'ember'], function (exports, Ember) {

	'use strict';

	//adiciona ao controller a capacidade de desabilitar e limpar campos do formulário baseado nas opções de
	//radio button

	exports['default'] = Ember['default'].Mixin.create({

		/*
	  *** DOCUMENTAÇÃO ***
	 	 - VARIÁVEL DE CONFIGURAÇÃO -
	  Responsável pela
	 	 camposExcludentesConfig: {
	  	 variavelComOpcoes: '<nome-da-variavel-que-controla-o-radio-button>',
	 	 valoresParaCampos: {
	 		 '<tipo-do-radio-1>': '<variavel-relacionada-ao-tipo-1>',
	 		 '<tipo-do-radio-2>': '<variavel-relacionada-ao-tipo-2>',
	      ...
	 		 '<tipo-do-radio-N>': '<variavel-relacionada-ao-tipo-N>'
	 	 }
	  }
	 	 Nota: se não houver uma variável relacionada pode-se usar 'null'
	 	 - PROPRIEDADES DE CONTROLE DE 'DISABLED' DOS CAMPOS EXCLUDENTES -
	  O mixin criará, na inicialização, as propriedades que vão controlar o "disable" dos campos
	  associados a cada tipo. O nome da propriedade será criado automaticamente a partir dos nomes da
	  variavelComOpcoes e cada um dos valoresParaCampos. O formato é:
	  <nome-da-variavel-que-controla-o-radio-button> + "Desabilitada" + <tipo-do-radio-[1...n]>
	 	 No exemplo abaixo as propriedades se chamarão:
	  "vigenciaTermoFinalDesabilitadaDATA" e "vigenciaTermoFinalDesabilitadaTEXTO".
	 
	 *** EXEMPLO ***
	 - CONTROLER -
	 	 camposExcludentesConfig: {
	 	 variavelComOpcoes: 'vigenciaTermoFinal',
	 	 valoresParaCampos: {
	 		 'DATA': 'model.novaDataVigencia',
	 		 'TEXTO':'model.termoFinalVigencia'
	 	 }
	  }
	  - TEMPLATE -
	   {{radio-button value="DATA" groupValue=vigenciaTermoFinal
	   id="radio||termo-inicial-data|vigencia|aditivo-alterar"}}
	    {{date-input texto="Data" valor=model.novaDataVigencia
	  disabled=vigenciaTermoFinalDesabilitadaDATA
	   id="tag||nova-data|vigencia|aditivo-alterar"}}
	    {{radio-button value="TEXTO" groupValue=vigenciaTermoFinal
	   id="radio||termo-inicial-texto|vigencia|aditivo-alterar"}}
	    {{tag-input texto="Texto" valor=model.termoFinalVigencia
	  disabled=vigenciaTermoFinalDesabilitadaTEXTO
	   id="tag||termo-final|vigencia|aditivo-alterar"}}
	  *** EXEMPLO ***
	 */

		inicializaOpcao: (function () {
			//Usa o último como padrão
			this.set(this.camposExcludentesConfig.variavelComOpcoes, valor);

			for (var valor in this.camposExcludentesConfig.valoresParaCampos) {
				if (this.get(this.camposExcludentesConfig.valoresParaCampos[valor])) {
					this.set(this.camposExcludentesConfig.variavelComOpcoes, valor);
					//return;
				}
			}

			// *** COMO ERA ANTES DA GENERALIZAÇÃO ***
			//this.set('vigenciaTermoFinal',
			//				 (this.get('model.termoFinalVigencia') === null)? "DATA" : "TEXTO");
		}).on('init'),

		// criaPropriedadeTesteDesabilitadaParaCadaValor: function() {
		// 	var self = this;
		// 	for (var valor in this.camposExcludentesConfig.valoresParaCampos) {
		// 		((valor) => {
		// 			this[this.camposExcludentesConfig.variavelComOpcoes + "Desabilitada" + valor ] =
		// 			function() {
		// 				//Nota: chamado aqui porque não foi possível adicionar por observer
		// 				self._limpaCamposNaoSelecionados();
		// 				return self[self.camposExcludentesConfig.variavelComOpcoes] !== valor;
		// 			}.property(this.camposExcludentesConfig.variavelComOpcoes);
		// 		})(valor);
		// 	}
		// }.on('init'),

		criaPropriedadeTesteDesabilitadaParaCadaValor: (function () {
			for (var valor in this.camposExcludentesConfig.valoresParaCampos) {
				this._criaPropriedadeTesteDesabilitadaParaUmValor(valor);
			}
		}).on('init'),

		_criaPropriedadeTesteDesabilitadaParaUmValor: function _criaPropriedadeTesteDesabilitadaParaUmValor(valor) {
			var self = this;
			this[this.camposExcludentesConfig.variavelComOpcoes + "Desabilitada" + valor] = (function () {
				//Nota: chamado aqui porque não foi possível adicionar por observer
				self._limpaCamposNaoSelecionados();
				return self[self.camposExcludentesConfig.variavelComOpcoes] !== valor;
			}).property(this.camposExcludentesConfig.variavelComOpcoes);
		},

		_limpaCamposNaoSelecionados: function _limpaCamposNaoSelecionados() {
			for (var valor in this.camposExcludentesConfig.valoresParaCampos) {
				if (this.get(this.camposExcludentesConfig.variavelComOpcoes) !== valor) {
					this.set(this.camposExcludentesConfig.valoresParaCampos[valor], null);
				}
			}
		}

		// *** COMO ERA ANTES DA GENERALIZAÇÃO ***
		//vigenciaTermoFinalDesabilitadaDATA: function() {
		//	return this.vigenciaTermoFinal	!== "DATA";
		//}.property("vigenciaTermoFinal"),

		//vigenciaTermoFinalDesabilitadaTEXTO: function() {
		//	return this.vigenciaTermoFinal	!== "TEXTO";
		//}.property("vigenciaTermoFinal"),

		//novaDataVigenciaLimpa: function() {
		//	if(this.vigenciaTermoFinal	!== "DATA") {
		//		this.set('model.novaDataVigencia',null);
		//	}
		//}.observes("vigenciaTermoFinal"),

		//termoFinalVigenciaLimpa: function() {
		//	if(this.vigenciaTermoFinal	!== "TEXTO") {
		//		this.set('model.termoFinalVigencia',null);
		//	}
		//}.observes("vigenciaTermoFinal")

	});

});
define('webapp/pods/aquisicao/compra/model', ['exports', 'ember', 'webapp/pods/fornecedor/model'], function (exports, Ember, Fornecedor) {

	'use strict';

	exports['default'] = Ember['default'].Object.extend({
		id: null,
		numero: 0,
		dataEfetivacao: '',
		itens: [],
		requisicoes: [],
		empenhos: [],
		idAta: 0,
		numeroApresentacaoAta: '',
		idContrato: 0,
		fornecedor: Fornecedor['default'].create({}),
		destinatarioPagamento: Fornecedor['default'].create({}),
		valorTotal: '',
		valorOriginal: '',
		valorAcrescido: '',
		valorSuprimido: '',
		percentualAcrescido: '',
		percentualSuprimido: '',
		numeroApresentacao: '',

		inicializar: (function () {
			this.set('fornecedor', Fornecedor['default'].create({}));
			this.set('destinatarioPagamento', Fornecedor['default'].create({}));
			this.set('itens', []);
			this.set('requisicoes', []);
			this.set('empenhos', []);
		}).on('init'),

		findUrlFn: function findUrlFn(id) {
			return "aquisicao/compra/" + id.toString();
		}
	});

});
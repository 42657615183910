define('webapp/mixins/ajusta-tamanho-input-mixin', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  //adiciona ao componente de input um mecanismo para que se possa definir sua largura, a largura de seu label e
  //do campo que recebe o input

  /*
    Utilizando esse mixin, o componente poderá receber 2 novos parametros

    tamanho: define a largura do componente em termos do bootstrap - um número X
            de 1 a 12 utilizado para montar a classe CSS "col-md-X". O default é 12
            
            Obs.: Se for necessário montar outras classes como "col-[xs/sm/lg]-X" basta 
            acrescentar o nome da(s) classe(s) desejada(s) como atributo(s) e o valor
            true. Ex.:
                 colSm=true colXs=true

    semPadding: caso seja true, os valores de padding do compoente serão todos zerados
                (aplicando-se a classe paddingZero), de forma que não haverá espaçamento
                entre a borda do conteúdo do componente (ex. botão, campo) e a borda do
                da div mais externa do próprio componente (aquela que contém o conteúdo
                do componente). O default é false.

  */

  exports['default'] = Ember['default'].Mixin.create({
    classNameBindings: ['classText'],
    classText: 'margem-inferior',

    tamanho: 12, //tamanho total do div mais externo do componente

    ajustaTamanho: (function () {
      if (!this.get('tamanho')) {
        return;
      }

      Ember['default'].assert('Valor não numérico atribuído ao parametro tamanho do componente ' + this.id, !isNaN(this.get('tamanho')));
      Ember['default'].assert('Parâmetro "tamanho" do componente ' + this.id + ' deve estar entre 1 e 12', this.get('tamanho') >= 1 && this.get('tamanho') <= 12);

      this.$().addClass('col-md-' + this.get('tamanho'));
      if (this.get('colXs')) {
        this.$().addClass('col-xs-' + this.get('tamanho'));
      }
      if (this.get('colSm')) {
        this.$().addClass('col-sm-' + this.get('tamanho'));
      }
      if (this.get('colLg')) {
        this.$().addClass('col-lg-' + this.get('tamanho'));
      }

      if (this.get('semPadding')) {
        this.$().addClass('paddingZero');
      }
    }).on('willInsertElement')

  });

});
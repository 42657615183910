define('webapp/pods/aquisicao/contrato/model', ['exports', 'ember', 'webapp/models/unidade-organizacional', 'webapp/pods/aquisicao/contrato/identificacao/descricao/indice-reajuste/model', 'webapp/models/servidor', 'webapp/pods/aquisicao/contrato/rescisao/model', 'webapp/pods/aquisicao/contrato/siasg/model', 'webapp/pods/aquisicao/contrato/garantia/model', 'webapp/pods/aquisicao/contrato/item/model', 'webapp/pods/aquisicao/contrato/aditivo/model', 'webapp/pods/aquisicao/contrato/modalidade/model', 'webapp/pods/aquisicao/contrato/evento/model', 'webapp/pods/aquisicao/contrato/andamento/model', 'webapp/pods/aquisicao/compra/model', 'webapp/pods/fornecedor/model', 'webapp/pods/aquisicao/contrato/efeito-financeiro/model', 'webapp/models/gestor', 'webapp/models/fiscal'], function (exports, Ember, UnidadeOrganizacional, IndiceReajuste, Servidor, Rescisao, Siasg, Garantia, ContratoItem, Aditivo, Modalidade, Evento, Andamento, Compra, Fornecedor, EfeitoFinanceiro, Gestor, Fiscal) {

  'use strict';

  exports['default'] = Ember['default'].Object.extend({
    id: null,
    garantias: [],
    link: '',

    dataInicioVigencia: '',
    dataFimVigencia: '',
    dataInicioExecucao: '',
    dataFimExecucao: '',
    dataAssinatura: '',
    dataPublicacao: '',

    contratoModalidade: Modalidade['default'].create({}),

    fiscais: [],
    gestores: [],

    numeroFisico: '',
    unidadeRequisitante: UnidadeOrganizacional['default'].create({}),
    temReajuste: false, // campo por enquanto só enviado ao back-end para este saber se deve obrigar o usuário a informar o indice de reajuste e o mês de reajuste, mas não para ser gravado no banco
    indiceReajuste: IndiceReajuste['default'].create({}),
    mesReajuste: null,

    objeto: '',
    observacao: '',

    valorInicialMensal: '',
    valorInicialUnico: '',
    valorInicialTotal: '',
    valorInicialAtualizadoMensal: '',
    valorInicialAtualizadoUnico: '',
    valorInicialAtualizadoTotal: '',
    valorAtualMensal: '',
    valorAtualUnico: '',
    valorAtualTotal: '',

    itens: [],
    aditivos: [],
    apostilas: [],

    rescisao: Rescisao['default'].create({}),
    siasg: Siasg['default'].create({}),

    andamentos: [],
    eventos: [],

    numeroApresentacao: '',
    percentualSuprimido: '',
    valorSuprimido: '',
    percentualAcrescido: '',
    valorAcrescido: '',
    percentualExigidoGarantia: '',
    valorExigidoGarantia: '',
    valorTotalGarantido: '',

    compras: [],

    dataInicioVigenciaAtualizada: '',
    dataFimVigenciaAtualizada: '',
    dataInicioExecucaoAtualizada: '',
    dataFimExecucaoAtualizada: '',
    numeroProcessoAdministrativo: '',

    fornecedoresAtuais: [],
    idAquisicao: 0,
    fiscaisAtuais: [],
    gestoresAtuais: [],
    efeitosFinanceiros: [],

    // Campo extra (só vale para a exportação da planilha): não retorna no rest do Contrato, mas sim com uma chamada extra ao rest de Aquisicao
    numeroAquisicao: '',

    inicializar: (function () {
      this.set('contratoModalidade', Modalidade['default'].create({}));
      this.set('indiceReajuste', IndiceReajuste['default'].create({}));
      this.set('fiscais', []);
      this.set('gestores', []);
      this.set('unidadeRequisitante', UnidadeOrganizacional['default'].create({}));
      this.set('rescisao', Rescisao['default'].create({}));
      this.set('siasg', Siasg['default'].create({}));
      this.set('fiscaisAtuais', []);
      this.set('gestoresAtuais', []);

      this.set('garantias', []);
      this.set('itens', []);
      this.set('aditivos', []);
      this.set('apostilas', []);
      this.set('andamentos', []);
      this.set('eventos', []);
      this.set('compras', []);
      this.set('fornecedoresAtuais', []);
      this.set('efeitosFinanceiros', []);
    }).on('init'),

    objDoArrayDoCampo: function objDoArrayDoCampo(metadado) {

      if (metadado.key === 'garantias') {
        return Garantia['default'].create({});
      } else if (metadado.key === 'itens') {
        return ContratoItem['default'].create({});
      } else if (metadado.key === 'fiscais') {
        return Fiscal['default'].create({});
      } else if (metadado.key === 'gestores') {
        return Gestor['default'].create({});
      } else if (metadado.key === 'fiscaisAtuais') {
        return Fiscal['default'].create({});
      } else if (metadado.key === 'gestoresAtuais') {
        return Gestor['default'].create({});
      } else if (metadado.key === 'aditivos') {
        return Aditivo['default'].create({});
      } else if (metadado.key === 'apostilas') {
        return Aditivo['default'].create({});
      } else if (metadado.key === 'andamentos') {
        return Andamento['default'].create({});
      } else if (metadado.key === 'eventos') {
        return Evento['default'].create({});
      } else if (metadado.key === 'compras') {
        return Compra['default'].create({});
      } else if (metadado.key === 'fornecedoresAtuais') {
        return Fornecedor['default'].create({});
      } else if (metadado.key === 'efeitosFinanceiros') {
        return EfeitoFinanceiro['default'].create({});
      } else {
        return metadado.valor;
      }
    },

    findUrlFn: function findUrlFn(id) {
      return 'aquisicao/contrato/' + id.toString();
    }

  });

});
define('webapp/pods/aquisicao/contrato/siasg/model', ['exports', 'ember'], function (exports, Ember) {

	'use strict';

	exports['default'] = Ember['default'].Object.extend({
		id: null,
		usuario: '',
		dataEnvio: ''
	});

});
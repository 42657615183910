define('webapp/pods/requisicao/item/criar/route', ['exports', 'ember', 'webapp/pods/requisicao/item/model', 'webapp/mixins/suprimir-templates-superiores-mixin'], function (exports, Ember, Item, SuprimirTemplatesSuperioresMixin) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(SuprimirTemplatesSuperioresMixin['default'], {
    controllerName: 'requisicao/item',
    setupController: function setupController(controller) {

      // Inicializa o item com alguns valores default específicos por default
      controller.set('model', Item['default'].create({ tipoValorItem: 'EXATO',
        naturezaItem: 'MATERIAL' }));

      this.get('comunicador').servico('get', 'classificacaoItem').then(function (resultado) {
        controller.set('classificacoesFinanceiras', resultado);
      });

      this.get('comunicador').servico('get', 'unidadeMedida').then(function (resultado) {
        controller.set('unidadesDeMedida', resultado);
      });

      // Configura informações da tela
      this.get('comunicador').servico('get', 'parametroExibicao').then(function (resultado) {
        controller.set('habilitaCodigoCatalogo', Util.converteParametrosDeExibicao(resultado).HABILITA_CODIGO_CATALOGO === "S");
      });
    },

    model: function model() {
      return Item['default'].create({ tipoValorItem: 'EXATO', naturezaItem: 'MATERIAL' });
    }

  });

});
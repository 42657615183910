define('webapp/pods/aquisicao/contrato/evento/model', ['exports', 'ember'], function (exports, Ember) {

	'use strict';

	exports['default'] = Ember['default'].Object.extend({
		id: null,
		tipo: '',
		data: '',
		numero: null,
		dataCriacao: '',
		observacao: '',
		numeroApresentacao: '',

		findUrlFn: function findUrlFn(id) {
			return 'contrato/evento/' + id.toString();
		}
	});

});
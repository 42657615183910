define('webapp/components/tag-inputmask-radio', ['exports', 'ember', 'webapp/mixins/ajusta-id-interno-mixin', 'webapp/mixins/ajusta-tamanho-input-mixin', 'webapp/utils/mascara-input', 'webapp/utils/id'], function (exports, Ember, AjustaIdInternoMixin, AjustaTamanhoInputMixin, MascaraInput, UtilID) {

	'use strict';

	exports['default'] = Ember['default'].Component.extend(AjustaIdInternoMixin['default'], AjustaTamanhoInputMixin['default'], {
		tipoInterno: 'campo',
		componenteMoeda: false,
		habilitado: true,

		insereAriaLabel: (function () {
			var botaoRadio = this.$('input')[0];
			var ariaLabelRadio = (this.labelAcessivel ? this.labelAcessivel : this.texto) + ' (botão rádio)';
			botaoRadio.setAttribute('aria-label', ariaLabelRadio);

			var campo = this.$('input')[1];
			var ariaLabelCampo = (this.labelAcessivel ? this.labelAcessivel : this.texto) + ' campo de texto ' + (this.obrigatorio ? 'obrigatório' : '');
			campo.setAttribute('aria-label', ariaLabelCampo);
		}).on('willInsertElement'),

		insereIdPermissoesBotaoRadio: (function () {
			var botaoRadio = this.$('input')[0];
			botaoRadio.setAttribute('nome', this.get('_idRadio'));
		}).on('willInsertElement'),

		_ajustaMascara: (function () {
			if (this.mascara === 'moeda') {
				this.set('componenteMoeda', true);
			} else {
				this.set('mascara', MascaraInput['default'].mascara(this.mascara)); //converte máscara
			}
		}).on('willInsertElement'),

		_idInput: (function () {
			return UtilID['default'].getIdModificado(this.id, 'tipo', 'text-input');
		}).property('id'),

		_idRadio: (function () {
			return UtilID['default'].getIdModificado(this.id, 'tipo', 'radio-button');
		}).property('id')

	});

});
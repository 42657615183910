define('webapp/helpers/comparador-variaveis', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    exports['default'] = Ember['default'].Handlebars.makeBoundHelper(function (operador, _ref) {
        var opcoes = _ref.hash;

        var v1 = opcoes.parametro1;
        var ct = opcoes.contexto;
        var operator = operador;
        var v2 = opcoes.parametro2;

        if (opcoes.caseInsensitive) {
            //handle case insensitive conditions if 4 param is passed.
            v1 = v1.toLowerCase();
            v2 = v2.toLowerCase();
        }

        if (ct != null) {
            // Se "opcoes.contexto" for definido, então "opcoes.parametro1" tem que estar no formato "[{campo: <nome do campo1>}, {campo: <nome do campo2>}, ...]"
            v1 = JSON.parse(v1);
            v1.forEach(function (e) {
                ct = ct[e.campo];
            }); // acessa a propriedade alvo deste contexto, que pode estar aninhada dentro de outra(s)
            v1 = ct;
        }

        if (v2 == "null") {
            if (operator == '==') {
                return v1 == null;
            } else {
                return v1 != null;
            }
        }

        switch (operator) {
            case '==':
                return v1 == v2;
            case '!=':
                return v1 != v2;
            case '===':
                return v1 === v2;
            case '<':
                return v1 < v2;
            case '<=':
                return v1 <= v2;
            case '>':
                return v1 > v2;
            case '>=':
                return v1 >= v2;
            case '&&':
                return !!(v1 && v2);
            case '||':
                return !!(v1 || v2);
            default:
                return false;
        }
    });

});
define('webapp/components/tag-upload', ['exports', 'ember', 'webapp/mixins/ajusta-id-interno-mixin', 'webapp/mixins/ajusta-tamanho-input-mixin', 'webapp/mixins/constroi-aria-label-mixin', 'webapp/utils/mascara-input', 'webapp/utils/id'], function (exports, Ember, AjustaIdInternoMixin, AjustaTamanhoInputMixin, ConstroiAriaLabelMixin, MascaraInput, UtilID) {

    'use strict';

    /*
    Exemplo de uso:
    {{tag-input
         texto=<texto do label>
         labelAcessivel=<texto para ser lido por leitores automáticos de interface
                      para acessibilidade por pessoas com deficiência visual.
                      Default: o valor do atributo "texto" e a indicação de se
                                        o campo é obrigatório e/ou somente leitura>
         valor=<valor associado ao input>
         mascara=<máscara de input a ser aplicada, ex. "mascaraCep">
         comprimentoMaximo=<uma string numérica, indicando o número máximo de caracteres
                                                que o input deve aceitar. Ex.: 10>
         tamanho=<largura do componente em termos do bootstrap - um número X
                de 1 a 12 utilizado para montar a classe CSS "col-md-X".
                O default é 12>
         somenteLeitura=<true ou false, sem aspas>
         desabilitado=<true | false (default), indicando se o componente estará desabilitado>
         obrigatorio=<true ou false, sem aspas, indicando se o campo deve apresentar um asterisco
                    ao lado do seu label. O default é false>
         autoFoco=<true | false (default), indicando se o input deve receber o foco assim que for
                              renderizado. Caso mais de um input que apareça na mesma tela tenha essa opção
                                marcada, o último que for renderizado receberá o foco>
        estiloFonteMouseEnter=Estilo da fonte. Utilizado para quando o campo tiver uma ação de clique (usado qdo. atributo acaoMouseClick estiver definido). 
                      Para manter o padrão sugerimos utilizar "underline", para que o valor do campo fique 
                      sublinhado como se fosse um link quando o cursor do mouse passa por cima dele.
    	corFonteMouseEnter=Cor da fonte. Utilizada para quando o campo tiver uma ação de clique (usado qdo. atributo acaoMouseClick estiver definido).  
                      Para manter o padrão sugerimos utilizar "#337ab7", para que o valor do campo fique 
                      da cor azul como se fosse um link quando o cursor do mouse passa por cima dele.
    	acaoMouseClick=Nome da ação. Para manter o padrão sugere-se utilizar o valor "acaoMouseClick"
    }}

    Todo tag-input com id    "tag_W_X_Y_Z"
    cria um componente de id "campo_W_X_Y_Z"

    O funcionamento de "tamanho", "tamanhoLabel" e "tamanhoCampo" é dependente do ajusta-tamanho-input-mixin.
    */

    exports['default'] = Ember['default'].Component.extend(AjustaIdInternoMixin['default'], AjustaTamanhoInputMixin['default'], ConstroiAriaLabelMixin['default'], {
        desabilitado: false,
        somenteLeitura: false,
        possuiBotoes: false,
        tipos_permitidos: '.pdf, .txt, .rtf, .doc, .docx, .xls, .xlsx, .odt, .ods, .sxw, .zip, .7z, .rar, .dwg, .dwt, .dxf, .dwf, .dwfx, .svg, .sldprt, .sldasm, .dgn, .ifc, .skp, .3ds, .dae, .obj, .rfa, .rte',

        insereAriaLabel: (function () {
            var campo = this.$('input')[0];
            campo.setAttribute('aria-label', this.get('_ariaLabel'));
        }).on('willInsertElement'),

        classesCSS: (function () {
            return "form-control input-sm";
        }).property(),

        focalizar: (function () {
            if (this.autoFoco) {
                this.$('input')[0].focus();
            }
        }).on('didInsertElement'),

        mouseEnter: function mouseEnter(event) {
            var campo = this.$('input')[0];
            if (this.acaoMouseClick && campo.value) {
                Ember['default'].$(campo).css("color", this.get('corFonteMouseEnter'));
                Ember['default'].$(campo).css("cursor", "pointer");
                Ember['default'].$(campo).css("text-decoration", this.get('estiloFonteMouseEnter'));
            }
        },

        mouseLeave: function mouseLeave(event) {
            var campo = this.$('input')[0];
            Ember['default'].$(campo).css("color", "");
            Ember['default'].$(campo).css("cursor", "");
            Ember['default'].$(campo).css("text-decoration", "");
        },

        _idLabel: (function () {
            if (!this.id) {
                return '';
            };

            var porcaoAdicionalIdComponente = UtilID['default'].getPorcaoOpcionalId(this.id);
            return porcaoAdicionalIdComponente ? UtilID['default'].prefixaSeccao('label', 'opcional', this.id) : UtilID['default'].incluiSeccaoOpcional(this.id, 'label');
        }).property('id'),

        _idInput: (function () {
            if (!this.id) {
                return '';
            };

            var porcaoAdicionalIdComponente = UtilID['default'].getPorcaoOpcionalId(this.id);
            return porcaoAdicionalIdComponente ? UtilID['default'].prefixaSeccao('input', 'opcional', this.id) : UtilID['default'].incluiSeccaoOpcional(this.id, 'input');
        }).property('id')

    });

});
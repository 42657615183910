define('webapp/pods/aquisicao/contrato/aditivo/alteracao-fiscal/controller', ['exports', 'ember', 'webapp/utils/util'], function (exports, Ember, Util) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({
    //Este controller deixa as ações "borbulharem" para o aditivo.

    needs: ['aquisicao/contrato', 'aquisicao/contrato/aditivo'],

    ehAditivoNovo: (function () {
      return !this.get('controllers.aquisicao/contrato/aditivo').model.version;
    }).property(),

    sugereFiscaisAtuais: (function () {
      if (this.get('ehAditivoNovo')) {
        return this.model.novosFiscais = Util['default'].clonaStringfyParse(this.get('controllers.aquisicao/contrato').model.fiscaisAtuais);
      }
    }).on('init')

  });

});
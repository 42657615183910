define('webapp/pods/publico/route', ['exports', 'ember', 'webapp/utils/util'], function (exports, Ember, Util) {

    'use strict';

    exports['default'] = Ember['default'].Route.extend({
        setupController: function setupController(controller) {
            // Configura informações da tela
            this.get('comunicador').servico('get', 'parametroExibicao').then(function (resultado) {
                controller.set('infoTela', Util['default'].converteParametrosDeExibicao(resultado).TRANSP_INFO_INICIAL);
                controller.set('exibeCabecalho', Util['default'].converteParametrosDeExibicao(resultado).TRANSP_EXIBE_CABECALHOS == "S");
                controller.set('urlLogo', Util['default'].converteParametrosDeExibicao(resultado).TRANSP_URL_LOGO);
                controller.set('msgAltLogo', Util['default'].converteParametrosDeExibicao(resultado).MSG_ALTERNATIVA_LOGO);
            });
        }
    });

});
define('webapp/pods/aquisicao/contrato/aditivo/model', ['exports', 'ember', 'webapp/pods/aquisicao/contrato/aditivo/tipo/model', 'webapp/pods/aquisicao/contrato/aditivo/alteracao-teor-clausula/model', 'webapp/pods/aquisicao/contrato/aditivo/repactuacao/model', 'webapp/pods/aquisicao/contrato/aditivo/revisao/model', 'webapp/pods/aquisicao/contrato/aditivo/acrescimo-ou-supressao/item/model', 'webapp/pods/aquisicao/contrato/aditivo/alteracao-tipo-societario/model', 'webapp/pods/aquisicao/contrato/aditivo/prorrogacao-vigencia/model', 'webapp/pods/aquisicao/contrato/aditivo/prorrogacao-execucao/model', 'webapp/pods/aquisicao/contrato/aditivo/alteracao-fiscal/model', 'webapp/pods/aquisicao/contrato/aditivo/alteracao-gestor/model', 'webapp/pods/aquisicao/contrato/aditivo/alteracao-sede-social/model', 'webapp/pods/aquisicao/contrato/aditivo/reajuste/model', 'webapp/utils/util'], function (exports, Ember, Tipo, AlteracaoTeorClausula, Repactuacao, Revisao, ItemAcrescimoSupressao, AlteracaoTipoSocietario, ProrrogacaoVigencia, ProrrogacaoExecucao, AlteracaoFiscal, AlteracaoGestor, AlteracaoSedeSocial, Reajuste, Util) {

	'use strict';

	exports['default'] = Ember['default'].Object.extend({

		id: null,
		tipos: [],
		tiposExibicao: '',
		alteracoesTeorClausula: [],
		repactuacoes: [],
		revisoes: [],
		alteracaoTipoSocietario: AlteracaoTipoSocietario['default'].create({}),
		prorrogacaoVigencia: ProrrogacaoVigencia['default'].create({}),
		prorrogacaoExecucao: ProrrogacaoExecucao['default'].create({}),
		alteracaoFiscal: AlteracaoFiscal['default'].create({}),
		alteracaoGestor: AlteracaoGestor['default'].create({}),
		alteracaoSedeSocial: AlteracaoSedeSocial['default'].create({}),
		reajuste: Reajuste['default'].create({}),
		numero: 0, //sempre vem do servidor
		apostila: '',
		percentualSupressao: '',
		valorSupressao: '',
		percentualAcrescimo: '',
		valorAcrescimo: '',
		itensAcrescimoSupressao: [],
		dataCriacao: '',
		numeroApresentacao: '',
		dataAssinatura: '',
		dataPublicacao: '',

		//tudo o que for objeto {} ou array [] deve ser inicializado aquisicao
		//caso contrário, ao criar um novo modelo, ele ficará com os valores de um
		//modelo criado anteriormente. (ex.: se, na criação de um aditivo forem
		//selecionados alguns tipos e, depois, se sair da criação e voltar, para
		//criar um novo aditivo, os tipos selecionados anteriormente aparecerão
		//selecionados, mesmo que na rota de criação se tente criar um aditivo vazio)
		inicializar: (function () {
			this.set('tipos', []);
			this.set('alteracoesTeorClausula', []);
			this.set('repactuacoes', []);
			this.set('revisoes', []);
			this.set('itensAcrescimoSupressao', []);

			this.set('alteracaoTipoSocietario', AlteracaoTipoSocietario['default'].create({}));
			this.set('prorrogacaoVigencia', ProrrogacaoVigencia['default'].create({}));
			this.set('prorrogacaoExecucao', ProrrogacaoExecucao['default'].create({}));
			this.set('alteracaoFiscal', AlteracaoFiscal['default'].create({}));
			this.set('alteracaoSedeSocial', AlteracaoSedeSocial['default'].create({}));
			this.set('reajuste', Reajuste['default'].create({}));
		}).on('init'),

		nomeDeCampoAmigavel: function nomeDeCampoAmigavel(campo) {
			if (campo === 'dataCriacao') {
				return 'Data de Criacao';
			} else {
				return Util['default'].separaECapitalizaPalavrasCamelCase(campo);
			}
		},

		objDoArrayDoCampo: function objDoArrayDoCampo(metadado) {
			if (metadado.key === 'tipos') {
				return Tipo['default'].create({});
			} else if (metadado.key === 'alteracoesTeorClausula') {
				return AlteracaoTeorClausula['default'].create({});
			} else if (metadado.key === 'repactuacoes') {
				return Repactuacao['default'].create({});
			} else if (metadado.key === 'revisoes') {
				return Revisao['default'].create({});
			} else if (metadado.key === 'itensAcrescimoSupressao') {
				return ItemAcrescimoSupressao['default'].create({});
			} else {
				return metadado.valor;
			}
		},

		findUrlFn: function findUrlFn(id, aditivoOuApostila) {
			return 'contrato/' + aditivoOuApostila.toLocaleLowerCase() + '/' + id.toString();
		}

	});

});
define('webapp/pods/pesquisa-contratos/constantes-pesquisa-contrato', ['exports'], function (exports) {

  'use strict';

  exports['default'] = {

    statusPossiveisVigenciaExecucao: [{ id: 'CONTRATO_ENCERRADO', nome: 'Encerrado' }, { id: 'CONTRATO_VIGENTE', nome: 'Vigente' }, { id: 'CONTRATO_EM_EXECUCAO', nome: 'Em Execução' }],

    statusPossiveisGarantiaMinima: [{ id: 'GARANTIA_MINIMA_EXIGIDA_CADASTRADA', nome: 'Cadastrada' }, { id: 'GARANTIA_MINIMA_EXIGIDA_SATISFEITA', nome: 'Satisfeita' }, { id: 'GARANTIA_MINIMA_EXIGIDA_NAO_SATISFEITA', nome: 'Não-Satisfeita' }]

  };

});
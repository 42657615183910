define('webapp/pods/fornecedor/dado-bancario/criar/route', ['exports', 'ember', 'webapp/pods/fornecedor/dado-bancario/model'], function (exports, Ember, DadoBancario) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({
    controllerName: 'fornecedor/dado-bancario',

    setupController: function setupController(controller) {
      controller.set('model', DadoBancario['default'].create({}));

      this.get('comunicador').leParaPropriedade(controller, 'banco', 'bancos', "Não foi possível obter a lista de bancos!");
    },

    model: function model() {
      return DadoBancario['default'].create();
    }
  });

});
define('webapp/pods/requisicao/item/orcamento/model', ['exports', 'ember', 'webapp/pods/fornecedor/model'], function (exports, Ember, Fornecedor) {

	'use strict';

	exports['default'] = Ember['default'].Object.extend({
		id: null,
		fornecedor: Fornecedor['default'].create({}),
		dataEmissao: '',
		dataValidade: '',
		valorUnitario: null,
		nomeContato: '',
		nroProtocolo: null,

		inicializar: (function () {
			//ISSO É NECESSÁRIO INICIALIZAR PORQUE, ESTRANHAMENTE, AO SE CRIAR UM
			//ORÇAMENTO DE ITEM DE REQUISIÇÃO, SE TENTAR SE CRIAR UM NOVO, A MODAL
			//DE CRIAÇÃO JÁ APARECERÁ COM O CAMPO FORNECEDOR PREENCHIDO, COM O
			//FORNECEDOR QUE FOI UTILIZADO NO ORÇAMENTO ANTERIORMENTE CRIADO
			this.set('fornecedor', Fornecedor['default'].create({}));
		}).on('init'),

		findUrlFn: function findUrlFn(id) {
			return 'itemRequisicao/orcamentoItem/' + id.toString();
		}
	});
	//

});
define('webapp/pods/aquisicao/item/controller', ['exports', 'ember', 'webapp/utils/util', 'webapp/mixins/crud-mixin', 'webapp/pods/aquisicao/constantes-aquisicao', 'npm:big.js'], function (exports, Ember, Util, CrudMixin, Constantes, Big) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend(CrudMixin['default'], {

    mostrarTemplate: true,

    needs: ['aquisicao'],

    breadCrumbConfig: {
      fnNomeApresentacao: 'fnNomeApresentacao'
    },

    habilitaCodigoCatalogo: false,

    inicializa: (function () {
      this.obrigaInformacaoOrcamentoSigiloso = this.get("controllers.aquisicao").obrigaInformacaoOrcamentoSigiloso;
    }).on('init'),

    fnNomeApresentacao: function fnNomeApresentacao() {
      return 'Item ' + (this.model.version ? 'Nº ' + this.model.numeroApresentacao : '(Novo)');
    },

    crudConfig: {
      criar: {
        urlFn: function urlFn() {
          return 'aquisicao/' + this.get("controllers.aquisicao").model.id + '/item';
        },
        okFn: function okFn(opcao) {
          this.get('controllers.aquisicao').send('ler');
          if (opcao === 'CRIAR_E_PROXIMO') {
            //ação "borbulhará" para a rota
            this.send('refreshRota');
            return;
          }
          this.transitionToRoute('aquisicao/item', this.model.id);
        }
      },
      atualizar: {
        urlFn: function urlFn() {
          return 'aquisicao/item';
        },
        okFn: function okFn() {
          this.get('controllers.aquisicao').send('ler');
        }
      }
    },

    motivosCriacaoItens: Constantes['default'].motivosCriacaoItens,

    permiteCriarCota: (function () {
      return this.model.motivoCriacao === 'ORIGINAL' && !this.temResultadoDefinido();
    }).property('model.motivoCriacao'),

    permiteExcluirCota: (function () {
      return (this.model.motivoCriacao === 'COTA' || this.model.motivoCriacao === 'PARTICIPACAO_EXCLUSIVA_PARA_ME_EPP') && !this.temResultadoDefinido() && !this.itemCotaPrincipalComResultadoDefinido();
    }).property('model.motivoCriacao'),

    permiteParticipacao: (function () {
      return this.get('aquisicaoTemRegistroDePreco') && (this.get('ehItemOriginal') || this.get('ehParticipacaoExclusivaParaMEEPP'));
    }).property('controllers.aquisicao.model.registroPreco', 'model.motivoCriacao'),

    aquisicaoTemRegistroDePreco: (function () {
      return this.get('controllers.aquisicao.model.registroPreco');
    }).property('controllers.aquisicao.model.registroPreco'),

    ehItemOriginal_OU_ehParticipacaoExclusivaParaMEEPP: (function () {
      return this.get('ehItemOriginal') || this.get('ehParticipacaoExclusivaParaMEEPP');
    }).property('model.motivoCriacao'),

    ehParticipacaoExclusivaParaMEEPP: (function () {
      return this.model.motivoCriacao === 'PARTICIPACAO_EXCLUSIVA_PARA_ME_EPP';
    }).property('model.motivoCriacao'),

    ehItemOriginal: (function () {
      return this.model.motivoCriacao === 'ORIGINAL';
    }).property('model.motivoCriacao'),

    ehItemAquisicaoExistente: (function () {
      return this.model.version;
    }).property('model.version'),

    valorTotalAdjudicacao: (function () {
      if (!this.model.valorUnitario || !this.model.quantidadeTotal) {
        return undefined;
      }

      var valorUnitario = parseFloat(Util['default'].stringNumericaBRparaUSA(this.model.valorUnitario));
      //return valorUnitario * this.model.quantidadeTotal;
      return new Big['default'](valorUnitario).times(new Big['default'](this.model.quantidadeTotal));
    }).property('this.model.valorUnitario', 'this.model.quantidadeTotal'),

    habilitarCatalogo: (function () {
      return this.get('habilitaCodigoCatalogo');
    }).property('habilitaCodigoCatalogo'),

    temResultadoDefinido: function temResultadoDefinido() {
      return this.itemTemResultadoDefinido(this.model);
    },

    itemTemResultadoDefinido: function itemTemResultadoDefinido(itemAquisicao) {
      return this.getLoteCorrespondenteDoItem(itemAquisicao).resultado;
    },

    itemCotaPrincipalComResultadoDefinido: function itemCotaPrincipalComResultadoDefinido() {
      var _this = this;

      var loteItemCotaPrincipal = this.get('controllers.aquisicao.model.lotes').find(function (lote) {
        return _this.model.itemCotaPrincipal.idLote === lote.id;
      });

      return loteItemCotaPrincipal ? loteItemCotaPrincipal.resultado : null;
    },

    itemAdjudicado: (function () {
      if (!this.model.adjudicado) {
        return false;
      }
      return this.model.adjudicado.id;
    }).property('this.model.adjudicado'),

    itemPorAdjudicar: (function () {
      return !this.get('itemAdjudicado');
    }).property('this.model.adjudicado'),

    ehItemComParticipacoes: (function () {
      return this.model.participacoes && this.model.participacoes.length > 0;
    }).property('this.model.participacoes'),

    getValorColunaAdjudicado: function getValorColunaAdjudicado(itemAquisicao) {
      if (this.itemTemResultadoDefinido(itemAquisicao) && !itemAquisicao.adjudicado.id) {
        return '-';
      }

      return this.concatenaNomeAdjudicadoCpfCnpj(itemAquisicao);
    },

    concatenaNomeAdjudicadoCpfCnpj: function concatenaNomeAdjudicadoCpfCnpj(itemAquisicao) {
      if (!itemAquisicao.adjudicado.id) {
        return;
      }
      var adjudicado = itemAquisicao.adjudicado;
      var docIdentificacao = adjudicado.cpf ? Util['default'].incluiPontuacaoCpf(adjudicado.cpf) : Util['default'].incluiPontuacaoCnpj(adjudicado.cnpj);
      return adjudicado.nome + ' (' + docIdentificacao + ')';
    },

    getStatusLoteCorrespondenteDoItem: function getStatusLoteCorrespondenteDoItem(itemAquisicao) {
      return this.getLoteCorrespondenteDoItem(itemAquisicao).resultado;
    },

    getLoteCorrespondenteDoItem: function getLoteCorrespondenteDoItem(itemAquisicao) {
      return this.get('controllers.aquisicao.model.lotes').find(function (lote) {
        return lote.id === itemAquisicao.idLote;
      });
    },

    getNomeLegivelMotivoCriacaoItem: function getNomeLegivelMotivoCriacaoItem(item) {
      var motivoCriacao = this.motivosCriacaoItens.find(function (motivo) {
        return item.motivoCriacao === motivo.id;
      });
      return motivoCriacao.nome;
    },

    exibeDadosCota: (function () {
      return this.especificacaoCota ? this.especificacaoCota.motivoCriacao === 'COTA' : false;
    }).property('especificacaoCota.motivoCriacao'),

    actions: {
      abrirModalDividirCota: function abrirModalDividirCota() {
        this.set('especificacaoCota', { idItemOriginal: this.model.id, quantidade: '', descricao: this.model.descricao, motivoCriacao: '' });
        this.set('mostrarModalCota', true);
      },
      fecharModalDividirCota: function fecharModalDividirCota() {
        this.set('especificacaoCota', undefined);
        this.set('mostrarModalCota', false);
      },
      criarCota: function criarCota() {
        var _this2 = this;

        this.get('comunicador').servico('post', 'aquisicao/item/cota', this.get('especificacaoCota')).then(function () {
          _this2.set('mostrarModalCota', false);
          _this2.get('controllers.aquisicao').send('ler');
          _this2.transitionToRoute('aquisicao');
        });
      },
      excluirCota: function excluirCota() {
        var _this3 = this;

        this.get('comunicador').servico('delete', 'aquisicao/item/cota/' + this.model.id).then(function () {
          _this3.get('controllers.aquisicao').send('ler');
          _this3.transitionToRoute('aquisicao');
        });
      }
    }

  });

});
define('webapp/pods/aquisicao/pncp/controller', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    exports['default'] = Ember['default'].Controller.extend({
        needs: ['aquisicao'],

        enviarPNCPDesabilitado: function enviarPNCPDesabilitado() {
            return this.model.enviarPNCP === 1;
        },

        urlPNCP: (function () {
            return this.model.urlPNCP;
        }).property('model.urlPNCP'),

        actions: {
            enviarAgora: function enviarAgora() {
                this.get('comunicador').servico('post', 'aquisicao/' + this.model.id + '/enviaPNCP', null).then(function (resultado) {
                    console.log("#### Resultado ####");
                    console.log(resultado);
                    window.location.reload(); // Atualiza a tela com os dados do servidor
                });
            }
        }

    });

});
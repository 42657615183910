define('webapp/pods/transparencia/documento/criar/route', ['exports', 'ember', 'webapp/utils/util', 'webapp/pods/transparencia/documento/model'], function (exports, Ember, Util, Documento) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({
    controllerName: 'transparencia/documento',
    setupController: function setupController(controller) {
      controller.set('model', Documento['default'].create({}));

      // Configura informações da tela
      this.get('comunicador').servico('get', 'parametroExibicao').then(function (resultado) {
        controller.set('exibeApostilas', Util['default'].converteParametrosDeExibicao(resultado).TRANSP_EXIBE_APOSTILAS === "S");
        controller.set('exibeAditivos', Util['default'].converteParametrosDeExibicao(resultado).TRANSP_EXIBE_ADITIVOS === "S");
      });
    }

  });

});
define('webapp/pods/aquisicao/identificacao/dados-basicos/template', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      var child0 = (function() {
        return {
          isHTMLBars: true,
          revision: "Ember@1.12.1",
          blockParams: 0,
          cachedFragment: null,
          hasRendered: false,
          build: function build(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("			");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          render: function render(context, env, contextualElement) {
            var dom = env.dom;
            var hooks = env.hooks, get = hooks.get, inline = hooks.inline;
            dom.detectNamespace(contextualElement);
            var fragment;
            if (env.useFragmentCache && dom.canClone) {
              if (this.cachedFragment === null) {
                fragment = this.build(dom);
                if (this.hasRendered) {
                  this.cachedFragment = fragment;
                } else {
                  this.hasRendered = true;
                }
              }
              if (this.cachedFragment) {
                fragment = dom.cloneNode(this.cachedFragment, true);
              }
            } else {
              fragment = this.build(dom);
            }
            var morph0 = dom.createMorphAt(fragment,1,1,contextualElement);
            inline(env, morph0, context, "tag-input", [], {"texto": "Modalidade", "valor": get(env, context, "descricaoModalidade"), "somenteLeitura": true, "tamanho": 12, "id": "tag||modalidade|aquisicao|identificacao"});
            return fragment;
          }
        };
      }());
      var child1 = (function() {
        return {
          isHTMLBars: true,
          revision: "Ember@1.12.1",
          blockParams: 0,
          cachedFragment: null,
          hasRendered: false,
          build: function build(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("			");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          render: function render(context, env, contextualElement) {
            var dom = env.dom;
            var hooks = env.hooks, get = hooks.get, inline = hooks.inline;
            dom.detectNamespace(contextualElement);
            var fragment;
            if (env.useFragmentCache && dom.canClone) {
              if (this.cachedFragment === null) {
                fragment = this.build(dom);
                if (this.hasRendered) {
                  this.cachedFragment = fragment;
                } else {
                  this.hasRendered = true;
                }
              }
              if (this.cachedFragment) {
                fragment = dom.cloneNode(this.cachedFragment, true);
              }
            } else {
              fragment = this.build(dom);
            }
            var morph0 = dom.createMorphAt(fragment,1,1,contextualElement);
            inline(env, morph0, context, "tag-select", [], {"texto": "Modalidade", "obrigatorio": true, "conteudo": get(env, context, "modalidadesAtivas"), "opcaoLabel": "nome", "opcaoValor": "id", "valor": get(env, context, "model.modalidade"), "textopadrao": "Selecione a modalidade", "permiteLimpar": true, "id": "select||modalidade|aquisicao|identificacao", "somenteLeitura": get(env, context, "ehAquisicaoExistente")});
            return fragment;
          }
        };
      }());
      var child2 = (function() {
        return {
          isHTMLBars: true,
          revision: "Ember@1.12.1",
          blockParams: 0,
          cachedFragment: null,
          hasRendered: false,
          build: function build(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("		");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","col-md-12 text-right");
            var el2 = dom.createTextNode("\n			");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("fieldset");
            dom.setAttribute(el2,"style","font-weight: bold");
            var el3 = dom.createTextNode("\n  				");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("legend");
            dom.setAttribute(el3,"style","margin-top: 10px; margin-bottom: 5px; font-size: 14px");
            var el4 = dom.createTextNode("Registro de Preços*");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n	            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n	            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n 			");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n		");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          render: function render(context, env, contextualElement) {
            var dom = env.dom;
            var hooks = env.hooks, get = hooks.get, inline = hooks.inline;
            dom.detectNamespace(contextualElement);
            var fragment;
            if (env.useFragmentCache && dom.canClone) {
              if (this.cachedFragment === null) {
                fragment = this.build(dom);
                if (this.hasRendered) {
                  this.cachedFragment = fragment;
                } else {
                  this.hasRendered = true;
                }
              }
              if (this.cachedFragment) {
                fragment = dom.cloneNode(this.cachedFragment, true);
              }
            } else {
              fragment = this.build(dom);
            }
            var element0 = dom.childAt(fragment, [1, 1]);
            var morph0 = dom.createMorphAt(element0,3,3);
            var morph1 = dom.createMorphAt(element0,5,5);
            inline(env, morph0, context, "tag-radio-button", [], {"texto": "Sim", "valor": true, "atributoDestino": get(env, context, "model.registroPreco"), "labelNegrito": true, "tamanho": 6, "desabilitado": get(env, context, "proibidoAlterarOpcaoRegistroPrecos"), "id": "radio||tem-registro-preco|aquisicao|identificacao"});
            inline(env, morph1, context, "tag-radio-button", [], {"texto": "Não", "valor": false, "atributoDestino": get(env, context, "model.registroPreco"), "labelNegrito": true, "tamanho": 6, "desabilitado": get(env, context, "proibidoAlterarOpcaoRegistroPrecos"), "id": "radio||nao-tem-registro-preco|aquisicao|identificacao"});
            return fragment;
          }
        };
      }());
      return {
        isHTMLBars: true,
        revision: "Ember@1.12.1",
        blockParams: 0,
        cachedFragment: null,
        hasRendered: false,
        build: function build(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n	");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","row");
          var el2 = dom.createTextNode("\n		");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n		");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n	");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n	");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","row");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("	");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n	");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","row");
          var el2 = dom.createTextNode("\n			");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n	");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("																				\n\n	");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","row");
          dom.setAttribute(el1,"style","margin-bottom: 10px");
          var el2 = dom.createTextNode("\n		");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n	");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n	");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","row");
          var el2 = dom.createTextNode("\n		");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col-md-12 text-right");
          var el3 = dom.createTextNode("\n			");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("fieldset");
          dom.setAttribute(el3,"style","font-weight: bold");
          var el4 = dom.createTextNode("\n  				");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("legend");
          dom.setAttribute(el4,"style","margin-top: 10px; margin-bottom: 5px; font-size: 14px");
          var el5 = dom.createTextNode("Critério de Sustentabilidade*");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n	            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n	            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n 			");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n		");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n	");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n	");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","row");
          var el2 = dom.createTextNode("\n		");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col-md-12 text-right");
          var el3 = dom.createTextNode("\n			");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("fieldset");
          dom.setAttribute(el3,"style","font-weight: bold");
          var el4 = dom.createTextNode("\n  				");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("legend");
          dom.setAttribute(el4,"style","margin-top: 10px; margin-bottom: 5px; font-size: 14px");
          var el5 = dom.createTextNode("Envio ao PNCP*");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n	            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n	            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n 			");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n		");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n	");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n	");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","row");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("	");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        render: function render(context, env, contextualElement) {
          var dom = env.dom;
          var hooks = env.hooks, get = hooks.get, inline = hooks.inline, block = hooks.block;
          dom.detectNamespace(contextualElement);
          var fragment;
          if (env.useFragmentCache && dom.canClone) {
            if (this.cachedFragment === null) {
              fragment = this.build(dom);
              if (this.hasRendered) {
                this.cachedFragment = fragment;
              } else {
                this.hasRendered = true;
              }
            }
            if (this.cachedFragment) {
              fragment = dom.cloneNode(this.cachedFragment, true);
            }
          } else {
            fragment = this.build(dom);
          }
          var element1 = dom.childAt(fragment, [1]);
          var element2 = dom.childAt(fragment, [9, 1, 1]);
          var element3 = dom.childAt(fragment, [11, 1, 1]);
          var morph0 = dom.createMorphAt(element1,1,1);
          var morph1 = dom.createMorphAt(element1,3,3);
          var morph2 = dom.createMorphAt(dom.childAt(fragment, [3]),1,1);
          var morph3 = dom.createMorphAt(dom.childAt(fragment, [5]),1,1);
          var morph4 = dom.createMorphAt(dom.childAt(fragment, [7]),1,1);
          var morph5 = dom.createMorphAt(element2,3,3);
          var morph6 = dom.createMorphAt(element2,5,5);
          var morph7 = dom.createMorphAt(element3,3,3);
          var morph8 = dom.createMorphAt(element3,5,5);
          var morph9 = dom.createMorphAt(dom.childAt(fragment, [13]),1,1);
          inline(env, morph0, context, "tag-input", [], {"texto": "Nº", "labelAcessivel": "Número da aquisição", "valor": get(env, context, "model.numeroApresentacao"), "somenteLeitura": true, "tamanho": 6, "id": "tag||id|aquisicao|identificacao"});
          inline(env, morph1, context, "date-input", [], {"texto": "Data Criação", "valor": get(env, context, "model.dataCriacao"), "somenteLeitura": true, "tamanho": 6, "id": "tag||dataCriacao|aquisicao|identificacao"});
          block(env, morph2, context, "if", [get(env, context, "ehAquisicaoExistente")], {}, child0, child1);
          inline(env, morph3, context, "tag-select", [], {"texto": "Fundamentos Legais", "obrigatorio": true, "conteudo": get(env, context, "pptyFundamentosLegaisPossiveis"), "opcaoLabel": "descricao", "valor": get(env, context, "model.fundamentosLegais"), "textopadrao": "Selecione o(s) fundamento(s)", "permiteLimpar": true, "id": "select||fundamentosLegais|aquisicao|identificacao", "multiplo": true, "formatoTabela": true, "somenteLeitura": get(env, context, "ehAquisicaoExistente"), "desabilitado": get(env, context, "notFundamentosLegaisPossiveis")});
          inline(env, morph4, context, "tag-input", [], {"texto": "Código UASG", "valor": get(env, context, "model.uasg"), "somenteLeitura": true, "obrigatorio": true, "tamanho": 12, "id": "tag||uasg|aquisicao|identificacao"});
          inline(env, morph5, context, "tag-radio-button", [], {"texto": "Sim", "valor": true, "atributoDestino": get(env, context, "model.criterioSustentabilidade"), "labelNegrito": true, "tamanho": 6, "id": "radio||tem-criterio-sustentabilidade||aquisicao|identificacao"});
          inline(env, morph6, context, "tag-radio-button", [], {"texto": "Não", "valor": false, "atributoDestino": get(env, context, "model.criterioSustentabilidade"), "labelNegrito": true, "tamanho": 6, "id": "radio||nao-tem-criterio-sustentabilidade|aquisicao|identificacao"});
          inline(env, morph7, context, "tag-radio-button", [], {"texto": "Sim", "valor": true, "atributoDestino": get(env, context, "model.enviaPNCP"), "labelNegrito": true, "tamanho": 6, "desabilitado": get(env, context, "desabilitaEnvioPNCP"), "id": "radio||envia-PNCP||aquisicao|identificacao"});
          inline(env, morph8, context, "tag-radio-button", [], {"texto": "Não", "valor": false, "atributoDestino": get(env, context, "model.enviaPNCP"), "labelNegrito": true, "tamanho": 6, "desabilitado": get(env, context, "desabilitaEnvioPNCP"), "id": "radio||nao-envia-PNCP|aquisicao|identificacao"});
          block(env, morph9, context, "if", [get(env, context, "permiteRegistroDePrecos")], {}, child2, null);
          return fragment;
        }
      };
    }());
    return {
      isHTMLBars: true,
      revision: "Ember@1.12.1",
      blockParams: 0,
      cachedFragment: null,
      hasRendered: false,
      build: function build(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      render: function render(context, env, contextualElement) {
        var dom = env.dom;
        var hooks = env.hooks, block = hooks.block;
        dom.detectNamespace(contextualElement);
        var fragment;
        if (env.useFragmentCache && dom.canClone) {
          if (this.cachedFragment === null) {
            fragment = this.build(dom);
            if (this.hasRendered) {
              this.cachedFragment = fragment;
            } else {
              this.hasRendered = true;
            }
          }
          if (this.cachedFragment) {
            fragment = dom.cloneNode(this.cachedFragment, true);
          }
        } else {
          fragment = this.build(dom);
        }
        var morph0 = dom.createMorphAt(fragment,0,0,contextualElement);
        dom.insertBoundary(fragment, null);
        dom.insertBoundary(fragment, 0);
        block(env, morph0, context, "tag-bloco", [], {"titulo": "Dados Básicos"}, child0, null);
        return fragment;
      }
    };
  }()));

});
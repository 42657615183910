define('webapp/pods/aquisicao/contrato/efeito-financeiro/criar/route', ['exports', 'ember', 'webapp/pods/aquisicao/contrato/efeito-financeiro/model'], function (exports, Ember, EfeitoFinanceiro) {

	'use strict';

	exports['default'] = Ember['default'].Route.extend({
		controllerName: 'aquisicao/contrato/efeito-financeiro',
		setupController: function setupController(controller) {
			controller.set('model', EfeitoFinanceiro['default'].create({}));
			controller.set('_aditivos', controller.get('controllers.aquisicao/contrato').model.aditivos);
			controller.set('_apostilas', controller.get('controllers.aquisicao/contrato').model.apostilas);

			/*		this.get('comunicador').leParaPropriedade(
	  			controller,
	  			`contrato/${controller.get('controllers.aquisicao/contrato').model.id}/aditivo`,
	  			'aditivos',
	  			'Não foi possível obter a lista de aditivos do contrato.'
	  			);
	  */
		},

		model: function model() {
			return EfeitoFinanceiro['default'].create();
		}
	});

});
define('webapp/components/botao-excluir', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  //Exemplo de uso:
  /*    {{botao-excluir
          texto=<Texto do botão. Default: "Excluir">
          labelAcessivel=<texto para ser lido por leitores automáticos de interface
                          para acessibilidade por pessoas com deficiência visual.
                          Se não for definido, será lido o texto apresentado pelo botão>
          exibirTexto=<true | false <default>, indicando se o texto do botão deve ser exibido>
          acao=<String indicando ação a ser executada pelo botão>
          elementoId=<id_do_elemento>
        }}
  */
  exports['default'] = Ember['default'].Component.extend({
    texto: 'Excluir',
    exibirTexto: false,
    tagName: 'span',

    textoAcessivel: (function () {
      return this.labelAcessivel ? this.labelAcessivel : this.texto;
    }).property('texto', 'labelAcessivel'),

    actions: {
      excluir: function excluir(id) {
        var _this = this;

        swal({
          title: "Confirma exclusão do registro?",
          type: 'warning',
          confirmButtonText: 'Confirmar',
          showCancelButton: true,
          cancelButtonText: 'Cancelar',
          focusCancel: true,
          reverseButtons: true
        }).then(function () {
          _this.sendAction('acao', id);
        });
      }
    }
  });

});
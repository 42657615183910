define('webapp/pods/aquisicao/compra/controller', ['exports', 'ember', 'webapp/mixins/crud-mixin'], function (exports, Ember, CrudMixin) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend(CrudMixin['default'], {

    needs: ['aquisicao'],

    breadCrumbConfig: {
      fnNomeApresentacao: 'fnNomeApresentacao'
    },

    fnNomeApresentacao: function fnNomeApresentacao() {
      return 'Compra ' + (this.model.version ? 'Nº ' + this.model.numeroApresentacao : '(Nova)');
    },

    crudConfig: {
      criar: {
        urlFn: function urlFn() {
          return 'aquisicao/' + this.get('controllers.aquisicao').model.id + '/compra';
        },
        okFn: function okFn(opcao) {
          this.get('controllers.aquisicao').send('ler');
          if (opcao === 'CRIAR_E_PROXIMO') {
            //ação 'borbulhará' para a rota
            this.send('refreshRota');
            return;
          }
          this.transitionToRoute('aquisicao/compra', this.model.id);
        }
      },
      atualizar: {
        okFn: function okFn() {
          this.get('controllers.aquisicao').send('ler');
        }
      },
      excluir: {
        okFn: function okFn() {
          this.get('controllers.aquisicao').send('ler');
        }
      }
    },

    ehCompraExistente: (function () {
      return this.model.version;
    }).property('model.version'),

    modalidadeAquisicaoPermiteOutroDestinatarioPagamento: (function () {
      return ['DISPENSA', 'INEXIGIBILIDADE'].includes(this.get('controllers.aquisicao').model.modalidade);
    }).property('controllers.aquisicao.model.modalidade'),

    permiteOutroDestinatarioPagamento: (function () {
      return !this.get('ehCompraExistente') && this.get('modalidadeAquisicaoPermiteOutroDestinatarioPagamento');
    }).property('ehCompraExistente', 'modalidadeAquisicaoPermiteOutroDestinatarioPagamento'),

    naoHaItensOriginais: (function () {
      //if(!this.model) { return true; }

      return !this.model.itens.some(function (item) {
        return item.motivoCriacao === 'ORIGINAL';
      });
    }).property('model', 'model.itens'),

    aquisicaoPossuiAtas: (function () {
      return this.get('atasDaAquisicao').length;
    }).property('atasDaAquisicao'),

    atasDaAquisicao: (function () {
      return this.get('controllers.aquisicao').model.atas;
    }).property('model', 'controller.aquisicao.model.atas'),

    numeroApresentacaoAta: (function () {
      var _this = this;

      var ata = this.get('atasDaAquisicao').find(function (item) {
        return item.id === _this.model.idAta;
      });
      return ata ? ata.numeroApresentacao : '';
    }).property('idAta'),

    possivelEscolherOutroDestinatarioPagamento: (function () {
      return this.get('outroDestinatarioPagamento') && !this.get('ehCompraExistente');
    }).property('outroDestinatarioPagamento', 'ehCompraExistente'),

    sugerirDataAtualParaCompra: (function () {
      if (this.get('ehCompraExistente')) {
        return;
      }
      this.set('model.dataEfetivacao', new Date());
    }).observes('model'),

    atualizarAtaConformeFornecedor: (function () {
      var _this2 = this;

      if (this.get('ehCompraExistente')) {
        return;
      }

      if (!this.model.fornecedor) {
        this.set('model.idAta', null);
        return;
      }

      var ataFornecedor = this.get('atasDaAquisicao').find(function (ata) {
        return ata.fornecedor.id === _this2.model.fornecedor.id;
      });
      this.set('model.idAta', ataFornecedor ? ataFornecedor.id : null);
    }).observes('model.fornecedor'),

    atualizarDestinatarioPagamentoAPartirFornecedor: (function () {
      if (this.outroDestinatarioPagamento) {
        return;
      }

      this.set('model.destinatarioPagamento', this.model.fornecedor);
    }).observes('model.fornecedor'),

    atualizarDestinatarioPagamentoAPartirCheckbox: (function () {
      if (this.outroDestinatarioPagamento) {
        this.set('model.destinatarioPagamento', undefined);
      } else {
        this.set('model.destinatarioPagamento', this.model.fornecedor);
      }
    }).observes('outroDestinatarioPagamento'),

    descricaoRelatorios: (function () {
      var itemsDeMenuARetornar = [{
        textoMenu: 'Relatório de Compra',
        servico: 'relatorio/relacaoDeCompra/' + this.get("model.id"),
        nomeArquivo: 'relacao_de_compra',
        nomeParaID: 'relacao-de-compra',
        controlePermissao: true
      }, {
        textoMenu: 'Relatório de Acréscimos e Supressões',
        servico: 'relatorio/relacaoDeAcrescimosESupressoes/' + this.get("model.id"),
        nomeArquivo: 'relacao_de_acrescimos_e_supressoes',
        nomeParaID: 'relacao-de-acrescimos-e-supressoes',
        controlePermissao: true
      }];

      return itemsDeMenuARetornar;
    }).property("model.id"),

    actions: {
      redirecionarAta: function redirecionarAta() {
        this.transitionToRoute('aquisicao/ata', this.model.idAta);
      }
    }

  });

});
define('webapp/models/classificacao-financeira-item', ['exports', 'ember'], function (exports, Ember) {

	'use strict';

	exports['default'] = Ember['default'].Object.extend({
			id: null,
			numero: '',
			nome: ''
	});

});
define('webapp/pods/requisicao/contato-entrega/model', ['exports', 'ember', 'webapp/models/contato'], function (exports, Ember, Contato) {

  'use strict';

  exports['default'] = Ember['default'].Object.extend(Contato['default'].create({}), {
    findUrlFn: function findUrlFn(id) {
      return "requisicao/contatoEntrega/" + id.toString();
    }
  });

});
define('webapp/components/botao-geral', ['exports', 'ember', 'webapp/mixins/ajusta-id-interno-mixin'], function (exports, Ember, AjustaIdInternoMixin) {

  'use strict';

  //Exemplo de uso:
  /*    {{botao-geral
          tipo=<primario | secundario (default)>
          texto=<texto do botão>
          exibirTexto=<false | true (default)>
          iconeUiIcon=<nome do ícone do Font Awsome - http://gkoberger.github.io/Font-Awesome/icons/>
          apenasIcone=<true | false (default) indicando se apenas o ícone do botão
                        deve ser apresentado>
          acao=<acao>
          parametroAcao=<valor para ser passado como parâmetro para a ação.
                         Pode ser uma string digitada diretamente no template
                         ou uma variável que aponte para um valor qualquer,
                         inclusive um objeto complexo>
          segundoParametro=<analogo ao parametroAcao>
          enderecoLink=<se o botão deve representar um link,
                        o endereco de destino é indicado aqui>
          desabilitar=<true | false (default) - opção para manter o botão desativado>
          ativarComEnter=<true | false (default) - se true, o botão é ativado ao teclar enter.
                          Essa opção só funciona se o botão estiver dentro de uma região
                          envolvida por tags "form" (por exemplo, em um tag-bloco com
                          a opção formulario=true).>
          id=<id do botão>
          classe=<classe CSS a ser aplicada ao botão - sobrescreve
                  as definições-padrão (como tipo principal ou secundário)>
          classesCSSAdicionais=<classes CSS a serem adicionadas às definições-padrão
                                ou ao que for definido no parâmetro "classe">
          estiloCSS=<Estilo CSS para ser aplicado inline pelo atributo "style">
          labelAcessivel=<texto para ser lido por leitores automáticos de interface
                          para acessibilidade por pessoas com deficiência visual.
                          Se não for definido, será lido o texto apresentado pelo botão>
          parteVariavelLabelAcessivel=<texto a ser concatenado ao final daquele
                                       definido no parâmetro labelAcessivel (útil
                                       para botões usados dentro de algum {{#each}})>
          tabindex=<ordem em que o botão deve receber o foco em razão de o usuário
                    pressionar o botão Tab>
        }}

        OBS.: TAMBÉM PODE SER UTILIZADO DA SEGUINTE MANEIRA

        {{#botao-geral ...}}
          <conteúdo a ser exibido no botão - podendo incluir múltiplas tags,
            tais como incluindo texto, ícones, ...>
        {{/botao-geral}}

        NO ENTANTO, O TEXTO COLOCADO ENTRE AS TAGS NÃO PASSA PELO TRATAMENTO PELO
        QUAL PASSA O ATRIBUTO "texto" (COMO VIRAR O aria-label DO BOTÃO)
  */
  exports['default'] = Ember['default'].Component.extend(AjustaIdInternoMixin['default'], {
    exibirTexto: true,
    tagName: 'span',
    tipoInterno: 'botao-interno',
    classe: '',
    classesCSSAdicionais: '',
    estiloCSS: '',
    ativarComEnter: false,
    desabilitar: false,
    labelAcessivel: '',

    _defineCSSBotao: (function () {
      var classesAAplicar = '';

      if (this.apenasIcone) {
        classesAAplicar = 'botao-apenas-icone';
      } else if (this.classe) {
        classesAAplicar = this.classe;
      } else if (this.tipo === 'primario') {
        classesAAplicar = 'btn btn-primary';
      } else {
        classesAAplicar = 'btn btn-default';
      }

      classesAAplicar += ' ' + this.classesCSSAdicionais;

      this.set('classe', classesAAplicar);
    }).on('willInsertElement'),

    textoAcessivel: (function () {
      var textoAcessivelFixo = this.labelAcessivel ? this.labelAcessivel : this.texto;
      return textoAcessivelFixo + ' ' + (this.parteVariavelLabelAcessivel ? this.parteVariavelLabelAcessivel : '');
    }).property('texto', 'labelAcessivel'),

    _verificaPermissao: (function () {
      var botaoPermitido = this._super();
      var projetadoParaAtivarComEnter = this.get('ativarComEnter');
      this.set('ativarComEnter', botaoPermitido && projetadoParaAtivarComEnter);
    }).on('willInsertElement'),

    _indicadorAtivarComEnter: (function () {
      //Se o botão tem 'type="button"', ele não é ativado com enter
      return this.ativarComEnter ? '' : 'button';
    }).property('ativarComEnter'),

    estiloCSSaplicado: (function () {
      return this.estiloCSS + (this.botaoSlim ? "padding-top: 0; padding-bottom: 0;" : "");
    }).property('estiloCSS', 'botaoSlim'),

    actions: {
      acao: function acao(id) {
        if (this.acao) {
          this.sendAction('acao', id);
        }
      }
    }
  });

});
define('webapp/pods/fornecedor/criar/route', ['exports', 'ember', 'webapp/pods/fornecedor/model', 'webapp/mixins/suprimir-templates-superiores-mixin'], function (exports, Ember, Fornecedor, SuprimirTemplatesSuperioresMixin) {

	'use strict';

	exports['default'] = Ember['default'].Route.extend(SuprimirTemplatesSuperioresMixin['default'], {
		controllerName: 'fornecedor',
		setupController: function setupController() {
			this.get('controller').set('model', Fornecedor['default'].create({}));
		},

		model: function model() {
			return Fornecedor['default'].create({});
		}
	});

});
define('webapp/pods/sobre/controller', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({
    buildInfo: '',

    breadCrumbConfig: {
      nomeApresentacao: 'Sobre'
    },

    /*buscaInformacoesBuild: function() {
      this.get('comunicador').leParaPropriedade(this, 'buildInfo', 'buildInfo',
                                                "Não foi possível obter as informações de versão");
    }.on('init'),*/

    versaoSistema: (function () {
      // chama serviço que busca parâmetro no banco de dados
      return this.buildInfo.nroVersao;
    }).property('buildInfo'),

    versaoCliente: (function () {
      try {
        // este valor é escrito no arquivo "/assets/versao.js" pelo jenkins e é importado no index.html
        /* jshint ignore:start */
        return __numeroVersaoCliente__;
        /* jshint ignore:end */
      } catch (e) {
        return "-";
      }
    }).property(),

    versaoServidor: (function () {
      var build = this.buildInfo.buildNumber ? this.buildInfo.buildNumber : '-';
      return build;
    }).property('buildInfo'),

    versaoNavegador: (function () {
      // função que busca a versão do browser
      var versaoBrowser = function versaoBrowser() {
        var N = navigator.appName,
            ua = navigator.userAgent,
            tem,
            retorno;
        var M = ua.match(/(opera|chrome|safari|firefox|msie|trident)\/?\s*(\.?\d+(\.\d+)*)/i);
        if (M && (tem = ua.match(/version\/([\.\d]+)/i)) != null) {
          M[2] = tem[1];
        }
        retorno = M ? { browser: M[1], versao: M[2] } : { browser: N, versao: navigator.appVersion };
        return retorno;
      };

      var objBrowser = versaoBrowser();
      return objBrowser.browser + ' (versão ' + objBrowser.versao + ')';
    }).property()

  });

});
define('webapp/pods/aquisicao/contrato/identificacao/descricao/template', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      var child0 = (function() {
        return {
          isHTMLBars: true,
          revision: "Ember@1.12.1",
          blockParams: 0,
          cachedFragment: null,
          hasRendered: false,
          build: function build(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("           ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n\n          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          render: function render(context, env, contextualElement) {
            var dom = env.dom;
            var hooks = env.hooks, get = hooks.get, inline = hooks.inline;
            dom.detectNamespace(contextualElement);
            var fragment;
            if (env.useFragmentCache && dom.canClone) {
              if (this.cachedFragment === null) {
                fragment = this.build(dom);
                if (this.hasRendered) {
                  this.cachedFragment = fragment;
                } else {
                  this.hasRendered = true;
                }
              }
              if (this.cachedFragment) {
                fragment = dom.cloneNode(this.cachedFragment, true);
              }
            } else {
              fragment = this.build(dom);
            }
            var morph0 = dom.createMorphAt(fragment,1,1,contextualElement);
            var morph1 = dom.createMorphAt(fragment,3,3,contextualElement);
            inline(env, morph0, context, "tag-select", [], {"texto": "Índice", "obrigatorio": get(env, context, "model.temReajuste"), "conteudo": get(env, context, "indicesReajuste"), "opcaoLabel": "nome", "valor": get(env, context, "model.indiceReajuste"), "textopadrao": "Selecione o índice", "permiteLimpar": true, "id": "select||indice-reajuste|aquisicao/contrato|identificacao", "tamanho": 6});
            inline(env, morph1, context, "tag-select", [], {"texto": "Mês Referência", "obrigatorio": get(env, context, "model.temReajuste"), "conteudo": get(env, context, "meses"), "opcaoLabel": "nome", "opcaoValor": "id", "valor": get(env, context, "model.mesReajuste"), "textopadrao": "Selecione o mês", "permiteLimpar": true, "id": "select||periodo-de-divulgacao|aquisicao/contrato|identificacao", "tamanho": 6});
            return fragment;
          }
        };
      }());
      var child1 = (function() {
        return {
          isHTMLBars: true,
          revision: "Ember@1.12.1",
          blockParams: 0,
          cachedFragment: null,
          hasRendered: false,
          build: function build(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("p");
            dom.setAttribute(el1,"style","text-align: center; font-weight: bold; font-size: 150%");
            var el2 = dom.createTextNode("Sem Reajuste");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          render: function render(context, env, contextualElement) {
            var dom = env.dom;
            dom.detectNamespace(contextualElement);
            var fragment;
            if (env.useFragmentCache && dom.canClone) {
              if (this.cachedFragment === null) {
                fragment = this.build(dom);
                if (this.hasRendered) {
                  this.cachedFragment = fragment;
                } else {
                  this.hasRendered = true;
                }
              }
              if (this.cachedFragment) {
                fragment = dom.cloneNode(this.cachedFragment, true);
              }
            } else {
              fragment = this.build(dom);
            }
            return fragment;
          }
        };
      }());
      return {
        isHTMLBars: true,
        revision: "Ember@1.12.1",
        blockParams: 0,
        cachedFragment: null,
        hasRendered: false,
        build: function build(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","row");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","row");
          var el2 = dom.createTextNode("\n  		");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("fieldset");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("legend");
          var el3 = dom.createTextNode("Datas");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","row");
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","row");
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","row");
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("fieldset");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("legend");
          var el3 = dom.createTextNode("Valores Iniciais");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","row");
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","row");
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","row");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","row");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","row");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("fieldset");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("legend");
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","row");
          var el4 = dom.createTextNode("\n");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        render: function render(context, env, contextualElement) {
          var dom = env.dom;
          var hooks = env.hooks, get = hooks.get, inline = hooks.inline, block = hooks.block;
          dom.detectNamespace(contextualElement);
          var fragment;
          if (env.useFragmentCache && dom.canClone) {
            if (this.cachedFragment === null) {
              fragment = this.build(dom);
              if (this.hasRendered) {
                this.cachedFragment = fragment;
              } else {
                this.hasRendered = true;
              }
            }
            if (this.cachedFragment) {
              fragment = dom.cloneNode(this.cachedFragment, true);
            }
          } else {
            fragment = this.build(dom);
          }
          var element0 = dom.childAt(fragment, [5]);
          var element1 = dom.childAt(element0, [3]);
          var element2 = dom.childAt(element0, [5]);
          var element3 = dom.childAt(element0, [7]);
          var element4 = dom.childAt(fragment, [7]);
          var element5 = dom.childAt(element4, [3]);
          var element6 = dom.childAt(element4, [5]);
          var morph0 = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
          var morph1 = dom.createMorphAt(dom.childAt(fragment, [3]),1,1);
          var morph2 = dom.createMorphAt(element1,1,1);
          var morph3 = dom.createMorphAt(element1,3,3);
          var morph4 = dom.createMorphAt(element2,1,1);
          var morph5 = dom.createMorphAt(element2,3,3);
          var morph6 = dom.createMorphAt(element3,1,1);
          var morph7 = dom.createMorphAt(element3,3,3);
          var morph8 = dom.createMorphAt(element5,1,1);
          var morph9 = dom.createMorphAt(element5,3,3);
          var morph10 = dom.createMorphAt(element6,1,1);
          var morph11 = dom.createMorphAt(element6,3,3);
          var morph12 = dom.createMorphAt(dom.childAt(fragment, [9]),1,1);
          var morph13 = dom.createMorphAt(dom.childAt(fragment, [11]),1,1);
          var morph14 = dom.createMorphAt(dom.childAt(fragment, [13, 1, 3]),1,1);
          inline(env, morph0, context, "tag-input", [], {"texto": "Objeto", "valor": get(env, context, "model.objeto"), "comprimentoMaximo": 255, "obrigatorio": true, "id": "tag||objeto|aquisicao/contrato|identificacao"});
          inline(env, morph1, context, "busca-pa", [], {"texto": "Processo Administrativo", "atributoDestino": get(env, context, "model.numeroProcessoAdministrativo"), "obrigatorio": true, "labelAcessivel": "Número Processo Administrativo", "controllerDoContexto": get(env, context, "this"), "id": "tag||numeroProcessoAdministrativo|aquisicao/contrato|identificacao"});
          inline(env, morph2, context, "date-input", [], {"texto": "Assinatura", "valor": get(env, context, "model.dataAssinatura"), "tamanho": 6, "id": "tag||dataAssinatura|aquisicao/contrato|identificacao"});
          inline(env, morph3, context, "date-input", [], {"texto": "Publicação", "valor": get(env, context, "model.dataPublicacao"), "tamanho": 6, "id": "tag||dataPublicacao|aquisicao/contrato|identificacao"});
          inline(env, morph4, context, "date-input", [], {"texto": "Início Vigência", "valor": get(env, context, "model.dataInicioVigencia"), "obrigatorio": true, "tamanho": 6, "id": "tag||dataInicioVigencia|aquisicao/contrato|identificacao"});
          inline(env, morph5, context, "date-input", [], {"texto": "Fim Vigência", "valor": get(env, context, "model.dataFimVigencia"), "tamanho": 6, "id": "tag||dataFimVigencia|aquisicao/contrato|identificacao"});
          inline(env, morph6, context, "date-input", [], {"texto": "Início Execução", "valor": get(env, context, "model.dataInicioExecucao"), "tamanho": 6, "id": "tag||dataInicioExecucao|aquisicao/contrato|identificacao"});
          inline(env, morph7, context, "date-input", [], {"texto": "Fim Execução", "valor": get(env, context, "model.dataFimExecucao"), "tamanho": 6, "id": "tag||dataFimExecucao|aquisicao/contrato|identificacao"});
          inline(env, morph8, context, "moeda-input", [], {"texto": "Mensal (Periódico)", "valor": get(env, context, "model.valorInicialMensal"), "tamanho": 6, "id": "tag||valorInicialMensal|aquisicao/contrato|identificacao"});
          inline(env, morph9, context, "moeda-input", [], {"texto": "Valor Único (Não-Periódico)", "valor": get(env, context, "model.valorInicialUnico"), "tamanho": 6, "id": "tag||valorInicialUnico|aquisicao/contrato|identificacao"});
          inline(env, morph10, context, "moeda-input", [], {"texto": "Valor Total", "valor": get(env, context, "model.valorInicialTotal"), "tamanho": 6, "id": "tag||valorInicialTotal|aquisicao/contrato|identificacao"});
          inline(env, morph11, context, "tag-input", [], {"texto": "Garantia Exigida (%)", "valor": get(env, context, "model.percentualExigidoGarantia"), "tamanho": 6, "id": "tag||percentualExigidoGarantia|aquisicao/contrato|valorInicial"});
          inline(env, morph12, context, "tag-input", [], {"texto": "Observações", "valor": get(env, context, "model.observacao"), "id": "tag||observacao|aquisicao/contrato|identificacao"});
          inline(env, morph13, context, "tag-checkbox", [], {"atributoDestino": get(env, context, "temReajuste"), "texto": "Reajuste", "id": "checkbox||indice-reajuste|aquisicao/contrato|identificacao"});
          block(env, morph14, context, "if", [get(env, context, "model.temReajuste")], {}, child0, child1);
          return fragment;
        }
      };
    }());
    return {
      isHTMLBars: true,
      revision: "Ember@1.12.1",
      blockParams: 0,
      cachedFragment: null,
      hasRendered: false,
      build: function build(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      render: function render(context, env, contextualElement) {
        var dom = env.dom;
        var hooks = env.hooks, block = hooks.block;
        dom.detectNamespace(contextualElement);
        var fragment;
        if (env.useFragmentCache && dom.canClone) {
          if (this.cachedFragment === null) {
            fragment = this.build(dom);
            if (this.hasRendered) {
              this.cachedFragment = fragment;
            } else {
              this.hasRendered = true;
            }
          }
          if (this.cachedFragment) {
            fragment = dom.cloneNode(this.cachedFragment, true);
          }
        } else {
          fragment = this.build(dom);
        }
        var morph0 = dom.createMorphAt(fragment,0,0,contextualElement);
        dom.insertBoundary(fragment, null);
        dom.insertBoundary(fragment, 0);
        block(env, morph0, context, "tag-bloco", [], {"tamanho": 12, "titulo": "Descrição"}, child0, null);
        return fragment;
      }
    };
  }()));

});
define('webapp/components/tabela-form', ['exports', 'ember', 'webapp/utils/id', 'webapp/utils/util'], function (exports, Ember, UtilID, Util) {

  'use strict';

  /*
   Exemplo de uso:
   {{tabela-form
   fonte=this.dadosBancarios
   colunas='["Código do Banco","Nome Do Banco", "Agência","Conta","Principal"]'
   fonte=this.dadosBancarios
   fonteColunas='["banco.codigo", "banco.nome", "agencia", "principal"]'
   atributoID='codSof'
   funcaoEscolhaCorCelula="nomeFuncao"
   metodosExibicaoFonteColunas='{"agencia": "mostraAgencia"}'
   colunasNaoEscapar='["agencia"]'
   contexto="dadoBancario" //opcionalmente pode se passar this (ver NOTA 2)
   funcaoIdsRota="nomeFuncao"
   rota="nome/da/rota"
   permiteAcessar=<true|false (default)>
   permiteExcluir=<true|false (default)>
   mostraTextoBotoes=<true|false (default)>
   paginacao=<true|false (default)>
   acaoCustomizada='{
                     "nomeNoController":"selecionarServidor",
                     "nomeLegivel": "Selecionar",
                     "icone": <texto que represente ícone do Font Awesome>,
                     "tooltip": <texto para aparecer quando passar o mouse no botão;
                                  o default é o texto do nomeLegivel>
                    }'
  }}

   // *** PARAMETROS ***
  - colunas: texto apresentado no título de cada coluna da tabela
  - fonte: de onde vêm os dados
  - fonteColunas: informação de 'fonte' para cada coluna
  - atributoID: informação de 'fonte'  que vai ser usada como id do objeto da linha, caso se queira
   utilizar um campo diferente de 'id'.
  - funcaoEscolhaCorCelula: string representando o nome da função do controller que será chamada
   para decidir qual será a cor do conteúdo da célula
  - metodosExibicaoFonteColunas: string com objeto onde cada chave representa uma coluna em
   fonteColunas e o valor é um string com o nome de um método no controller do contexto para transformar
   os dados em valores para visualização. O método deve receber o objeto inteiro da linha da tabela e
   devolver o valor que se deseja para a coluna indicada (mesmo que esse valor seja baseado em mais de
   um atributo do objeto). O nome da coluna, nesses casos, não precisa ser o nome de um atributo específico,
   podendo ser um nome representativo do valor que se obterá na coluna.
  - colunasNaoEscapar: string com um array dos nomes das propriedades que representam colunas cujo
   valor deve ser interpretado como código HTML (por exemplo, para usar CSS de algum caractere especial)
  - contexto: controller relacionado à tabela
  - funcaoIdsRota: função que recebe o objeto inteiro da linha da tabela e devolve um array com os ids
    necessários para que se consiga acessar a rota representada pelo contexto, na ordem em que eles devem
    aparecer na rota. Isso é necessário quando as rotas e controllers necessários para chegar ao último
    nodo da rota do contexto ainda não estão instanciadas (por exemplo, se se estiver na rota "/" e se
    quiser acessar diretamente "/rotaA/12/rotaB/141/rotaC/9"). Caso não seja informada essa função, o link
    será inferido automaticamente pelo Ember dado o contexto informado e o apenas o id da entidade a ser
    acessada.
  - rota: caso se queira utilizar uma rota alternativa para acessar os itens da tabela (que tenha um nome
    diferente do contexto passado para o componente), deve-se usar essa opção (ex. "rotaA/rotaB")
  - permiteAcessar: mostra botão "Acessar"
  - permiteExcluir: mostra botão "Excluir"
  - paginacao: se true, apresenta as linhas de forma paginada; se false (default), os resultados são
    apresentados, com uma barra de rolagem.
  - acaoCustomizada: cria um botão com a ação descrita por "nomeNoController", com o texto "nomeLegivel".

   -- INFO --
  Toda tabela com id "tabela_W_X_Y_Z"
  cria botões com id "botao_excluir_item<id>_Y_tabela"     ===> caso permiteExcluir=true
  e links com id "link_ler_item<id>_Y_tabela"              ===> caso permiteAcessar=true

   NOTA: As colunas são um array de strings dentro de um string (ex. '["coluna1", "coluna2"]']),
   que será "parsed" pelo JSON. Isso é necessário porque não conseguimos passar o array javascript
   diretamente.

   NOTA 2: caso o contexto seja um controller, basta indicar seu nome no contexto.
   Caso seja um componente externo, é necessário que se passe a própria instância do controller
   para que, ao chamar ações do contexto do componente externo, se tenha acesso às variáveis
   internas do componente externo. NESSES CASOS HAVERÁ PROBLEMAS DE ACESSO AOS ITENS DA LISTA,
   JÁ QUE ELE É BASEADO EM UM ENDEREÇO REPRESENTADO PELO NOME DO CONTEXTO .
   */

  exports['default'] = Ember['default'].Component.extend({
    tagName: 'table',
    classNameBindings: ['classText'],
    classText: 'containerTabela table table-striped table-hover display',
    // attributeBindings: ['colunas', 'fonte', 'fonteColunas', 'acaoCustomizada',
    //                     'atributoID', 'permiteExcluir', 'permiteAcessar',
    //                     'contexto', 'funcaoConstrucaoLink', 'funcaoEscolhaCorCelula',
    // 									  'metodosExibicaoFonteColunas', 'colunasNaoEscapar', 'scroll'],
    atributoID: 'id', //indica o atributo que identifica os itens da fonte - o default é 'id'
    permiteExcluir: false, //indica se mostra o botão de excluir nos itens da tabela
    permiteAcessar: false, //indica se mostra o botão de acessar nos itens da tabela
    mostraTextoBotoes: false, //indica se os botões da tabela terão texto;
    //se for falso, aparecerão apenas os ícones
    table: null, //armazena o objeto dataTables

    metodosExibicaoFonteColunas: '{}', //inicialização de parâmetro
    colunasNaoEscapar: '[]', //inicialização de parâmetro
    scroll: false,

    tooltipAcaoCustomizada: (function () {
      return this.get('acaoCustomizadaParse').tooltip || this.get('acaoCustomizadaParse').nomeLegivel;
      //return 'teste';
    }).property('acaoCustomizada'),

    _verificaContexto: (function () {
      var temAcaoDefinida = this.permiteExcluir || this.permiteAcessar || this.acaoCustomizada;
      Ember['default'].assert('Erro. Não é possível atributir ações (excluir, acessar, ...) sem definir um contexto para os itens da tabela.', !(temAcaoDefinida && !this.contexto));
    }).on('willInsertElement'),

    mostraColunaAcoes: (function () {
      var temAcaoParaMostrar = this.permiteAcessar || this.permiteExcluir || this.acaoCustomizada;
      return temAcaoParaMostrar && this.contexto;
    }).property('contexto', 'permiteAcessar', 'permiteExcluir', 'acaoCustomizada'),

    controllerDoContexto: (function () {
      if (typeof this.contexto === 'string') {
        return this.container.lookup("controller:" + this.contexto);
      }

      return this.contexto;
    }).property('contexto'),

    //Possíveis configurações disponíveis em https://datatables.net/reference/option/
    _adicionarDataTables: (function () {

      var alturaScroll = "500px";
      var paginacao = false;
      if (this.paginacao) {
        alturaScroll = undefined;
        paginacao = true;
      }

      this.table = this.$().DataTable({
        //"columnDefs": [ {"type": "numeric", "targets": 2} ],
        "processing": true,
        //"autoWidth": false,
        "scrollY": alturaScroll,
        "scrollCollapse": true,
        "paging": paginacao,
        "pageLength": 50,
        "deferRender": true,
        "language": {
          "sEmptyTable": "Nenhum registro encontrado",
          "sInfo": "Mostrando de _START_ até _END_ de _TOTAL_ registros",
          "sInfoEmpty": "Mostrando 0 até 0 de 0 registros",
          "sInfoFiltered": "(Filtrados de _MAX_ registros)",
          "sInfoPostFix": "",
          "sInfoThousands": ".",
          "sLengthMenu": "_MENU_ resultados por página",
          "sLoadingRecords": "Carregando...",
          "sProcessing": "Processando...",
          "sZeroRecords": "Nenhum registro encontrado",
          "sSearch": "Pesquisar",
          "oPaginate": {
            "sNext": "Próximo",
            "sPrevious": "Anterior",
            "sFirst": "Primeiro",
            "sLast": "Último"
          },
          "oAria": {
            "sSortAscending": ": Ordenar colunas de forma ascendente",
            "sSortDescending": ": Ordenar colunas de forma descendente"
          }
        }
      });
    }).on('didInsertElement'),

    _destruirDataTables: (function () {
      this.table.destroy();
    }).on('willDestroyElement'),

    _refreshDataTables: (function () {
      this.rerender();
    }).observes('fonteInterna'),

    _chamaMetodosExibicaoColunas: function _chamaMetodosExibicaoColunas(linha) {
      for (var colunaFonte in this.get('metodosExibicaoFonteColunasParse')) {
        var metodo = this.get('metodosExibicaoFonteColunasParse')[colunaFonte];
        linha[colunaFonte] = this.get('controllerDoContexto')[metodo](linha);
      }
    },

    fonteInterna: (function () {
      var _this = this;

      if (!this.fonte) {
        return [];
      }

      Ember['default'].assert('Não foi definido o ID da tabela ' + this.toString(), this.id);

      var contadorLinhasTabela = 0;

      return this._obtemArrayFonte().map(function (linha) {

        contadorLinhasTabela++;

        var linhaClone = Util['default'].clonaStringfyParse(linha);

        _this._chamaMetodosExibicaoColunas(linhaClone);

        var resultado = [];
        //Adiciona o atributo identificador do objeto original (para os links, botões, ...).
        //Caso não haja id no item da tabela (ex. item de acréscimo/supressão em contrato),
        //coloca um número aleatório no id (caso contrário, todos os botões excluir ficarão com
        //id igual, baseado no 'undefined' do id do item)
        resultado.id = linhaClone[_this.atributoID] ? linhaClone[_this.atributoID] : 'Provisorio' + Math.random();

        resultado.objetoOriginal = linhaClone; //adiciona o objeto original (para as ações,
        //pois há casos em que os objetos na tabela
        //ainda não têm id - ex. itens recém a adicionar
        //em acrescimo-supressao em contrato/aditivo)

        var idLinha = UtilID['default'].getSeccao(_this.id, 'informacao') + '-linha' + contadorLinhasTabela + '-item' + resultado.id;
        var contexto = UtilID['default'].getSeccao(_this.id, 'contexto');

        resultado.idLink = UtilID['default'].constroi('link', 'ler', idLinha, contexto, 'tabela');
        resultado.idBotaoAcessar = UtilID['default'].constroi('botao', 'ler', idLinha, contexto, 'tabela');
        resultado.idBotaoExcluir = UtilID['default'].constroi('botao', 'excluir', idLinha, contexto, 'tabela');

        //Define cada célula da tabela como um objeto do tipo {valor: "...", ordem: "...", cor: ...}
        _this._fonteColunasParse().forEach(function (coluna) {
          var celula = {};
          celula.valor = _this._recuperaValorCelula(linhaClone, coluna);
          celula.ordem = _this._calculaValorOrdinalCelula(celula.valor);
          celula.cor = _this._defineCorCelula(linhaClone);
          celula.notEscape = _this.get('colunasNaoEscaparParse').includes(coluna.join('.'));
          resultado.push(celula);
        });

        return resultado;
      });
    }).property("fonte", "fonte.@each"),

    //recupera valor interno do objeto seguindo o array de indireções
    //ex:
    //a = {b: {c: "d"}}
    //fonte = a, indirecoes = ["b","c"] => "d"
    //internamente: a["b"] -> {c: "d"} ["c"] -> "d"
    _recuperaValorCelula: function _recuperaValorCelula(fonte, indirecoes) {
      var resultado = fonte;
      indirecoes.forEach(function (indirecao) {
        resultado = resultado ? resultado[indirecao] : resultado;
      });
      return resultado;
    },

    //Calcula um valor para a célula a ser usado na ordenação da respectiva coluna da tabela
    _calculaValorOrdinalCelula: function _calculaValorOrdinalCelula(valorCelula) {
      //Valores de texto e (a princípio) numéricos são ordenadas corretamente na tabela
      //Outros tipos (datas, números anuais, booleanos) são ordenados como string, o que
      //causa problemas (ex. 20/01/2015 > 10/02/2016)
      //Esta função trata as exceções identificadas.

      //TODO Fazer mecanismo para, no caso de tipos de valores que ocorram raramente e
      //necessitem ordenação especial, possa ser passada uma função indicando como ordenar
      //(ou seja, retorne um valor numérico de forma que, para elementos A e B, o número
      //de A seja menor que o de B caso A deva aparecer antes de B)

      //Se a coluna for data (Date), o inteiro correspondente à sua data é utilizado na
      if (valorCelula instanceof Date) {
        return Date.parse(valorCelula);
      }
      //Se a coluna for boolean, a ordenação ocorre primeiro com os valores verdadeiros
      else if (valorCelula === true || valorCelula === false) {
          return valorCelula ? '0' : '1';
        }
        //Se a coluna for do tipo "número/ano" (ex. 151/2016, 200/16), a ordenação ocorre
        //primeiramente pelo ano e depois pelo número
        else if (/^[0-9]+\/[0-9]+$/.test(valorCelula)) {
            var numero = valorCelula.split('/')[0];
            var ano = valorCelula.split('/')[1];
            //Multiplica o ano por um valor alto para ficar mais significativo que o número
            return ano * 100000 + numero;
          }
          //Se não for caso especial, ordena pelo próprio valor da célula
          else {
              return valorCelula;
            }
    },

    //Define a cor a ser utilizada no texto das células de uma linha, quando for o caso
    _defineCorCelula: function _defineCorCelula(linha) {
      if (this.funcaoEscolhaCorCelula) {
        var controller = this.get('controllerDoContexto');
        return controller[this.funcaoEscolhaCorCelula].call(controller, linha);
      }
    },

    _obtemArrayFonte: function _obtemArrayFonte() {
      return Array.isArray(this.fonte) ? this.fonte : this.fonte.get('model');
    },

    colunasParse: (function () {
      return JSON.parse(this.colunas);
    }).property(),

    //retorna arrays de strings do parametro fonteColunas
    //ex ["a","a.b"] -> [["a"],["a","b"]]
    _fonteColunasParse: function _fonteColunasParse() {
      return JSON.parse(this.fonteColunas).map(function (c) {
        return c.split(".");
      });
    },

    acaoCustomizadaParse: (function () {
      return JSON.parse(this.acaoCustomizada);
    }).property(),

    metodosExibicaoFonteColunasParse: (function () {
      return JSON.parse(this.metodosExibicaoFonteColunas);
    }).property(),

    colunasNaoEscaparParse: (function () {
      return JSON.parse(this.colunasNaoEscapar);
    }).property(),

    _enviaAcaoParaControllerDoContexto: function _enviaAcaoParaControllerDoContexto(nomeAcao, objeto) {
      this.get('controllerDoContexto').send(nomeAcao, objeto);
    },

    actions: {
      excluirFonte: function excluirFonte(objeto) {
        this._enviaAcaoParaControllerDoContexto('excluir', objeto);
      },
      acaoCustomizada: function acaoCustomizada(objeto) {
        this._enviaAcaoParaControllerDoContexto(this.get('acaoCustomizadaParse').nomeNoController, objeto);
      },
      acessar: function acessar(objetoLinha) {
        var controller = this.get('controllerDoContexto');
        var idsRota = this.funcaoIdsRota ? controller[this.funcaoIdsRota](objetoLinha) : idsRota = [objetoLinha.id];
        var argumentos = [this.rota ? this.rota : this.contexto].concat(idsRota);
        controller.transitionToRoute.apply(controller, argumentos);
      }
    }
  });

});
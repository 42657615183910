define('webapp/components/busca-fornecedor', ['exports', 'ember', 'webapp/mixins/ajusta-tamanho-input-mixin', 'webapp/mixins/constroi-aria-label-mixin'], function (exports, Ember, AjustaTamanhoInputMixin, ConstroiAriaLabelMixin) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(AjustaTamanhoInputMixin['default'], ConstroiAriaLabelMixin['default'], {

    comunicador: Ember['default'].inject.service(),

    mostrarBuscaFornecedores: false,
    mostrarResultados: false,
    fornecedores: [],
    fornecedorSelecionado: {}, //para uso interno do componente (de onde serão
    //buscados os dados a serem apresentados, etc.)

    texto: 'Nome',

    nomeAPesquisar: '',
    nomeFantasiaAPesquisar: '',
    municipioFantasiaAPesquisar: '',
    cnpjFantasiaAPesquisar: '',
    cpfFantasiaAPesquisar: '',
    fornecidoFantasiaAPesquisar: '',
    fornecivelFantasiaAPesquisar: '',

    desabilitarBotoes: (function () {
      return this.desabilitado || this.somenteLeitura;
    }).property('desabilitado', 'somenteLeitura'),

    inicializar: (function () {
      this.inicializarFornecedorSelecionado();
      this.buscaCategoriasProduto();
    }).on('init'),

    inicializarFornecedorSelecionado: (function () {
      this.set('fornecedorSelecionado', this.atributoDestino);
    }).observes('atributoDestino'),

    labelAcessivelCampo: (function () {
      return this.labelAcessivel;
    }).property('labelAcessivel'),

    instrucoesAdicionaisCampo: (function () {
      return this.get('desabilitarBotoes') ? 'Botões para alteração desabilitados.' : 'Use os botões que vêm a seguir para alterar o fornecedor.';
    }).property('desabilitarBotoes'),

    labelAcessivelBotaoPesquisar: (function () {
      return 'Iniciar busca de ' + (this.labelAcessivel ? this.labelAcessivel : this.texto ? this.texto : 'fornecedor');
    }).property('labelAcessivel'),

    labelAcessivelBotaoExcluir: (function () {
      return 'Limpar ' + (this.labelAcessivel ? this.labelAcessivel : 'fornecedor') + ' selecionado';
    }).property('labelAcessivel'),

    // insereAriaLabel: function() {
    //   if(this.apenasBotao) {
    //     return;
    //   }
    //
    //   var campo = this.$('input')[0];
    //   var identificacaoInicial = 'Componente para busca de fornecedores, ';
    //   var instrucoesFinais = ', use o botão ao lado para selecionar';
    //   campo.setAttribute('aria-label', identificacaoInicial + this.get('_ariaLabel') + instrucoesFinais);
    // }.on('willInsertElement'),
    //
    buscaCategoriasProduto: function buscaCategoriasProduto() {
      this.get('comunicador').leParaPropriedade(this, 'classificacaoItem', 'categoriasProduto', "Não foi possível obter a lista de categorias de produtos!");
    },

    actions: {

      excluir: function excluir() {
        this.set('fornecedorSelecionado', null);
        this.set('atributoDestino', null);
      },

      iniciarBusca: function iniciarBusca() {
        this.set('nomeAPesquisar', undefined);
        this.set('nomeFantasiaAPesquisar', undefined);
        this.set('municipioAPesquisar', undefined);
        this.set('cnpjAPesquisar', undefined);
        this.set('cpfAPesquisar', undefined);
        this.set('fornecidoAPesquisar', undefined);
        this.set('fornecivelAPesquisar', undefined);

        this.set('mostrarBuscaFornecedores', true);
      },

      pesquisarFornecedor: function pesquisarFornecedor() {
        var _this = this;

        if (!this.nomeAPesquisar && !this.nomeFantasiaAPesquisar && !this.municipioAPesquisar && !this.cnpjAPesquisar && !this.cpfAPesquisar && !this.fornecidoAPesquisar && !this.fornecivelAPesquisar) {
          return;
        }

        var parametrosPesquisaFornecedor = {
          nome: this.nomeAPesquisar,
          nomeFantasia: this.nomeFantasiaAPesquisar,
          municipio: this.municipioAPesquisar,
          cnpj: this.cnpjAPesquisar,
          cpf: this.cpfAPesquisar,
          fornecido: this.fornecidoAPesquisar,
          fornecivel: this.fornecivelAPesquisar
        };

        this.set('mostrarResultados', false);

        this.get('comunicador').servico('post', 'fornecedor/pesquisa', parametrosPesquisaFornecedor).then(function (resultado) {
          _this.set('fornecedoresPesquisados', resultado);
          _this.set('mostrarResultados', true);
        });
      },

      selecionarFornecedor: function selecionarFornecedor(fornecedor) {
        this.set('atributoDestino', fornecedor);
        this.set('fornecedorSelecionado', fornecedor);
        this.set('mostrarBuscaFornecedores', false);
      },

      cancelarBusca: function cancelarBusca() {
        this.set('mostrarBuscaFornecedores', false);
      }

    }

  });

});
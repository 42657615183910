define('webapp/helpers/mostra-celula', ['exports', 'ember'], function (exports, Ember) {

	'use strict';

	exports.apenasDigitosComVirgulaDecimal = apenasDigitosComVirgulaDecimal;
	exports.incluiSeparadorMilharStringDecimal = incluiSeparadorMilharStringDecimal;
	exports.formataValorDate = formataValorDate;
	exports.preparaValorParaApresentar = preparaValorParaApresentar;
	exports.truncaSeNecessario = truncaSeNecessario;
	exports.adicionaEstiloHTML = adicionaEstiloHTML;
	exports.montarTagAberturaEstilo = montarTagAberturaEstilo;

	exports['default'] = Ember['default'].Handlebars.makeBoundHelper(function (linha, _ref) {
		var opcoes = _ref.hash;

		if (!linha) {
			return '';
		}

		var atributo = opcoes.coluna.atributo;
		var notEscape = opcoes.coluna.notEscape;
		var comprimentoMaximo = opcoes.coluna.comprimentoMaximo;

		var metodoApresentacao = opcoes.coluna.metodoApresentacao;
		var controller = opcoes.controllerReferencia;

		var cor = opcoes.coluna.cor;
		if (opcoes.funcaoEscolhaCorCelula) {
			cor = controller[opcoes.funcaoEscolhaCorCelula](linha);
		}

		if (metodoApresentacao) {
			return preparaValorParaApresentar(controller[metodoApresentacao](linha), comprimentoMaximo, cor);
		}

		var valor = Ember['default'].get(linha, atributo);

		if (notEscape) {
			return preparaValorParaApresentar(valor, comprimentoMaximo, cor);
		}

		var escaped = Ember['default'].Handlebars.Utils.escapeExpression(valor);

		//Se o valor for "true", mostra o símbolo "✓"
		if (escaped === "true") {
			return new Ember['default'].Handlebars.SafeString('<i class="fa fa-check" title="sim"></i>');
		}

		if (escaped === "false") {
			return preparaValorParaApresentar('', comprimentoMaximo, cor);
		}

		//Em strings representando valores decimais (ex.: moeda), inclui-se separador de milhar
		if (apenasDigitosComVirgulaDecimal(escaped)) {
			var numeroFormatado = incluiSeparadorMilharStringDecimal(escaped);
			return preparaValorParaApresentar(numeroFormatado, comprimentoMaximo, cor);
		}

		var valorParaApresentar = '';

		//O sistema só usa Date -> apenas objetos desse tipo devem ser convertidos para formato de data
		if (valor instanceof Date) {
			valorParaApresentar = preparaValorParaApresentar(formataValorDate(valor), comprimentoMaximo, cor);
		} else {
			valorParaApresentar = preparaValorParaApresentar(escaped, comprimentoMaximo, cor);
		}

		var tituloColunaAcessivel = opcoes.coluna.tituloAcessivel ? opcoes.coluna.tituloAcessivel : opcoes.coluna.titulo;

		var textoAcessivel = tituloColunaAcessivel + ", " + valorParaApresentar;
		if (opcoes.coluna.textoAcessivel) {
			textoAcessivel = controller[opcoes.coluna.textoAcessivel](valor, tituloColunaAcessivel, textoAcessivel);
		}

		return adicionaEstiloHTML(valorParaApresentar, cor, textoAcessivel);
	});

	function apenasDigitosComVirgulaDecimal(string) {
		return (/^\d+,\d{1,2}$/.test(string)
		);
	}

	function incluiSeparadorMilharStringDecimal(stringDecimal) {
		if (!stringDecimal || typeof stringDecimal !== 'string') {
			return '';
		}
		var formatoAmericano = stringDecimal.replace(',', '.');
		var stringComSeparador = Number(formatoAmericano).toLocaleString('pt', { minimumFractionDigits: 2 });
		return stringComSeparador === 'NaN' ? stringDecimal : stringComSeparador;
	}

	//Recebe uma instância de Date e retorna DD/MM/AAAA ou DD/MM/AAAA 99:99:99

	function formataValorDate(date) {
		var opcoesFormatoData = { day: 'numeric', month: 'numeric', year: 'numeric' };
		if (date.getHours() || date.getMinutes() || date.getSeconds()) {
			opcoesFormatoData['hour'] = '2-digit';
			opcoesFormatoData['minute'] = '2-digit';
		}
		return date.toLocaleString('pt-BR', opcoesFormatoData);
	}

	//export function preparaValorParaApresentar(valor, comprimentoMaximo, cor) {
	function preparaValorParaApresentar(valor, comprimentoMaximo) {
		if (!valor && valor !== 0) {
			return "";
		}
		return truncaSeNecessario(valor, comprimentoMaximo);
	}

	function truncaSeNecessario(valor, comprimentoMaximo) {
		if (valor && valor.length > comprimentoMaximo) {
			return valor.substring(0, comprimentoMaximo);
		} else {
			return valor;
		}
	}

	function adicionaEstiloHTML(valor, cor, textoAcessivel) {
		var tagAberturaEstilo = montarTagAberturaEstilo(cor, textoAcessivel);
		var tagFechamentoDeEstilo = '</font>';

		return new Ember['default'].Handlebars.SafeString(tagAberturaEstilo + valor + tagFechamentoDeEstilo);
	}

	function montarTagAberturaEstilo(cor, textoAcessivel) {
		var atributosParaCor = " style=\"font-weight: bold\" color=\"" + cor + "\"";
		var atributosAcessibilidade = " aria-label=\"" + textoAcessivel + "\"";
		return "<font" + (cor ? atributosParaCor : '') + (textoAcessivel ? atributosAcessibilidade : '') + ">";
	}

});
define('webapp/tests/unit/utils/util-test', ['ember-qunit', 'webapp/utils/util'], function (ember_qunit, Util) {

  'use strict';

  /* global ok, _ */

  ember_qunit.test('Util.compactaObjeto', function () {

    var o = Util['default'].compactaObjeto({
      id: 3,
      version: 5,
      foo: 'bar',
      a: 0,
      b: false,
      c: '',
      d: null,
      e: undefined,
      g: [],
      h: function h() {},
      i: { j: "asdf", k: "", l: { m: {} } },
      n: { o: "", p: { q: {} } }
    });

    var resultadoEsperado = { id: 3,
      version: 5,
      foo: "bar",
      a: 0,
      b: false,
      g: [],
      h: function h() {},
      i: { j: "asdf" }
    };

    ok(_.isEqual(JSON.parse(JSON.stringify(o)), JSON.parse(JSON.stringify(resultadoEsperado))));
  });

  ember_qunit.test('Util.compactaObjetoComData', function () {

    var dataAtual = new Date();

    var o = Util['default'].compactaObjeto({
      id: 3,
      version: 5,
      data: dataAtual
    });

    var resultadoEsperado = { id: 3,
      version: 5,
      data: dataAtual
    };

    ok(_.isEqual(JSON.parse(JSON.stringify(o)), JSON.parse(JSON.stringify(resultadoEsperado))));
  });

  // ehStringNumericaBR: function(numero) {
  ember_qunit.test('Util.ehStringNumericaBR', function () {
    //TODO SEPARAR ACERTOS EM MAIS VARIÁVEIS EXPLICANDO O QUE SE ESTÁ BUSCANDO TESTAR
    //EX. MOEDA, DECIMAL, INTEIRO
    var inteirosSemSeparadorMilhar = ['9', '99', '999', '9999', '99999', '999999'];
    var umaCasaDecimalSemMilhar = ['9,9', '99,9', '999,9', '9999,9', '99999,9', '999999,9'];
    var duasCasasDecimaisSemMilhar = ['9,99', '99,99', '999,99', '9999,99', '99999,99', '999999,99'];
    var tresCasasDecimaisSemMilhar = ['9,999', '99,999', '999,999', '9999,999', '99999,999', '999999,999'];
    var inteiroComSeparadorMilhar = ['9.999', '99.999', '999.999', '9.999.999'];
    var umaCasaDecimalComMilhar = ['9.999,9', '99.999,9', '999.999,9', '9.999.999,9'];
    var duasCasasDecimaisComMilhar = ['9.999,99', '99.999,99', '999.999,99', '9.999.999,99'];
    var tresCasasDecimaisComMilhar = ['9.999,999', '99.999,999', '999.999,999', '9.999.999,999'];

    var numerosValidosBR = inteirosSemSeparadorMilhar.concat(umaCasaDecimalSemMilhar).concat(duasCasasDecimaisSemMilhar).concat(tresCasasDecimaisSemMilhar).concat(inteiroComSeparadorMilhar).concat(umaCasaDecimalComMilhar).concat(duasCasasDecimaisComMilhar).concat(tresCasasDecimaisComMilhar);

    numerosValidosBR.forEach(function (numeroValido) {
      ok(Util['default'].ehStringNumericaBR(numeroValido), '"' + numeroValido + '" deve ser aceito como string numérica no formato PT-BR');
    });

    //TODO SEPARAR ERROS EM MAIS VARIÁVEIS EXPLICANDO O QUE SE ESTÁ BUSCANDO TESTAR
    //Simular erros de uso dos separadores milhar/decimal BR e erros por uso de separadores USA
    var numerosSeparadoresUSA = ['9.', '99.', '999.', '9999.', '9.9', '99.9', '999.9', '9999.9', '9.99', '99.99', '999.99', '9999.99', '9,999.9', '99,999.9', '999,999.9', '9,999,999.9', '9,999.99', '99,999.99', '999,999.99', '9,999.999.99'];

    var separadorDecimalSemParteInteira = [',', ',9', ',99', ',999', ',9999', ',99999', ',999999'];

    var separadorDecimalaSemParteDecimal = ['9,', '99,', '999,', '9999,', '99999,', '999999,'];

    var doisSeparadoresDecimais = [',9,', '9,9,', '99,9,', '999,9,', '9999,9,', '99999,9,', '999999,9,', ',9,9', '9,9,9', '99,9,9', '999,9,9', '9999,9,9', '99999,9,9', '999999,9,9', ',99,9', '9,99,9', '99,99,9', '999,99,9', '9999,99,9', '99999,99,9', '999999,99,9'];

    var separadorMilharNoInicio = ['.', '.9', '.99', '.999', '.9999', '.99999', '.9,9', '.99,9', '.999,9', '.9999,9', '.9,99', '.99,99', '.999,99', '.9999,99', '.9,999', '.99,999', '.999,999', '.9999,999', '.9,9999', '.99,9999', '.999,9999', '.9999,9999'];

    var separadorMilharNoFinal = ['9.', '99.', '999.', '9.999.'];

    var separadorMilharSeguidoDeMenosDe3Digitos = ['9.9', '99.9', '999.9', '9.999.9', '9.99', '99.99', '999.99', '9.999.99', '9.9.999', '99.9.999', '999.9.999', '9.99.999', '99.99.999', '999.99.999'];

    var outrosErros = [',9.', '9,9.', '99,9.', '999,9.', '9999,9.', '99999,9.', '999999,9.'];

    var numerosInvalidosBR = numerosSeparadoresUSA.concat(separadorDecimalSemParteInteira).concat(separadorDecimalaSemParteDecimal).concat(doisSeparadoresDecimais).concat(separadorMilharNoInicio).concat(separadorMilharNoFinal).concat(separadorMilharSeguidoDeMenosDe3Digitos).concat(outrosErros);

    numerosInvalidosBR.forEach(function (numeroInvalido) {
      ok(!Util['default'].ehStringNumericaBR(numeroInvalido), '"' + numeroInvalido + '" NÃO deve ser aceito como string numérica no formato PT-BR');
    });
  });

  // ehStringNumericaUSA: function(numero) {
  ember_qunit.test('Util.ehStringNumericaUSA', function () {
    //TODO SEPARAR ACERTOS EM MAIS VARIÁVEIS EXPLICANDO O QUE SE ESTÁ BUSCANDO TESTAR
    //EX. MOEDA, DECIMAL, INTEIRO
    var inteirosSemSeparadorMilhar = ['9', '99', '999', '9999', '99999', '999999'];
    var umaCasaDecimalSemMilhar = ['9.9', '99.9', '999.9', '9999.9', '99999.9', '999999.9'];
    var duasCasasDecimaisSemMilhar = ['9.99', '99.99', '999.99', '9999.99', '99999.99', '999999.99'];
    var tresCasasDecimaisSemMilhar = ['9.999', '99.999', '999.999', '9999.999', '99999.999', '999999.999'];
    var inteiroComSeparadorMilhar = ['9,999', '99,999', '999,999', '9,999,999'];
    var umaCasaDecimalComMilhar = ['9,999.9', '99,999.9', '999,999.9', '9,999,999.9'];
    var duasCasasDecimaisComMilhar = ['9,999.99', '99,999.99', '999,999.99', '9,999,999.99'];
    var tresCasasDecimaisComMilhar = ['9,999.999', '99,999.999', '999,999.999', '9,999,999.999'];

    var numerosValidosUSA = inteirosSemSeparadorMilhar.concat(umaCasaDecimalSemMilhar).concat(duasCasasDecimaisSemMilhar).concat(tresCasasDecimaisSemMilhar).concat(inteiroComSeparadorMilhar).concat(umaCasaDecimalComMilhar).concat(duasCasasDecimaisComMilhar).concat(tresCasasDecimaisComMilhar);

    numerosValidosUSA.forEach(function (numeroValido) {
      ok(Util['default'].ehStringNumericaUSA(numeroValido), '"' + numeroValido + '" deve ser aceito como string numérica no formato EN-US');
    });

    //TODO SEPARAR ERROS EM MAIS VARIÁVEIS EXPLICANDO O QUE SE ESTÁ BUSCANDO TESTAR
    //Simular erros de uso dos separadores milhar/decimal BR e erros por uso de separadores USA
    var numerosSeparadoresBR = ['9,', '99,', '999,', '9999,', '9,9', '99,9', '999,9', '9999,9', '9,99', '99,99', '999,99', '9999,99', '9.999,9', '99.999,9', '999.999,9', '9.999.999,9', '9.999,99', '99.999,99', '999.999,99', '9.999.999,99'];

    var separadorDecimalSemParteInteira = ['.', '.9', '.99', '.999', '.9999', '.99999', '.999999'];

    var separadorDecimalSemParteDecimal = ['9.', '99.', '999.', '9999.', '99999,', '999999.'];

    var doisSeparadoresDecimais = ['.9.', '9.9.', '99.9.', '999.9.', '9999.9.', '99999.9.', '999999.9.', '.9.9', '9.9.9', '99.9.9', '999.9.9', '9999.9.9', '99999.9.9', '999999.9.9', '.99.9', '9.99.9', '99.99.9', '999.99.9', '9999.99.9', '99999.99.9', '999999.99.9'];

    var separadorMilharNoInicio = [',', ',9', ',99', ',999', ',9999', ',99999', ',9.9', ',99.9', ',999.9', ',9999.9', ',9.99', ',99.99', ',999.99', ',9999.99', ',9.999', ',99.999', ',999.999', ',9999.999', ',9.9999', ',99.9999', ',999.9999', ',9999.9999'];

    var separadorMilharNoFinal = ['9,', '99,', '999,', '9,999,'];

    var separadorMilharSeguidoDeMenosDe3Digitos = ['9,9', '99,9', '999,9', '9,999,9', '9,99', '99,99', '999,99', '9,999,99', '9,9,999', '99,9,999', '999,9,999', '9,99,999', '99,99,999', '999,99,999'];

    var outrosErros = [',9.', '9,9.', '99,9.', '999,9.', '9999,9.', '99999,9.', '999999,9.', '.9,', '9.9,', '99.9,', '999.9,', '9999.9,', '99999.9,', '999999.9,'];

    var numerosInvalidosUSA = numerosSeparadoresBR.concat(separadorDecimalSemParteInteira).concat(separadorDecimalSemParteDecimal).concat(doisSeparadoresDecimais).concat(separadorMilharNoInicio).concat(separadorMilharNoFinal).concat(separadorMilharSeguidoDeMenosDe3Digitos).concat(outrosErros);

    numerosInvalidosUSA.forEach(function (numeroInvalido) {
      ok(!Util['default'].ehStringNumericaUSA(numeroInvalido), '"' + numeroInvalido + '" NÃO deve ser aceito como string numérica no formato EN-US');
    });
  });

  var umaCasaDecimalSemMilhar = [{ BR: '9,9', USA: '9.9' }, { BR: '99,9', USA: '99.9' }, { BR: '999,9', USA: '999.9' }, { BR: '9999,9', USA: '9999.9' }, { BR: '99999,9', USA: '99999.9' }, { BR: '999999,9', USA: '999999.9' }];

  var duasCasasDecimaisSemMilhar = [{ BR: '9,99', USA: '9.99' }, { BR: '99,99', USA: '99.99' }, { BR: '999,99', USA: '999.99' }, { BR: '9999,99', USA: '9999.99' }, { BR: '99999,99', USA: '99999.99' }, { BR: '999999,99', USA: '999999.99' }];

  var tresCasasDecimaisSemMilhar = [{ BR: '9,999', USA: '9.999' }, { BR: '99,999', USA: '99.999' }, { BR: '999,999', USA: '999.999' }, { BR: '9999,999', USA: '9999.999' }, { BR: '99999,999', USA: '99999.999' }, { BR: '999999,999', USA: '999999.999' }];

  var inteiroComSeparadorMilhar = [{ BR: '9.999', USA: '9,999' }, { BR: '99.999', USA: '99,999' }, { BR: '999.999', USA: '999,999' }, { BR: '9.999.999', USA: '9,999,999' }];

  var umaCasaDecimalComMilhar = [{ BR: '9.999,9', USA: '9,999.9' }, { BR: '99.999,9', USA: '99,999.9' }, { BR: '999.999,9', USA: '999,999.9' }, { BR: '9.999.999,9', USA: '9,999,999.9' }];

  var duasCasasDecimaisComMilhar = [{ BR: '9.999,99', USA: '9,999.99' }, { BR: '99.999,99', USA: '99,999.99' }, { BR: '999.999,99', USA: '999,999.99' }, { BR: '9.999.999,99', USA: '9,999,999.99' }];

  var tresCasasDecimaisComMilhar = [{ BR: '9.999,999', USA: '9,999.999' }, { BR: '99.999,999', USA: '99,999.999' }, { BR: '999.999,999', USA: '999,999.999' }, { BR: '9.999.999,999', USA: '9,999,999.999' }];

  var numerosComFormatacao = umaCasaDecimalSemMilhar.concat(duasCasasDecimaisSemMilhar).concat(tresCasasDecimaisSemMilhar).concat(inteiroComSeparadorMilhar).concat(umaCasaDecimalComMilhar).concat(duasCasasDecimaisComMilhar).concat(tresCasasDecimaisComMilhar);

  var inteirosSemSeparadorMilhar = ['9', '99', '999', '9999', '99999', '999999'];

  // stringNumericaUSAparaBR: function(numeroUSA) {
  //TODO SE A STRING ESTIVER MAL FORMATADA, RETORNAR INTACTA
  ember_qunit.test('Util.stringNumericaUSAparaBR', function () {
    inteirosSemSeparadorMilhar.forEach(function (i) {
      ok(_.isEqual(Util['default'].stringNumericaUSAparaBR(i), i), 'Se receber um inteiro sem separador de milhar (como "' + i + '"), deve retornar o mesmo número.');
    });
    numerosComFormatacao.forEach(function (n) {
      ok(_.isEqual(Util['default'].stringNumericaUSAparaBR(n.USA), n.BR), 'Se receber um número no formato "' + n.USA + '", deve retornar "' + n.BR + '".');
    });
  });

  // stringNumericaBRparaUSA: function(numeroBR) {
  //TODO SE A STRING ESTIVER MAL FORMATADA, RETORNAR INTACTA
  ember_qunit.test('Util.stringNumericaBRparaUSA', function () {
    inteirosSemSeparadorMilhar.forEach(function (i) {
      ok(_.isEqual(Util['default'].stringNumericaBRparaUSA(i), i), 'Se receber um inteiro sem separador de milhar (como "' + i + '"), deve retornar o mesmo número.');
    });
    numerosComFormatacao.forEach(function (n) {
      ok(_.isEqual(Util['default'].stringNumericaBRparaUSA(n.BR), n.USA), 'Se receber um número no formato "' + n.BR + '", deve retornar "' + n.USA + '".');
    });
  });

  /* Recebe um array [{chave: "FUNDAMENTO_ADESAO", valor: "xxxx"}, {chave: ...}...]
     e retorna um objeto do tipo {FUNDAMENTO_ADESAO: "xxx", ...}
  */
  // converteParametrosDeExibicao: function(parametros) {
  ember_qunit.test('Util.converteParametrosDeExibicao', function () {
    var arrayParametros = [{ chave: "C_1", valor: "V1" }, { chave: "C_2", valor: "V2" }, { chave: "C_3", valor: "V3" }];
    var objParametrosConvertido = Util['default'].converteParametrosDeExibicao(arrayParametros);
    var objParametrosEsperado = { "C_1": "V1", "C_2": "V2", "C_3": "V3" };

    ok(_.isEqual(objParametrosConvertido, objParametrosEsperado), 'Se converteParametrosDeExibicao receber como parâmetro ' + JSON.stringify(arrayParametros) + '\n      deve retornar ' + JSON.stringify(objParametrosEsperado));
  });

  //incluiPontuacaoCpf: function(cpf) {
  ember_qunit.test('Util.incluiPontuacaoCpf', function () {

    var cpfSemPontuacao = '12345678901';
    var nroInteiroCom11Digitos = 12345678901;
    var cpfComPontuacao = '123.456.789-01';

    ok(Util['default'].incluiPontuacaoCpf(cpfSemPontuacao) === cpfComPontuacao, 'A pontuação do CPF deve seguir o formato "xxx.xxx.xxx-xx". Fornecendo-se\n       o número ' + cpfSemPontuacao + ', a função deve retornar ' + cpfComPontuacao + '\n       (e não ' + Util['default'].incluiPontuacaoCpf(cpfSemPontuacao) + ')');

    ok(Util['default'].incluiPontuacaoCpf(nroInteiroCom11Digitos) === cpfComPontuacao, 'Fornecendo-se um número inteiro com 11 dígitos, a função deve retornar a\n       string correspondente, pontuada como CPF (e.g. fornecendo-se o número\n      ' + nroInteiroCom11Digitos + ', a função deve retornar ' + cpfComPontuacao + '\n      (e não ' + Util['default'].incluiPontuacaoCpf(nroInteiroCom11Digitos) + ')');

    var strComMaisDe11Digitos = '123456789012';
    var nroInteiroComMaisDe11Digitos = 123456789012;

    ok(Util['default'].incluiPontuacaoCpf(strComMaisDe11Digitos) === strComMaisDe11Digitos, 'Fornecendo-se um número com mais de 11 dígitos, a função deve retornar o\n      mesmo número, sem pontuação.');

    ok(Util['default'].incluiPontuacaoCpf(nroInteiroComMaisDe11Digitos) === nroInteiroComMaisDe11Digitos, 'Fornecendo-se um número inteiro com mais de 11 dígitos, a função deve\n      retornar o próprio número.');

    var strComMenosDe11Digitos = '1234567890';
    var nroInteiroComMenosDe11Digitos = 1234567890;

    ok(Util['default'].incluiPontuacaoCpf(strComMenosDe11Digitos) === strComMenosDe11Digitos, 'Fornecendo-se um número com menos de 11 dígitos, a função deve retornar o\n      mesmo número, sem pontuação.');

    ok(Util['default'].incluiPontuacaoCpf(nroInteiroComMenosDe11Digitos) === nroInteiroComMenosDe11Digitos, 'Fornecendo-se um número inteiro com menos de 11 dígitos, a função deve\n      retornar o próprio número.');

    ok(Util['default'].incluiPontuacaoCpf(null) === null, 'Fornecendo-se null, a função deve retornar null.');

    ok(Util['default'].incluiPontuacaoCpf(undefined) === undefined, 'Fornecendo-se undefined, a função deve retornar undefined.');

    ok(Util['default'].incluiPontuacaoCpf('') === '', 'Fornecendo-se \'\', a função deve retornar \'\'.');
  });

  // incluiPontuacaoCnpj: function(cnpj) {
  ember_qunit.test('Util.incluiPontuacaoCnpj', function () {

    var cnpjSemPontuacao = '12345678000112';
    var nroInteiroCom14Digitos = 12345678000112;
    var cnpjComPontuacao = '12.345.678/0001-12';

    ok(Util['default'].incluiPontuacaoCnpj(cnpjSemPontuacao) === cnpjComPontuacao, 'A pontuação do CNPJ deve seguir o formato "xx.xxx.xxx/xxxx-xx". Fornecendo-se\n       o número ' + cnpjSemPontuacao + ', a função deve retornar ' + cnpjComPontuacao + '\n       (e não ' + Util['default'].incluiPontuacaoCnpj(cnpjSemPontuacao) + ')');

    ok(Util['default'].incluiPontuacaoCnpj(nroInteiroCom14Digitos) === cnpjComPontuacao, 'Fornecendo-se um número inteiro com 14 dígitos, a função deve retornar a\n       string correspondente, pontuada como CNPJ (e.g. fornecendo-se o número\n      ' + nroInteiroCom14Digitos + ', a função deve retornar ' + cnpjComPontuacao + '\n      (e não ' + Util['default'].incluiPontuacaoCnpj(nroInteiroCom14Digitos) + ')');

    var strComMaisDe14Digitos = '123456789012345';
    var nroInteiroComMaisDe14Digitos = 123456789012345;

    ok(Util['default'].incluiPontuacaoCnpj(strComMaisDe14Digitos) === strComMaisDe14Digitos, 'Fornecendo-se um número com mais de 14 dígitos, a função deve retornar o\n      mesmo número, sem pontuação.');

    ok(Util['default'].incluiPontuacaoCnpj(nroInteiroComMaisDe14Digitos) === nroInteiroComMaisDe14Digitos, 'Fornecendo-se um número inteiro com mais de 14 dígitos, a função deve\n      retornar o próprio número.');

    var strComMenosDe14Digitos = '1234567890123';
    var nroInteiroComMenosDe14Digitos = 1234567890123;

    ok(Util['default'].incluiPontuacaoCnpj(strComMenosDe14Digitos) === strComMenosDe14Digitos, 'Fornecendo-se um número com menos de 14 dígitos, a função deve retornar o\n      mesmo número, sem pontuação.');

    ok(Util['default'].incluiPontuacaoCnpj(nroInteiroComMenosDe14Digitos) === nroInteiroComMenosDe14Digitos, 'Fornecendo-se um número inteiro com menos de 14 dígitos, a função deve\n      retornar o próprio número.');

    ok(Util['default'].incluiPontuacaoCnpj(null) === null, 'Fornecendo-se null, a função deve retornar null.');

    ok(Util['default'].incluiPontuacaoCnpj(undefined) === undefined, 'Fornecendo-se undefined, a função deve retornar undefined.');

    ok(Util['default'].incluiPontuacaoCnpj('') === '', 'Fornecendo-se \'\', a função deve retornar \'\'.');
  });

  ember_qunit.test('Util.comparaNumeros', function () {

    ok(Util['default'].comparaNumeros(2, 1) === 1, '2 deve ser MAIOR que 1');
    ok(Util['default'].comparaNumeros(2, 1.5) === 1, '2 deve ser MAIOR que 1.5');
    ok(Util['default'].comparaNumeros(2, 0) === 1, '2 deve ser MAIOR que 0');
    ok(Util['default'].comparaNumeros(2, -1) === 1, '2 deve ser MAIOR que -1');
    ok(Util['default'].comparaNumeros(0, -1) === 1, '0 deve ser MAIOR que -1');
    ok(Util['default'].comparaNumeros(-1.5, -2) === 1, '-1.5 deve ser MAIOR que -2');
    ok(Util['default'].comparaNumeros(-1, -2) === 1, '-1 deve ser MAIOR que -2');

    ok(Util['default'].comparaNumeros(1, 2) === -1, '1 deve ser MENROS que 2');
    ok(Util['default'].comparaNumeros(1.5, 2) === -1, '1.5 deve ser MENROS que 2');
    ok(Util['default'].comparaNumeros(0, 2) === -1, '0 deve ser MENROS que 2');
    ok(Util['default'].comparaNumeros(-1, 2) === -1, '-1 deve ser MENROS que 2');
    ok(Util['default'].comparaNumeros(-1, 0) === -1, '-1 deve ser MENROS que 0');
    ok(Util['default'].comparaNumeros(-2, -1.5) === -1, '-2 deve ser MENROS que -1.5');
    ok(Util['default'].comparaNumeros(-2, -1) === -1, '-2 deve ser MENROS que -1');

    ok(Util['default'].comparaNumeros(2, 2) === 0, '2 deve ser IGUAL a 2');
    ok(Util['default'].comparaNumeros(1.5, 1.5) === 0, '1.5 deve ser IGUAL a 1.5');
    ok(Util['default'].comparaNumeros(1, 1) === 0, '1 deve ser IGUAL a 1');
    ok(Util['default'].comparaNumeros(0, 0) === 0, '0 deve ser IGUAL a 0');
    ok(Util['default'].comparaNumeros(-1, -1) === 0, '-1 deve ser IGUAL a -1');
    ok(Util['default'].comparaNumeros(-1.5, -1.5) === 0, '1.5 deve ser IGUAL a 1.5');
    ok(Util['default'].comparaNumeros(-2, -2) === 0, '-2 deve ser IGUAL a -2');

    var valoresNaoNumericos = ['', null, undefined, 'aaa', '111'];
    var valorMuitoGrande = 9999999;
    var valorMuitoPequeno = -9999999;

    valoresNaoNumericos.forEach(function (valorNaoNumerico) {
      ok(Util['default'].comparaNumeros(valorNaoNumerico, 9999999) === -1, 'Se 1º argumento não for um número (como ' + valorNaoNumerico + ') a função deve retornar -1');
    });

    valoresNaoNumericos.forEach(function (valorNaoNumerico) {
      ok(Util['default'].comparaNumeros(-9999999, valorNaoNumerico) === 1, 'Se 2º argumento não for um número (como \'' + valorNaoNumerico + '\') a função deve retornar 1');
    });

    valoresNaoNumericos.forEach(function (n1) {
      valoresNaoNumericos.forEach(function (n2) {
        ok(Util['default'].comparaNumeros(n1, n2) === -1, 'Se ambos argumentos não forem números (como ' + (typeof n1 === 'string' ? '"' + n1 + '"' : n1) + ' e ' + (typeof n2 === 'string' ? '"' + n2 + '"' : n2) + ') a função deve retornar -1');
      });
    });
  });

  // parseFloatStringPtBR: function(numeroPtBr) {

  // _substituiPontosPorVirgulasEVirgulasPorPontos : function(texto) {

  // primeiraLetraEmMaiuscula: function(string) {
  //Exemplo: ASDF_FDSA -> Asdf Fdsa

  //USUAL: umaStringEmCamelCase -> Uma String Em Camel Case - OK
  //CASO ESPECIAL: stringComIDFuncionaTambem -> String Com ID Funciona Tambem - AINDA NÃO
  // separaECapitalizaPalavrasCamelCase: function(stringCamelCase) {

  //--------------------------------------------------

  // alerta: function(msg) {

  // alertaServidor: function(msgCliente, msgServidor) {

  // alertaErro: function(msgCliente, msgServidor) {

  // alertaSimples: function(msgCliente, msgServidor, titulo, tipo) {

  // clona: function(obj) {

  //Clona transformando o objeto em string e depois no JSON correspondente.
  //Não copia funções. Funciona bem com arrays.
  // clonaStringfyParse: function(obj) {

  //Copia os atributos 'importantes' de um Ember.Object para um objeto nativo do Javascript
  // serializaObjeto: function(objetoASerializar) {

});
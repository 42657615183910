define('webapp/pods/publico/constantes-transparencia', ['exports'], function (exports) {

    'use strict';

    exports['default'] = {

        opcoesAnos: function opcoesAnos(retroagir) {
            var anoAtual = parseInt(new Date().getFullYear());
            var retorno = [];

            for (var i = 0; i < retroagir; i++) {
                var ano = (anoAtual - i).toString();
                retorno[i] = { id: ano, valor: ano };
            }
            return retorno;
        },

        opcoesMeses: [{ id: '12', valor: 'Dezembro' }, { id: '11', valor: 'Novembro' }, { id: '10', valor: 'Outubro' }, { id: '09', valor: 'Setembro' }, { id: '08', valor: 'Agosto' }, { id: '07', valor: 'Julho' }, { id: '06', valor: 'Junho' }, { id: '05', valor: 'Maio' }, { id: '04', valor: 'Abril' }, { id: '03', valor: 'Março' }, { id: '02', valor: 'Fevereiro' }, { id: '01', valor: 'Janeiro' }],

        statusLicitacao: [{ id: 'ABERTA', nome: 'Aberta' }, { id: 'HOMOLOGADA', nome: 'Homologada' }],

        statusAta: [{ id: 'ANDAMENTO', nome: 'Em andamento' }, { id: 'CONCLUIDA', nome: 'Concluída' }],

        modalidadesDeLicitacao: [{ id: 'LICITACAO_PREGAO_ELETRONICO', nome: 'Licitação (pregão eletrônico)' }, { id: 'LICITACAO_PREGAO_PRESENCIAL', nome: 'Licitação (pregão presencial)' }, { id: 'LICITACAO_CONVITE', nome: 'Licitação (convite)' }, { id: 'LICITACAO_TOMADA_PRECOS', nome: 'Licitação (tomada de preços)' }, { id: 'LICITACAO_CONCORRENCIA', nome: 'Licitação (concorrência presencial)' }, { id: 'LICITACAO_CONCORRENCIA_ELETRONICA', nome: 'Licitação (concorrência eletrônica)' }],

        modalidadesDeCompras: [{ id: 'INEXIGIBILIDADE', nome: 'Inexigibilidade de Licitação' }, { id: 'DISPENSA', nome: 'Dispensa de Licitação' }]

    };

});
define('webapp/pods/aquisicao/route', ['exports', 'ember', 'webapp/utils/util', 'webapp/mixins/suprimir-templates-superiores-mixin'], function (exports, Ember, Util, SuprimirTemplatesSuperioresMixin) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(SuprimirTemplatesSuperioresMixin['default'], {
    setupController: function setupController(controller, model) {
      controller.set('model', Util['default'].clona(model));

      this.get('comunicador').leParaPropriedade(controller, 'orgao', 'orgaos', "Não foi possível obter os orgãos externos!");

      this.get('comunicador').leParaPropriedade(controller, 'programaTrabalho', 'programasDeTrabalho', "Não foi possível obter os programas de trabalho!");

      this.get('comunicador').leParaPropriedade(controller, 'tipoFiscalGestor', 'tiposFiscalGestor', "Não foi possível obter os tipos de fiscal/gestor!");

      this.get('comunicador').leParaPropriedade(controller, 'unidadeOrcamentaria/selecionavel/unidadeOrganizacionalCorrespondente', 'unidadesOrganizacionais', "Não foi possível obter as unidades organizacionais.");

      this.get('comunicador').leParaPropriedade(controller, 'fundamentoLegal/aquisicao', 'fundamentosLegais', 'Não foi possível obter os servidores');

      //fundamento.tipo pode ser penalidade ou uma modalidade de aquisição
      this.get('comunicador').servico('get', 'fundamentoLegal').then(function (fundamentosLegais) {
        var fundamentosLegaisPorModalidade = fundamentosLegais.reduce(function (arrayDeFundamentosLegaisPorModalidade_, fundamento) {
          if (!arrayDeFundamentosLegaisPorModalidade_[fundamento.tipo]) {
            arrayDeFundamentosLegaisPorModalidade_[fundamento.tipo] = [];
          }
          arrayDeFundamentosLegaisPorModalidade_[fundamento.tipo].pushObject(fundamento);
          return arrayDeFundamentosLegaisPorModalidade_;
        }, {});
        controller.set('arrayDeFundamentosLegaisPorModalidade', fundamentosLegaisPorModalidade);
      });

      this.get('comunicador').servico('get', 'modosDisputa').then(function (modosDeDisputa) {
        var modosDeDisputaPorModalidade = modosDeDisputa.reduce(function (arrayDeModosDeDisputaPorModalidade_, modoDeDisputa) {
          modoDeDisputa.modalidades.forEach(function (modalidade) {
            if (!arrayDeModosDeDisputaPorModalidade_[modalidade]) {
              arrayDeModosDeDisputaPorModalidade_[modalidade] = [];
            }
            var modoDeDisputaParaRadioGroup = {};
            modoDeDisputaParaRadioGroup.texto = modoDeDisputa.descricao;
            modoDeDisputaParaRadioGroup.valor = modoDeDisputa.codigoEnum;
            arrayDeModosDeDisputaPorModalidade_[modalidade].pushObject(modoDeDisputaParaRadioGroup);
          });
          return arrayDeModosDeDisputaPorModalidade_;
        }, {});
        controller.set('arrayDeModosDeDisputaPorModalidade', modosDeDisputaPorModalidade);
      });

      this.get('comunicador').servico('get', 'criteriosJulgamento').then(function (criteriosDeJulgamento) {
        var criteriosDeJulgamentoPorModalidade = criteriosDeJulgamento.reduce(function (arrayDeCriteriosDeJulgamentoPorModalidade_, criterioDeJulgamento) {
          criterioDeJulgamento.modalidades.forEach(function (modalidade) {
            if (!arrayDeCriteriosDeJulgamentoPorModalidade_[modalidade]) {
              arrayDeCriteriosDeJulgamentoPorModalidade_[modalidade] = [];
            }
            var criteriosDeJulgamentoParaRadioGroup = {};
            criteriosDeJulgamentoParaRadioGroup.texto = criterioDeJulgamento.descricao;
            criteriosDeJulgamentoParaRadioGroup.valor = criterioDeJulgamento.codigoEnum;
            arrayDeCriteriosDeJulgamentoPorModalidade_[modalidade].pushObject(criteriosDeJulgamentoParaRadioGroup);
          });
          return arrayDeCriteriosDeJulgamentoPorModalidade_;
        }, {});
        controller.set('arrayDeCriteriosDeJulgamentoPorModalidade', criteriosDeJulgamentoPorModalidade);
      });

      //Ao iniciar a rota de consulta de aquisição, resetam-se o fiscal e o gestor,
      //provocando a sugestão de seus emails, quando deveria ser respeitado
      //o email que veio gravado do servidor.
      //Assim, aqui desfazem-se essas sugestões de email inapropriadas
      controller.set('model.emailFiscal', controller.backupEmailFiscal);
      controller.set('model.emailGestor', controller.backupEmailGestor);

      setTimeout(function () {
        controller.selecionaAbaIdentificacao();
      }, "1000"); //
    },

    actions: {
      willTransition: function willTransition(transition) {
        this._preparaParametrosTransicaoRotaPesquisa(transition);
      } /*,
        didTransition: function() {
        // Ember.$(window).on('load', this.controller.executa);
         //this.controller.executa();
         return true; // Bubble the didTransition event
        }*/
    },

    //Se a transição for para a rota de pesquisa, envia um parâmetro indicando que é
    //necessário manter os dados da pesquisa anterior na tela
    _preparaParametrosTransicaoRotaPesquisa: function _preparaParametrosTransicaoRotaPesquisa(transition) {
      if (transition.targetName !== 'aquisicao/listagem') {
        return true;
      }

      var parametros = transition.queryParams ? Object.keys(transition.queryParams) : [];
      if (parametros.includes('manterDadosPesquisaAnterior')) {
        return true;
      }

      transition.abort();
      this.transitionTo('aquisicao/listagem', { queryParams: { manterDadosPesquisaAnterior: 'true' } });
    }

  });

});
define('webapp/models/unidade-medida', ['exports', 'ember'], function (exports, Ember) {

	'use strict';

	exports['default'] = Ember['default'].Object.extend({
			id: null,
			nome: '',
			nomeExibicao: ''
	});

});
define('webapp/defs', ['exports', 'webapp/config/environment'], function (exports, config) {

    'use strict';

    var enderecoEscolhido = document.location.protocol + "//localhost:8080/silc-api/rest/";
    //var enderecoEscolhido = "https://silc-dev.paas-hml.trt4.jus.br/silc-api/rest/"
    var enderecoEscolhido2 = document.location.protocol + "//10.4.2.99:8080/silc-api/rest/";

    exports['default'] = {

        /* -- SELECIONA AUTOMATICAMENTE O ENDEREÇO DO SERVIDOR BASEADO NO ENDEREÇO DO CLIENTE - NÃO FUNCIONA PARA TESTE - */
        enderecoServer: window.location.origin === config['default'].APP.dominioPorta ? enderecoEscolhido : window.location.origin === config['default'].APP.dominioPorta2 ? enderecoEscolhido2 : window.location.origin + "/silc-api/rest/",
        // Ex.: http://localhost:8080/silc-api/rest/

        //enderecoServer: `${window.location.origin}/sac-api/rest/`, //deploy
        //enderecoServer: enderecoEscolhido,

        enderecoCliente: window.location.origin + config['default'].baseURL,
        // Ex.:  http://localhost:8080/silc/

        //códigos de retorno http
        HTTP_CODIGO_OK: 200,
        HTTP_CODIGO_OK_SEM_RESULTADOS: 204,
        HTTP_CODIGO_ERRO_DADOS_SERVIDOR: 400, //gerado pelos frameworks do servidor
        HTTP_CODIGO_SESSAO_INVALIDA: 401,
        HTTP_CODIGO_NAO_AUTORIZADO: 403,
        HTTP_CODIGO_SERVICO_NAO_ENCONTRADO: 404,
        HTTP_CODIGO_ERRO_CONCORRENCIA: 409,
        HTTP_CODIGO_ERRO_TAMANHO_REQUISICAO: 413, // 413 Request Entity Too Large
        HTTP_CODIGO_ITEM_PESQUISADO_INEXISTENTE: 444, //convencionado
        HTTP_CODIGO_REQUISICAO_INVALIDA: 480, //convencionado
        HTTP_CODIGO_REQUISICAO_FORA_DO_HORARIO_PERMITIDO: 481, //convencionado
        HTTP_CODIGO_FALHA_ENVIO_PNCP: 482, //convencionado
        HTTP_CODIGO_ERRO_DE_VALIDACAO_PNCP: 483, //convencionado
        HTTP_CODIGO_ERRO_INTERNO_SERVIDOR: 500,
        HTTP_CODIGO_SERVICO_EM_MANUTENCAO: 503, //convencionado (usado pela comunidade http://www.checkupdown.com/status/E503.html)
        HTTP_CODIGO_ERRO_TENTATIVA_DE_GRAV_NO_BCO_COM_DIFS_TIMEZONES: 598, // convencionado
        HTTP_CODIGO_SISTEMA_EM_MANUTENCAO: 599, //convencionado

        // Configurações padrões do componente de tabela
        paginacao: {
            quantidadePaginas: 5, // Quantidade de páginas visíveis no paginador
            registrosPorPagina: 20 // Total de registros por página
        },

        // Configurações padrões do spinner de consultas
        spinner: {
            timeOut: 120000 // TimeOut em milisegundos
        },

        arrayFiltrosPNCP: ["NaoEnviados", "Rejeitados", "Todos"]

    };

});
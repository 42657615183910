define('webapp/pods/aquisicao/contrato/efeito-financeiro/route', ['exports', 'ember', 'webapp/utils/util'], function (exports, Ember, Util) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({
    //sempre vem do controller contrato.
    setupController: function setupController(controller, model) {
      controller.set('model', Util['default'].clona(model));
      controller.set('_aditivos', controller.get('controllers.aquisicao/contrato').model.aditivos);
      controller.set('_apostilas', controller.get('controllers.aquisicao/contrato').model.apostilas);
      /*
          this.get('comunicador').leParaPropriedade(
            controller,
            `contrato/${controller.get('controllers.aquisicao/contrato').model.id}/aditivo`,
            'aditivos',
            'Não foi possível obter a lista de aditivos do contrato.'
          );
      */
    }
  });

});
define('webapp/pods/aquisicao/item/quantidade-participacao/model', ['exports', 'ember'], function (exports, Ember) {

	'use strict';

	exports['default'] = Ember['default'].Object.extend({
		id: '',
		orgao: null,
		quantidade: null,

		findUrlFn: function findUrlFn(id) {
			return "itemAquisicao/participacao/" + id.toString();
		}
	});

});
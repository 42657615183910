define('webapp/pods/aquisicao/ata/adesao/item/model', ['exports', 'ember', 'webapp/pods/aquisicao/ata/item/model'], function (exports, Ember, ItemAta) {

	'use strict';

	exports['default'] = Ember['default'].Object.extend({
			id: null,
			//ataItem: ItemAquisicao.create(),
			ataItem: ItemAta['default'].create(),
			quantidade: null,

			findUrlFn: function findUrlFn(id) {
					return "adesaoExterna/itemAdesaoExterna/" + id.toString();
			}
	});

});
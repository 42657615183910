define('webapp/pods/aquisicao/contrato/aditivo/alteracao-fiscal/model', ['exports', 'ember', 'webapp/models/servidor'], function (exports, Ember, Servidor) {

	'use strict';

	exports['default'] = Ember['default'].Object.extend({
		id: null,

		novosFiscais: [],
		descricao: '',

		inicializar: (function () {
			this.set('novosFiscais', []);
		}).on('init')

	});

});
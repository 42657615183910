define('webapp/helpers/mostra-valor', ['exports', 'ember'], function (exports, Ember) {

	'use strict';

	exports.apenasDigitosComVirgulaDecimal = apenasDigitosComVirgulaDecimal;
	exports.incluiSeparadorMilharStringDecimal = incluiSeparadorMilharStringDecimal;
	exports.formataValorDate = formataValorDate;
	exports.preparaValorParaApresentar = preparaValorParaApresentar;
	exports.truncaSeNecessario = truncaSeNecessario;
	exports.adicionaEstiloHTML = adicionaEstiloHTML;

	exports['default'] = Ember['default'].Handlebars.makeBoundHelper(function (valor, _ref) {
		var opcoes = _ref.hash;

		if (!valor) {
			return '';
		}

		var cor = opcoes.cor;
		var notEscape = opcoes.notEscape;
		var comprimentoMaximo = opcoes.comprimentoMaximo;

		if (notEscape) {
			return preparaValorParaApresentar(valor, comprimentoMaximo, cor);
		}

		var escaped = Ember['default'].Handlebars.Utils.escapeExpression(valor);

		if (escaped === "true") {
			return preparaValorParaApresentar('<i class="fa fa-check" title="sim"></i>', comprimentoMaximo, cor);
		}

		if (escaped === "false") {
			return preparaValorParaApresentar('', comprimentoMaximo, cor);
		}

		//Em strings representando valores decimais (ex.: moeda), inclui-se separador de milhar
		if (apenasDigitosComVirgulaDecimal(escaped)) {
			var numeroFormatado = incluiSeparadorMilharStringDecimal(escaped);
			return preparaValorParaApresentar(numeroFormatado, comprimentoMaximo, cor);
		}

		//O sistema só usa Date -> apenas objetos desse tipo devem ser convertidos para formato de data
		if (valor instanceof Date) {
			return preparaValorParaApresentar(formataValorDate(valor), comprimentoMaximo, cor);
		}

		return preparaValorParaApresentar(escaped, comprimentoMaximo, cor);
	});

	function apenasDigitosComVirgulaDecimal(string) {
		return (/^\d+,\d{1,2}$/.test(string)
		);
	}

	function incluiSeparadorMilharStringDecimal(stringDecimal) {
		if (!stringDecimal || typeof stringDecimal !== 'string') {
			return '';
		}
		var formatoAmericano = stringDecimal.replace(',', '.');
		var stringComSeparador = Number(formatoAmericano).toLocaleString('pt', { minimumFractionDigits: 2 });
		return stringComSeparador === 'NaN' ? stringDecimal : stringComSeparador;
	}

	//Recebe uma instância de Date e retorna DD/MM/AAAA ou DD/MM/AAAA 99:99:99

	function formataValorDate(date) {
		var opcoesFormatoData = { day: 'numeric', month: 'numeric', year: 'numeric' };
		if (date.getHours() || date.getMinutes() || date.getSeconds()) {
			opcoesFormatoData['hour'] = '2-digit';
			opcoesFormatoData['minute'] = '2-digit';
		}
		return date.toLocaleString('pt-BR', opcoesFormatoData);
	}

	function preparaValorParaApresentar(valor, comprimentoMaximo, cor) {
		var valorParaEstilizar = truncaSeNecessario(valor, comprimentoMaximo);
		return adicionaEstiloHTML(valorParaEstilizar, cor);
	}

	function truncaSeNecessario(valor, comprimentoMaximo) {
		if (valor && valor.length > comprimentoMaximo) {
			return valor.substring(0, comprimentoMaximo);
		} else {
			return valor;
		}
	}

	function adicionaEstiloHTML(valor, cor) {
		var abreTagDeEstilo = '<font>';
		if (cor) {
			abreTagDeEstilo = "<font style=\"font-weight: bold\" color=\"" + cor + "\">";
		}
		var fechaTagDeEstilo = '</font>';
		return new Ember['default'].Handlebars.SafeString(abreTagDeEstilo + valor + fechaTagDeEstilo);
	}

});
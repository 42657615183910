define('webapp/pods/fornecedor/listagem/route', ['exports', 'ember', 'webapp/mixins/suprimir-templates-superiores-mixin'], function (exports, Ember, SuprimirTemplatesSuperioresMixin) {

	'use strict';

	exports['default'] = Ember['default'].Route.extend(SuprimirTemplatesSuperioresMixin['default'], {
		setupController: function setupController(controller) {
			this.get('comunicador').servico('get', 'classificacaoItem').then(function (resultado) {
				controller.set('categoriasProduto', resultado);
			});

			controller._gerenciarDadosPesquisaAnterior();
		}
	});

});
define('webapp/tests/helpers/teste-crud-controllers', ['exports', 'ember', 'webapp/mixins/crud-mixin'], function (exports, Ember, CrudMixin) {

          'use strict';



          exports['default'] = testaCrudController;
          function testaCrudController(assert, controller) {

                    assert.expect(7);

                    var nomeController = controller.toString().split(':')[1];
                    var crudMixin = CrudMixin['default'];

                    assert.ok(crudMixin.detect(controller), 'Controller de "' + nomeController + '" deve implementar o CrudMixin');

                    assert.ok(controller.crudConfig, 'Controller de "' + nomeController + '" deve ter uma propriedade "crudConfig" preenchida');

                    assert.ok(controller._actions, 'Controller de "' + nomeController + '" deve ter a propriedade "actions" preenchida');

                    assert.ok(controller._actions.criar, 'Controller de "' + nomeController + '" precisa ter uma ação "criar"');

                    assert.ok(controller._actions.ler, 'Controller de "adesao" precisa ter uma ação "ler"');

                    assert.ok(controller._actions.atualizar, 'Controller de "' + nomeController + '" precisa ter uma ação "atualizar"');

                    assert.ok(controller._actions.excluir, 'Controller de "' + nomeController + '" precisa ter uma ação "excluir"');
          }

});
define('webapp/utils/mascara-input', ['exports'], function (exports) {

	'use strict';

	//REGRAS PARA A MÁSCARA EM https://github.com/RobinHerbots/jquery.inputmask#masking-types

	exports['default'] = {
		mascara: function mascara(tipo) {
			switch (tipo) {
				case 'telefone':
					return '(99)9999-9999[9]';
				// case('email'):
				// 	return '*{+}[.*{+}][.*{+}][.*{+}]@*{+}.*{+}[.*{+}][.*{+}]';
				case 'numeros':
					return '9{*}';
				case 'decimal':
					return '9{*}[,9][9]';
				case 'cpf':
					return '999.999.999-99';
				case 'cep':
					return '99999-999';
				case 'cnpj':
					return '99.999.999/9999-99';
				case 'pisPasep':
					return '999.99999.99-9';
				case 'inss':
					return '99.999.99999/99';
				case 'empenho':
					//ex. 2015NE400332
					return '9999aa999999';
			}
			return tipo;
		}
	};

});
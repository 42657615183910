define('webapp/pods/aquisicao/ata/adesao/route', ['exports', 'ember', 'webapp/utils/util', 'webapp/mixins/suprimir-templates-superiores-mixin'], function (exports, Ember, Util, SuprimirTemplatesSuperioresMixin) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(SuprimirTemplatesSuperioresMixin['default'], {
    setupController: function setupController(controller, model) {
      controller.set('model', Util['default'].clona(model));

      this.get('comunicador').leParaPropriedade(controller, 'orgao', 'orgaos', 'Não foi possível obter os Órgãos Externos!');
    }

  });

});
define('webapp/components/tag-bloco', ['exports', 'ember', 'webapp/utils/id'], function (exports, Ember, UtilID) {

  'use strict';

  // *** Bloco de apresentação de informações agrupadas usadas na interface***
  //Exemplo de uso:
  //    {{#tag-bloco tamanho=[1-12] paddingZero=[true|false] titulo="<texto do título>" formulario=[true|false]}}
  //          <elementos internos>
  //    {{/tag-bloco}}
  //Atributos opcionais:
  //    obrigatorio:<false|true (defaul)> indica se a seção representada pelo bloco é de preenchimento obrigatório. Se true, aparecerá um "*" ao lado do título.
  //    complementosTitulo: Array de informações complementares ao título, transformado em texto separado de acordo com o atributo complementosTituloSeparador
  //                        Array composto por objetos com as seguintes propriedades:
  //                            tipo: "atributo", "função" ou "texto". Indica se a exibição daquele objeto será através de um atributo do controller, uma chamada de função ou um texto simples.
  //                            valor: No caso de "atributo", qual o nome do atributo a se buscar. No caso de "função", qual o nome da função. No caso de "texto", qual o texto.
  //                            atributoController: no caso de função, qual o atributo do controller será passado como parâmetro da função (se não informado, procurará pelo atributo "model").
  //                            propriedadesObservar: no caso de função, indica quais as propriedades tornam necessária nova chamada na função para atualização dos dados.
  //                            prefixo/sufixo: Adiciona um prefixo ou sufixo ao texto.
  //                        Exemplo de uso: complementosTitulo='[{"tipo":"atributo", "valor":"model.nome", "prefixo": "Nome: "},
  //                                                             {"tipo":"função", "valor":"geraTextoPadronizadoCpfCnpj", "atributoController": "model"}, "propriedadesObservar": ["model.cpf","model.cnpj"]]'
  //    complementosTituloSeparador: Atributo de separação na exibição do complementosTitulo
  //    botaoCabecalho: Objeto contendo a descrição de um botão que deve vir ao lado do título, na barra de titulo do bloco. Deve conter as seguintes propriedades:
  //                        acao: nome da acao que deve ser enviada ao controllerDoContexto.
  //                        icone: <texto que represente ícone do Font Awesome - http://gkoberger.github.io/Font-Awesome/icons/>.
  //                        labelAcessivel: texto para apresentar como label acessível do botão
  //                    Exemplo de uso: botaoCabecalho='{"acao":"abrirTelaSelecao", "icone":"search", "labelAcessivel": "abrir seleção de servidores"}'
  //    controllerDoContexto: referência ao controller do qual devem ser buscados os complementos do título e para o qual devem ser enviadas as ações do botão de cabeçalho.
  //    tamanho: Utilizado para montar a classe "col-md-xx" que será aplicada ao bloco. O default é 12.
  //    paddingZero [true | false (defaul)]: faz com que não haja padding em torno do bloco (que sempre é
  //                                 introduzido pelas classes "col-md-xx"). Default: true.
  //    formulario [true | false (defaul)]: Indica que o conteúdo do bloco deve ser envolvido por uma tag <form>,
  //                               de modo que ao teclar-se "Enter", o primeiro botão da tela seja acionado.
  //                               Default: false.
  //    formatoDiscreto [true | false (default)]: faz com que o componente seja apenas um painel branco com as informações a serem apresentadas.
  //                                              Caso não seja marcada esta opção, o painel segue apresentando bordas, com o aspecto "3D" (flutuante) e com a barra de título azul.

  exports['default'] = Ember['default'].Component.extend({
    tagName: 'span',
    tamanhoDefault: 12,
    titulo: null,
    complementosTitulo: null,
    complementosTituloSeparador: ' - ',
    controleAlteracao: false,

    ajustaTamanho: (function () {
      if (this.get('tamanho')) {
        Ember['default'].assert('Valor não numérico atribuído a tamanho de tag-bloco', !isNaN(this.get('tamanho')));
        Ember['default'].assert('tamanho de tag-bloco deve estar entre 1 e 12', this.get('tamanho') >= 1 && this.get('tamanho') <= 12);
      }

      var tamanhoAjustado = this.get('tamanho') || this.tamanhoDefault;

      this.$().addClass('col-md-' + tamanhoAjustado);

      if (this.get('paddingZero') === undefined || this.get('paddingZero')) {
        //default is true
        this.$().addClass('paddingZero');
      }
    }).on('willInsertElement'),

    classesCssPainel: (function () {
      if (this.formatoDiscreto) {
        return "panel";
      } else {
        return "panel panel-primary caixa-3d";
      }
    }).property(),

    tituloComplemento: (function () {
      var _this = this;

      // Se setado o título sendo um array, faz a varredura e monta o título.
      if (this.complementosTitulo) {
        var arrayComplementos = JSON.parse(this.complementosTitulo);
        var tituloComplementoMontado = "";
        if (arrayComplementos && arrayComplementos.length > 0) {
          arrayComplementos.forEach(function (item) {
            var valor = null;
            if (item.tipo === "atributo" && _this.controllerDoContexto) {
              valor = Ember['default'].get(_this.controllerDoContexto, item.valor);
              _this.controllerDoContexto.addObserver(item.valor, function () {
                // Testa se é diferente de null e se não está destruído para evitar que tente alterar o
                // atributo quando o componente já foi destruído - o que gera exceção e trava o sistema
                if (_this.controleAlteracao != null && !_this.isDestroyed && !_this.isDestro) {
                  _this.toggleProperty('controleAlteracao');
                }
              });
            } else if (item.tipo === "função") {
              var localNoController = _this.atributoController ? _this.atributoController : "model";
              valor = _this.controllerDoContexto[item.valor](Ember['default'].get(_this.controllerDoContexto, localNoController));

              if (item.propriedadesObservar) {
                item.propriedadesObservar.forEach(function (propriedade) {
                  _this.controllerDoContexto.addObserver(propriedade, function () {
                    // Testa se é diferente de null e se não está destruído para evitar que tente alterar o
                    // atributo quando o componente já foi destruído - o que gera exceção e trava o sistema
                    if (_this.controleAlteracao != null && !_this.isDestroyed && !_this.isDestro) {
                      _this.toggleProperty('controleAlteracao');
                    }
                  });
                });
              }
            } else if (item.tipo === "texto") {
              valor = item.valor;
            }

            if (valor) {
              if (item.prefixo) {
                valor = item.prefixo + valor;
              }
              if (item.sufixo) {
                valor = valor + item.sufixo;
              }

              tituloComplementoMontado += tituloComplementoMontado === "" ? valor : '' + _this.complementosTituloSeparador + valor;
            }
          });
        }

        return tituloComplementoMontado;
      }

      return "";
    }).property("complementosTitulo", "controleAlteracao"),

    _idBotaoCabecalho: (function () {
      if (!this.id) {
        return '';
      }

      return UtilID['default'].constroi('botao', 'acao-cabecalho-bloco', UtilID['default'].getSeccao(this.id, 'informacao'), UtilID['default'].getSeccao(this.id, 'contexto'), UtilID['default'].getSeccao(this.id, 'local'));
    }).property('id'),

    botaoCabecalhoParse: (function () {
      return JSON.parse(this.botaoCabecalho);
    }).property(),

    _enviaAcaoParaControllerDoContexto: function _enviaAcaoParaControllerDoContexto(nomeAcao) {
      this.get('controllerDoContexto').send(nomeAcao);
    },

    actions: {
      acaoBotaoCabecalho: function acaoBotaoCabecalho() {
        this._enviaAcaoParaControllerDoContexto(this.get('botaoCabecalhoParse').acao);
      }
    }
  });

});
define('webapp/pods/requisicao/listagem/controller', ['exports', 'ember'], function (exports, Ember) {

	'use strict';

	exports['default'] = Ember['default'].ArrayController.extend({
		queryParams: ['manterDadosPesquisaAnterior'],
		manterDadosPesquisaAnterior: false,

		breadCrumbConfig: {
			nomeApresentacao: 'Requisições'
		},

		opcoesBooleanasComDescricao: [{ id: 0, valor: true, descricao: 'Sim' }, { id: 1, valor: false, descricao: 'Não' }],

		temAquisicoes: (function () {
			if (!this.utilizadaEmAquisicoes && this.utilizadaEmAquisicoes !== false) {
				return undefined;
			} else {
				return this.utilizadaEmAquisicoes.valor;
			}
		}).property('utilizadaEmAquisicoes'),

		temItens: (function () {
			if (!this.possuiItens && this.possuiItens !== false) {
				return undefined;
			} else {
				return this.possuiItens.valor;
			}
		}).property('possuiItens'),

		_gerenciarDadosPesquisaAnterior: function _gerenciarDadosPesquisaAnterior() {
			if (!this.manterDadosPesquisaAnterior) {
				this.limparDadosPesquisa();
			}

			this.set('manterDadosPesquisaAnterior', false);
		},

		limparDadosPesquisa: function limparDadosPesquisa() {
			this.limparCamposPesquisa();
			this.limparTabelaResultados();
		},

		limparCamposPesquisa: function limparCamposPesquisa() {
			this.set('numero', undefined);
			this.set('ano', undefined);
			this.set('dataInicial', undefined);
			this.set('dataFinal', undefined);
			this.set('programa', undefined);
			this.set('objeto', undefined);
			this.set('requisitante', undefined);
			this.set('classificacoesItens', undefined);
			this.set('descricaoItem', undefined);
			this.set('possuiItens', undefined); //limpando temItens
			this.set('utilizadaEmAquisicoes', undefined); //limpando temAquisicoes
		},

		limparTabelaResultados: function limparTabelaResultados() {
			//limpa tabela de resultados
			this.set('mostraTabelaResultadoPesquisa', false);
			this.set('mostraMensagemPesquisaSemResultado', false);
		},

		actions: {
			pesquisar: function pesquisar() {
				var _this = this;

				this.set('mostrarResultados', false);

				this.get('comunicador').servico('post', 'requisicao/pesquisa', this.getProperties('numero', 'ano', 'dataInicial', 'dataFinal', 'programa', 'objeto', 'requisitante', 'classificacoesItens', 'descricaoItem', 'temItens', 'temAquisicoes'), this).then(function (resultado) {
					_this.set('model', resultado);
					var retornouItems = _this.get('model').length > 0;
					_this.set('mostraTabelaResultadoPesquisa', retornouItems);
					_this.set('mostraMensagemPesquisaSemResultado', !retornouItems);
					_this.set('mostrarResultados', true);
				});
			},

			limparPesquisa: function limparPesquisa() {
				this.limparDadosPesquisa();
				this.set('model', []);
			}
		}
	});

});
define('webapp/pods/aquisicao/contrato/apostilamento-reajuste/model', ['exports', 'ember'], function (exports, Ember) {

	'use strict';

	exports['default'] = Ember['default'].Object.extend({
		id: null,
		dataTermoInicial: '',
		tipoTermoInicial: '',
		dataTermoFinal: '',
		observacaoTermoInicial: '',
		duracao: 1,
		valorIndice: '',
		tipoValorBase: '', //ARBITRADO, INICIAL, INICIAL_ATUALIZADO, TOTAL_ATUAL
		valorBase: '',
		valorFinal: '',
		valorReajustado: '',
		dataCriacao: '',
		numeroApresentacao: '',
		dataAssinatura: '',
		indice: null,

		findUrlFn: function findUrlFn(id) {
			return 'contrato/apostilamento/' + id.toString();
		}
	});

});
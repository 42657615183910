define('webapp/overrides/component', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Component.reopen({

    _tiposComponenteDispensamID: ['ui-icon', 'input-mask', 'ember-modal-dialog-positioned-container', 'ember-wormhole'],

    //Nota: asserts transformados em warns enquanto verificamos a adequação da nomenclatura e sua obrigatoriedade
    _validarID: (function () {
      if (this._idDesnecessario()) {
        return;
      }

      /* Só usar quando o id está definido 
        //Ember.assert(`Não foi definido o ID do componente ${this.toString()}`, this.id !== undefined);
        if(this.id === undefined) {
        Ember.warn(`Não foi definido o ID do componente ${this.toString()} - ${this.value}`);
        }
        //Ember.assert(`O componente ${this.toString()} tem ID inválido (${this.id}).`, UtilID.ehValido(this.id));
        else if(!UtilID.ehValido(this.id)) {
        Ember.warn(`O componente ${this.toString()} tem ID inválido (${this.id}).`);
        }
      */
    }).on('didInsertElement'),

    _idDesnecessario: function _idDesnecessario() {
      var _this = this;

      return this._tiposComponenteDispensamID.find(function (x) {
        return x === _this._getTipoComponente();
      });
    },

    _getTipoComponente: function _getTipoComponente() {
      return this.toString().split(':')[1];
    },

    _teste: (function () {
      alert("INIT");
    }).on("init")

  });

});
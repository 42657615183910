define('webapp/tests/unit/controllers/index-test', ['ember-qunit'], function (ember_qunit) {

  'use strict';

  ember_qunit.moduleFor('controller:index', {});

  ember_qunit.test('index controller existe', function (assert) {
    var controller = this.subject();
    assert.ok(controller);
  });

});
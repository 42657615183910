define('webapp/pods/aquisicao/contrato/aditivo/listagem/controller', ['exports', 'ember'], function (exports, Ember) {

   'use strict';

   exports['default'] = Ember['default'].Controller.extend({

      needs: ['aquisicao/contrato'],

      textoBotao: (function () {
         return 'Adicionar ' + this.get('controllers.aquisicao/contrato.aditivoOuApostila');
      }).property('controllers.aquisicao/contrato.aditivoOuApostila'),

      titulo: (function () {
         return this.get('controllers.aquisicao/contrato.aditivoOuApostila') + "s";
      }).property('controllers.aquisicao/contrato.aditivoOuApostila')

   });

});
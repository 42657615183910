define('webapp/pods/aquisicao/andamento/criar/route', ['exports', 'ember', 'webapp/pods/aquisicao/andamento/model'], function (exports, Ember, Andamento) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({
    controllerName: 'aquisicao/andamento',
    setupController: function setupController(controller) {
      controller.set('model', Andamento['default'].create());
    },

    model: function model() {
      return Andamento['default'].create();
    }
  });

});
define('webapp/models/uf', ['exports', 'ember'], function (exports, Ember) {

	'use strict';

	exports['default'] = Ember['default'].Object.extend({
		id: null,
		nome: "",
		pais: null
	});

});
define('webapp/pods/application/controller', ['exports', 'ember', 'webapp/utils/util', 'webapp/config/environment'], function (exports, Ember, Util, config) {

  'use strict';

  /* global $ */
  exports['default'] = Ember['default'].Controller.extend({
    needs: ['login', 'index', 'application', 'fornecedor/listagem'],
    alertas_service: Ember['default'].inject.service('alertas_service'),
    usuarioLogado: Ember['default'].inject.service('usuario-logado'),
    estaNaRotaLogin: Ember['default'].computed.equal('controllers.application.currentRouteName', 'login'),

    breadCrumbsStickyOptions: {
      topSpacing: 52 //px, default: 0
    },

    PRD: function PRD() {
      return this.tipoAmbiente === 'PRD' && window.location.origin !== config['default'].APP.dominioPorta && window.location.origin !== config['default'].APP.dominioPorta2;
    },

    PRDLocal: function PRDLocal() {
      return this.tipoAmbiente === 'PRD' && (window.location.origin === config['default'].APP.dominioPorta || window.location.origin === config['default'].APP.dominioPorta2);
    },

    HML: function HML() {
      return this.tipoAmbiente === 'HML';
    },

    DESENV: function DESENV() {
      return this.tipoAmbiente === 'DESENV';
    },

    corMensFusosDif: (function () {
      return this.PRDLocal() ? "color: white;" : "color: red";
    }).property('tipoAmbiente'),

    ehPRD: (function () {
      return this.PRD();
    }).property('tipoAmbiente'),

    ehPRDLocal: (function () {
      return this.PRDLocal();
    }).property('tipoAmbiente'),

    ehHML: (function () {
      return this.HML();
    }).property('tipoAmbiente'),

    ehDESENV: (function () {
      return this.DESENV();
    }).property('tipoAmbiente'),

    estiloCabecalhoConformeAmbiente: (function () {
      var estilo = '';

      if (this.PRDLocal()) {
        estilo += ' background-color: red;';
        $('body').addClass('fundoVermelho');
      } else {
        $('body').removeClass('fundoVermelho');
      }

      if (this.HML()) {
        estilo += ' background-color: yellow;';
      } else if (this.DESENV()) {
        //estilo += ' background-color: orange;';
        //OPÇÃO ADICIONADA TEMPORARIAMENTE ATÉ IMPLEMENTAR-SE NO SISTEMA TRATAMENTO PARA A OPÇÃO DE VALOR "HML" DO PARÂMETRO "AMBIENTE"
        estilo += ' background-color: yellow;';
      }

      return estilo;
    }).property('tipoAmbiente'),

    classeDivCabecalhoConformeAmbiente: (function () {
      return this.PRD() ? "col-lg-6" : "col-lg-5";
    }).property('tipoAmbiente'),

    estiloTextoCabecalhoConformeAmbiente: (function () {
      var estilo = '';

      if (this.PRDLocal()) {
        estilo = ' color:red; background-color: black;';
      }

      if (this.HML()) {
        estilo = ' color: black; background-color: yellow;';
      } else if (this.DESENV()) {
        //estilo += ' background-color: orange;';
        //OPÇÃO ADICIONADA TEMPORARIAMENTE ATÉ IMPLEMENTAR-SE NO SISTEMA TRATAMENTO PARA A OPÇÃO DE VALOR "HML" DO PARÂMETRO "AMBIENTE"
        estilo = ' color: black; background-color: white;';
      }

      return estilo;
    }).property('tipoAmbiente'),

    tipoAmbiente: 'PRD',

    notificacao_tela: (function () {
      if (this.get('logado') && this.get('usuarioAdmin') && this.get('alertas_service').excecaoNaIdentificacaoOuNoEnvioDoAlerta()) {
        this.set('notificacao', 1);
      } else {
        this.set('notificacao', null);
      }
      return this.get('notificacao');
    }).property('usuarioLogado.info', 'alertas_service.estadoDaGravacao'),

    inicio: (function () {
      var self = this;

      setInterval(self.blink_text, 1000);

      this.get('alertas_service').set('alertas', null);

      this.get('comunicador').servico('get', 'parametroExibicao').then(function (resultado) {
        self.set('tipoAmbiente', Util['default'].converteParametrosDeExibicao(resultado).AMBIENTE);
      });
    }).on('init'),

    blink_text: function blink_text() {
      Ember['default'].$('.blink').fadeOut(500);
      Ember['default'].$('.blink').fadeIn(500);
    },

    // Rotas públicas não vão exibir menu lateral e nem menu superior
    rotaPublica: (function () {
      var rotaAtual = this.get('controllers.application.currentPath');
      return rotaAtual === "publico" || rotaAtual.indexOf('publico/') === 0;
    }).property('controllers.application.currentPath'),

    logado: (function () {
      return this.get('usuarioLogado').estaLogado();
    }).property('usuarioLogado.info'),

    nomeUsuarioLogado: (function () {
      var servidor = this.get('usuarioLogado').info.servidor;
      return servidor ? servidor.nome : '';
    }).property('usuarioLogado.info'),

    usuarioAdmin: (function () {
      var ehAdmin = this.get('usuarioLogado').ehAdmin.bind(this.get('usuarioLogado'));
      return ehAdmin();
    }).property('usuarioLogado.info'),

    tZsDifs: (function () {
      return this.get('usuarioLogado').info.tZsDifs;
    }).property('usuarioLogado.info.tZsDifs'),

    actions: {
      sair: function sair() {
        this.get('controllers.login').send('logout');
      },
      alertas: function alertas() {
        this.transitionToRoute('alerta/listagem');
      },
      abreFechaMenu: function abreFechaMenu() {
        if ($("nav.sidebar").hasClass('sidebar-aberta')) {
          $("nav.sidebar").removeClass('sidebar-aberta');
        } else {
          $("nav.sidebar").addClass('sidebar-aberta');
        }
      }
    } });

  Ember['default'].LinkView.reopen({
    attributeBindings: ['data-toggle', 'data-target']
  });

});
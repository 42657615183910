define('webapp/pods/aquisicao/contrato/aditivo/acrescimo-ou-supressao/item/criar/route', ['exports', 'ember', 'webapp/pods/aquisicao/contrato/aditivo/acrescimo-ou-supressao/item/model'], function (exports, Ember, ItemAcrescimoSupressao) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({
    controllerName: "aquisicao/contrato/aditivo/acrescimo-ou-supressao/item",
    setupController: function setupController(controller) {
      controller.set('model', ItemAcrescimoSupressao['default'].create({}));

      this.get('comunicador').leParaPropriedade(controller, 'classificacaoItem', 'classificacoesItens', "Não foi possível obter as Classificações Financeiras!");

      this.get('comunicador').leParaPropriedade(controller, 'unidadeMedida', 'unidadesDeMedida', 'Não foi possível obter as Unidades de Medida!');
    },

    model: function model() {
      return ItemAcrescimoSupressao['default'].create();
    }

  });

});
define('webapp/components/tag-textarea', ['exports', 'ember', 'webapp/mixins/ajusta-id-interno-mixin', 'webapp/mixins/ajusta-tamanho-input-mixin', 'webapp/mixins/constroi-aria-label-mixin'], function (exports, Ember, AjustaIdInternoMixin, AjustaTamanhoInputMixin, ConstroiAriaLabelMixin) {

	'use strict';

	/* Exemplo de uso
	{{tag-textarea
		texto=<texto do label>
		numeroLinhas=<quantidade de linhas a serem suportadas pelo text-area>
		comprimentoMaximo=<uma string numérica, indicando o número máximo de caracteres
																					 que p textarea deve aceitar. Ex.: 10>
	 	labelAcessivel=<texto para ser lido por leitores automáticos de interface
	              para acessibilidade por pessoas com deficiência visual.
	              Se não for definido, será lido o texto do label do campo>
	 	valor=<valor associado ao input>
		textoPlaceHolder=<mensagem que aparece no text-area quando não há nada preenchido>
		labelDestacado=<true | false (default), indicando se o label deve aparecer como
		uma tag "legend" (letras maiores, com uma linha horizontal separando da caixa de
		texto) ou uma tag "label" (texto em negrito, logo acima da caixa de texto)>
	}}

	*/
	exports['default'] = Ember['default'].Component.extend(AjustaIdInternoMixin['default'], AjustaTamanhoInputMixin['default'], ConstroiAriaLabelMixin['default'], {
		tipoInterno: 'textarea',

		insereAriaLabel: (function () {
			var campo = this.$('textarea')[0];
			campo.setAttribute('aria-label', this.get('_ariaLabel'));
		}).on('willInsertElement')

	});

});
define('webapp/pods/fornecedor/penalidade/model', ['exports', 'ember'], function (exports, Ember) {

	'use strict';

	exports['default'] = Ember['default'].Object.extend({
		id: null,

		tipos: [], //lista de TipoPenalidade
		numero: 0,
		fundamentosLegais: [], //lista FundamentoLegal
		motivo: '',

		objetoContratacao: '',
		numeroLicitacaoOuCompraDireta: '',
		numeroAtaRegistroPrecos: '',

		prazoPgtoMultaMoratoria: '', //data
		dataPgtoMultaMoratoria: '', //data
		multaMoratoriaInscritaDividaAtiva: false, //true ou false
		dataInscricaoMultaMoratoriaDividaAtiva: '',
		valorMultaMoratoria: '',

		prazoPgtoMultaCompensatoria: '', //data
		dataPgtoMultaCompensatoria: '', //data
		multaCompensatoriaInscritaDividaAtiva: false, //true ou false
		dataInscricaoMultaCompensatoriaDividaAtiva: '',
		valorMultaCompensatoria: '',

		ambitoIncidencia: '', //"ADMINISTRACAO" ou "ADMINISTRACAO_PUBLICA"
		processoAdministrativo: '',
		dataInicio: '',
		dataFim: '',
		dataAplicacao: '',
		nroContrato: '',
		nroEmpenho: '',
		contratoOuEmpenho: '', //"CONTRATO" ou "EMPENHO"

		numeroApresentacao: '',

		findUrlFn: function findUrlFn(id) {
			return 'fornecedor/penalidade/' + id.toString();
		}
	});

});
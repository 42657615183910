define('webapp/components/busca-servidor', ['exports', 'ember', 'webapp/mixins/ajusta-tamanho-input-mixin', 'webapp/mixins/constroi-aria-label-mixin'], function (exports, Ember, AjustaTamanhoInputMixin, ConstroiAriaLabelMixin) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(AjustaTamanhoInputMixin['default'], ConstroiAriaLabelMixin['default'], {

    comunicador: Ember['default'].inject.service(),

    mostrarBuscaServidores: false,
    mostrarResultados: false,
    servidoresPesquisados: [],
    servidorSelecionado: {}, //para uso interno do componente (de onde serão
    //buscados os dados a serem apresentados, etc.)
    texto: 'Nome',

    nomeAPesquisar: '',
    usernameAPesquisar: '',
    cpfAPesquisar: '',
    codSofAPesquisar: '',

    desabilitarBotoes: (function () {
      return this.desabilitado || this.somenteLeitura;
    }).property('desabilitado', 'somenteLeitura'),

    inicializar: (function () {
      this.inicializarServidorSelecionado();
    }).on('init'),

    inicializarServidorSelecionado: (function () {
      this.set('servidorSelecionado', this.atributoDestino);
    }).on('init').observes('atributoDestino'),

    labelAcessivelCampo: (function () {
      return this.labelAcessivel;
    }).property('labelAcessivel'),

    instrucoesAdicionaisCampo: (function () {
      return this.get('desabilitarBotoes') ? 'Botões para alteração desabilitados.' : 'Use os botões que vêm a seguir para alterar o servidor.';
    }).property('desabilitarBotoes'),

    labelAcessivelBotaoPesquisar: (function () {
      return 'Iniciar busca de ' + (this.labelAcessivel ? this.labelAcessivel : 'servidor');
    }).property('labelAcessivel'),

    labelAcessivelBotaoExcluir: (function () {
      return 'Limpar ' + (this.labelAcessivel ? this.labelAcessivel : 'servidor') + ' selecionado';
    }).property('labelAcessivel'),

    focalizar: (function () {
      if (this.autoFoco) {
        this.$('input')[0].focus();
      }
    }).on('didInsertElement'),

    actions: {

      excluir: function excluir() {
        this.set('servidorSelecionado', null);
        this.set('atributoDestino', null);
      },

      iniciarBusca: function iniciarBusca() {
        this.set('nomeAPesquisar', '');
        this.set('usernameAPesquisar', '');
        this.set('codSofAPesquisar', '');

        this.set('mostrarBuscaServidores', true);
      },

      pesquisarServidor: function pesquisarServidor() {
        var _this = this;

        if (!this.nomeAPesquisar && !this.usernameAPesquisar && !this.codSofAPesquisar) {
          return;
        }

        var parametrosPesquisaServidor = {
          nome: this.nomeAPesquisar,
          username: this.usernameAPesquisar,
          codSof: this.codSofAPesquisar
        };

        this.set('mostrarResultados', false);

        this.get('comunicador').servico('post', 'servidor/pesquisa', parametrosPesquisaServidor).then(function (resultado) {
          _this.set('servidoresPesquisados', resultado);
          _this.set('mostrarResultados', true);
        });
      },

      selecionarServidor: function selecionarServidor(servidor) {
        this.set('atributoDestino', servidor);
        this.set('servidorSelecionado', servidor);
        this.set('mostrarBuscaServidores', false);
      },

      cancelarBusca: function cancelarBusca() {
        this.set('mostrarBuscaServidores', false);
      }

    }

  });

});
define('webapp/mixins/crud-mixin', ['exports', 'ember', 'webapp/utils/util'], function (exports, Ember, Util) {

  'use strict';

  exports['default'] = Ember['default'].Mixin.create({

    // //** Variável de configuração **
    // crudConfig : {
    //   criar: {
    //     modelFn: function() {/*<função que define o modelo a enviar>*/},
    //     urlFn: function() {/*<função que retorna url da api>*/},
    //     okFn: function() {/*<função para executar em caso de sucesso>*/},
    //     erroFn: function() {/*<função para executar em caso de erro>*/}
    //   },
    //   ler: {
    //     urlFn: function() {/*<função que retorna url da api>*/},
    //     okFn: function() {/*<função para executar em caso de sucesso>*/},
    //     erroFn: function() {/*<função para executar em caso de erro>*/}
    //   },
    //   atualizar: {
    //     urlFn: function() {/*<função que retorna url da api>*/},
    //     okFn: function() {/*<função para executar em caso de sucesso>*/},
    //     erroFn: function() {/*<função para executar em caso de erro>*/}
    //   },
    //   excluir: {
    //     urlFn: function(modeloInput) {/*<função que retorna url da api>*/},
    //     okFn: function() {/*<função para executar em caso de sucesso>*/},
    //     erroFn: function() {/*<função para executar em caso de erro>*/}
    //   }
    // }
    //
    // OBS.: "criar", "ler", "atualizar" e "excluir" são Ember actions e podem receber
    // parâmetros. Para as ações "criar", "ler" e "atualizar", o primeiro parâmetro
    // será passado às funções "okFn" e "erroFn" - com isso elas podem ajustar seu
    // comportamento conforme o valor recebido. No caso da ação "excluir", o primeiro
    // parâmetro recebido será o modelo do item que deve ser excluído e o segundo será
    // aquele passado às funções "okFn" e "erroFn" .

    _nomesLegiveisParaAcoes: {
      'criar': 'Criação',
      'ler': 'Leitura',
      'atualizar': 'Alteração',
      'excluir': 'Exclusão'
    },
    _nomesMetodoHttpParaAcoes: {
      'criar': 'post',
      'ler': 'get',
      'atualizar': 'put',
      'excluir': 'delete'
    },

    _determinarUrlPeloConfig: function _determinarUrlPeloConfig(acao, idParaConcatenarNaUrl, modeloParaExecutarAcao) {
      return this.crudConfig[acao].urlFn ? this.crudConfig[acao].urlFn.call(this, idParaConcatenarNaUrl, modeloParaExecutarAcao) : null;
    },

    _determinarUrlPadrao: function _determinarUrlPadrao(idParaConcatenarNaUrl) {
      var classeModelo = this.toString().split(":")[1];
      var aditivoOuApostila = '';
      var controllerContrato;
      if (classeModelo == "aquisicao/contrato/aditivo") {
        controllerContrato = this.container.lookup('controller:aquisicao/contrato');
        aditivoOuApostila = controllerContrato.get('aditivoOuApostila');
        if (aditivoOuApostila == null || aditivoOuApostila.trim() == '') {
          // Se está vazio, significa que houve refresh da página, com isso a informação do campo aditivoOuApostila é perdido...
          aditivoOuApostila = JSON.parse(localStorage.getItem('aditivoOuApostila')); // ... então é preciso buscá-lo do localStorage
          controllerContrato.set('aditivoOuApostila', aditivoOuApostila);
        } else localStorage.setItem('aditivoOuApostila', JSON.stringify(aditivoOuApostila)); // Salva no localStorage para poder recuperar quando houver refresh da página         

        if (idParaConcatenarNaUrl !== undefined) {
          return this.container.lookup("model:" + classeModelo).findUrlFn(idParaConcatenarNaUrl, aditivoOuApostila);
        }

        var urlComIdFake = this.container.lookup("model:" + classeModelo).findUrlFn(0, aditivoOuApostila);
        return urlComIdFake.substring(0, urlComIdFake.length - 2); //remove o id fake "/0"
      }

      if (idParaConcatenarNaUrl !== undefined) {
        return this.container.lookup("model:" + classeModelo).findUrlFn(idParaConcatenarNaUrl);
      }

      var urlComIdFake = this.container.lookup("model:" + classeModelo).findUrlFn(0);
      return urlComIdFake.substring(0, urlComIdFake.length - 2); //remove o id fake "/0"
    },

    //usa o findUrlFn do modelo para definir a url do servico no servidor caso não tenha sido definida no crudConfig.
    //Nota: o urlFn do criar sempre tem que ser definido para elementos aninhados.
    _determinarUrlServico: function _determinarUrlServico(acao, modeloParaExecutarAcao) {
      var idParaConcatenarNaUrl;

      if (acao === 'ler') {
        idParaConcatenarNaUrl = this.model.id;
      }

      if (acao === 'excluir') {
        idParaConcatenarNaUrl = modeloParaExecutarAcao.id;
      }

      var urlConfig = this._determinarUrlPeloConfig(acao, idParaConcatenarNaUrl, modeloParaExecutarAcao);
      return urlConfig ? urlConfig : this._determinarUrlPadrao(idParaConcatenarNaUrl);
    },

    _determinarDadosAEnviar: function _determinarDadosAEnviar(acao) {
      if (acao === 'criar' || acao === 'atualizar') {
        return this.crudConfig[acao].modelFn ? this.crudConfig[acao].modelFn.call(this) : this.model;
      }
      return null;
    },

    _exectuarAcaoCrud: function _exectuarAcaoCrud(acao, modeloParaExecutarAcao, opcaoOkErroFn) {
      var _this = this;

      Ember['default'].assert('Ação CRUD inválida', Object.getOwnPropertyNames(this._nomesMetodoHttpParaAcoes).indexOf(acao) !== -1);
      Ember['default'].assert('Variável crudConfig não definida', this.crudConfig);

      if (!this.crudConfig[acao]) {
        //cria objeto de configuração vazio - evita exceção de acessar atributo de undefined
        this.crudConfig[acao] = {};
      }

      this.get('comunicador').servico(this._nomesMetodoHttpParaAcoes[acao], this._determinarUrlServico(acao, modeloParaExecutarAcao), this._determinarDadosAEnviar(acao)).then(function (resultado) {
        if (acao !== 'excluir') {
          _this.set("model", resultado);
        }
        if (acao !== 'ler') {
          Util['default'].alerta(_this._nomesLegiveisParaAcoes[acao] + ' realizada');
        }
        if (_this.crudConfig[acao].okFn) {
          _this.crudConfig[acao].okFn.call(_this, opcaoOkErroFn);
        }
      }, function () {
        if (_this.crudConfig[acao].erroFn) {
          _this.crudConfig[acao].erroFn.call(_this, opcaoOkErroFn);
        }
      });
    },

    actions: {
      criar: function criar(opcaoOkErroFn) {
        this._exectuarAcaoCrud('criar', null, opcaoOkErroFn);
      },

      ler: function ler(opcaoOkErroFn) {
        this._exectuarAcaoCrud('ler', null, opcaoOkErroFn);
      },

      atualizar: function atualizar(opcaoOkErroFn) {
        this._exectuarAcaoCrud('atualizar', null, opcaoOkErroFn);
      },

      excluir: function excluir(modeloInput, opcaoOkErroFn) {
        this._exectuarAcaoCrud('excluir', modeloInput, opcaoOkErroFn);
      }
    }
  });

});
define('webapp/router', ['exports', 'ember', 'webapp/config/environment', 'webapp/utils/util'], function (exports, Ember, config, Util) {

  'use strict';

  var Router = Ember['default'].Router.extend({

    alertas_service: Ember['default'].inject.service('alertas_service'),

    usuarioLogado: Ember['default'].inject.service('usuario-logado'),
    location: config['default'].locationType,

    root: Ember['default'].Route.extend({
      index: Ember['default'].Route.extend({
        route: '/'
      })
    }),

    _verificaPermissao: (function (transition) {
      //transition formato -> rota1.rota2....rotaN
      var rota = transition.targetName.split(".")[0];
      var rotasAcessoLivre = ['login', 'index', 'sobre', 'bad-url', 'manutencao'];

      // tratamento para rotas públicas
      if (rota.indexOf("publico") == 0) {
        rotasAcessoLivre.push(rota);
      }

      //  Ember.Logger.debug("INDO PARA A ROTA : "+ rota);

      if (!rotasAcessoLivre.contains(rota) && !this.get('usuarioLogado').estaLogado()) {
        this.get('usuarioLogado').salvaRota(transition);
        this.transitionTo('index');
        return;
      }

      if (this.get('usuarioLogado').estaLogado() && this.get('usuarioLogado').temRotaSalva()) {
        var rotaRecuperada = this.get('usuarioLogado').recuperaRota();
        this.get('usuarioLogado').apagaRota();
        rota = rotaRecuperada.targetName.split(".")[0];
        if (!this.get('usuarioLogado').podeAcessar(rota)) {
          this._usuarioSemPermissao(this, rota);
          return;
        }
        rotaRecuperada.retry();
        return;
      }

      if (!rotasAcessoLivre.contains(rota) && !this.get('usuarioLogado').podeAcessar(rota)) {
        this._usuarioSemPermissao(this, rota);
      }
    }).on('willTransition'),

    _verificaNotificacoes: (function () {
      // Ember.Logger.debug("ENTROU NA ROTA!");

      if (this.get('usuarioLogado').estaLogado() && this.get('usuarioLogado').ehAdmin()) {
        this.get('alertas_service').buscaEstadoDaGravacaoDosAlertas();
      }
    }).on('didTransition'),

    _usuarioSemPermissao: function _usuarioSemPermissao(_this, _rota) {
      _this.transitionTo('index');
      Util['default'].alertaErro('Usuário não pode acessar essa área (' + _rota + ')');
    }

  });

  Router.map(function () {
    this.resource('login');
    //this.resource('index');
    this.resource('sobre');
    this.resource('manutencao');

    //******************************** USUÁRIO **********************************

    //'usuarios' possui um outlet onde serão apresentados 'usuario' ou 'usuario-criar'.
    //Portanto, esses podem ser recursos aninhados

    // PARA ENTENDER: O 1o. parâmetro é o path relativo a "app/pods" da pasta onde o arquivo route.js da rota se encontra.
    //                Ou seja, "usuario/listagem" nada mais é do que a rota que fica na pasta app/pods/usuario/listagem.
    //                O 2o. parâmetro é o path para se chegar a esta rota. Já com o path "usuarios", temos que o endereço:
    //                http://localhost:4200/silc/#/usuarios no navegador nos leva a rota especificada no 1o. parâmetro.
    this.resource('usuario/listagem', { path: 'usuarios' });
    this.resource('usuario/criar', { path: 'usuario/novo' });
    this.resource('usuario', { path: 'usuario/:usuario_id' });

    //******************************** ALERTA **********************************

    this.resource('alerta/listagem', { path: 'alerta' });

    //******************************** PAPEL ************************************
    this.resource('papel/listagem', { path: 'papeis' });
    this.resource('papel/criar', { path: 'papel/novo' });
    this.resource('papel', { path: 'papel/:papel_id' });

    //******************************** ADM OUTROS********************************

    this.resource('adm-outras-entidades', { path: 'outras-entidades' });

    //****************************** FORNECEDOR *********************************

    //'fornecedores' não tem outlet nenhum. 'fornecedor' e 'fornecedor-criar' são
    //apresentados em "páginas" separadas. Assim, não podem ser recursos aninhados
    this.resource('fornecedor/listagem', { path: 'fornecedor/pesquisar' });

    this.resource('fornecedor/criar', { path: 'fornecedor/novo' });
    this.resource('fornecedor', { path: 'fornecedor/:fornecedor_id' }, function () {

      this.resource('fornecedor/penalidade/criar', { path: 'penalidade/nova' });
      this.resource('fornecedor/penalidade', { path: 'penalidade/:fornecedor.penalidade_id' });

      this.resource('fornecedor/prova-regularidade/criar', { path: 'prova-regularidade/nova' });
      this.resource('fornecedor/prova-regularidade', { path: 'prova-regularidade/:fornecedor.prova-regularidade_id' });

      this.resource('fornecedor/dado-bancario/criar', { path: 'dado-bancario/novo' });
      this.resource('fornecedor/dado-bancario', { path: 'dado-bancario/:fornecedor.dado-bancario_id' });

      this.resource('fornecedor/fornecivel/criar', { path: 'fornecivel/novo' });

      this.resource('fornecedor/evento/criar', { path: 'evento/novo' });
      this.resource('fornecedor/evento', { path: 'evento/:fornecedor.evento_id' });
    });

    //******************************* REQUISIÇÃO ********************************

    this.resource('requisicao/listagem', { path: 'requisicao/pesquisar' });

    this.resource('requisicao/criar', { path: 'requisicao/nova' });

    this.resource('requisicao', { path: 'requisicao/:requisicao_id' }, function () {

      this.resource('requisicao/contato-entrega/criar', { path: 'contato/novo' });
      this.resource('requisicao/contato-entrega', { path: 'contato/:requisicao.contato_entrega_id' });

      //COMO FAZER PARA QUE ITEM E ITEM-CRIAR APAREÇAM EM UMA NOVA PÁGINA (E NÃO NO OUTLET)?
      //DA FORMA COMO ESTÁ, FICA APARECENDO NO OUTLET
      this.resource('requisicao/item/criar', { path: 'item/novo' });
      this.resource('requisicao/item', { path: 'item/:requisicao.item_id' }, function () {

        this.resource('requisicao/item/orcamento/criar', { path: 'orcamento/novo' });
        this.resource('requisicao/item/orcamento', { path: 'orcamento/:requisicao.item.orcamento_id' });
      });
    });

    //******************************* AQUISICAO *********************************

    this.resource('aquisicao/listagem', { path: 'aquisicao/pesquisar' });

    this.resource('aquisicao/criar', { path: 'aquisicao/nova' });

    this.resource('aquisicao', { path: 'aquisicao/:aquisicao_id' }, function () {

      this.resource('aquisicao/andamento/criar', { path: 'andamento/novo' });
      this.resource('aquisicao/andamento', { path: 'andamento/:aquisicao.andamento_id' });

      this.resource('aquisicao/evento/criar', { path: 'evento/novo' });
      this.resource('aquisicao/evento', { path: 'evento/:aquisicao.evento_id' });

      //******************************* CONTRATO ********************************

      this.resource('aquisicao/contrato/criar', { path: 'contrato/novo' });
      this.resource('aquisicao/contrato', { path: 'contrato/:aquisicao.contrato_id' }, function () {

        this.resource('aquisicao/contrato/garantia/criar', { path: 'garantia/nova' });
        this.resource('aquisicao/contrato/garantia', { path: 'garantia/:aquisicao.contrato.garantia_id' });

        this.resource('aquisicao/contrato/item', { path: 'item/:aquisicao.contrato.item_id' });

        this.resource('aquisicao/contrato/evento/criar', { path: 'evento/novo' });
        this.resource('aquisicao/contrato/evento', { path: 'evento/:aquisicao.contrato.evento_id' });

        this.resource('aquisicao/contrato/andamento/criar', { path: 'andamento/novo' });
        this.resource('aquisicao/contrato/andamento', { path: 'andamento/:aquisicao.contrato.andamento_id' });

        this.resource('aquisicao/contrato/efeito-financeiro/criar', { path: 'efeito-financeiro/novo' });
        this.resource('aquisicao/contrato/efeito-financeiro', { path: 'efeito-financeiro/:aquisicao.contrato.efeito-financeiro_id' });

        this.resource('aquisicao/contrato/apostilamento-reajuste/criar', { path: 'apostilamento-reajuste/novo' });
        this.resource('aquisicao/contrato/apostilamento-reajuste', { path: 'apostilamento-reajuste/:aquisicao.contrato.apostilamento-reajuste_id' });

        // **** ADITIVO ****
        this.resource('aquisicao/contrato/aditivo/criar', { path: 'aditivo/novo' });
        this.resource('aquisicao/contrato/aditivo', { path: 'aditivo/:aquisicao.contrato.aditivo_id' }, function () {

          this.resource('aquisicao/contrato/aditivo/alteracao-teor-clausula/criar', { path: 'alteracao-teor-clausula/nova' });
          this.resource('aquisicao/contrato/aditivo/alteracao-teor-clausula', { path: 'alteracao-teor-clausula/:aquisicao.contrato.aditivo.alteracao-teor-clausula_id' });

          this.resource('aquisicao/contrato/aditivo/repactuacao/criar', { path: 'repactuacao/nova' });
          this.resource('aquisicao/contrato/aditivo/repactuacao', { path: 'repactuacao/:aquisicao.contrato.aditivo.repactuacao_id' });

          this.resource('aquisicao/contrato/aditivo/revisao/criar', { path: 'revisao/nova' });
          this.resource('aquisicao/contrato/aditivo/revisao', { path: 'revisao/:aquisicao.contrato.aditivo.revisao_id' });

          this.resource('aquisicao/contrato/aditivo/acrescimo-ou-supressao/item/criar', { path: 'acrescimo-ou-supressao/item/novo' });
          this.resource('aquisicao/contrato/aditivo/acrescimo-ou-supressao/item', { path: 'acrescimo-ou-supressao/item/:aquisicao.contrato.aditivo.acrescimo-ou-supressao.item_id' });
        });
      });

      this.resource('aquisicao/compra/criar', { path: 'compra/nova' });
      this.resource('aquisicao/compra', { path: 'compra/:aquisicao.compra_id' }, function () {

        this.resource('aquisicao/compra/item/criar', { path: 'item/novo' });
        this.resource('aquisicao/compra/item', { path: 'item/:aquisicao.compra.item_id' });

        this.resource('aquisicao/compra/empenho/criar', { path: 'empenho/novo' });
        this.resource('aquisicao/compra/empenho', { path: 'empenho/:aquisicao.compra.empenho_id' });
      });

      this.resource('aquisicao/ata/criar', { path: 'ata/nova' });
      this.resource('aquisicao/ata', { path: 'ata/:aquisicao.ata_id' }, function () {

        this.resource('aquisicao/ata/adesao/criar', { path: 'adesao/nova' });
        this.resource('aquisicao/ata/adesao', { path: 'adesao/:aquisicao.ata.adesao_id' }, function () {

          this.resource('aquisicao/ata/adesao/item/criar', { path: 'item/novo' });
          this.resource('aquisicao/ata/adesao/item', { path: 'item/:aquisicao.ata.adesao.item_id' });
        });
      });

      this.resource('aquisicao/item', { path: 'item/:aquisicao.item_id' }, function () {

        this.resource('aquisicao/item/quantidade-participacao/criar', { path: 'quantidade-participacao/nova' });
        this.resource('aquisicao/item/quantidade-participacao', { path: 'quantidade-participacao/:aquisicao.item.quantidade-participacao_id' });
      });

      this.resource('aquisicao/lote/criar', { path: 'lote/novo' });
      this.resource('aquisicao/lote', { path: 'lote/:aquisicao.lote_id' });
      this.resource('aquisicao/lote/adjudicar', { path: 'adjudicar-lote/:aquisicao.lote_id' });
    });

    //******************************* PESQUISA DE CONTRATO *****************************
    this.resource('pesquisa-contratos', { path: 'contrato/pesquisar' });

    //******************************* TRANSPARÊNCIA ********************************
    this.resource('transparencia/documento/listagem', { path: 'transparencia/documento/pesquisar' });
    this.resource('transparencia/documento/criar', { path: 'transparencia/documento/novo' });
    this.resource('transparencia/documento', { path: 'transparencia/documento/:documento_id' });

    //******************************* ROTAS PÚBLICAS ********************************
    this.resource('publico', { path: 'publico' });
    this.resource('publico/adesoes', { path: 'publico/adesoes' });
    this.resource('publico/licitacoes', { path: 'publico/licitacoes' });
    this.resource('publico/compras', { path: 'publico/compras' });
    this.resource('publico/atas', { path: 'publico/atas' });
    this.resource('publico/contratos', { path: 'publico/contratos' });
    this.resource('publico/participacoes', { path: 'publico/participacoes' });

    //******************************* ERRO ***************************************
    this.route('bad-url', { path: '*path' });

    this.route('pncp/listagem', { path: 'pncp/listagem' });
  });

  exports['default'] = Router;

});
define('webapp/pods/fornecedor/fornecivel/criar/route', ['exports', 'ember', 'webapp/pods/fornecedor/fornecivel/model'], function (exports, Ember, Fornecivel) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({
    controllerName: 'fornecedor/fornecivel',
    setupController: function setupController(controller) {
      controller.set('model', Fornecivel['default'].create({}));

      this.get('comunicador').servico('get', 'classificacaoItem').then(function (resultado) {
        controller.set('classificacoesItens', resultado);
      });
    },

    model: function model() {
      return Fornecivel['default'].create();
    }
  });

});
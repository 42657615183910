define('webapp/pods/pesquisa-contratos/route', ['exports', 'ember', 'webapp/utils/util', 'webapp/mixins/suprimir-templates-superiores-mixin'], function (exports, Ember, Util, SuprimirTemplatesSuperioresMixin) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(SuprimirTemplatesSuperioresMixin['default'], {
    setupController: function setupController(controller, model) {
      controller.atribuiValoresDefault();
      controller.inicializaCampos(Util['default'].clona(model));
      controller._gerenciarDadosPesquisaAnterior();
    }

  });

});
define('webapp/pods/fornecedor/penalidade/identificacao/template', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          return {
            isHTMLBars: true,
            revision: "Ember@1.12.1",
            blockParams: 0,
            cachedFragment: null,
            hasRendered: false,
            build: function build(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            render: function render(context, env, contextualElement) {
              var dom = env.dom;
              var hooks = env.hooks, get = hooks.get, inline = hooks.inline;
              dom.detectNamespace(contextualElement);
              var fragment;
              if (env.useFragmentCache && dom.canClone) {
                if (this.cachedFragment === null) {
                  fragment = this.build(dom);
                  if (this.hasRendered) {
                    this.cachedFragment = fragment;
                  } else {
                    this.hasRendered = true;
                  }
                }
                if (this.cachedFragment) {
                  fragment = dom.cloneNode(this.cachedFragment, true);
                }
              } else {
                fragment = this.build(dom);
              }
              var morph0 = dom.createMorphAt(fragment,1,1,contextualElement);
              inline(env, morph0, context, "date-input", [], {"texto": "Data de Inscrição", "valor": get(env, context, "model.dataInscricaoMultaMoratoriaDividaAtiva"), "tamanho": 4, "id": "tag||dataInscricaoMultaMoratoriaDividaAtiva|penalidade|identificacao"});
              return fragment;
            }
          };
        }());
        return {
          isHTMLBars: true,
          revision: "Ember@1.12.1",
          blockParams: 0,
          cachedFragment: null,
          hasRendered: false,
          build: function build(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","row");
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("fieldset");
            dom.setAttribute(el2,"style","margin-top: 1em");
            var el3 = dom.createTextNode("\n\n          ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("legend");
            var el4 = dom.createTextNode(" Multa Moratória ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n\n          ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n\n          ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n\n          ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n\n          ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("        ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          render: function render(context, env, contextualElement) {
            var dom = env.dom;
            var hooks = env.hooks, get = hooks.get, inline = hooks.inline, block = hooks.block;
            dom.detectNamespace(contextualElement);
            var fragment;
            if (env.useFragmentCache && dom.canClone) {
              if (this.cachedFragment === null) {
                fragment = this.build(dom);
                if (this.hasRendered) {
                  this.cachedFragment = fragment;
                } else {
                  this.hasRendered = true;
                }
              }
              if (this.cachedFragment) {
                fragment = dom.cloneNode(this.cachedFragment, true);
              }
            } else {
              fragment = this.build(dom);
            }
            var element1 = dom.childAt(fragment, [1, 1]);
            var morph0 = dom.createMorphAt(element1,3,3);
            var morph1 = dom.createMorphAt(element1,5,5);
            var morph2 = dom.createMorphAt(element1,7,7);
            var morph3 = dom.createMorphAt(element1,9,9);
            var morph4 = dom.createMorphAt(element1,11,11);
            inline(env, morph0, context, "moeda-input", [], {"texto": "Valor", "valor": get(env, context, "model.valorMultaMoratoria"), "obrigatorio": true, "labelAcessivel": "Valor da multa moratória", "tamanho": 4, "id": "tag||valorMultaMoratoria|penalidade|identificacao"});
            inline(env, morph1, context, "date-input", [], {"texto": "Prazo de Pagamento", "valor": get(env, context, "model.prazoPgtoMultaMoratoria"), "tamanho": 4, "id": "tag||prazoPgtoMultaMoratoria|penalidade|identificacao"});
            inline(env, morph2, context, "date-input", [], {"texto": "Data de Pagamento", "valor": get(env, context, "model.dataPgtoMultaMoratoria"), "tamanho": 4, "id": "tag||dataPgtoMultaMoratoria|penalidade|identificacao"});
            inline(env, morph3, context, "tag-checkbox", [], {"texto": "Inscrita em Dívida Ativa", "nome": "multaMoratoriaInscritaDividaAtiva", "atributoDestino": get(env, context, "model.multaMoratoriaInscritaDividaAtiva"), "tamanho": 4, "labelNegrito": true, "id": "checkbox||multaMoratoriaInscritaDividaAtiva|penalidade|identificacao"});
            block(env, morph4, context, "if", [get(env, context, "model.multaMoratoriaInscritaDividaAtiva")], {}, child0, null);
            return fragment;
          }
        };
      }());
      var child1 = (function() {
        var child0 = (function() {
          return {
            isHTMLBars: true,
            revision: "Ember@1.12.1",
            blockParams: 0,
            cachedFragment: null,
            hasRendered: false,
            build: function build(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            render: function render(context, env, contextualElement) {
              var dom = env.dom;
              var hooks = env.hooks, get = hooks.get, inline = hooks.inline;
              dom.detectNamespace(contextualElement);
              var fragment;
              if (env.useFragmentCache && dom.canClone) {
                if (this.cachedFragment === null) {
                  fragment = this.build(dom);
                  if (this.hasRendered) {
                    this.cachedFragment = fragment;
                  } else {
                    this.hasRendered = true;
                  }
                }
                if (this.cachedFragment) {
                  fragment = dom.cloneNode(this.cachedFragment, true);
                }
              } else {
                fragment = this.build(dom);
              }
              var morph0 = dom.createMorphAt(fragment,1,1,contextualElement);
              inline(env, morph0, context, "date-input", [], {"texto": "Data de Inscrição", "valor": get(env, context, "model.dataInscricaoMultaCompensatoriaDividaAtiva"), "tamanho": 4, "id": "tag||dataInscricaoDividaAtivaMultaCompensatoria|penalidade|identificacao"});
              return fragment;
            }
          };
        }());
        return {
          isHTMLBars: true,
          revision: "Ember@1.12.1",
          blockParams: 0,
          cachedFragment: null,
          hasRendered: false,
          build: function build(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","row");
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("fieldset");
            dom.setAttribute(el2,"style","margin-top: 1em");
            var el3 = dom.createTextNode("\n\n          ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("legend");
            var el4 = dom.createTextNode(" Multa Compensatória ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n\n          ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n\n          ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n\n          ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n\n          ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("div class=\"col-md-4\">\n    		      {{input type=\"checkbox\" name=\"multaCompensatoriaInscritaDividaAtiva\"\n                checked=model.multaCompensatoriaInscritaDividaAtiva\n                id=\"checkbox||multaCompensatoriaInscritaDividaAtiva|penalidade|identificacao\"}}\n\n              <label class=\"control-label\">Inscrita em Dívida Ativa</label>\n          </div");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n\n          ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          render: function render(context, env, contextualElement) {
            var dom = env.dom;
            var hooks = env.hooks, get = hooks.get, inline = hooks.inline, block = hooks.block;
            dom.detectNamespace(contextualElement);
            var fragment;
            if (env.useFragmentCache && dom.canClone) {
              if (this.cachedFragment === null) {
                fragment = this.build(dom);
                if (this.hasRendered) {
                  this.cachedFragment = fragment;
                } else {
                  this.hasRendered = true;
                }
              }
              if (this.cachedFragment) {
                fragment = dom.cloneNode(this.cachedFragment, true);
              }
            } else {
              fragment = this.build(dom);
            }
            var element0 = dom.childAt(fragment, [1, 1]);
            var morph0 = dom.createMorphAt(element0,3,3);
            var morph1 = dom.createMorphAt(element0,5,5);
            var morph2 = dom.createMorphAt(element0,7,7);
            var morph3 = dom.createMorphAt(element0,11,11);
            var morph4 = dom.createMorphAt(element0,13,13);
            inline(env, morph0, context, "moeda-input", [], {"texto": "Valor", "valor": get(env, context, "model.valorMultaCompensatoria"), "obrigatorio": true, "labelAcessivel": "Valor da multa compensatória", "tamanho": 4, "id": "tag||valorMultaCompensatoria|penalidade|identificacao"});
            inline(env, morph1, context, "date-input", [], {"texto": "Prazo de Pagamento", "valor": get(env, context, "model.prazoPgtoMultaCompensatoria"), "tamanho": 4, "id": "tag||prazoPgtoMultaCompensatoria|penalidade|identificacao"});
            inline(env, morph2, context, "date-input", [], {"texto": "Data de Pagamento", "valor": get(env, context, "model.dataPgtoMultaCompensatoria"), "tamanho": 4, "id": "tag||dataPgtoMultaCompensatoria|penalidade|identificacao"});
            inline(env, morph3, context, "tag-checkbox", [], {"texto": "Inscrita em Dívida Ativa", "nome": "multaCompensatoriaInscritaDividaAtiva", "atributoDestino": get(env, context, "model.multaCompensatoriaInscritaDividaAtiva"), "tamanho": 4, "labelNegrito": true, "id": "checkbox||multaCompensatoriaInscritaDividaAtiva|penalidade|identificacao"});
            block(env, morph4, context, "if", [get(env, context, "model.multaCompensatoriaInscritaDividaAtiva")], {}, child0, null);
            return fragment;
          }
        };
      }());
      return {
        isHTMLBars: true,
        revision: "Ember@1.12.1",
        blockParams: 0,
        cachedFragment: null,
        hasRendered: false,
        build: function build(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","panel-body");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","row");
          var el3 = dom.createTextNode("\n  	  ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","row");
          var el3 = dom.createTextNode("\n  	  ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("fieldset");
          dom.setAttribute(el3,"style","margin-top: 1em");
          var el4 = dom.createTextNode("\n  		");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("legend");
          var el5 = dom.createTextNode(" Tipo e Razões ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n\n  		");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n\n  		");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n\n  		");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n\n  	  ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","row");
          var el3 = dom.createTextNode("\n  	  ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("fieldset");
          dom.setAttribute(el3,"style","margin-top: 1em");
          var el4 = dom.createTextNode("\n  		");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("legend");
          var el5 = dom.createTextNode(" Aplicação ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n\n  		");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("label");
          dom.setAttribute(el4,"class","col-md-4");
          var el5 = dom.createTextNode("Âmbito de Incidência:");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n\n  		");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("br");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("br");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n\n  		");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n\n  		");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n\n  		");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n\n   		");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n\n  	  ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","row");
          var el3 = dom.createTextNode("\n  	  ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("fieldset");
          dom.setAttribute(el3,"style","margin-top: 1em");
          var el4 = dom.createTextNode("\n  		");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("legend");
          var el5 = dom.createTextNode(" Transação Considerada ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n\n  		");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n\n  		");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n\n  		");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n\n  		");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n\n  		");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n\n  	  ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        render: function render(context, env, contextualElement) {
          var dom = env.dom;
          var hooks = env.hooks, get = hooks.get, inline = hooks.inline, block = hooks.block;
          dom.detectNamespace(contextualElement);
          var fragment;
          if (env.useFragmentCache && dom.canClone) {
            if (this.cachedFragment === null) {
              fragment = this.build(dom);
              if (this.hasRendered) {
                this.cachedFragment = fragment;
              } else {
                this.hasRendered = true;
              }
            }
            if (this.cachedFragment) {
              fragment = dom.cloneNode(this.cachedFragment, true);
            }
          } else {
            fragment = this.build(dom);
          }
          var element2 = dom.childAt(fragment, [1]);
          var element3 = dom.childAt(element2, [1]);
          var element4 = dom.childAt(element2, [3, 1]);
          var element5 = dom.childAt(element2, [5, 1]);
          var element6 = dom.childAt(element2, [7, 1]);
          var morph0 = dom.createMorphAt(element3,1,1);
          var morph1 = dom.createMorphAt(element3,3,3);
          var morph2 = dom.createMorphAt(element4,3,3);
          var morph3 = dom.createMorphAt(element4,5,5);
          var morph4 = dom.createMorphAt(element4,7,7);
          var morph5 = dom.createMorphAt(element5,5,5);
          var morph6 = dom.createMorphAt(element5,7,7);
          var morph7 = dom.createMorphAt(element5,12,12);
          var morph8 = dom.createMorphAt(element5,14,14);
          var morph9 = dom.createMorphAt(element5,16,16);
          var morph10 = dom.createMorphAt(element5,18,18);
          var morph11 = dom.createMorphAt(element6,3,3);
          var morph12 = dom.createMorphAt(element6,5,5);
          var morph13 = dom.createMorphAt(element6,7,7);
          var morph14 = dom.createMorphAt(element6,9,9);
          var morph15 = dom.createMorphAt(element6,11,11);
          var morph16 = dom.createMorphAt(element2,9,9);
          var morph17 = dom.createMorphAt(element2,11,11);
          inline(env, morph0, context, "tag-input", [], {"texto": "Nº", "valor": get(env, context, "model.numeroApresentacao"), "tamanho": 3, "somenteLeitura": true, "autoFoco": true, "labelAcessivel": "Número da Penalidade", "id": "tag||numeroApresentacao|penalidade|identificacao"});
          inline(env, morph1, context, "tag-relatorio", [], {"descricaoRelatorios": get(env, context, "descricaoRelatorios"), "controlePermissao": true, "posicaoLivre": true, "id": "componente|gerar|relatorios|fornecedor/penalidade|apresentacao-penalidade"});
          inline(env, morph2, context, "tag-select", [], {"texto": "Tipos", "obrigatorio": true, "conteudo": get(env, context, "tiposPenalidade"), "opcaoLabel": "nome", "valor": get(env, context, "tiposPenalidadeSelecionados"), "textopadrao": "Selecione os tipos de penalidade", "permiteLimpar": true, "id": "select||tipos|penalidade|identificacao", "multiplo": true, "formatoTabela": true});
          inline(env, morph3, context, "tag-select", [], {"texto": "Fundamentos Legais", "obrigatorio": true, "conteudo": get(env, context, "fundamentosPenalidade"), "opcaoLabel": "descricao", "valor": get(env, context, "model.fundamentosLegais"), "textopadrao": "Selecione os fundamentos", "permiteLimpar": true, "id": "select||fundamentosLegais|penalidade|identificacao", "multiplo": true, "formatoTabela": true});
          inline(env, morph4, context, "tag-input", [], {"texto": "Motivo", "valor": get(env, context, "model.motivo"), "id": "tag-input||motivo|penalidade|identificacao"});
          inline(env, morph5, context, "tag-radio-button", [], {"texto": "Administração", "valor": "ADMINISTRACAO", "atributoDestino": get(env, context, "model.ambitoIncidencia"), "tamanho": 4, "labelAcessivel": "Âmbito de Incidência: Administração", "id": "radio||ambitoIncidencia-administracao|penalidade|identificacao"});
          inline(env, morph6, context, "tag-radio-button", [], {"texto": "Administração Pública", "valor": "ADMINISTRACAO_PUBLICA", "atributoDestino": get(env, context, "model.ambitoIncidencia"), "tamanho": 4, "labelAcessivel": "Âmbito de Incidência: Administração Pública", "id": "radio||ambitoIncidencia-administracaoPublica|penalidade|identificacao"});
          inline(env, morph7, context, "date-input", [], {"texto": "Início", "valor": get(env, context, "model.dataInicio"), "obrigatorio": get(env, context, "dataInicioObrigatoria"), "labelAcessivel": "Início da aplicação da penalidade", "tamanho": 4, "id": "tag||data-inicio|penalidade|identificacao"});
          inline(env, morph8, context, "date-input", [], {"texto": "Fim", "valor": get(env, context, "model.dataFim"), "obrigatorio": get(env, context, "dataFimObrigatoria"), "labelAcessivel": "Fim da aplicação da penalidade", "tamanho": 4, "id": "tag||data-fim|penalidade|identificacao"});
          inline(env, morph9, context, "date-input", [], {"texto": "Data de Aplicação", "valor": get(env, context, "model.dataAplicacao"), "obrigatorio": true, "labelAcessivel": "Data da aplicação da penalidade", "tamanho": 4, "id": "tag||data-aplicacao|penalidade|identificacao"});
          inline(env, morph10, context, "busca-pa", [], {"texto": "Processo Administrativo", "atributoDestino": get(env, context, "model.processoAdministrativo"), "labelAcessivel": "Número Processo Administrativo", "controllerDoContexto": get(env, context, "this"), "mostrarDetalhes": false, "tamanho": 12, "id": "tag||processo|penalidade|identificacao"});
          inline(env, morph11, context, "tag-input", [], {"texto": "Contrato", "valor": get(env, context, "model.nroContrato"), "labelAcessivel": "Contrato relacionado à penalidade", "tamanho": 6, "id": "tag||contrato|penalidade|identificacao"});
          inline(env, morph12, context, "tag-input", [], {"texto": "Empenho", "valor": get(env, context, "model.nroEmpenho"), "labelAcessivel": "Empenho relacionado à penalidade", "tamanho": 6, "id": "tag||empenho|penalidade|identificacao"});
          inline(env, morph13, context, "tag-input", [], {"texto": "Aquisição", "valor": get(env, context, "model.numeroLicitacaoOuCompraDireta"), "labelAcessivel": "Aquisição relacionada à penalidade", "tamanho": 6, "id": "tag||numeroLicitacaoOuCompraDireta|penalidade|identificacao"});
          inline(env, morph14, context, "tag-input", [], {"texto": "Ata de Registro Preços", "valor": get(env, context, "model.numeroAtaRegistroPrecos"), "labelAcessivel": "Ata relacionada à penalidade", "tamanho": 6, "id": "tag||numeroAtaRegistroPrecos|penalidade|identificacao"});
          inline(env, morph15, context, "tag-input", [], {"texto": "Objeto", "valor": get(env, context, "model.objetoContratacao"), "labelAcessivel": "Objeto da transação relacionada à penalidade", "tamanho": 12, "id": "tag||objetoContratacao|penalidade|identificacao"});
          block(env, morph16, context, "if", [get(env, context, "envolveMultaMoratoria")], {}, child0, null);
          block(env, morph17, context, "if", [get(env, context, "envolveMultaCompensatoria")], {}, child1, null);
          return fragment;
        }
      };
    }());
    return {
      isHTMLBars: true,
      revision: "Ember@1.12.1",
      blockParams: 0,
      cachedFragment: null,
      hasRendered: false,
      build: function build(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      render: function render(context, env, contextualElement) {
        var dom = env.dom;
        var hooks = env.hooks, block = hooks.block;
        dom.detectNamespace(contextualElement);
        var fragment;
        if (env.useFragmentCache && dom.canClone) {
          if (this.cachedFragment === null) {
            fragment = this.build(dom);
            if (this.hasRendered) {
              this.cachedFragment = fragment;
            } else {
              this.hasRendered = true;
            }
          }
          if (this.cachedFragment) {
            fragment = dom.cloneNode(this.cachedFragment, true);
          }
        } else {
          fragment = this.build(dom);
        }
        var morph0 = dom.createMorphAt(fragment,0,0,contextualElement);
        dom.insertBoundary(fragment, null);
        dom.insertBoundary(fragment, 0);
        block(env, morph0, context, "tag-bloco", [], {"titulo": "Penalidade"}, child0, null);
        return fragment;
      }
    };
  }()));

});
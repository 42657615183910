define('webapp/overrides/route', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Route.reopen({

    //Não surtiu efeito (nunca é chamado). Pq?
    // teste: function() {
    //   var router = this.router.router;
    //
    //   // se fizer assim, o último elemento de handlerInfos será referente à rota atual
    //   //var handlerInfos = router.state.handlerInfos
    //
    //   // se fizer assim, o último elemento de handlerInfos será referente à rota superior
    //   var handlerInfos = router.currentHandlerInfos
    //
    //   var nomeRotaSuperior = handlerInfos[handlerInfos.length - 1].handler.routeName;
    //   alert(nomeRotaSuperior);
    //   var rotaSuperior = this.container.lookup('route:' + nomeRotaSuperior);
    //
    //   return rotaSuperior;
    // }

  });

});
define('webapp/tests/helpers/start-app', ['exports', 'ember', 'webapp/app', 'webapp/router', 'webapp/config/environment'], function (exports, Ember, Application, Router, config) {

  'use strict';


  exports['default'] = startApp;
  function startApp(attrs) {
    var application;

    var attributes = Ember['default'].merge({}, config['default'].APP);
    attributes = Ember['default'].merge(attributes, attrs); // use defaults, but you can override;

    Ember['default'].run(function () {
      application = Application['default'].create(attributes);
      application.setupForTesting();
      application.injectTestHelpers();
    });

    return application;
  }

});
define('webapp/pods/aquisicao/ata/listagem/controller', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({

    needs: ['aquisicao'],

    //true se for registro de preços e se já tiver sido criada alguma ata OU
    //se não for registro de preços, todos os lotes tiverem resultado, algum tiver
    //sido adjudicado
    permitidoIncluirAta: (function () {
      return this.get('controllers.aquisicao.model.registroPreco') && this.get('controllers.aquisicao.haLoteAdjudicado');
    }).property('controllers.aquisicao.haLoteAdjudicado')

  });

});
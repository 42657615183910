define('webapp/components/tag-modal', ['exports', 'ember', 'webapp/utils/util'], function (exports, Ember, Util) {

  'use strict';

  /* global $ */
  // *** Modal para apresentação de informações na interface, com alguns comportamentos predefinidos ***
  //
  //Exemplo de uso:
  //    {{#tag-modal tamanho=[1-12] id=<identificador no padrão definido>}}
  //      <elementos internos>
  //    {{/tag-modal}}
  //
  //ATENÇÃO!!! Ainda não sabemos o porquê, mas um width de 50% está sendo atribuído ao modal através da classe
  //           .col-md-6 (quando declaramos o atributo tamanho=6) que é atribuída quando a largura é maior ou
  //           igual a 992px. Para anular este efeito, foi criada a classe "widthAuto" em estilosModal.scss,
  //           que pode ser então atribuída ao atributo classesContainerAdicionais quando se declara uma ins-
  //           tância deste componente.
  //
  //OBSERVAÇÃO: Este modal guarda todos os elementos HTML focáveis de dentro da página que fica por trás dele,
  //            em um array, para que possa desativar estes elementos a fim de que não recebam o foco, que
  //            deve funcionar apenas dentro do modal, quando este está sendo exibido. A desativação ocorre
  //            devido ao fato de setarmos a propriedade tabIdex de todos estes elementos para -1. Neste
  //            array, portanto, guardamos os tabIndex de cada um destes elementos, para que possam ser
  //            restaurados depois de o modal ser fechado. Isto significa que devemos DESABILITAR a tecla F5
  //            neste momento e restabelecê-la após o fechamento do modal, caso contrário, um refresh tornará
  //            esses elementos focáveis novamente sem ter fechado o modal.
  //
  //Atributos opcionais:
  //    exibirModal: <true | false>
  //    controllerReferencia: um controller (o objeto em si) no qual a instância deste componente está inserida
  //    tamanho: Utilizado para montar a classe "col-md-xx" que será aplicada à modal. O default é 12.
  //    classesContainerAdicionais: Permite atribuir classes CSS à DIV gerada por este componente. Uma das
  //                                classes que podem ser úteis é a "widthAuto" (ler o item "ATENÇÃO!!! mais acima).
  //    id: um identificador no padrão "modal_acao_informacao_contexto_local".
  //    formulario [true | false]: Indica que o conteúdo do bloco deve ser envolvido por uma tag <form>,
  //                               de modo que ao teclar-se "Enter", o primeiro botão da tela seja acionado.
  //                               Default: false.
  //    rotaCancelamento: uma rota para onde a aplicação deve ser redirecionada caso se feche a modal
  //                      (clicando no botão 'x') ou se clique fora dela
  //    acaoCancelamento: o nome da ação que deve ser executada, no controller de referênciam, caso se
  //                      feche a modal (clicalndo no botão 'x') ou se clique fora dela
  //    ativarModalNaCriacao: Deve ser true quando uma modal não é mostrada através da ativação do valor da
  //                      propriedade exibirModal (não considerar a ativação via código dentro deste compo-
  //                      nente) , mas sim quando a modal é apresentada automaticamente, como quando o sim-
  //                      ples fato de se chegar a uma rota, já exibe a modal.
  //                      O DEFAULT desta propriedade é false.
  //Dependências: modal-dialog (componente externo), estilos-modal.scss (arquivo CSS do sistema)

  exports['default'] = Ember['default'].Component.extend({
    //attributeBindings: ['tamanho'],
    tamanhoDefault: 8,
    classesContainer: 'container-centralizado-com-scroll modal-centralizada',
    classesOverlay: 'overlay-centralizado-com-scroll',
    classesWraper: 'wrapper-centralizado-com-scroll',
    rotaCancelamento: null,
    acaoCancelamento: null,
    ativarModalNaCriacao: false,
    modalAberta: false,
    exibirModal: true,
    controllerReferencia: null,
    FOCUSABLE_SELECTORS: 'a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, embed, *[tabindex], *[contenteditable]',
    ElementosFocaveis: null,

    ajustaApresentacao: (function () {
      this.modalAberta = false;
      this.set('classesContainer', this.classesContainer + ' ' + this._getClasseLargura() + ' ' + this.classesContainerAdicionais);
      if (this.ativarModalNaCriacao) {
        this.set('exibirModal', true);
        this.decideExibicaoDoModal();
      }
    }).on('init'),

    retiraScrollBody: (function () {
      if (this.exibirModal) {
        $('body').addClass('mostrando-modal-centralizado');
      };
    }).on('willInsertElement'),

    recolocaScrollBody: (function () {
      this.procedimentosDeFechamentoDaModal();
    }).on('willDestroyElement'),

    desabilitaTabsForaDaModal: function desabilitaTabsForaDaModal() {
      this.ElementosFocaveis = $('body')[0].querySelectorAll(this.FOCUSABLE_SELECTORS);

      this.ElementosFocaveis.forEach(function (elementoFocavel) {
        elementoFocavel.tabIndex_backup = elementoFocavel.tabIndex;
        elementoFocavel.setAttribute('tabindex', '-1');
      });
    },

    habilitaTabsForaDaModal: function habilitaTabsForaDaModal() {
      this.ElementosFocaveis.forEach(function (elementoFocavel) {
        elementoFocavel.setAttribute('tabindex', elementoFocavel.tabIndex_backup);
      });
    },

    decideExibicaoDoModal: (function () {
      if (this.exibirModal) {
        this.procedimentosDeAberturaDaModal();
      } else {
        this.procedimentosDeFechamentoDaModal();
      }
    }).observes('exibirModal'),

    procedimentosDeAberturaDaModal: function procedimentosDeAberturaDaModal() {
      this.modalAberta = true;

      this.desabilitaTabsForaDaModal();
      Util['default'].desabilitaTeclaF5();

      $('body').addClass('mostrando-modal-centralizado');

      if ($(".ember-modal-dialog")[0] && $(".ember-modal-dialog")[0].querySelector("input")) {
        window.setTimeout(function () {
          $(".ember-modal-dialog")[0].querySelector("input").focus();
        }, 0);
      }
    },

    procedimentosDeFechamentoDaModal: function procedimentosDeFechamentoDaModal() {
      if (this.modalAberta) {
        this.modalAberta = false;

        $('body').removeClass('mostrando-modal-centralizado');

        this.habilitaTabsForaDaModal();
        Util['default'].habilitaTeclaF5();
      }
    },

    _getClasseLargura: function _getClasseLargura() {
      if (!this.get('tamanho')) {
        return 'col-md-' + this.tamanhoDefault;
      }

      Ember['default'].assert('Valor não numérico atribuído a tamanho de tag-modal', !isNaN(this.get('tamanho')));
      Ember['default'].assert('Tamanho de tag-modal deve estar entre 1 e 12', this.get('tamanho') >= 1 && this.get('tamanho') <= 12);

      return 'col-md-' + this.tamanho;
    },

    actions: {
      cancelar: function cancelar() {
        if (this.acaoCancelamento) {
          Ember['default'].assert('Faltando controller na tag-modal', this.controllerReferencia);
          this.get('controllerReferencia').send(this.acaoCancelamento);
        } else if (this.rotaCancelamento) {
          Ember['default'].assert('Faltando controller na tag-modal', this.controllerReferencia);
          this.get('controllerReferencia').transitionToRoute(this.rotaCancelamento);
        } else {
          this.set('exibirModal', false);
        }
      }
    }

  });

});
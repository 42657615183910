define('webapp/pods/aquisicao/compra/criar/route', ['exports', 'ember', 'webapp/pods/aquisicao/compra/model', 'webapp/mixins/suprimir-templates-superiores-mixin'], function (exports, Ember, Compra, SuprimirTemplatesSuperioresMixin) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(SuprimirTemplatesSuperioresMixin['default'], {
    controllerName: 'aquisicao/compra',
    setupController: function setupController(controller) {
      controller.set('model', Compra['default'].create());
      this.get('comunicador').leParaPropriedade(controller, 'aquisicao/' + controller.get('controllers.aquisicao').model.id + '/adjudicado', 'fornecedoresAdjudicadosNaAquisicao', "Não foi possível obter os Fornecedores Adjudicados!");
    },

    model: function model() {
      return Compra['default'].create();
    }
  });

});
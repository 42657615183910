define('webapp/pods/aquisicao/contrato/aditivo/alteracao-teor-clausula/model', ['exports', 'ember'], function (exports, Ember) {

	'use strict';

	exports['default'] = Ember['default'].Object.extend({
		id: null,
		numeroClausula: '',
		novaRedacao: '',
		numero: 0,
		numeroApresentacao: '',
		descricao: '',

		findUrlFn: function findUrlFn(id) {
			return 'aditivo/alteracaoTeorClausula/' + id.toString() + '/';
		}

	});

});
define('webapp/pods/aquisicao/contrato/rescisao/controller', ['exports', 'ember', 'webapp/mixins/crud-mixin', 'webapp/utils/util'], function (exports, Ember, crudMixin, Util) {

    'use strict';

    exports['default'] = Ember['default'].Controller.extend(crudMixin['default'], {

        needs: ['aquisicao', 'aquisicao/contrato'],
        controllerContrato: null,
        dadosDeEnvioDaRescisao: {
            data: '',
            tipo: ''
        },

        inicializar: (function () {
            this.controllerContrato = this.get("controllers.aquisicao/contrato");
            this.set('this.dadosDeEnvioDaRescisao.tipo', '');
            this.set('this.dadosDeEnvioDaRescisao.data', '');
        }).on('init'),

        actions: {
            confirmarRescisao: function confirmarRescisao() {
                var _this = this;

                swal({
                    title: "Uma vez rescindido o contrato, \no sistema não permitirá que isto seja desfeito.\nConfirma a rescisão ?",
                    type: 'warning',
                    confirmButtonText: 'Confirmar',
                    showCancelButton: true,
                    cancelButtonText: 'Cancelar',
                    focusCancel: true,
                    reverseButtons: true
                }).then(function () {
                    _this.send('rescinde');
                });
            },
            rescinde: function rescinde() {
                var _this2 = this;

                this.get('comunicador').servico('PUT', 'aquisicao/contrato/' + this.controllerContrato.model.id + '/rescisao', this.dadosDeEnvioDaRescisao).then(function (resultado) {
                    Util['default'].alerta('contrato rescindido.');
                    _this2.send('cancelarModalRescisao');
                    _this2.controllerContrato.send('ler');
                });
            },
            cancelarModal: function cancelarModal() {
                this.set('this.dadosDeEnvioDaRescisao.tipo', '');
                this.set('this.dadosDeEnvioDaRescisao.data', '');
                this.send('cancelarModalRescisao');
            }
        }

    });

});
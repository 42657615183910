define('webapp/pods/aquisicao/contrato/aditivo/alteracao-sede-social/model', ['exports', 'ember', 'webapp/models/endereco'], function (exports, Ember, Endereco) {

	'use strict';

	exports['default'] = Ember['default'].Object.extend({
		id: null,
		novoEndereco: Endereco['default'].create({}),
		descricao: '',

		inicializar: (function () {
			this.set('novoEndereco', Endereco['default'].create({}));
		}).on('init')

	});

});
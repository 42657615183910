define('webapp/components/tag-checkbox', ['exports', 'ember', 'webapp/mixins/ajusta-id-interno-mixin', 'webapp/mixins/ajusta-tamanho-input-mixin'], function (exports, Ember, AjustaIdInternoMixin, AjustaTamanhoInputMixin) {

	'use strict';

	/* Exemplo de Uso

		{{tag-checkbox
			texto=<texto do label>
			nome=<string com o nome do checkbox - não é o label, mas o "name" do input>
			atributoDestino=<referência à variável externa que armazenará o valor>
		 	labelAcessivel=<texto para ser lido por leitores automáticos de interface
				              para acessibilidade por pessoas com deficiência visual.
				              Default: o valor do atributo "texto" e a indicação de se
											o campo é obrigatório>
			tamanho=<largura do componente em termos do bootstrap - um número X
					      de 1 a 12 utilizado para montar a classe CSS "col-md-X".
					      O default é 12>
			desabilitado=<true|false (default), indicando se é possível marcar o checkbox>
			labelNegrito=<true|false (default), indicando se o label deve aparecer negrito>
			id="checkbox|..."}}

	*/
	exports['default'] = Ember['default'].Component.extend(AjustaIdInternoMixin['default'], AjustaTamanhoInputMixin['default'], {
		labelAcessivel: '',
		valor: '',
		opcaoSelecionada: undefined,
		negrito: false,
		atributoDestino: '',
		comprimentoMaximo: undefined,
		desabilitado: false,
		somenteLeitura: false,

		_idLabel: (function () {
			return this.id + '::label';
		}).property('id'),

		_idCheckbox: (function () {
			return this.id + '::input';
		}).property('id'),

		insereAriaLabel: (function () {
			var checkbox = this.$('input')[0];
			var labelAcessivel = this.labelAcessivel ? this.labelAcessivel : this.texto;
			var complementoCampoObrigatorio = this.obrigatorio ? ', campo obrigatório' : '';
			var complementoSomenteLeitura = this.somenteLeitura ? ', somente leitura' : '';

			checkbox.setAttribute('aria-label', labelAcessivel + complementoCampoObrigatorio + complementoSomenteLeitura);
		}).on('willInsertElement'),

		focalizar: (function () {
			if (this.autoFoco) {
				this.$('input')[0].focus();
			}
		}).on('didInsertElement')

	});

});
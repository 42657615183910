define('webapp/pods/aquisicao/ata/adesao/controller', ['exports', 'ember', 'webapp/mixins/crud-mixin'], function (exports, Ember, CrudMixin) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend(CrudMixin['default'], {

    needs: ['aquisicao/ata'],

    breadCrumbConfig: {
      fnNomeApresentacao: 'fnNomeApresentacao'
    },

    fnNomeApresentacao: function fnNomeApresentacao() {
      return 'Adesão ' + (this.model.version ? 'Nº ' + this.model.numeroApresentacao : '(Nova)');
    },

    crudConfig: {
      criar: {
        urlFn: function urlFn() {
          return 'ata/' + this.get("controllers.aquisicao/ata").model.id + '/adesaoExterna';
        },
        okFn: function okFn(opcao) {
          this.get('controllers.aquisicao/ata').send('ler');
          if (opcao === 'CRIAR_E_PROXIMO') {
            //ação "borbulhará" para a rota
            this.send('refreshRota');
            return;
          }
          this.transitionToRoute('aquisicao/ata/adesao', this.model.id);
        }
      },
      atualizar: {
        okFn: function okFn() {
          this.get('controllers.aquisicao/ata').send('ler');
        }
      },
      excluir: {
        okFn: function okFn() {
          this.get('controllers.aquisicao/ata').send('ler');
        }
      }
    },

    ehPedidoAdesaoNovo: (function () {
      return !this.model.version;
    }).property('model.version'),

    sugereDataAtualComoDataPedido: (function () {
      if (this.get('ehPedidoAdesaoNovo')) {
        this.set('model.data', new Date());
      }
    }).observes('model')

  });

});
define('webapp/pods/requisicao/item/controller', ['exports', 'ember', 'webapp/mixins/crud-mixin', 'webapp/utils/util', 'npm:big.js'], function (exports, Ember, CrudMixin, Util, Big) {

	'use strict';

	exports['default'] = Ember['default'].Controller.extend(CrudMixin['default'], {

		needs: ['requisicao'],

		breadCrumbConfig: { fnNomeApresentacao: 'fnNomeApresentacao' },

		habilitaCodigoCatalogo: false,

		fnNomeApresentacao: function fnNomeApresentacao() {
			return 'Item ' + (this.model.version ? this.model.numeroApresentacao : '(Novo)');
		},

		crudConfig: {

			criar: {
				urlFn: function urlFn() {
					return 'requisicao/' + this.get("controllers.requisicao").model.id + '/itemRequisicao';
				},
				okFn: function okFn(opcao) {
					this.get('controllers.requisicao').send('ler');
					if (opcao === 'CRIAR_E_PROXIMO') {
						//ação "borbulhará" para a rota
						this.send('refreshRota');
						return;
					}

					this.transitionToRoute('requisicao/item', this.model.id);
				}
			},

			atualizar: {
				okFn: function okFn() {
					this.get('controllers.requisicao').send('ler');
				}
			},

			excluir: {
				okFn: function okFn() {
					this.get('controllers.requisicao').send('ler');
				}
			}

		},

		permitidoAlterarItem: (function () {
			return !this.get('controllers.requisicao').model.idAquisicoes.length;
		}).property('controllers.requisicao.model.idAquisicoes.@each'),

		ehItemRequisicaoExistente: (function () {
			return this.model.version;
		}).property('model.version'),

		mostraMediaEMediana: (function () {
			return this.model.orcamentos.length > 0;
		}).property('model.orcamentos.length'),

		//Seta o valor da duração para 1 quando o item não for periódico
		resetaDuracao: (function () {
			if (this.model.periodico) {
				return;
			}

			this.set('model.duracao', 1);
		}).observes('model.periodico'),

		habilitarCatalogo: (function () {
			return this.get('habilitaCodigoCatalogo');
		}).property('habilitaCodigoCatalogo'),

		sugereValorTotal: (function () {
			var quantidade = this.model.quantidade ? Util['default'].parseFloatStringPtBR(this.model.quantidade) : 0;

			var duracao;

			if (this.model.periodico) {
				duracao = this.model.duracao ? Util['default'].parseFloatStringPtBR(this.model.duracao) : 0;
			} else {
				duracao = 1;
			}

			var valorUnitario = 0;
			switch (this.model.tipoValorItem) {
				case 'EXATO':
					valorUnitario = this.model.valorUnitarioExato ? Util['default'].parseFloatStringPtBR(this.model.valorUnitarioExato) : 0;
					break;
				case 'MEDIA':
					valorUnitario = this.model.valorUnitarioMedio ? Util['default'].parseFloatStringPtBR(this.model.valorUnitarioMedio) : 0;
					break;
				case 'MEDIANA':
					valorUnitario = this.model.valorUnitarioMediano ? Util['default'].parseFloatStringPtBR(this.model.valorUnitarioMediano) : 0;
					break;
			}

			//this.set('model.valorTotal', valorUnitario * quantidade * duracao);
			var stringValorTotalFormatoUSA = new Big['default'](valorUnitario).times(new Big['default'](quantidade)).times(new Big['default'](duracao)).toString();

			this.set('model.valorTotal', Util['default'].stringNumericaUSAparaBR(stringValorTotalFormatoUSA));
		}).observes('model.tipoValorItem', 'model.valorUnitarioExato', 'model.quantidade', 'model.duracao', 'model.periodico')

	});

});
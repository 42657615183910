define('webapp/components/crud-simples', ['exports', 'ember', 'webapp/mixins/crud-mixin', 'webapp/utils/util', 'webapp/utils/id'], function (exports, Ember, CrudMixin, Util, UtilID) {

  'use strict';

  /*
  exibirPesquisa=<true|false (default)>
  controlePermissaoPesquisar=<true|false (default)>
  controlePermissaoAcessar=<true|false (default)>
  controlePermissaoAdicionar=<true|false (default)>
  controlePermissaoCriar=<true|false (default)>
  controlePermissaoAlterar=<true|false (default)>
  controlePermissaoExcluir=<true|false (default)>

  -- Mostra o botao adicionar no fim da tabela --
  mostraBotaoAdicionar= true | false - default=true
  -- Mostra o botão de editar para cada elemento da tabela --
  permiteAcessar= true | false - default=false
  -- Mostra o botão de excluir para cada elemento da tabela --
  permiteExcluir= true | false - default=true

  ---- PARAMETROS -----
  urlListagem -> url do serviço que vai retornar os elementos a serem exibidos.
    Se não for definido o parâmetro "urlCRUD" será utilizado.

  elementosDesabilitadosNoForm -> string com a lista de elementos a serem desabilitados,
    separados por vírgula. Ex. "elemento1,elemento2".

  elementosNaoMostradosNaListagem -> string com a lista de elementos que não apareceram na lista
    de resultados. Ex. "elemento1,elemento2".

  elementosNaoMostradosNoCrud -> string com a lista de elementos que não apareceram na tela de
    entrada de dados de crud. Ex. "elemento1,elemento2".

  camposTextArea -> string com a lista de elementos que deverão aparecer na tela de entrada de dados
    de crud através do componente tag-textarea em vez do componente tag-input, que é o default para 
    todos os demais campos.

  */

  exports['default'] = Ember['default'].Component.extend(CrudMixin['default'], {
    comunicador: Ember['default'].inject.service(),

    atributoID: 'id', //indica o atributo que identifica os itens da fonte - o default é 'id'

    mostraBotaoAdicionar: true,
    permiteAcessar: false,
    permiteExcluir: true,

    montarTabela: false,
    listagemEntidadesExistentes: [],
    entidadeEmAnalise: {},
    descricaoModelo: {},
    mostraModalCadastro: false,
    dicionarioAcentuacao: {
      codigo: 'código',
      acao: 'ação',
      numero: "número",
      cnpj: "CNPJ",
      exibicao: "exibição",
      descricao: "descrição",
      email: "e-mail"
    },

    //Este mapeamento é desnecessário no Ember 2.x, pois então existirá o {{#each-in}}
    atributosEntidadeMapeadosParaCrud: [],

    idTabela: (function () {
      var idTabela = UtilID['default'].getIdModificado(this.id, 'tipo', 'tabela');
      return UtilID['default'].getIdModificado(idTabela, 'acao', 'listar');
      //return `tabela___${this.id}_lista`;
    }).property('id'),

    idBotaoAdicionar: (function () {
      var idBotao = UtilID['default'].getIdModificado(this.id, 'tipo', 'botao');
      return UtilID['default'].getIdModificado(idBotao, 'acao', 'adicionar');
    }).property('id'),

    idBotaoCriar: (function () {
      var idBotao = UtilID['default'].getIdModificado(this.id, 'tipo', 'botao');
      idBotao = UtilID['default'].getIdModificado(idBotao, 'acao', 'criar');
      return UtilID['default'].getIdModificado(idBotao, 'local', 'identificacao');
    }).property('id'),

    idBotaoCriarEProximo: (function () {
      var idBotao = UtilID['default'].getIdModificado(this.id, 'tipo', 'botao');
      idBotao = UtilID['default'].getIdModificado(idBotao, 'acao', 'criarEProximo');
      return UtilID['default'].getIdModificado(idBotao, 'local', 'identificacao');
    }).property('id'),

    idBotaoAlterar: (function () {
      var idBotao = UtilID['default'].getIdModificado(this.id, 'tipo', 'botao');
      idBotao = UtilID['default'].getIdModificado(idBotao, 'acao', 'alterar');
      return UtilID['default'].getIdModificado(idBotao, 'local', 'identificacao');
    }).property('id'),

    idBotaoCancelar: (function () {
      var idBotao = UtilID['default'].getIdModificado(this.id, 'tipo', 'botao');
      idBotao = UtilID['default'].getIdModificado(idBotao, 'acao', 'cancelar');
      return UtilID['default'].getIdModificado(idBotao, 'local', 'identificacao');
    }).property('id'),

    crudConfig: {
      criar: {
        urlFn: function urlFn() {
          return this.get('urlCRUD');
        },
        modelFn: function modelFn() {
          return this.get('entidadeEmAnalise');
        },
        okFn: function okFn(opcao) {
          this.buscarListagemEntidadesExistentes();
          this.set('entidadeEmAnalise', {});
          this.set('mostraModalCadastro', opcao === 'CRIAR_E_PROXIMO');
        }
      },
      ler: {
        urlFn: function urlFn(idParaConcatenarNaUrl) {
          return this.get('urlCRUD') + '/' + idParaConcatenarNaUrl;
        }
      },
      atualizar: {
        urlFn: function urlFn() {
          return this.get('urlCRUD');
        },
        modelFn: function modelFn() {
          return this.get('entidadeEmAnalise');
        },
        okFn: function okFn() {
          this.buscarListagemEntidadesExistentes();
          this.set('mostraModalCadastro', false);
        }
      },
      excluir: {
        urlFn: function urlFn(idParaConcatenarNaUrl) {
          return this.get('urlCRUD') + '/' + idParaConcatenarNaUrl;
        },
        okFn: function okFn() {
          this.buscarListagemEntidadesExistentes();
        }
      }
    },

    buscarDescricaoModelo: (function () {
      this.get('comunicador').leParaPropriedade(this, this.get('urlCRUD') + '/desc', 'descricaoModelo', 'Não foi possível obter a descrição da entidade', true);
    }).on('init'),

    buscarListagemEntidadesExistentes: (function () {
      if (this.exibirPesquisa) {
        return;
      }

      this.get('comunicador').leParaPropriedade(this, this.get('urlListagem') || this.get('urlCRUD'), 'listagemEntidadesExistentes', 'Não foi possível obter as entidades existentes', true);
    }).on('init'),

    configurar: (function () {
      if (!this.descricaoModelo) {
        return;
      }

      var nomesAtributosRelevantesParaListagem = [];
      var nomesAtributosRelevantesParaCrud = [];
      var atributosRelevantesListagem = [];

      var elementosNaoMostradosNaListagem = this.get('elementosNaoMostradosNaListagem') && this.get('elementosNaoMostradosNaListagem').split(",") || [];

      var elementosNaoMostradosNoCrud = this.get('elementosNaoMostradosNoCrud') && this.get('elementosNaoMostradosNoCrud').split(",") || [];

      var arrCamposTextArea = this.get('camposTextArea') && this.get('camposTextArea').split(",") || [];

      var nomesAtributosAIgnorarNaInterface = ['version', 'pais'];

      for (var nomeAtributo in this.descricaoModelo.properties) {
        if (nomeAtributo && !nomesAtributosAIgnorarNaInterface.contains(nomeAtributo)) {
          if (!elementosNaoMostradosNaListagem.contains(nomeAtributo)) {
            atributosRelevantesListagem.push({ titulo: this.traduzirNomeAtributo(nomeAtributo), atributo: nomeAtributo });
            nomesAtributosRelevantesParaListagem.push(nomeAtributo);
          }
          if (!elementosNaoMostradosNoCrud.contains(nomeAtributo)) {
            nomesAtributosRelevantesParaCrud.push(nomeAtributo);
          }
        }
      }

      this.set('atributosEntidadeMapeadosParaCrud', this.mapearAtributosEntidade(nomesAtributosRelevantesParaCrud, arrCamposTextArea));

      this.set('atributosRelevantesParaListagem', JSON.stringify(atributosRelevantesListagem));
      this.set('montarTabela', true);
    }).observes('descricaoModelo'),

    //Este mapeamento é desnecessário no Ember 2.x, pois então existirá o {{#each-in}}
    mapearAtributosEntidade: function mapearAtributosEntidade(argNomesAtributosRelevantesParaCrud, argCamposTextArea) {
      var _this = this;

      var elementosDesabilitadosNoForm = this.get('elementosDesabilitadosNoForm') && this.get('elementosDesabilitadosNoForm').split(",");
      var nomesAtributosEntidade = argNomesAtributosRelevantesParaCrud;
      var arrayAtributosEntidade = [];
      nomesAtributosEntidade.forEach(function (nomeAtributo) {
        var tiposNumericos = ['integer', 'float', 'double'];
        var tipoAtributo = _this.descricaoModelo.properties[nomeAtributo].type;

        arrayAtributosEntidade.push({
          nomeAtributoCorrespondente: nomeAtributo,
          nomeLegivel: _this.traduzirNomeAtributo(nomeAtributo),
          valor: '',
          mascara: tiposNumericos.contains(tipoAtributo) ? 'numeros' : '',
          // Nota: estou adicionando os atributos específicos do caso dos parametros de exibição aqui (chave, descricao)
          disabled: nomeAtributo === 'id' || elementosDesabilitadosNoForm && elementosDesabilitadosNoForm.includes(nomeAtributo),
          id: 'campo__' + nomeAtributo + '_' + _this.id + '_apresentacao',
          campoTextArea: argCamposTextArea.indexOf(nomeAtributo) > -1
        });
      });
      return arrayAtributosEntidade;
    },

    traduzirNomeAtributo: function traduzirNomeAtributo(nomeAtributo) {
      var splitTitulo = Util['default'].separaECapitalizaPalavrasCamelCase(nomeAtributo).split(" ");
      for (var i = 0; i < splitTitulo.length; i++) {
        if (this.dicionarioAcentuacao[splitTitulo[i].toLowerCase()]) {
          splitTitulo[i] = Util['default'].separaECapitalizaPalavrasCamelCase(this.dicionarioAcentuacao[splitTitulo[i].toLowerCase()]);
        }
      }

      return splitTitulo.join(" ");
    },

    preencheCamposComValoresEntidade: (function () {
      var _this2 = this;

      this.atributosEntidadeMapeadosParaCrud.forEach(function (atributoMapeado) {
        Ember['default'].set(atributoMapeado, 'valor', _this2.entidadeEmAnalise[atributoMapeado.nomeAtributoCorrespondente]);
      });
    }).observes('entidadeEmAnalise'),

    atualizaEntidadeEmAnalise: function atualizaEntidadeEmAnalise() {
      var _this3 = this;

      this.atributosEntidadeMapeadosParaCrud.forEach(function (atributoMapeado) {
        Ember['default'].set(_this3.get('entidadeEmAnalise'), atributoMapeado.nomeAtributoCorrespondente, atributoMapeado.valor);
      });
    },

    actions: {
      enviarDadosAoServidor: function enviarDadosAoServidor(operacao) {
        this.atualizaEntidadeEmAnalise();
        if (operacao === 'criacao') {
          this.send('criar');
        } else if (operacao === 'criar_e_proximo') {
          this.send('criar', 'CRIAR_E_PROXIMO');
        } else if (operacao === 'alteracao') {
          this.send('atualizar');
        }
      },
      adicionar: function adicionar() {
        this.set('adicionando', true);
        this.set('entidadeEmAnalise', {});
        this.set('mostraModalCadastro', true);
      },
      acessar: function acessar(entidade) {
        this.set('adicionando', false);
        this.set('entidadeEmAnalise', entidade);
        this.set('mostraModalCadastro', true);
      },
      cancelarModal: function cancelarModal() {
        this.set('entidadeEmAnalise', {});
        this.set('mostraModalCadastro', false);
      }
    }
  });

});
define('webapp/tests/helpers/exemplos-ids', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var ExemplosIds = Ember['default'].Object.extend({
    idsComFormatoValido: ["____", "tipo_acao_info_contexto_local", "0_0_0_0_0", "t0_a-a_I-i_c.c.c_L1", "_a_a_a_", "_a__a_"],

    idsComFormatoInvalido: ["", "tipoAcaoInfoContextoLocal", "tipo_AcaoInfoContextoLocal", "tipo_Acao_InfoContextoLocal", "tipo_Acao_Info_ContextoLocal", "tipo_Acao_Info_Contexto_Local_", "_tipo_Acao_Info_Contexto_Local", "tipoAcao_Info_Contexto_Local", "tipoAcaoInfo_Contexto_Local", "tipoAcaoInfoContexto_Local"],

    idsComConteudoInvalido: ["xxx_xxx_xxx_xxx_xxx", "botao_xxx_xxx_xxx_xxx", "xxx_criar_xxx_xxx_xxx"],

    idsValidos: ["botao_criar_xxx_xxx_xxx", "link___xxx_xxx_xxx"],

    idsInvalidos: (function () {
      return this.idsComConteudoInvalido.concat(this.idsComFormatoInvalido);
    }).property()

  });

  exports['default'] = ExemplosIds.create({});

});
define('webapp/pods/aquisicao/contrato/aditivo/reajuste/model', ['exports', 'ember'], function (exports, Ember) {

	'use strict';

	exports['default'] = Ember['default'].Object.extend({
		id: null,
		dataTermoInicial: '',
		tipoTermoInicial: '',
		dataTermoFinal: '',
		duracao: 1,
		observacaoTermoInicial: '',
		valorIndice: null,
		//valorIndice: 1,
		valorBase: null,
		valorFinal: null,
		tipoValorBase: null, //ARBITRADO, INICIAL, INICIAL_ATUALIZADO, TOTAL_ATUAL
		indice: null,
		descricao: '',
		valorReajustado: '',

		inicializar: (function () {
			this.set('duracao', 1);
		}).on('init')

	});

});
define('webapp/components/tag-endereco', ['exports', 'ember', 'webapp/utils/id', 'webapp/utils/util'], function (exports, Ember, UtilID, Util) {

	'use strict';

	/* global _ */

	/*
	Exemplo de uso:
					{{tag-endereco
						endereco=<entidade que contém o endereço sendo editado>
						labelAcessivel:<introdução para compor o label acessível dos componentes
														internos - por exemplo, com "Endereço Procurador" a tag-select
														com os países ficaria "Endereço Procurador - País">
						id=<um nome para o componente, usado para compor a identificação
								de cada select - necessários para buscar os valores selecionados no DOM - 	SEM ISSO NÃO FUNCIONA>
						labelLogradouro="Lograd." //para específicar um label para o caso
																			//abreviar em telas pequenas, por exemplo
						obrigatorio=<true | false (default), indicando se (todos os campos do)
						 						 endereço são de preenchimento obrigatório>
						somenteLeitura=<true | false (default), indicando se (todos os campos do)
						 						 endereço são somente para leitura>

					}}

	Toda tag-endereco com id "endereco_<acao>_<info>_<contexto>_<local>"
	cria um select com id "select__pais-<info>_<contexto>_<local>"
	cria um select com id "select__uf-<info>_<contexto>_<local>"
	cria um select com id "select__municipio-<info>_<contexto>_<local>"
	cria um input com id "campo__cep-<info>_<contexto>_<local>"
	cria um input com id "campo__logradouro-<info>_<contexto>_<local>"
	cria um input com id "campo__bairro-<info>_<contexto>_<local>"

	*/

	exports['default'] = Ember['default'].Component.extend({
		comunicador: Ember['default'].inject.service(),

		atualizandoCEP: false,
		uFDoCEP: '',
		municipioDoCEP: '',
		obj_busca_ender_cep: null,
		componenteCEPAtual: null,

		// ==================  C E P

		TAM_CEP_INVALIDO: "O campo CEP deve conter 8 caracteres numéricos e um hífen.",

		promessaBuscaPeloCEP: null,
		promessaBuscaPeloCEP_TESTE: null,

		// É uma validação que acontece apenas quando pressionamos o botão de pesquisa pelo CEP, independente da validação do back-end, que pode até ser diferente.
		validaCEP: function validaCEP() {
			this.set('endereco.cep', this.endereco.cep.replace('-', ''));
			this.set('endereco.cep', this.endereco.cep.replace('_', ''));
			if (this.endereco.cep.length != 8) throw this.TAM_CEP_INVALIDO;
		},

		desabilitarBotaoCEP: (function () {
			return this.somenteLeitura;
		}).property('somenteLeitura'),

		labelAcessivelCEP: (function () {
			return this.labelAcessivel;
		}).property('labelAcessivel'),

		labelAcessivelBotaoPesquisar: (function () {
			return 'Iniciar busca de ' + (this.labelAcessivel ? this.labelAcessivel : this.texto ? this.texto : 'CEP');
		}).property('labelAcessivel'),

		// ==================  C E P

		insere: (function () {
			//		this.obj_busca_ender_cep.focar();
		}).on('init'),

		limpaEndereco: function limpaEndereco(pais, logradouro, bairro, localidade, uf) {
			this.set('endereco.pais', null);
			this.set('endereco.uf', null);
			this.set('endereco.municipio', null);
			this.set('endereco.bairro', null);
			this.set('endereco.logradouro', null);
		},

		atribuirEndereco: function atribuirEndereco(pais, logradouro, bairro, localidade, uf) {
			var _this2 = this;

			try {
				this.set('atualizandoCEP', true);

				this.set('endereco.pais', null);
				this.set('endereco.uf', null);
				this.set('endereco.municipio', null);

				this.set('uFDoCEP', uf); // uFDoCEP guarda o Uf recebido pelo webservice do CEP para que só após o carregamento do combobox com os estados o uf seja selecionado neste combobox
				this.set('municipioDoCEP', localidade); // municipioDoCEP serve para o mesmo propósito que o uFDoCEP

				this.set('endereco.bairro', bairro);
				this.set('endereco.logradouro', logradouro);
				this.set('endereco.pais', this.obtemObjPais(pais));
			} finally {
				(function () {
					var _this = _this2;
					setTimeout(function () {
						_this.set('atualizandoCEP', false);
					}, 6000);
				})();
			};
		},

		actions: {
			buscarCEP: function buscarCEP() {
				var _this3 = this;

				try {
					this.validaCEP();
					if (!this.todosCamposDeEnderecoVazios()) {
						swal({
							title: "Há campos preenchidos no endereço.\nPesquisar CEP os apagará.\nConfirma a pesquisa ?",
							type: 'warning',
							confirmButtonText: 'Confirmar',
							showCancelButton: true,
							cancelButtonText: 'Cancelar',
							focusCancel: true,
							reverseButtons: true
						}).then(function () {
							_this3.limpaEndereco();
							_this3.buscaCEP();
						});
					} else {
						this.buscaCEP();
					}
				} catch (e) {
					this.focar();
					Util['default'].alertaErro(e);
				}
			}
		},

		todosCamposDeEnderecoVazios: function todosCamposDeEnderecoVazios() {
			return _.isEmpty(this.get('endereco.pais')) && _.isEmpty(this.get('endereco.uf')) && _.isEmpty(this.get('endereco.municipio')) && _.isEmpty(this.get('endereco.bairro')) && _.isEmpty(this.get('endereco.logradouro'));
		},

		buscaCEP: function buscaCEP() {
			var _this4 = this;

			var _this = this;
			this.promessaBuscaPeloCEP = this.criaPromessaBuscaPeloCEP();
			this.promessaBuscaPeloCEP.then(function (resultado) {
				_this4.atribuirEndereco('Brasil', resultado.logradouro, resultado.bairro, resultado.localidade, resultado.uf);
			})['catch'](function (e) {
				console.error("Erro ao chamar webservice de CEP: " + e);
				// this.componenteCEPAtual.focar();
				_this.focar();
			});
		},

		criaPromessaBuscaPeloCEP: function criaPromessaBuscaPeloCEP() {
			var _this = this;

			this.ControleSpinner.empilharTransacao();
			var promessa = null;
			try {
				promessa = new Ember['default'].RSVP.Promise(function (resolve, reject) {
					Util['default'].getJSON('https://viacep.com.br/ws/' + _this.endereco.cep + '/json', function (err, json) {
						_this.ControleSpinner.desempilharTransacao();
						if (err) {
							reject("Erro ao tentar acessar o web service de ceps: " + err);
						} else if (!json || "erro" in json) {
							reject('CEP Inválido ou Erro interno do servidor da webservice.');
						} else {
							resolve(json);
						}
					});
				});
			} catch (e) {
				this.ControleSpinner.desempilharTransacao();
				console.log('Erro ao tentar chamar webservice de CEP: ' + e.message);
				return null;
			}
			return promessa;
		},

		focar: function focar() {
			this.$('input')[0].focus();
			this.$('input')[0].select();
		},

		observaAtualizandoCEP: (function () {
			if (this.atualizandoCEP) {
				this.ControleSpinner.empilharTransacao();
			} else {
				this.ControleSpinner.desempilharTransacao();
			}
		}).observes('this.atualizandoCEP'),

		observaUfsParaCEP: (function () {
			if (this.atualizandoCEP) {
				this.set('endereco.uf', this.obtemObjUf(this.uFDoCEP));
			};
		}).observes('this.ufs'),

		observaMunicipiosParaCEP: (function () {
			try {
				if (this.atualizandoCEP) {
					this.set('endereco.municipio', this.obtemObjMunicipio(this.municipioDoCEP));
				};
			} finally {
				this.set('this.atualizandoCEP', false);
			}
		}).observes('this.municipios'),

		obtemObjPais: function obtemObjPais(pais) {
			pais = Util['default'].stringParaComparacao(pais);
			var objPais = _.find(this.paises, function (pPais) {
				return Util['default'].stringParaComparacao(pPais.nome) === pais;
			});
			return objPais;
		},

		obtemObjUf: function obtemObjUf(uf) {
			uf = Util['default'].stringParaComparacao(uf);
			var objUf = _.find(this.ufs, function (pUf) {
				return Util['default'].stringParaComparacao(pUf.nome) === uf;
			});
			return objUf;
		},

		obtemObjMunicipio: function obtemObjMunicipio(municipio) {
			municipio = Util['default'].stringParaComparacao(municipio);
			var objMunicipio = _.find(this.municipios, function (pMunicipio) {
				return Util['default'].stringParaComparacao(pMunicipio.nome) === municipio;
			});
			if (objMunicipio == null) console.log('Município "' + municipio + '" não encontrado no banco de dados');
			return objMunicipio;
		},

		paises: (function () {
			this.get('comunicador').leParaPropriedade(this, 'pais', 'paises');
		}).property(),

		ufs: [],
		atualizaUfs: (function () {
			if (!this.endereco || !this.endereco.pais || !this.endereco.pais.id && this.endereco.pais.id !== 0) {
				if (!this.atualizandoCEP) {
					this.set('ufs', []);
				}
				return;
			};

			this.get('comunicador').leParaPropriedade(this, 'pais/' + this.endereco.pais.id.toString() + '/uf', 'ufs');
		}).on('didInsertElement').observes('endereco.pais.id'),

		municipios: [],
		atualizaMunicipios: (function () {
			if (!this.endereco || !this.endereco.uf || !this.endereco.uf.id && this.endereco.uf.id !== 0) {
				if (!this.atualizandoCEP) {
					this.set('municipios', []);
				}
				return;
			}

			this.get('comunicador').leParaPropriedade(this, 'uf/' + this.endereco.uf.id.toString() + '/municipio', 'municipios');
		}).on('didInsertElement').observes('endereco.uf.id'),

		campoObrigatorioUF: (function () {
			return this.obrigatorio || this.endereco.pais && this.endereco.pais.id;
		}).property('obrigatorio', 'endereco.pais.id'),

		campoObrigatorioMunicipio: (function () {
			return this.obrigatorio || this.endereco.pais && this.endereco.pais.id;
		}).property('obrigatorio', 'endereco.pais.id'),

		//----------------------------------------------------------------------------
		//--------------------------- Labels Acessíveis ------------------------------
		//----------------------------------------------------------------------------

		labelAcessivelPais: (function () {
			return this._montaLabelAcessivel('País');
		}).property("labelAcessivel"),

		labelAcessivelUF: (function () {
			return this._montaLabelAcessivel('UF');
		}).property("labelAcessivel"),

		labelAcessivelMunicipio: (function () {
			return this._montaLabelAcessivel('Município');
		}).property("labelAcessivel"),

		labelAcessivelCEP: (function () {
			return this._montaLabelAcessivel('CEP');
		}).property("labelAcessivel"),

		labelAcessivelBairro: (function () {
			return this._montaLabelAcessivel('Bairro');
		}).property("labelAcessivel"),

		labelAcessivelLogradouro: (function () {
			var denominacaoLogradouro = this.labelLogradouro ? this.labelLogradouro : 'Logradouro';
			return this._montaLabelAcessivel(denominacaoLogradouro);
		}).property("labelAcessivel"),

		_montaLabelAcessivel: function _montaLabelAcessivel(trechoEspecifico) {
			var label = this.labelAcessivel ? this.labelAcessivel : 'Endereço';
			return label + ', ' + trechoEspecifico + ' ';
		},

		//----------------------------------------------------------------------------
		//------------------------------------ IDs -----------------------------------
		//----------------------------------------------------------------------------

		idSelectPais: (function () {
			return UtilID['default'].constroi('select', '', 'pais-' + UtilID['default'].getSeccao(this.id, 'informacao'), UtilID['default'].getSeccao(this.id, 'contexto'), UtilID['default'].getSeccao(this.id, 'local'));
		}).property("id"),
		idSelectUF: (function () {
			return UtilID['default'].constroi('select', '', 'uf-' + UtilID['default'].getSeccao(this.id, 'informacao'), UtilID['default'].getSeccao(this.id, 'contexto'), UtilID['default'].getSeccao(this.id, 'local'));
		}).property("id"),
		idSelectMunicipio: (function () {
			return UtilID['default'].constroi('select', '', 'municipio-' + UtilID['default'].getSeccao(this.id, 'informacao'), UtilID['default'].getSeccao(this.id, 'contexto'), UtilID['default'].getSeccao(this.id, 'local'));
		}).property("id"),
		idTagCep: (function () {
			return UtilID['default'].constroi('tag', '', 'cep-' + UtilID['default'].getSeccao(this.id, 'informacao'), UtilID['default'].getSeccao(this.id, 'contexto'), UtilID['default'].getSeccao(this.id, 'local'));
		}).property("id"),
		idTagBotaoCep: (function () {
			return UtilID['default'].constroi('tag', '', 'botaocep-' + UtilID['default'].getSeccao(this.id, 'informacao'), UtilID['default'].getSeccao(this.id, 'contexto'), UtilID['default'].getSeccao(this.id, 'local'));
		}).property("id"),
		idTagBairro: (function () {
			return UtilID['default'].constroi('tag', '', 'bairro-' + UtilID['default'].getSeccao(this.id, 'informacao'), UtilID['default'].getSeccao(this.id, 'contexto'), UtilID['default'].getSeccao(this.id, 'local'));
		}).property("id"),
		idTagLogradouro: (function () {
			return UtilID['default'].constroi('tag', '', 'logradouro-' + UtilID['default'].getSeccao(this.id, 'informacao'), UtilID['default'].getSeccao(this.id, 'contexto'), UtilID['default'].getSeccao(this.id, 'local'));
		}).property("id")

	});

});
define('webapp/pods/fornecedor/penalidade/controller', ['exports', 'ember', 'webapp/mixins/crud-mixin'], function (exports, Ember, CrudMixin) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend(CrudMixin['default'], {

    needs: ['fornecedor'],

    breadCrumbConfig: {
      fnNomeApresentacao: 'fnNomeApresentacao'
    },

    fnNomeApresentacao: function fnNomeApresentacao() {
      return 'Penalidade ' + (this.model.version ? 'Nº' + this.model.numeroApresentacao : '(Nova)');
    },

    getNomesTipos: function getNomesTipos(penalidade) {
      var hash = this.hashIdTipoPenalidadeVsNome;
      return penalidade.tipos.map(function (tipoObj) {
        return ' ' + hash[tipoObj.tipo];
      });
    },

    tiposPenalidadeSelecionados: [],

    crudConfig: {
      criar: {
        urlFn: function urlFn() {
          return 'fornecedor/' + this.get("controllers.fornecedor").model.id + '/penalidade';
        },
        okFn: function okFn(opcao) {
          this.get('controllers.fornecedor').send('ler');
          if (opcao === 'CRIAR_E_PROXIMO') {
            //ação "borbulhará" para a rota
            this.send('refreshRota');
            return;
          }
          this.transitionToRoute('fornecedor');
        }
      },
      atualizar: {
        okFn: function okFn() {
          this.get('controllers.fornecedor').send('ler');
          this.transitionToRoute('fornecedor');
        }
      },
      excluir: {
        okFn: function okFn() {
          this.get('controllers.fornecedor').send('ler');
          this.transitionToRoute('fornecedor');
        }
      }
    },

    hashIdTipoPenalidadeVsNome: {
      'DESCREDENCIAMENTO_SICAF': 'Descredenciamento do SICAF',
      'ADVERTENCIA': 'Advertência',
      'MULTA_MORATORIA': 'Multa Moratória',
      'MULTA_COMPENSATORIA': 'Multa Compensatória',
      'SUSPENSAO_TEMPORARIA_IMPEDIMENTO': 'Suspensão Temporária / Impedimento',
      'DECLARACAO_INIDONEIDADE': 'Declaração de Inidoneidade',
      'IMPEDIMENTO_LEI_PREGAO': 'Impedimento (Lei do Pregão)'
    },

    tiposPenalidade: (function () {
      var _this = this;

      var tiposPenalidade = [];
      Object.keys(this.hashIdTipoPenalidadeVsNome).forEach(function (idTipo) {
        tiposPenalidade.push({
          id: idTipo,
          nome: _this.hashIdTipoPenalidadeVsNome[idTipo]
        });
      });
      return tiposPenalidade;
    }).property(),

    setTiposPenalidadeModelo: (function () {
      this.set('model.tipos', this.tiposPenalidadeSelecionados.map(function (tipo) {
        return { tipo: tipo.id };
      }));
    }).observes('tiposPenalidadeSelecionados'),

    envolveMultaMoratoria: (function () {
      return this.verificaSeEnvolveTipo('MULTA_MORATORIA');
    }).property('model.tipos'),

    envolveMultaCompensatoria: (function () {
      return this.verificaSeEnvolveTipo('MULTA_COMPENSATORIA');
    }).property('model.tipos'),

    verificaSeEnvolveTipo: function verificaSeEnvolveTipo(idTipo) {
      return this.model.tipos.map(function (tipo) {
        return tipo.tipo;
      }).includes(idTipo);
    },

    dataFimObrigatoria: (function () {
      return this.tiposPenalidadeSelecionados.find(function (tipoPenalidade) {
        return tipoPenalidade.id === 'DESCREDENCIAMENTO_SICAF' || tipoPenalidade.id === 'SUSPENSAO_TEMPORARIA_IMPEDIMENTO' || tipoPenalidade.id === 'DECLARACAO_INIDONEIDADE' || tipoPenalidade.id === 'IMPEDIMENTO_LEI_PREGAO';
      });
    }).property('tiposPenalidadeSelecionados'),

    dataInicioObrigatoria: (function () {
      return this.tiposPenalidadeSelecionados.find(function (tipoPenalidade) {
        return tipoPenalidade.id === 'DESCREDENCIAMENTO_SICAF' || tipoPenalidade.id === 'SUSPENSAO_TEMPORARIA_IMPEDIMENTO' || tipoPenalidade.id === 'DECLARACAO_INIDONEIDADE' || tipoPenalidade.id === 'IMPEDIMENTO_LEI_PREGAO';
      });
    }).property('tiposPenalidadeSelecionados'),

    limparDataInscricaoMultaMoratoriaDividaAtiva: (function () {
      if (!this.model.multaMoratoriaInscritaDividaAtiva) {
        Ember['default'].set(this.model, 'dataInscricaoMultaMoratoriaDividaAtiva', '');
      }
    }).observes('model.multaMoratoriaInscritaDividaAtiva'),

    limparDataInscricaoMultaCompensatoriaDividaAtiva: (function () {
      if (!this.model.multaCompensatoriaInscritaDividaAtiva) {
        Ember['default'].set(this.model, 'dataInscricaoMultaCompensatoriaDividaAtiva', '');
      }
    }).observes('model.multaCompensatoriaInscritaDividaAtiva'),

    limparDadosMultaMoratoria: (function () {
      if (this.get('envolveMultaMoratoria')) {
        return;
      }

      this.set('model.valorMultaMoratoria', null);
      this.set('model.prazoPgtoMultaMoratoria', '');
      this.set('model.dataPgtoMultaMoratoria', '');
      this.set('model.multaMoratoriaInscritaDividaAtiva', false);
      this.set('model.dataInscricaoMultaMoratoriaDividaAtiva', '');
    }).observes('envolveMultaMoratoria'),

    limparDadosMultaCompensatoria: (function () {
      if (this.get('envolveMultaCompensatoria')) {
        return;
      }

      this.set('model.valorMultaCompensatoria', null);
      this.set('model.prazoPgtoMultaCompensatoria', '');
      this.set('model.dataPgtoMultaCompensatoria', '');
      this.set('model.multaCompensatoriaInscritaDividaAtiva', false);
      this.set('model.dataInscricaoMultaCompensatoriaDividaAtiva', '');
    }).observes('envolveMultaCompensatoria'),

    descricaoRelatorios: (function () {

      return [{
        textoMenu: 'Registro Penalidade',
        servico: 'relatorio/registroPenalidade/' + this.get("model.id"),
        nomeArquivo: 'registro_penalidade',
        nomeParaID: 'registro-penalidade',
        controlePermissao: true
      }];
    }).property('model.id')

  });

});
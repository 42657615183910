define('webapp/pods/usuario/model', ['exports', 'ember', 'webapp/models/servidor'], function (exports, Ember, Servidor) {

	'use strict';

	exports['default'] = Ember['default'].Object.extend({
		id: null,
		servidor: Servidor['default'].create({}),
		roles: [],
		version: null,
		authToken: null,

		findUrlFn: function findUrlFn(id) {
			return "usuario/" + id.toString();
		}

	});

});
define('webapp/pods/aquisicao/item/quantidade-participacao/criar/route', ['exports', 'ember', 'webapp/pods/aquisicao/item/quantidade-participacao/model'], function (exports, Ember, QuantidadeParticipacao) {

	'use strict';

	exports['default'] = Ember['default'].Route.extend({
		controllerName: 'aquisicao/item/quantidade-participacao',

		setupController: function setupController(controller) {
			controller.set('model', QuantidadeParticipacao['default'].create({}));

			this.get('comunicador').leParaPropriedade(controller, 'orgao', 'orgaos', 'Não foi possível obter os orgãos externos!');
		},

		model: function model() {
			return QuantidadeParticipacao['default'].create();
		}
	});

});
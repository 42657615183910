define('webapp/components/busca-requisicoes', ['exports', 'ember', 'webapp/utils/util'], function (exports, Ember, Util) {

  'use strict';

  /* global _ */
  exports['default'] = Ember['default'].Component.extend({
    tagName: 'span',
    comunicador: Ember['default'].inject.service(),

    mostrarListaRequisicoes: false,
    requisicoesSelecionadas: Ember['default'].A(),
    resumosRequisicoes: [],
    parametrosPesquisa: {
      numero: '',
      ano: '',
      dataInicial: '',
      dataFinal: '',
      programa: '',
      objeto: '',
      classificacoesItens: [],
      descricaoItem: '',
      temItens: '',
      temAquisicoes: ''
    },

    mostrarVazias: true,
    mostrarUtilizadasEmAquisicao: true,

    texto: 'Nome',

    labelAcessivelBotao: (function () {
      var label = 'Selecionar ' + (this.labelAcessivel ? this.labelAcessivel : this.texto ? this.texto : 'requisições');
      return '' + label + (this.obrigatorio ? ', seleção obrigatória' : '');
    }).property('labelAcessivel', 'texto'),

    inicializar: (function () {
      this.set('requisicoesSelecionadas', Ember['default'].A());
      this.set('mostrarListaRequisicoes', false);
      this.set('resumosRequisicoes', []);
      this._inicializarParametrosPesquisa();
      this.get('comunicador').leParaPropriedade(this, 'programaTrabalho', 'programasDeTrabalho', 'Não foi possível obter os programas de trabalho.');
      this.get('comunicador').leParaPropriedade(this, 'classificacaoItem', 'classificacoesFinanceiras', 'Não foi possível obter as classificações financeiras dos itens.');
    }).on('init'),

    requisicoesSelecionadasOrdenadas: (function () {
      return this.requisicoesSelecionadas.sortBy('numeroApresentacao');
    }).property('requisicoesSelecionadas.@each'),

    //Apenas as requisições que não foram selecionadas
    resumosRequisicoesDisponiveis: (function () {
      var _this = this;

      return this.resumosRequisicoes.filter(function (requisicao) {
        return !_.findWhere(_this.requisicoesSelecionadas, { id: requisicao.id });
      });
    }).property('requisicoesSelecionadas.@each', 'resumosRequisicoes'),

    _limparPesquisa: function _limparPesquisa() {
      this.set('parametrosPesquisa.numero', '');
      this.set('parametrosPesquisa.ano', '');
      this.set('parametrosPesquisa.dataInicial', '');
      this.set('parametrosPesquisa.dataFinal', '');
      this.set('parametrosPesquisa.programa', '');
      this.set('parametrosPesquisa.objeto', '');
      this.set('parametrosPesquisa.classificacoesItens', []);
      this.set('parametrosPesquisa.descricaoItem', '');
    },

    _inicializarParametrosPesquisa: function _inicializarParametrosPesquisa() {
      this._limparPesquisa();
      //quando o componente for aberto, ele pesquisará automaticamente as requisições do ano corrente
      this.set('parametrosPesquisa.ano', new Date().getFullYear());
      this.set('parametrosPesquisa.temItens', !this.mostrarVazias);
      this.set('parametrosPesquisa.temAquisicoes', this.mostrarUtilizadasEmAquisicao);
    },

    actions: {

      abrirTelaSelecao: function abrirTelaSelecao() {
        this._inicializarParametrosPesquisa();
        this.send('buscarRequisicoes');
      },

      limparPesquisa: function limparPesquisa() {
        this._limparPesquisa();
      },

      buscarRequisicoes: function buscarRequisicoes() {
        var _this2 = this;

        if (!this.mostrarUtilizadasEmAquisicao) {
          Util['default'].alerta('Não apresenta requisições já utilizadas em alguma aquisição');
        }
        if (!this.mostrarVazias) {
          Util['default'].alerta('Não apresenta requisições que não possuam itens');
        }

        this.requisicoesSelecionadas.addObjects(Util['default'].clonaStringfyParse(this.listaRequisicoes));

        // this.set('parametrosPesquisa.temItens', !this.mostrarVazias);
        // this.set('parametrosPesquisa.temAquisicoes', this.mostrarUtilizadasEmAquisicao);

        this.get('comunicador').servico('post', 'requisicao/pesquisa', this.parametrosPesquisa).then(function (resultado) {
          _this2.set('resumosRequisicoes', resultado);
          _this2.set('mostrarListaRequisicoes', true);
        });
      },

      selecionarRequisicao: function selecionarRequisicao(requisicao) {
        var _this3 = this;

        if (_.findWhere(this.requisicoesSelecionadas, { id: requisicao.id })) {
          return;
        }

        this.get('comunicador').servico('get', 'requisicao/' + requisicao.id).then(function (resultado) {
          _this3.requisicoesSelecionadas.addObject(resultado);
        });
      },

      desfazerSelecao: function desfazerSelecao(requisicao) {
        var requisicaoARemover = _.findWhere(this.requisicoesSelecionadas, { id: requisicao.id });
        this.requisicoesSelecionadas.removeObject(requisicaoARemover);
      },

      finalizarBusca: function finalizarBusca() {
        var _this4 = this;

        this.listaRequisicoes.length = 0; //limpa o array

        this.get('requisicoesSelecionadasOrdenadas').forEach(function (req) {
          _this4.listaRequisicoes.addObject(req);
        });

        this.set('requisicoesSelecionadas', Ember['default'].A());
        this.set('mostrarListaRequisicoes', false);

        /*
          Esta instrução (renderizar novamente) é necessária pois a lista de requisições
          selecionados apresentada no bloco {{each}} (logo após sair da tela de
          seleção) não é automaticamente atualizada conforme a a lista selecionada
          (por exemplo, se forem selecionados os itens 1 e 2, a lista aparecerá como
          "1 2". Entretanto, se, logo após, forem selecionados o item 3 e
          deselecionarem-se os itens 1 e 2, a lista aparecerá como "1 2 3" - ou
          seja, o {{each}} não atualiza a lista, mantendo os itens da seleção
          antiga juntamente com os da seleção atual (e os botões "X" ao lado
          dos itens também deixam de funcionar)
        */
        this.rerender();
      },

      cancelarBusca: function cancelarBusca() {
        this.set('requisicoesSelecionadas', Ember['default'].A());
        this.set('mostrarListaRequisicoes', false);
      },

      excluirRequisicaoBase: function excluirRequisicaoBase(requisicao) {
        this.listaRequisicoes.removeObject(requisicao);
      }

    }

  });

});
define('webapp/pods/aquisicao/item/quantidade-participacao/controller', ['exports', 'ember', 'webapp/mixins/crud-mixin'], function (exports, Ember, CrudMixin) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend(CrudMixin['default'], {

    needs: ['aquisicao', 'aquisicao/item'],

    breadCrumbConfig: {
      fnNomeApresentacao: 'fnNomeApresentacao'
    },

    fnNomeApresentacao: function fnNomeApresentacao() {
      return 'Participação ' + (this.model.version ? 'Nº ' + this.model.numeroApresentacao : '(Nova)');
    },

    crudConfig: {
      criar: {
        urlFn: function urlFn() {
          return 'itemAquisicao/' + this.get("controllers.aquisicao/item").model.id + '/participacao';
        },
        okFn: function okFn(opcao) {
          this.get('controllers.aquisicao').send('ler');
          this.get('controllers.aquisicao/item').send('ler');
          if (opcao === 'CRIAR_E_PROXIMO') {
            //ação "borbulhará" para a rota
            this.send('refreshRota');
            return;
          }
          this.transitionToRoute('aquisicao/item');
        }
      },
      atualizar: {
        okFn: function okFn() {
          this.get('controllers.aquisicao').send('ler');
          this.get('controllers.aquisicao/item').send('ler');
          this.transitionToRoute('aquisicao/item');
        }
      },
      excluir: {
        okFn: function okFn() {
          this.get('controllers.aquisicao').send('ler');
          this.get('controllers.aquisicao/item').send('ler');
        }
      }
    }

  });

});
define('webapp/pods/aquisicao/contrato/efeito-financeiro/controller', ['exports', 'ember', 'webapp/mixins/crud-mixin'], function (exports, Ember, CrudMixin) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend(CrudMixin['default'], {

    needs: ['aquisicao/contrato'],

    breadCrumbConfig: {
      fnNomeApresentacao: 'fnNomeApresentacao'
    },

    fnNomeApresentacao: function fnNomeApresentacao() {
      return 'Efeito Financeiro ' + (this.model.version ? 'Nº ' + this.model.numeroApresentacao : '(Novo)');
    },

    aditivos: (function () {
      return this._aditivos;
    }).property('_aditivos'),

    apostilamentos: (function () {
      return this._apostilas;
    }).property('_apostilas'),

    referencia: 'Apostilamento',

    crudConfig: {
      criar: {
        urlFn: function urlFn() {
          return 'contrato/' + this.get('controllers.aquisicao/contrato').model.id + '/efeitoFinanceiro';
        },
        okFn: function okFn(opcao) {
          this.get('controllers.aquisicao/contrato').send('ler');
          if (opcao === 'CRIAR_E_PROXIMO') {
            //ação "borbulhará" para a rota
            this.send('refreshRota');
            return;
          }
          this.get('controllers.aquisicao/contrato').send('ler');
          this.transitionToRoute('aquisicao/contrato');
        }
      },
      atualizar: {
        okFn: function okFn() {
          this.get('controllers.aquisicao/contrato').send('ler');
          this.transitionToRoute('aquisicao/contrato');
        }
      },
      excluir: {
        okFn: function okFn() {
          this.get('controllers.aquisicao/contrato').send('ler');
          this.transitionToRoute('aquisicao/contrato');
        }
      }
    },

    ehEfeitoNovo: (function () {
      return !this.model.version;
    }).property('model.version'),

    _ajustaReferencia: (function () {
      if (this.get('ehEfeitoNovo')) {
        this.set('referencia', '');
      } else if (this.model.apostilamento) {
        this.set('referencia', 'Apostilamento');
      } else if (this.model.aditivo) {
        this.set('referencia', 'Aditivo');
      } else {
        this.set('referencia', '');
      }
    }).observes('model'),

    aditivoDesabilitado: (function () {
      return this.referencia !== 'Aditivo';
    }).property('referencia'),

    apostilamentoDesabilitado: (function () {
      return this.referencia !== 'Apostilamento';
    }).property('referencia'),

    ajustaModelsAditivoApostilamento: (function () {
      if (this.referencia === 'Apostilamento') {
        Ember['default'].set(this, 'model.aditivo', null);
      } else if (this.referencia === 'Aditivo') {
        Ember['default'].set(this, 'model.apostilamento', null);
      } else {
        Ember['default'].set(this, 'model.aditivo', null);
        Ember['default'].set(this, 'model.apostilamento', null);
      }
    }).observes('referencia'),

    origemEfeitoFinanceiro: (function (efeitoFinanceiro) {
      if (efeitoFinanceiro.apostilamento) {
        return 'Apostilamento ' + efeitoFinanceiro.apostilamento.numeroApresentacao;
      } else if (efeitoFinanceiro.aditivo) {
        return 'Aditivo ' + efeitoFinanceiro.aditivo.numeroApresentacao;
      } else {
        return '-';
      }
    }).observes('model.aditivo', 'model.apostilamento')

  });

});
define('webapp/models/municipio', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Object.extend({
    id: null,
    nome: '',
    uf: null
  });

});
define('webapp/models/unidade-organizacional', ['exports', 'ember'], function (exports, Ember) {

	'use strict';

	exports['default'] = Ember['default'].Object.extend({
			id: null,
			nome: '',
			telefone: '',
			email: ''
	});

});
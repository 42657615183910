define('webapp/pods/aquisicao/compra/listagem/controller', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({

    needs: ['aquisicao'],

    //true se for registro de preços e se já tiver sido criada alguma ata OU
    //se não for registro de preços e algum lote tiver sido adjudicado
    permitidoIncluirCompra: (function () {
      if (this.get('controllers.aquisicao.model.registroPreco')) {
        return this.get('controllers.aquisicao.model.atas').length;
      } else {
        return this.get('controllers.aquisicao.haLoteAdjudicado');
      }
    }).property('model.registroPreco', 'model.atas', 'controllers.aquisicao.haLoteAdjudicado'),

    mostraBotaoComprarTudo: (function () {
      var modelAquisicao = this.get('controllers.aquisicao.model');
      return !modelAquisicao.registroPreco && modelAquisicao.compras.length === 0;
    }).property('controllers.aquisicao.model.compras.@each', 'controllers.aquisicao.model.registroPreco'),

    actions: {
      comprarTudo: function comprarTudo() {
        var _this = this;

        this.get('comunicador').servico('get', 'aquisicao/' + this.get('controllers.aquisicao').model.id + '/comprarTudo').then(function () {
          _this.send('ler');
          return;
        });
      }
    }

  });

});
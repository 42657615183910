define('webapp/pods/fornecedor/fornecivel/controller', ['exports', 'ember', 'webapp/mixins/crud-mixin'], function (exports, Ember, CrudMixin) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend(CrudMixin['default'], {

    needs: ['fornecedor'],

    breadCrumbConfig: {
      fnNomeApresentacao: 'fnNomeApresentacao'
    },

    fnNomeApresentacao: function fnNomeApresentacao() {
      return 'Fornecível ' + (this.model.version ? this.model.id : '(Novo)');
    },

    crudConfig: {
      criar: {
        urlFn: function urlFn() {
          return 'fornecedor/' + this.get("controllers.fornecedor").model.id + '/fornecivel';
        },
        okFn: function okFn(opcao) {
          this.get("controllers.fornecedor").send('ler');
          if (opcao === 'CRIAR_E_PROXIMO') {
            //ação "borbulhará" para a rota
            this.send('refreshRota');
            return;
          }
          this.transitionToRoute('fornecedor');
        }
      },
      excluir: {
        okFn: function okFn() {
          this.get("controllers.fornecedor").send('ler');
          this.transitionToRoute('fornecedor');
        }
      }
    }

  });

});
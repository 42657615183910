define('webapp/pods/aquisicao/evento/route', ['exports', 'ember', 'webapp/utils/util'], function (exports, Ember, Util) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({
    setupController: function setupController(controller, model) {
      controller.set('model', Util['default'].clona(model));

      this.get('comunicador').leParaPropriedade(controller, 'tipoEventoAquisicao', 'tiposEvento', "Não foi possível obter os tipos de eventos de aquisição.");
    }
  });

});
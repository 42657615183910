define('webapp/components/radio-group', ['exports', 'ember', 'webapp/mixins/ajusta-id-interno-mixin', 'webapp/mixins/ajusta-tamanho-input-mixin', 'webapp/mixins/constroi-aria-label-mixin'], function (exports, Ember, AjustaIdInternoMixin, AjustaTamanhoInputMixin, ConstroiAriaLabelMixin) {

	'use strict';

	/* Exemplo de Uso

		{{radio-group
			radios=array de objetos com a seguinte estrutura:
					texto=<texto do radiobutton>
					valor=<valor do radiobutton>
			atributoDestino=<referência à variável externa que armazenará o valor selecionado>
			tamanho=<largura do componente em termos do bootstrap - um número X
					      de 1 a 12 utilizado para montar a classe CSS "col-md-X".
						  O default é 12>		labelAcessivel="Âmbito de Incidência: Administração Pública"
			somenteLeitura=<true ou false, sem aspas>
			desabilitado=<true|false (default), indicando se é possível marcar o radio-button>
			id="radio||ambitoIncidencia-administracaoPublica|penalidade|identificacao"}}
	*/

	exports['default'] = Ember['default'].Component.extend(AjustaIdInternoMixin['default'], AjustaTamanhoInputMixin['default'], ConstroiAriaLabelMixin['default'], {
		radios: [],
		atributoDestino: '',
		estiloCSS: '',
		desabilitado: false,
		somenteLeitura: false,
		getTamanho: (function () {
			var auxTamanho = this.get('radios').length >= 4 ? 4 : this.get('radios').length;
			return 12 / auxTamanho;
		}).property('')
	});

});
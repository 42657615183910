define('webapp/components/decimal-input', ['exports', 'ember', 'webapp/mixins/ajusta-id-interno-mixin', 'webapp/mixins/ajusta-tamanho-input-mixin', 'webapp/mixins/constroi-aria-label-mixin'], function (exports, Ember, AjustaIdInternoMixin, AjustaTamanhoInputMixin, ConstroiAriaLabelMixin) {

	'use strict';

	/*
	Exemplo de uso:
	{{numero-input
		 texto=<texto do label>
	   labelAcessivel=<texto para ser lido por leitores automáticos de interface
	                para acessibilidade por pessoas com deficiência visual.
	                Se não for definido, será lido o texto do label do campo>
		 valor=<valor associado ao input>
	   tamanho=<largura do componente em termos do bootstrap - um número X
	            de 1 a 12 utilizado para montar a classe CSS "col-md-X".
	            O default é 12>
		 classeLabel=<classe do label, default é "col-md-2 control-label">
		 classeElementoInterno=<classe do input, default é "col-md-10">
		 somenteLeitura=<true ou false, sem aspas>
	   obrigatorio=<true ou false, sem aspas, indicando se o campo deve apresentar um asterisco
	                ao lado do seu label>
		 desabilitado=<true | false (default), indicando se o componente estará desabilitado>
	}}

	Todo moeda-input com id  "tag_W_X_Y_Z"
	cria um c  _ajustaMascara: function() {
			this.set('mascara', MascaraInput.mascara(this.mascara)); //converte máscara
	  }.on('willInsertElement')omponente de id "campo_W_X_Y_Z"

	O funcionamento de "tamanho", "tamanhoLabel" e "tamanhoCampo" é dependente do ajusta-tamanho-input-mixin.
	*/

	exports['default'] = Ember['default'].Component.extend(AjustaIdInternoMixin['default'], AjustaTamanhoInputMixin['default'], ConstroiAriaLabelMixin['default'], {
		desabilitado: false,
		somenteLeitura: false,

		tipoInterno: 'campo',

		insereAriaLabel: (function () {
			var campo = this.$('input')[0];
			campo.setAttribute('aria-label', this.get('_ariaLabel'));
		}).on('willInsertElement')

	});

});
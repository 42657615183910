define('webapp/pods/aquisicao/contrato/aditivo/tipo/model', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Object.extend({
    tipo: '',

    findUrlFn: null
  });

});
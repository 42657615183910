define('webapp/pods/aquisicao/contrato/aditivo/prorrogacao-execucao/controller', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({
    needs: ['aquisicao/contrato', 'aquisicao/contrato/aditivo'],

    ehAditivoExistente: (function () {
      return this.get('model').version;
    }).property('controllers.aquisicao/contrato/aditivo.model.version'),

    dataInicioExecucaoAtualizada: (function () {
      return this.get('controllers.aquisicao/contrato.model.dataInicioExecucaoAtualizada');
    }).property('controllers.aquisicao/contrato.model'),

    dataFimExecucaoAtualizada: (function () {
      return this.get('controllers.aquisicao/contrato.model.dataFimExecucaoAtualizada');
    }).property('controllers.aquisicao/contrato.model')
  });

});
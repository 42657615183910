define('webapp/components/busca-servidores', ['exports', 'ember', 'webapp/utils/util'], function (exports, Ember, Util) {

  'use strict';

  /* global _ */
  exports['default'] = Ember['default'].Component.extend({

    comunicador: Ember['default'].inject.service(),

    mostrarTelaSelecao: false,
    servidoresSelecionados: Ember['default'].A(),
    resumosServidores: [],
    parametrosPesquisa: {
      nome: '',
      usuario: '',
      codSof: ''
    },

    texto: 'Nome',

    labelAcessivelBotao: (function () {
      var label = 'Selecionar ' + (this.labelAcessivel ? this.labelAcessivel : this.texto ? this.texto : 'servidores');
      return '' + label + (this.obrigatorio ? ', seleção obrigatória' : '');
    }).property('labelAcessivel', 'texto'),

    inicializar: (function () {
      this.set('servidoresSelecionados', Ember['default'].A());
      this.set('mostrarTelaSelecao', false);
      this.set('resumosServidores', []);
      this._inicializarParametrosPesquisa();
    }).on('init'),

    servidoresSelecionadosOrdenados: (function () {
      return this.servidoresSelecionados.sortBy('nome');
    }).property('servidoresSelecionados.@each'),

    //Apenas as servidores que não foram selecionadas
    resumosServidoresDisponiveis: (function () {
      var _this = this;

      return this.resumosServidores.filter(function (servidor) {
        return !_.findWhere(_this.servidoresSelecionados, { codSof: servidor.codSof });
      });
    }).property('servidoresSelecionados.@each', 'resumosServidores'),

    _limparPesquisa: function _limparPesquisa() {
      this.set('parametrosPesquisa.nome', '');
      this.set('parametrosPesquisa.usuario', '');
      this.set('parametrosPesquisa.codSof', '');
    },

    _inicializarParametrosPesquisa: function _inicializarParametrosPesquisa() {
      this._limparPesquisa();
    },

    _inicializarServidoresSelecionados: function _inicializarServidoresSelecionados() {
      this.servidoresSelecionados.addObjects(Util['default'].clonaStringfyParse(this.listaServidores));
    },

    actions: {

      abrirTelaSelecao: function abrirTelaSelecao() {
        this._inicializarServidoresSelecionados();
        this._inicializarParametrosPesquisa();
        this.set('mostrarTelaSelecao', true);
      },

      limparPesquisa: function limparPesquisa() {
        this._limparPesquisa();
      },

      buscarServidores: function buscarServidores() {
        var _this2 = this;

        if (this.listaServidores) {} else {
          this.set('servidoresSelecionados', Ember['default'].A());
        }

        this.get('comunicador').servico('post', 'servidor/pesquisa', this.parametrosPesquisa).then(function (resultado) {
          _this2.set('resumosServidores', resultado);
        });
      },

      selecionarServidor: function selecionarServidor(servidor) {
        if (_.findWhere(this.servidoresSelecionados, { codSof: servidor.codSof })) {
          return;
        }

        this.servidoresSelecionados.addObject(servidor);
      },

      desfazerSelecao: function desfazerSelecao(servidor) {
        var servidorARemover = _.findWhere(this.servidoresSelecionados, { codSof: servidor.codSof });
        this.servidoresSelecionados.removeObject(servidorARemover);
      },

      finalizarBusca: function finalizarBusca() {
        var _this3 = this;

        this.listaServidores.length = 0; //limpa o array

        this.get('servidoresSelecionadosOrdenados').forEach(function (servidor) {
          _this3.listaServidores.addObject(servidor);
        });

        this.set('servidoresSelecionados', Ember['default'].A());
        this.set('mostrarTelaSelecao', false);

        /*
          Esta instrução (renderizar novamente) é necessária pois a lista de fiscais
          selecionados apresentada no bloco {{each}} (logo após sair da tela de
          seleção) não é automaticamente atualizada conforme a a lista selecionada
          (por exemplo, se forem selecionados os itens 1 e 2, a lista aparecerá como
          "1 2". Entretanto, se, logo após, forem selecionados o item 3 e
          deselecionarem-se os itens 1 e 2, a lista aparecerá como "1 2 3" - ou
          seja, o {{each}} não atualiza a lista, mantendo os itens da seleção
          antiga juntamente com os da seleção atual (e os botões "X" ao lado
          dos itens também deixam de funcionar)
        */
        this.rerender();
      },

      cancelarBusca: function cancelarBusca() {
        this.set('servidoresSelecionados', Ember['default'].A());
        this.set('mostrarTelaSelecao', false);
      },

      excluirServidorBase: function excluirServidorBase(servidor) {
        this.listaServidores.removeObject(servidor);
      }

    }

  });

});
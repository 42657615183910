define('webapp/models/programa-trabalho', ['exports', 'ember'], function (exports, Ember) {

	'use strict';

	exports['default'] = Ember['default'].Object.extend({
			id: null,
			nome: '',
			codigo: null,
			dataValidade: ''
	});

});
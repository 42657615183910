define('webapp/pods/aquisicao/contrato/aditivo/revisao/controller', ['exports', 'ember', 'webapp/mixins/crud-mixin', 'webapp/utils/util'], function (exports, Ember, CrudMixin, Utils) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend(CrudMixin['default'], {

    needs: ['aquisicao/contrato', 'aquisicao/contrato/aditivo'],

    breadCrumbConfig: {
      fnNomeApresentacao: 'fnNomeApresentacao'
    },

    fnNomeApresentacao: function fnNomeApresentacao() {
      return 'Item de Revisão Contratual ' + (this.model.version ? 'Nº ' + this.model.numeroApresentacao : '(Novo)');
    },

    crudConfig: {
      criar: {
        urlFn: function urlFn() {
          return 'aditivo/' + this.get("controllers.aquisicao/contrato/aditivo").model.id + '/revisaoFinanceira';
        },
        okFn: function okFn(opcao) {
          this.get('controllers.aquisicao/contrato/aditivo').send('ler');
          if (opcao === 'CRIAR_E_PROXIMO') {
            //ação "borbulhará" para a rota
            this.send('refreshRota');
            return;
          }
          this.transitionToRoute('aquisicao/contrato/aditivo');
        }
      },
      atualizar: {
        okFn: function okFn() {
          this.get('controllers.aquisicao/contrato/aditivo').send('ler');
          this.transitionToRoute('aquisicao/contrato/aditivo');
        }
      },
      excluir: {
        okFn: function okFn() {
          this.get('controllers.aquisicao/contrato/aditivo').send('ler');
          this.transitionToRoute('aquisicao/contrato/aditivo');
        }
      }
    },

    ehRevisaoNova: (function () {
      return !this.model.version;
    }).property('model.version'),

    valorMensalAtual: (function () {
      if (!this.situacaoAtualContrato) {
        return;
      }
      return Utils['default'].colocaPontosSeparadoresDeMilhar(this.situacaoAtualContrato.valorAtualMensal);
    }).property('situacaoAtualContrato'),

    valorUnicoAtual: (function () {
      if (!this.situacaoAtualContrato) {
        return;
      }
      return Utils['default'].colocaPontosSeparadoresDeMilhar(this.situacaoAtualContrato.valorAtualUnico);
    }).property('situacaoAtualContrato'),

    valorTotalAtual: (function () {
      if (!this.situacaoAtualContrato) {
        return;
      }
      return Utils['default'].colocaPontosSeparadoresDeMilhar(this.situacaoAtualContrato.valorAtualTotal);
    }).property('situacaoAtualContrato')

  });

});
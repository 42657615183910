define('webapp/components/busca-ender-cep', ['exports', 'ember', 'webapp/mixins/ajusta-tamanho-input-mixin', 'webapp/mixins/constroi-aria-label-mixin', 'webapp/utils/util'], function (exports, Ember, AjustaTamanhoInputMixin, ConstroiAriaLabelMixin, Util) {

  'use strict';

  var thisDoComponente = null;
  //function f1(this1) {___this = this1};

  exports['default'] = Ember['default'].Component.extend(AjustaTamanhoInputMixin['default'], ConstroiAriaLabelMixin['default'], {

    TAM_CEP_INVALIDO: "O campo CEP deve conter 9 caracteres incluindo o hífen.",

    texto: 'CEP',
    endereco: null,
    regComp_busca_ender_cep: 'regComp_busca_ender_cep',
    promessaBuscaPeloCEP: null,

    desabilitarBotao: (function () {
      return this.desabilitado || this.somenteLeitura;
    }).property('desabilitado', 'somenteLeitura'),

    retiraClasseMargemInferior: (function () {
      thisDoComponente = this;
      this.set('classText', false);
    }).on('didInsertElement'),

    labelAcessivelCampo: (function () {
      return this.labelAcessivel;
    }).property('labelAcessivel'),

    labelAcessivelBotaoPesquisar: (function () {
      return 'Iniciar busca de ' + (this.labelAcessivel ? this.labelAcessivel : this.texto ? this.texto : 'CEP');
    }).property('labelAcessivel'),

    validaCEP: function validaCEP() {
      //  if (this.valor.length > 8)
      if (this.valor.indexOf("_") >= 0) throw this.TAM_CEP_INVALIDO;
    },

    /*
      buscaDadosPeloCEP: function() {
        var _this = this;
        this.ControleSpinner.empilharTransacao();
        try {
              Util.getJSON('https://viacep.com.br/ws/'+ this.valor + '/json', 
                          function(err, json) {
                              _this.ControleSpinner.desempilharTransacao();
                              if (err !== null) {
                                  console.log('Erro no retorno da chamada ao webservice de CEP: ' + err);
                              } else {
                                  _this.metodoParaAtribuirEndereco('Brasil', json.logradouro, json.bairro, json.localidade, json.uf);
                              }
                          }
              );
        } catch(e) {
              this.ControleSpinner.desempilharTransacao();
              console.log('Erro ao tentar chamar webservice de CEP: ' + e);
        }
     },
    
     actions: {
       buscarCEP: function() {
           // https://viacep.com.br/
           // https://github.com/wgenial/consultacep
           // formato da pesquisa: viacep.com.br/ws/01001000/json/
           if (this.podeSobreporCampos()) {
               this.buscaDadosPeloCEP();
           }
       },
     }
    */

    criaPromessaBuscaPeloCEP: function criaPromessaBuscaPeloCEP() {
      var _this = this;

      this.ControleSpinner.empilharTransacao();
      var promessa = null;
      try {
        promessa = new Ember['default'].RSVP.Promise(function (resolve, reject) {
          Util['default'].getJSON('https://viacep.com.br/ws/' + _this.valor + '/json', function (err, json) {
            _this.ControleSpinner.desempilharTransacao();
            if (err) {
              reject(err);
            } else if (json.erro) {
              reject('CEP Inválido ou Erro interno do servidor da webservice.');
            } else {
              var retorno = { componenteEndereco: _this.componenteEndereco,
                JSON: json
              };
              resolve(retorno);
            }
          });
        });
      } catch (e) {
        this.ControleSpinner.desempilharTransacao();
        console.log('Erro ao tentar chamar webservice de CEP: ' + e.message);
        return null;
      }
      return promessa;
    },

    focar: function focar() {
      this.$('input')[0].focus();
      this.$('input')[0].select();
    },

    actions: {
      buscarCEP: function buscarCEP() {
        // https://viacep.com.br/
        // https://github.com/wgenial/consultacep
        // formato da pesquisa: viacep.com.br/ws/01001000/json/
        try {
          this.validaCEP();
          this.promessaBuscaPeloCEP = this.criaPromessaBuscaPeloCEP();
          this.sendAction('buscarCEP', this);
        } catch (e) {
          this.focar();
          if (e === this.TAM_CEP_INVALIDO) {
            Util['default'].alertaAviso(e);
          } else {
            Ember['default'].Logger.error(e.message);
          }
        }
      }
    }

  });

});
define('webapp/pods/aquisicao/ata/adesao/item/criar/route', ['exports', 'ember', 'webapp/pods/aquisicao/ata/adesao/item/model'], function (exports, Ember, ItemAdesaoExterna) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({
    controllerName: 'aquisicao/ata/adesao/item',

    setupController: function setupController(controller) {
      controller.set('model', ItemAdesaoExterna['default'].create());
    },

    model: function model() {
      return ItemAdesaoExterna['default'].create();
    }

  });

});
define('webapp/components/busca-contrato', ['exports', 'ember', 'webapp/mixins/ajusta-tamanho-input-mixin'], function (exports, Ember, AjustaTamanhoInputMixin) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(AjustaTamanhoInputMixin['default'], {

    comunicador: Ember['default'].inject.service(),

    mostrarBuscaContrato: false,
    mostrarResultados: false,
    contratos: [],
    contratoSelecionado: {}, //para uso interno do componente (de onde serão
    //buscados os dados a serem apresentados, etc.)

    texto: 'Contrato',

    numero: undefined,
    ano: undefined,
    dataInicial: undefined,
    dataFinal: undefined,
    programa: undefined,
    objeto: undefined,
    requisitante: undefined,
    classificacoesItens: undefined,
    descricaoItem: undefined,
    mostraTabelaResultadoPesquisa: false,
    mostraMensagemPesquisaSemResultado: false,

    desabilitarBotoes: (function () {
      return this.desabilitado || this.somenteLeitura;
    }).property('desabilitado', 'somenteLeitura'),

    labelAcessivelCampo: (function () {
      return this.labelAcessivel;
    }).property('labelAcessivel'),

    instrucoesAdicionaisCampo: (function () {
      return this.get('desabilitarBotoes') ? 'Botões para alteração desabilitados.' : 'Use os botões que vêm a seguir para alterar o contrato.';
    }).property('desabilitarBotoes'),

    labelAcessivelBotaoPesquisar: (function () {
      return 'Iniciar busca de ' + (this.labelAcessivel ? this.labelAcessivel : this.texto ? this.texto : 'contrato');
    }).property('labelAcessivel'),

    labelAcessivelBotaoExcluir: (function () {
      return 'Limpar ' + (this.labelAcessivel ? this.labelAcessivel : 'contrato') + ' selecionado';
    }).property('labelAcessivel'),

    labelDescricaoQuandoSelecionado: (function () {
      if (!this.contratoSelecionado) {
        return "";
      }

      return this.contratoSelecionado.numeroApresentacao;
    }).property('contratoSelecionado'),

    inicializar: (function () {
      this.inicializarContratoSelecionado();
    }).on('init'),

    inicializarContratoSelecionado: (function () {
      this.set('contratoSelecionado', this.atributoDestino);
    }).observes('objetoPai'),

    //---------

    _labelAcessivelBotao: (function () {
      return 'Iniciar busca de contrato, ' + this.texto;
    }).property('texto'),

    actions: {

      excluir: function excluir() {
        this.set('contratoSelecionado', null);
        this.set('atributoDestino', null);
      },

      iniciarBusca: function iniciarBusca() {

        this.set('numero', undefined);
        this.set('ano', undefined);

        this.set('mostraTabelaResultadoPesquisa', false);
        this.set('mostraMensagemPesquisaSemResultado', false);
        this.set('mostrarBuscaContrato', true);
      },

      pesquisarContrato: function pesquisarContrato() {
        var _this = this;

        if (!this.numero && !this.ano) {
          return;
        }

        var parametrosPesquisaContrato = {
          numero: this.numero,
          ano: this.ano,
          considerarApenasContratosSemReajuste: false
        };

        this.set('mostrarResultados', false);

        this.get('comunicador').servico('post', 'aquisicao/contrato/pesquisa', parametrosPesquisaContrato).then(function (resultado) {
          _this.set('contratosPesquisados', resultado);
          _this.set('mostrarResultados', true);
        });
      },

      selecionarContrato: function selecionarContrato(contrato) {
        this.set('atributoDestino', contrato);
        this.set('contratoSelecionado', contrato);
        this.set('mostrarBuscaContrato', false);
      },

      cancelarBusca: function cancelarBusca() {
        this.set('mostrarBuscaContrato', false);
      }

    }

  });

});
define('webapp/pods/aquisicao/contrato/aditivo/prorrogacao-execucao/model', ['exports', 'ember'], function (exports, Ember) {

	'use strict';

	exports['default'] = Ember['default'].Object.extend({
		id: null,
		novaDataExecucao: '',
		descricao: ''
	});

});
define('webapp/pods/transparencia/documento/controller', ['exports', 'ember', 'webapp/mixins/crud-mixin', 'webapp/utils/util', 'webapp/defs', 'webapp/pods/transparencia/documento/constantes-documento', 'webapp/pods/aquisicao/constantes-aquisicao'], function (exports, Ember, CrudMixin, Util, Defs, Constantes, ConstantesAquisicao) {

    'use strict';

    exports['default'] = Ember['default'].Controller.extend(CrudMixin['default'], {
        needs: ['transparencia/documento/listagem'],

        breadCrumbConfig: {
            fnNomeApresentacao: 'fnNomeApresentacao'
        },

        fnNomeApresentacao: function fnNomeApresentacao() {
            return 'Documentos ' + (this.model.descricao ? this.model.descricao : '(Novo)');
        },

        tiposPNCP: [],

        crudConfig: {
            criar: {
                okFn: function okFn(opcao) {
                    if (opcao === 'CRIAR_E_PROXIMO') {
                        //ação "borbulhará" para a rota
                        this.set('model', []);
                        this.send('refreshRota');
                        return;
                    }
                    this.transitionToRoute('transparencia/documento', this.model.id);
                }
            },
            excluir: {
                urlFn: function urlFn(id, model) {
                    if (!model) {
                        return "";
                    }

                    switch (model.modulo) {
                        case "AQUISICAO":
                            return 'transparencia/documento/aquisicao/' + id;
                        case "CONTRATO":
                            return 'transparencia/documento/contrato/' + id;
                        default:
                            return "";
                    }
                },
                okFn: function okFn() {
                    this.get('controllers.transparencia/documento/listagem').send('pesquisar');
                }
            }
        },

        tipos: Constantes['default'].tipos,
        modalidadesAquisicao: ConstantesAquisicao['default'].modalidades,

        documentoDe: "AQUISICAO",
        opcoesItemCompra: [],
        opcoesAtas: [],
        opcoesAditivos: [],

        documentoDeAquisicaoOuAta: (function () {
            return this.documentoDe === "AQUISICAO" || this.documentoDe === "ATA";
        }).property('documentoDe'),

        documentoDeAquisicao: (function () {
            return this.documentoDe === "AQUISICAO";
        }).property('documentoDe'),

        documentoDeAquisicaoPNCP: (function () {
            if (this.documentoDe === "AQUISICAO") {
                if (this.model && this.model.aquisicao) return this.model.aquisicao.enviaPNCP;
            }
            return false;
        }).property('documentoDe', 'model.aquisicao'),

        documentoDeAta: (function () {
            return this.documentoDe === "ATA";
        }).property('documentoDe'),

        documentoDeContrato: (function () {
            return this.documentoDe === "CONTRATO";
        }).property('documentoDe'),

        carregaItensDeCompraEAtasETiposDeDocumento: (function () {
            var _this = this;

            if (this.model.aquisicao) {
                this.get('comunicador').servico('GET', 'aquisicao/' + this.model.aquisicao.id).then(function (resultado) {
                    var itens = [];
                    if (resultado.compras) {
                        resultado.compras.forEach(function (compra) {
                            compra.itens.forEach(function (itemCompra) {
                                var itemArray = { "id": itemCompra.id,
                                    "descricao": 'Compra ' + compra.numeroApresentacao + ' - Item ' + itemCompra.numeroApresentacao + ' - ' + itemCompra.itemAquisicao.descricao };
                                itens.push(itemArray);
                            });
                        });
                    }
                    _this.set('opcoesItemCompra', itens);

                    var atas = [];
                    if (resultado.atas) {
                        resultado.atas.forEach(function (ata) {
                            var itemArray = { "id": ata.id,
                                "descricao": 'Ata ' + ata.numeroApresentacao };
                            atas.push(itemArray);
                        });
                    }
                    _this.set('opcoesAtas', atas);
                });

                this.get('comunicador').servico('GET', 'tiposDeDocumentoPNCP/' + this.model.aquisicao.id).then(function (resultado) {
                    _this.set('tiposPNCP', resultado);
                });
            } else {
                this.set('opcoesItemCompra', []);
                this.set('opcoesAtas', []);
            }
        }).observes('model.aquisicao'),

        carregaAditivos: (function () {
            var _this2 = this;

            this.set('model.idAditivo', null);
            if (this.model.contrato) {
                this.get('comunicador').servico('GET', 'aquisicao/contrato/' + this.model.contrato.id).then(function (resultado) {
                    var adt = [];
                    if (_this2.get('exibeApostilas')) {
                        resultado.apostilas.forEach(function (apostila) {
                            var itemArray = { "id": apostila.id,
                                "descricao": 'Apostila ' + apostila.numeroApresentacao + ' - ' + apostila.tiposExibicao };
                            adt.push(itemArray);
                        });
                    }
                    if (_this2.get('exibeAditivos')) {
                        resultado.aditivos.forEach(function (aditivo) {
                            var itemArray = { "id": aditivo.id,
                                "descricao": 'Aditivo ' + aditivo.numeroApresentacao + ' - ' + aditivo.tiposExibicao };
                            adt.push(itemArray);
                        });
                    }

                    _this2.set('opcoesAditivos', adt);
                });
            } else {
                this.set('opcoesAditivos', []);
            }
        }).observes('model.contrato'),

        criarDoc: function criarDoc(criarNovoAposSalvar) {
            var self = this;

            // Prepara o formulário Multpart para enviar
            var formData = new FormData();
            if (this.get('documentoDeAquisicao') === true) {
                formData.append("modulo", "AQUISICAO");
                if (this.model.aquisicao) {
                    formData.append("idAquisicao", this.model.aquisicao.id);
                }
                if (this.model.idItemCompra) {
                    formData.append("idItemCompra", this.model.idItemCompra);
                }
            } else if (this.get('documentoDeAta') === true) {
                formData.append("modulo", "AQUISICAO"); // o módulo ATA é apenas para melhor visualização em tela, no banco de dados deve salvar AQUISICAO.
                if (this.model.aquisicao) {
                    formData.append("idAquisicao", this.model.aquisicao.id);
                }
                if (this.model.idAta) {
                    formData.append("idAta", this.model.idAta);
                }
            } else if (this.get('documentoDeContrato') === true) {
                formData.append("modulo", "CONTRATO");
                if (this.model.contrato) {
                    formData.append("idContrato", this.model.contrato.id);
                }
                if (this.model.idAditivo) {
                    formData.append("idAditivo", this.model.idAditivo);
                }
            }
            if (this.model.descricao) {
                formData.append("descricao", this.model.descricao);
            }
            /*if(this.model.ordem) {
                formData.append("ordem", this.model.ordem);
            }*/
            formData.append("ordem", "0");
            if (this.model.tipo) {
                formData.append("tipo", this.model.tipo);
            }
            if (this.model.tipoPNCP) {
                formData.append("tipoPNCP", this.model.tipoPNCP);
            }
            var uploadInput = $(".row-input-upload input[type=file]").get(0);

            if (uploadInput.files[0]) {
                if (!Util['default'].verificarTipoArquivoValido(uploadInput.files[0].name)) {
                    Util['default'].alertaErro('Formato inválido para o arquivo.');
                    return;
                }

                if (!Util['default'].verificarTamanhoArquivoValido(uploadInput.files[0].size)) {
                    Util['default'].alertaErro('Arquivo com tamanho superior a 30MB.');
                    return;
                }

                formData.append('documento', uploadInput.files[0]);
            }

            this.get('comunicador').servicoMultipart('transparencia/documento', formData).then(function (resultado) {
                Util['default'].alerta('Operação realizada com sucesso!');
                if (criarNovoAposSalvar) {
                    self.set('model', []);
                    self.send('refreshRota');
                    return;
                }
                self.transitionToRoute('transparencia/documento/listagem');
                self.get('controllers.transparencia/documento/listagem').send('refazerPesquisa');
            });
        },

        actions: {

            criarDocumento: function criarDocumento() {
                this.criarDoc(false);
            },

            criarDocumentoENovo: function criarDocumentoENovo() {
                this.criarDoc(true);
            }

            /*download: function (documento) {
                var a = document.createElement("a");
                a.href = Defs.enderecoServer + documento.urlDownload;
                a.click();
                 var comunicador = this.get('comunicador');
                var nomeArquivo = documento.nome ? documento.nome : "documento.pdf";
                var mimeType = documento.mimeType ? documento.mimeType : "application/pdf";
                 comunicador.servicoBlob('get', `transparencia/documento/download/${documento.id}`, null, "BAIXAR_ARQUIVO").then(
                    resultado => {
                        var a = document.createElement("a");
                        document.body.appendChild(a);
                        a.style = "display: none";
                        var blob = new Blob([resultado], { type: mimeType }),
                            url = window.URL.createObjectURL(blob);
                        a.href = url;
                        a.download = nomeArquivo;
                        a.click();
                        window.URL.revokeObjectURL(url);
                    }
                );
            },*/
        },

        // Dados da tabela
        getNumeroLinhaTabela: function getNumeroLinhaTabela(documento) {
            var retorno = "";
            if (documento.modulo === "AQUISICAO") {
                retorno = documento.aquisicao.numeroApresentacao + ' - ' + this.getNomeLegivelModalidade(documento.aquisicao);
            } else if (documento.modulo === "CONTRATO") {
                retorno = documento.contrato.numeroApresentacao;
            }

            return retorno;
        },

        // Link para o documento
        getLink: function getLink(documento) {
            return Defs['default'].enderecoServer + documento.urlDownload;
        },

        // Texto acessível do documento
        getTextoAcessivel: function getTextoAcessivel(valor, tituloColuna, textoAcessivelDefaultDoComponente) {
            return textoAcessivelDefaultDoComponente + ", download";
        },

        getItemLinhaTabela: function getItemLinhaTabela(documento) {
            var retorno = "";
            if (documento.modulo === "AQUISICAO") {
                if (documento.itemCompra) {
                    retorno = 'Item de compra ' + documento.itemCompra.numeroApresentacao + ' (' + documento.itemCompra.itemAquisicao.descricao + ')';
                }
                if (documento.ata) {
                    retorno = 'Ata ' + documento.ata.numeroApresentacao;
                }
            } else if (documento.modulo === "CONTRATO") {
                if (documento.aditivo) {
                    retorno += 'Aditivo ' + documento.aditivo.numero + ' (' + documento.aditivo.tiposExibicao + ')';
                }
            }

            return retorno;
        },

        getNomeLegivelModalidade: function getNomeLegivelModalidade(aquisicao) {
            var modalidadeDaAquisicao = this.modalidadesAquisicao.find(function (modalidade) {
                return aquisicao.modalidade === modalidade.id;
            });
            return modalidadeDaAquisicao.nome;
        },

        getModuloLegivel: function getModuloLegivel(documento) {
            switch (documento.modulo) {
                case "AQUISICAO":
                    return "Aquisição";
                case "CONTRATO":
                    return "Contrato";
                default:
                    return "";
            }
        }

    });

});
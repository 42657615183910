define('webapp/pods/aquisicao/contrato/aditivo/route', ['exports', 'ember', 'webapp/mixins/suprimir-templates-superiores-mixin', 'webapp/utils/util'], function (exports, Ember, SuprimirTemplatesSuperioresMixin, Util) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(SuprimirTemplatesSuperioresMixin['default'], {
    setupController: function setupController(controller, model) {
      controller.set('model', Util['default'].clona(model));
      controller.ajustaSelecaoTiposAditivosConformeListaNoModelo();

      controller.registraTiposGravadosNoBanco();

      controller.set('ehAditivoVazioMigracao', false);

      //TODO: apenas para certificar se é um aditivo incluído para suprir a migração
      this.get('comunicador').servico('get', 'parametroExibicao').then(function (resultado) {
        var obj = Util['default'].converteParametrosDeExibicao(resultado);
        if (obj.UASG === '80014' && (!controller.model.tipos || controller.model.tipos.length === 0)) {
          controller.set('ehAditivoVazioMigracao', true);
        }
      });
    }
  });

});
define('webapp/pods/requisicao/listagem/route', ['exports', 'ember', 'webapp/mixins/suprimir-templates-superiores-mixin'], function (exports, Ember, SuprimirTemplatesSuperioresMixin) {

	'use strict';

	exports['default'] = Ember['default'].Route.extend(SuprimirTemplatesSuperioresMixin['default'], {
		queryParams: { manterDadosPesquisaAnterior: false },
		setupController: function setupController(controller) {

			this.get('comunicador').leParaPropriedade(controller, 'programaTrabalho', 'programasDeTrabalho', 'Não foi possível obter os programas de trabalho.');

			this.get('comunicador').leParaPropriedade(controller, 'unidadeOrcamentaria/selecionavel/unidadeOrganizacionalCorrespondente', 'unidadesOrganizacionais', 'Não foi possível obter as unidades organizacionais.');

			this.get('comunicador').leParaPropriedade(controller, 'classificacaoItem', 'classificacoesFinanceiras', 'Não foi possível obter as classificações financeiras dos itens.');

			controller._gerenciarDadosPesquisaAnterior();
		}

	});

});
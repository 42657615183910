define('webapp/pods/aquisicao/contrato/aditivo/revisao/criar/route', ['exports', 'ember', 'webapp/pods/aquisicao/contrato/aditivo/revisao/model'], function (exports, Ember, Revisao) {

	'use strict';

	exports['default'] = Ember['default'].Route.extend({
		controllerName: 'aquisicao/contrato/aditivo/revisao',
		//sempre vem do controller aditivo.
		setupController: function setupController(controller) {
			controller.set('model', Revisao['default'].create({}));

			this.get('comunicador').servico('post', 'aquisicao/contrato/' + controller.get('controllers.aquisicao/contrato').model.id + '/simulacao', { data: new Date() }).then(function (contrato) {
				controller.set('situacaoAtualContrato', contrato);
			});
		},

		model: function model() {
			return Revisao['default'].create();
		}
	});

});
define('webapp/pods/transparencia/documento/constantes-documento', ['exports'], function (exports) {

  'use strict';

  exports['default'] = {

    tipos: [{ id: 'EDITAL', nome: 'Edital' }, { id: 'RESULTADO', nome: 'Resultado' }],

    modulos: [{ id: 'AQUISICAO', nome: 'Aquisição' }, { id: 'CONTRATO', nome: 'Contrato' }]

  };

});
define('webapp/pods/aquisicao/contrato/aditivo/alteracao-tipo-societario/model', ['exports', 'ember', 'webapp/pods/fornecedor/model'], function (exports, Ember, Fornecedor) {

	'use strict';

	exports['default'] = Ember['default'].Object.extend({
		id: null,
		dataAlteracao: '',
		descricao: '',
		novoFornecedor: Fornecedor['default'].create({}),
		fornecedorSubstituido: Fornecedor['default'].create({}),

		inicializar: (function () {
			this.set('dataAlteracao', '');
			this.set('descricao', '');
			this.set('novoFornecedor', Fornecedor['default'].create({}));
			this.set('fornecedorSubstituido', Fornecedor['default'].create({}));
		}).on('init')

	});

});
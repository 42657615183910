define('webapp/components/busca-requisicao', ['exports', 'ember', 'webapp/mixins/ajusta-tamanho-input-mixin'], function (exports, Ember, AjustaTamanhoInputMixin) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(AjustaTamanhoInputMixin['default'], {

    comunicador: Ember['default'].inject.service(),

    mostrarBuscaRequisicao: false,
    mostrarResultados: false,
    requisicoes: [],
    requisicaoSelecionada: {}, //para uso interno do componente (de onde serão
    //buscados os dados a serem apresentados, etc.)

    texto: 'Requisição',

    numero: undefined,
    ano: undefined,
    dataInicial: undefined,
    dataFinal: undefined,
    programa: undefined,
    objeto: undefined,
    requisitante: undefined,
    classificacoesItens: undefined,
    descricaoItem: undefined,
    mostraTabelaResultadoPesquisa: false,
    mostraMensagemPesquisaSemResultado: false,

    desabilitarBotoes: (function () {
      return this.desabilitado || this.somenteLeitura;
    }).property('desabilitado', 'somenteLeitura'),

    labelAcessivelCampo: (function () {
      return this.labelAcessivel;
    }).property('labelAcessivel'),

    instrucoesAdicionaisCampo: (function () {
      return this.get('desabilitarBotoes') ? 'Botões para alteração desabilitados.' : 'Use os botões que vêm a seguir para alterar a requisição.';
    }).property('desabilitarBotoes'),

    labelAcessivelBotaoPesquisar: (function () {
      return 'Iniciar busca de ' + (this.labelAcessivel ? this.labelAcessivel : this.texto ? this.texto : 'requisição');
    }).property('labelAcessivel'),

    labelAcessivelBotaoExcluir: (function () {
      return 'Limpar ' + (this.labelAcessivel ? this.labelAcessivel : 'requisição') + ' selecionada';
    }).property('labelAcessivel'),

    inicializar: (function () {
      this.inicializarRequisicaoSelecionada();
      this.buscaProgramasDeTrabalho();
      this.buscaUnidadesOrganizacionais();
      this.buscaClassificacoesFincanceiras();
    }).on('init'),

    inicializarRequisicaoSelecionada: (function () {
      this.set('requisicaoSelecionada', this.atributoDestino);
    }).observes('objetoPai'),

    buscaProgramasDeTrabalho: function buscaProgramasDeTrabalho() {
      this.get('comunicador').leParaPropriedade(this, 'programaTrabalho', 'programasDeTrabalho', 'Não foi possível obter os programas de trabalho.');
    },

    buscaUnidadesOrganizacionais: function buscaUnidadesOrganizacionais() {
      this.get('comunicador').leParaPropriedade(this, 'unidadeOrganizacional', 'unidadesOrganizacionais', 'Não foi possível obter as unidades organizacionais.');
    },

    buscaClassificacoesFincanceiras: function buscaClassificacoesFincanceiras() {
      this.get('comunicador').leParaPropriedade(this, 'classificacaoItem', 'classificacoesFinanceiras', 'Não foi possível obter as classificações financeiras dos itens.');
    },
    //---------

    _labelAcessivelBotao: (function () {
      return 'Iniciar busca de requisição, ' + this.texto;
    }).property('texto'),

    actions: {

      excluir: function excluir() {
        this.set('requisicaoSelecionada', null);
        this.set('atributoDestino', null);
      },

      iniciarBusca: function iniciarBusca() {

        this.set('numero', undefined);
        this.set('ano', undefined);
        this.set('dataInicial', undefined);
        this.set('dataFinal', undefined);
        this.set('programa', undefined);
        this.set('objeto', undefined);
        this.set('requisitante', undefined);
        this.set('classificacoesItens', undefined);
        this.set('descricaoItem', undefined);
        this.set('mostraTabelaResultadoPesquisa', false);
        this.set('mostraMensagemPesquisaSemResultado', false);

        this.set('mostrarBuscaRequisicao', true);
      },

      pesquisarRequisicao: function pesquisarRequisicao() {
        var _this = this;

        if (!this.numero && !this.ano && !this.dataInicial && !this.dataFinal && !this.programa && !this.objeto && !this.requisitante && !this.classificacoesItens && !this.descricaoItem
        /*&& !this.temItens && !this.temAquisicoes*/) {
            return;
          }

        var parametrosPesquisaRequisicao = {
          numero: this.numero,
          ano: this.ano,
          dataInicial: this.dataInicial,
          dataFinal: this.dataFinal,
          programa: this.programa,
          objeto: this.objeto,
          requisitante: this.requisitante,
          classificacoesItens: this.classificacoesItens,
          descricaoItem: this.descricaoItem,
          temItens: this.temItens,
          temAquisicoes: this.temAquisicoes
        };

        this.set('mostrarResultados', false);

        this.get('comunicador').servico('post', 'requisicao/pesquisa', parametrosPesquisaRequisicao).then(function (resultado) {
          _this.set('requisicoesPesquisadas', resultado);
          _this.set('mostrarResultados', true);
        });
      },

      selecionarRequisicao: function selecionarRequisicao(requisicao) {
        this.set('atributoDestino', requisicao);
        this.set('requisicaoSelecionada', requisicao);
        this.set('mostrarBuscaRequisicao', false);
      },

      cancelarBusca: function cancelarBusca() {
        this.set('mostrarBuscaRequisicao', false);
      }

    }

  });

});
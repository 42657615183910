define('webapp/pods/aquisicao/contrato/garantia/model', ['exports', 'ember'], function (exports, Ember) {

	'use strict';

	exports['default'] = Ember['default'].Object.extend({
		id: null,
		valor: '',
		vigenciaInicio: '',
		vigenciaFim: '',
		modalidade: '',
		numeroApresentacao: '',

		findUrlFn: function findUrlFn(id) {
			return 'contrato/garantia/' + id.toString();
		}
	});

});
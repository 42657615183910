define('webapp/pods/transparencia/documento/listagem/controller', ['exports', 'ember', 'webapp/mixins/crud-mixin', 'webapp/utils/util', 'webapp/pods/transparencia/documento/constantes-documento', 'webapp/pods/aquisicao/constantes-aquisicao'], function (exports, Ember, CrudMixin, Util, Constantes, ConstantesAquisicao) {

    'use strict';

    exports['default'] = Ember['default'].Controller.extend(CrudMixin['default'], {
        queryParams: ['manterDadosPesquisaAnterior'],
        manterDadosPesquisaAnterior: false,
        criteriosPesquisaBackup: null,

        breadCrumbConfig: {
            nomeApresentacao: 'Documentos'
        },

        limparDadosPesquisa: function limparDadosPesquisa() {
            this.limparCamposPesquisa();
            this.limparTabelaResultados();
        },

        limparCamposPesquisa: function limparCamposPesquisa() {
            this.set('modulo', undefined);
            this.set('numeroAquisicao', undefined);
            this.set('numeroContrato', undefined);
            this.set('ano', undefined);
            this.set('tipoDeDocumento', undefined);
            this.set('possuiItens', undefined); //limpando temItens
            this.set('utilizadaEmAquisicoes', undefined); //limpando temAquisicoes
        },

        limparTabelaResultados: function limparTabelaResultados() {
            //limpa tabela de resultados
            this.set('mostrarResultados', false);
            this.set('mostraTabelaResultadoPesquisa', false);
            this.set('mostraMensagemPesquisaSemResultado', false);
        },

        tipos: Constantes['default'].tipos,
        modulos: Constantes['default'].modulos,
        modalidadesAquisicao: ConstantesAquisicao['default'].modalidades,

        actions: {
            pesquisar: function pesquisar() {
                var _this = this;

                this.set('mostrarResultados', false);

                var criteriosPesquisa = this.getProperties('modulo', 'numeroAquisicao', 'modalidade', 'numeroContrato', 'ano', 'tipoDeDocumento');

                this.criteriosPesquisaBackup = Util['default'].clona(criteriosPesquisa);

                this.get('comunicador').servico('post', 'transparencia/documento/pesquisa', criteriosPesquisa, this).then(function (resultado) {
                    _this.set('model', resultado);
                    var retornouItems = _this.get('model').length > 0;
                    _this.set('mostraTabelaResultadoPesquisa', retornouItems);
                    _this.set('mostraMensagemPesquisaSemResultado', !retornouItems);
                    _this.set('mostrarResultados', true);
                });
            },

            limparPesquisa: function limparPesquisa() {
                this.limparDadosPesquisa();
                this.set('model', []);
            },

            refazerPesquisa: function refazerPesquisa() {
                if (this.get('mostrarResultados') === true) {
                    this.send('pesquisar');
                }
            }

        }

    });

});
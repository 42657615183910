define('webapp/pods/requisicao/item/identificacao/template', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        isHTMLBars: true,
        revision: "Ember@1.12.1",
        blockParams: 0,
        cachedFragment: null,
        hasRendered: false,
        build: function build(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("				");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        render: function render(context, env, contextualElement) {
          var dom = env.dom;
          var hooks = env.hooks, get = hooks.get, inline = hooks.inline;
          dom.detectNamespace(contextualElement);
          var fragment;
          if (env.useFragmentCache && dom.canClone) {
            if (this.cachedFragment === null) {
              fragment = this.build(dom);
              if (this.hasRendered) {
                this.cachedFragment = fragment;
              } else {
                this.hasRendered = true;
              }
            }
            if (this.cachedFragment) {
              fragment = dom.cloneNode(this.cachedFragment, true);
            }
          } else {
            fragment = this.build(dom);
          }
          var morph0 = dom.createMorphAt(fragment,1,1,contextualElement);
          inline(env, morph0, context, "decimal-input", [], {"texto": "Duração (meses)", "valor": get(env, context, "model.duracao"), "obrigatorio": true, "id": "tag||duracao|requisicao/item|identificacao"});
          return fragment;
        }
      };
    }());
    var child1 = (function() {
      return {
        isHTMLBars: true,
        revision: "Ember@1.12.1",
        blockParams: 0,
        cachedFragment: null,
        hasRendered: false,
        build: function build(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("				");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        render: function render(context, env, contextualElement) {
          var dom = env.dom;
          var hooks = env.hooks, get = hooks.get, inline = hooks.inline;
          dom.detectNamespace(contextualElement);
          var fragment;
          if (env.useFragmentCache && dom.canClone) {
            if (this.cachedFragment === null) {
              fragment = this.build(dom);
              if (this.hasRendered) {
                this.cachedFragment = fragment;
              } else {
                this.hasRendered = true;
              }
            }
            if (this.cachedFragment) {
              fragment = dom.cloneNode(this.cachedFragment, true);
            }
          } else {
            fragment = this.build(dom);
          }
          var morph0 = dom.createMorphAt(fragment,1,1,contextualElement);
          inline(env, morph0, context, "tag-input", [], {"texto": "Cód. Catálogo", "valor": get(env, context, "model.codigoCatalogo"), "id": "tag||codigoCatalogo|requisicao/item|identificacao"});
          return fragment;
        }
      };
    }());
    var child2 = (function() {
      return {
        isHTMLBars: true,
        revision: "Ember@1.12.1",
        blockParams: 0,
        cachedFragment: null,
        hasRendered: false,
        build: function build(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("							");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n							");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        render: function render(context, env, contextualElement) {
          var dom = env.dom;
          var hooks = env.hooks, get = hooks.get, inline = hooks.inline;
          dom.detectNamespace(contextualElement);
          var fragment;
          if (env.useFragmentCache && dom.canClone) {
            if (this.cachedFragment === null) {
              fragment = this.build(dom);
              if (this.hasRendered) {
                this.cachedFragment = fragment;
              } else {
                this.hasRendered = true;
              }
            }
            if (this.cachedFragment) {
              fragment = dom.cloneNode(this.cachedFragment, true);
            }
          } else {
            fragment = this.build(dom);
          }
          var morph0 = dom.createMorphAt(fragment,1,1,contextualElement);
          var morph1 = dom.createMorphAt(fragment,3,3,contextualElement);
          inline(env, morph0, context, "tag-inputmask-radio", [], {"grupoRadio": get(env, context, "model.tipoValorItem"), "texto": "Médio", "valorRadio": "MEDIA", "valor": get(env, context, "model.valorUnitarioMedio"), "valorSemMascara": get(env, context, "model.valorUnitarioMedio"), "mascara": "moeda", "obrigatorio": false, "somenteLeitura": true, "desabilitado": false, "id": "tag-radio||valorUnitarioMedio|requisicao/item|identificacao"});
          inline(env, morph1, context, "tag-inputmask-radio", [], {"grupoRadio": get(env, context, "model.tipoValorItem"), "texto": "Mediano", "valorRadio": "MEDIANA", "valor": get(env, context, "model.valorUnitarioMediano"), "valorSemMascara": get(env, context, "model.valorUnitarioMediano"), "mascara": "moeda", "obrigatorio": false, "somenteLeitura": true, "desabilitado": false, "id": "tag-radio||valorUnitarioMediano|requisicao/item|identificacao"});
          return fragment;
        }
      };
    }());
    return {
      isHTMLBars: true,
      revision: "Ember@1.12.1",
      blockParams: 0,
      cachedFragment: null,
      hasRendered: false,
      build: function build(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","panel-body");
        var el2 = dom.createTextNode("\n\n		");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","col-md-12");
        dom.setAttribute(el2,"style","margin-bottom:1em;");
        var el3 = dom.createTextNode("\n			");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","col-md-12");
        var el4 = dom.createTextNode("\n				");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n				");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        dom.setAttribute(el4,"class","control-label");
        var el5 = dom.createTextNode("Item Periódico");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n			");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n		");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n\n		");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","col-md-2 panel panel-default panel-caixa");
        var el3 = dom.createTextNode("\n			");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n			");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n			");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n			");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        var el4 = dom.createTextNode("\n				");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("fieldset");
        dom.setAttribute(el4,"style","font-weight: bold;");
        var el5 = dom.createTextNode("\n					");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("legend");
        dom.setAttribute(el5,"style","margin-bottom: 5px; font-size: 14px");
        var el6 = dom.createTextNode("Natureza*");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n			\n					");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n					");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n				");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n			");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n		");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n		");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","col-md-7");
        var el3 = dom.createTextNode("\n			");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n			");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n		");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n		");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","col-md-3");
        var el3 = dom.createTextNode("\n			");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","panel panel-default panel-caixa");
        var el4 = dom.createTextNode("\n				");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("fieldset");
        var el5 = dom.createTextNode("\n					");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("legend");
        var el6 = dom.createTextNode("Valor Unitário*");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n					");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        var el6 = dom.createTextNode("\n						");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("					");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n				");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n			");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n			");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        var el4 = dom.createTextNode("\n				");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n			");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n		");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      render: function render(context, env, contextualElement) {
        var dom = env.dom;
        var hooks = env.hooks, get = hooks.get, inline = hooks.inline, block = hooks.block;
        dom.detectNamespace(contextualElement);
        var fragment;
        if (env.useFragmentCache && dom.canClone) {
          if (this.cachedFragment === null) {
            fragment = this.build(dom);
            if (this.hasRendered) {
              this.cachedFragment = fragment;
            } else {
              this.hasRendered = true;
            }
          }
          if (this.cachedFragment) {
            fragment = dom.cloneNode(this.cachedFragment, true);
          }
        } else {
          fragment = this.build(dom);
        }
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(element0, [3]);
        var element2 = dom.childAt(element1, [9, 1]);
        var element3 = dom.childAt(element0, [5]);
        var element4 = dom.childAt(element0, [7]);
        var element5 = dom.childAt(element4, [1, 1, 3]);
        var morph0 = dom.createMorphAt(dom.childAt(element0, [1, 1]),1,1);
        var morph1 = dom.createMorphAt(element1,1,1);
        var morph2 = dom.createMorphAt(element1,3,3);
        var morph3 = dom.createMorphAt(element1,5,5);
        var morph4 = dom.createMorphAt(element1,7,7);
        var morph5 = dom.createMorphAt(element2,3,3);
        var morph6 = dom.createMorphAt(element2,5,5);
        var morph7 = dom.createMorphAt(element3,1,1);
        var morph8 = dom.createMorphAt(element3,3,3);
        var morph9 = dom.createMorphAt(element3,5,5);
        var morph10 = dom.createMorphAt(element5,1,1);
        var morph11 = dom.createMorphAt(element5,3,3);
        var morph12 = dom.createMorphAt(dom.childAt(element4, [3]),1,1);
        inline(env, morph0, context, "input", [], {"type": "checkbox", "name": "ehPeriodico", "checked": get(env, context, "model.periodico"), "id": "checkbox||periodico|requisicao/item|identificacao"});
        inline(env, morph1, context, "tag-input", [], {"texto": "Nº Item", "somenteLeitura": true, "valor": get(env, context, "model.numeroApresentacao"), "id": "tag||numero|requisicao/item|identificacao"});
        inline(env, morph2, context, "decimal-input", [], {"texto": "Quantidade", "valor": get(env, context, "model.quantidade"), "obrigatorio": true, "id": "tag||quantidade|requisicao/item|identificacao"});
        inline(env, morph3, context, "tag-select", [], {"texto": "Unidade", "obrigatorio": true, "conteudo": get(env, context, "unidadesDeMedida"), "opcaoLabel": "nome", "valor": get(env, context, "model.unidadeMedida"), "textopadrao": "Selecione", "permiteLimpar": true, "id": "select||unidadeMedidaItem|requisicao/item|identificacao"});
        block(env, morph4, context, "if", [get(env, context, "model.periodico")], {}, child0, null);
        inline(env, morph5, context, "tag-radio-button", [], {"texto": "Material", "labelAcessivel": "Material", "autoFoco": true, "labelNegrito": true, "tamanho": 6, "colSm": true, "valor": "MATERIAL", "atributoDestino": get(env, context, "model.naturezaItem"), "id": "radio||naturezaMaterial|requisicao/item|identificacao"});
        inline(env, morph6, context, "tag-radio-button", [], {"texto": "Serviço", "labelAcessivel": "Serviço", "valor": "SERVICO", "atributoDestino": get(env, context, "model.naturezaItem"), "labelNegrito": true, "tamanho": 6, "colSm": true, "id": "radio||naturezaServico|requisicao/item|identificacao"});
        inline(env, morph7, context, "tag-select", [], {"texto": "Classificação", "obrigatorio": true, "conteudo": get(env, context, "classificacoesFinanceiras"), "opcaoLabel": "nome", "valor": get(env, context, "model.classificacao"), "textopadrao": "Selecione a classificação do item", "permiteLimpar": true, "id": "select||classificacao|requisicao/item|identificacao"});
        inline(env, morph8, context, "tag-textarea", [], {"texto": "Descrição", "obrigatorio": true, "numeroLinhas": "4", "valor": get(env, context, "model.descricao"), "textoPlaceHolder": "...", "id": "tag||descricao|requisicao/item|identificacao"});
        block(env, morph9, context, "if", [get(env, context, "habilitarCatalogo")], {}, child1, null);
        inline(env, morph10, context, "tag-inputmask-radio", [], {"grupoRadio": get(env, context, "model.tipoValorItem"), "valorSemMascara": get(env, context, "model.valorUnitarioExato"), "texto": "Previsto", "valorRadio": "EXATO", "valor": get(env, context, "model.valorUnitarioExato"), "mascara": "moeda", "obrigatorio": false, "somenteLeitura": false, "desabilitado": false, "id": "tag-radio||valorUnitarioExato|requisicao/item|identificacao"});
        block(env, morph11, context, "if", [get(env, context, "mostraMediaEMediana")], {}, child2, null);
        inline(env, morph12, context, "moeda-input", [], {"texto": "Valor Total", "valor": get(env, context, "model.valorTotal"), "tamanho": 12, "somenteLeitura": true, "id": "tag||valorTotal|requisicao/item|identificacao"});
        return fragment;
      }
    };
  }()));

});
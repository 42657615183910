define('webapp/pods/papel/model', ['exports', 'ember'], function (exports, Ember) {

	'use strict';

	exports['default'] = Ember['default'].Object.extend({
		id: null,
		operacoes: [],
		version: null,
		nome: null,
		descricao: null,

		findUrlFn: function findUrlFn(id) {
			return "role/" + id.toString();
		}

	});

});
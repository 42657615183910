define('webapp/models/contrato', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Object.extend({
    id: null,
    numero: undefined,
    dataAssinatura: '',
    link: ''
  });

});
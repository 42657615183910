define('webapp/pods/aquisicao/contrato/andamento/route', ['exports', 'ember', 'webapp/utils/util'], function (exports, Ember, Util) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({
    setupController: function setupController(controller, model) {
      controller.set('model', Util['default'].clona(model));
    }
  });

});
define('webapp/pods/aquisicao/contrato/efeito-financeiro/identificacao/template', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        isHTMLBars: true,
        revision: "Ember@1.12.1",
        blockParams: 0,
        cachedFragment: null,
        hasRendered: false,
        build: function build(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","row");
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","row");
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col-md-4");
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","panel panel-default panel-caixa");
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("fieldset");
          var el5 = dom.createTextNode("\n        ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("legend");
          var el6 = dom.createTextNode("Valor Mensal (Periódico)");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n        ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"class","row");
          var el6 = dom.createTextNode("\n          ");
          dom.appendChild(el5, el6);
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n        ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n        ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"class","row");
          var el6 = dom.createTextNode("\n          ");
          dom.appendChild(el5, el6);
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n        ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n      ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n    ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n  ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col-md-4");
          dom.setAttribute(el2,"style","padding-left:0;");
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","panel panel-default panel-caixa");
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("fieldset");
          var el5 = dom.createTextNode("\n        ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("legend");
          var el6 = dom.createTextNode("Valor Único (Não-Periódico)");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n        ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"class","row");
          var el6 = dom.createTextNode("\n          ");
          dom.appendChild(el5, el6);
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n        ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n        ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"class","row");
          var el6 = dom.createTextNode("\n          ");
          dom.appendChild(el5, el6);
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n        ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n      ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n    ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n  ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col-md-4");
          dom.setAttribute(el2,"style","padding-left:0;");
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","panel panel-default panel-caixa");
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("fieldset");
          var el5 = dom.createTextNode("\n        ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("legend");
          var el6 = dom.createTextNode("Valor Total da Contratação");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n        ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"class","row");
          var el6 = dom.createTextNode("\n          ");
          dom.appendChild(el5, el6);
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n        ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n        ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"class","row");
          var el6 = dom.createTextNode("\n          ");
          dom.appendChild(el5, el6);
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n        ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n      ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n    ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n  ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","row");
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","panel panel-default panel-caixa");
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("fieldset");
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("legend");
          var el5 = dom.createTextNode("Referência");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","col-md-6");
          var el5 = dom.createTextNode("\n      	");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n        ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"id","referenciaApostilamento");
          var el6 = dom.createTextNode("\n          ");
          dom.appendChild(el5, el6);
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n        ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n      ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","col-md-6");
          dom.setAttribute(el4,"style","padding-left:0;");
          var el5 = dom.createTextNode("\n      	");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n\n        ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"id","referenciaReajuste");
          var el6 = dom.createTextNode("\n          ");
          dom.appendChild(el5, el6);
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n        ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n      ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n    ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n  ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","row");
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        render: function render(context, env, contextualElement) {
          var dom = env.dom;
          var hooks = env.hooks, get = hooks.get, inline = hooks.inline;
          dom.detectNamespace(contextualElement);
          var fragment;
          if (env.useFragmentCache && dom.canClone) {
            if (this.cachedFragment === null) {
              fragment = this.build(dom);
              if (this.hasRendered) {
                this.cachedFragment = fragment;
              } else {
                this.hasRendered = true;
              }
            }
            if (this.cachedFragment) {
              fragment = dom.cloneNode(this.cachedFragment, true);
            }
          } else {
            fragment = this.build(dom);
          }
          var element0 = dom.childAt(fragment, [3]);
          var element1 = dom.childAt(element0, [1, 1, 1]);
          var element2 = dom.childAt(element0, [3, 1, 1]);
          var element3 = dom.childAt(element0, [5, 1, 1]);
          var element4 = dom.childAt(fragment, [5, 1, 1]);
          var element5 = dom.childAt(element4, [3]);
          var element6 = dom.childAt(element4, [5]);
          var morph0 = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
          var morph1 = dom.createMorphAt(dom.childAt(element1, [3]),1,1);
          var morph2 = dom.createMorphAt(dom.childAt(element1, [5]),1,1);
          var morph3 = dom.createMorphAt(dom.childAt(element2, [3]),1,1);
          var morph4 = dom.createMorphAt(dom.childAt(element2, [5]),1,1);
          var morph5 = dom.createMorphAt(dom.childAt(element3, [3]),1,1);
          var morph6 = dom.createMorphAt(dom.childAt(element3, [5]),1,1);
          var morph7 = dom.createMorphAt(element5,1,1);
          var morph8 = dom.createMorphAt(dom.childAt(element5, [3]),1,1);
          var morph9 = dom.createMorphAt(element6,1,1);
          var morph10 = dom.createMorphAt(dom.childAt(element6, [3]),1,1);
          var morph11 = dom.createMorphAt(dom.childAt(fragment, [7]),1,1);
          inline(env, morph0, context, "date-input", [], {"texto": "Início Vigência", "valor": get(env, context, "model.dataInicio"), "obrigatorio": true, "dica": "Quando o efeito financeiro passa a valer. Os valores do efeito financeiro serão considerados como valores efetivos do contrato entre esta data e o início da vigência do efeito financeiro seguinte.", "id": "tag||dataInicio|contrato/efeito-financeiro|identificacao"});
          inline(env, morph1, context, "moeda-input", [], {"texto": "Inicial Atualizado", "labelAcessivel": "Valor mensal inicial atualizado", "valor": get(env, context, "model.valorInicialAtualizadoMensal"), "dica": "Valor mensal inicial do contrato, atualizado considerando apenas reajustes.", "id": "tag||valorInicialAtualizadoMensal|contrato/efeito-financeiro|identificacao"});
          inline(env, morph2, context, "moeda-input", [], {"texto": "Atual", "labelAcessivel": "Valor mensal atual", "valor": get(env, context, "model.valorAtualMensal"), "dica": "Valor mensal inicial do contrato, atualizado considerando tanto reajustes quanto aditivos com impacto financeiro.", "id": "tag||valorAtualMensal|contrato/efeito-financeiro|identificacao"});
          inline(env, morph3, context, "moeda-input", [], {"texto": "Inicial Atualizado", "labelAcessivel": "Valor não-periódico inicial atualizado", "valor": get(env, context, "model.valorInicialAtualizadoUnico"), "dica": "Valor não-periódico inicial do contrato, atualizado considerando apenas reajustes.", "id": "tag||valorInicialAtualizadoUnico|contrato/efeito-financeiro|identificacao"});
          inline(env, morph4, context, "moeda-input", [], {"texto": "Atual", "labelAcessivel": "Valor não-periódico atual", "valor": get(env, context, "model.valorAtualUnico"), "dica": "Valor não-periódico inicial do contrato, atualizado considerando tanto reajustes quanto aditivos com impacto financeiro.", "id": "tag||valorAtualUnico|contrato/efeito-financeiro|identificacao"});
          inline(env, morph5, context, "moeda-input", [], {"texto": "Inicial Atualizado", "labelAcessivel": "Valor total inicial atualizado", "valor": get(env, context, "model.valorInicialAtualizadoTotal"), "dica": "Valor total inicial do contrato (valor não-periódico + somatório do valor mensal por toda a duração do contrato), atualizado considerando apenas reajustes.", "id": "tag||valorInicialAtualizadoTotal|contrato/efeito-financeiro|identificacao"});
          inline(env, morph6, context, "moeda-input", [], {"texto": "Atual", "labelAcessivel": "Valor total atual", "valor": get(env, context, "model.valorAtualTotal"), "dica": "Valor total inicial do contrato (valor não-periódico + somatório do valor mensal por toda a duração do contrato), atualizado considerando tanto reajustes quanto aditivos com impacto financeiro.", "id": "tag||valorAtualTotal|contrato/efeito-financeiro|identificacao"});
          inline(env, morph7, context, "tag-radio-button", [], {"texto": "Apostilamento", "valor": "Apostilamento", "atributoDestino": get(env, context, "referencia"), "labelAcessivel": "Apostilamento de referência", "labelNegrito": true, "dica": "Indica que o efeito financeiro foi criado para refletir alterações no valor do contrato causadas por um apostilamento de reajuste específico.", "id": "radio||referenciaApostilamento|contrato/efeito-financeiro|identificacao"});
          inline(env, morph8, context, "tag-select", [], {"labelAcessivel": "Selecione a apostila relacionada ao efeito financeiro", "obrigatorio": false, "conteudo": get(env, context, "apostilamentos"), "opcaoLabel": "numeroApresentacao", "valor": get(env, context, "model.apostilamento"), "textopadrao": "Selecione a apostila", "permiteLimpar": true, "dica": "Referência a apostila que justifica a mudança nos valores do contrato (representada pelo efeito financeiro).", "id": "select||referenciaApostilamento|contrato/efeito-financeiro|identificacao", "desabilitado": get(env, context, "apostilamentoDesabilitado")});
          inline(env, morph9, context, "tag-radio-button", [], {"texto": "Aditivo", "valor": "Aditivo", "atributoDestino": get(env, context, "referencia"), "labelAcessivel": "Aditivo de referência", "labelNegrito": true, "dica": "Indica que o efeito financeiro foi criado para refletir alterações no valor do contrato causadas por um aditivo específico.", "id": "radio||referenciaAditivo|contrato/efeito-financeiro|identificacao"});
          inline(env, morph10, context, "tag-select", [], {"labelAcessivel": "Selecione o aditivo relacionado ao efeito financeiro", "obrigatorio": false, "conteudo": get(env, context, "aditivos"), "opcaoLabel": "numero", "valor": get(env, context, "model.aditivo"), "textopadrao": "Selecione o aditivo", "permiteLimpar": true, "dica": "Referência ao aditivo que justifica a mudança nos valores do contrato (representada pelo efeito financeiro).", "id": "select||referenciaAditivo|contrato/efeito-financeiro|identificacao", "desabilitado": get(env, context, "aditivoDesabilitado")});
          inline(env, morph11, context, "tag-textarea", [], {"texto": "Observações", "numeroLinhas": "4", "valor": get(env, context, "model.observacao"), "id": "text-area||observacoes|contrato/efeito-financeiro|identificacao"});
          return fragment;
        }
      };
    }());
    return {
      isHTMLBars: true,
      revision: "Ember@1.12.1",
      blockParams: 0,
      cachedFragment: null,
      hasRendered: false,
      build: function build(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      render: function render(context, env, contextualElement) {
        var dom = env.dom;
        var hooks = env.hooks, block = hooks.block;
        dom.detectNamespace(contextualElement);
        var fragment;
        if (env.useFragmentCache && dom.canClone) {
          if (this.cachedFragment === null) {
            fragment = this.build(dom);
            if (this.hasRendered) {
              this.cachedFragment = fragment;
            } else {
              this.hasRendered = true;
            }
          }
          if (this.cachedFragment) {
            fragment = dom.cloneNode(this.cachedFragment, true);
          }
        } else {
          fragment = this.build(dom);
        }
        var morph0 = dom.createMorphAt(fragment,0,0,contextualElement);
        dom.insertBoundary(fragment, null);
        dom.insertBoundary(fragment, 0);
        block(env, morph0, context, "tag-bloco", [], {"titulo": "Efeito Financeiro"}, child0, null);
        return fragment;
      }
    };
  }()));

});
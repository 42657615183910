define('webapp/pods/aquisicao/contrato/evento/controller', ['exports', 'ember', 'webapp/mixins/crud-mixin'], function (exports, Ember, CrudMixin) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend(CrudMixin['default'], {

    needs: ['aquisicao/contrato'],

    breadCrumbConfig: {
      fnNomeApresentacao: 'fnNomeApresentacao'
    },

    fnNomeApresentacao: function fnNomeApresentacao() {
      return 'Evento ' + (this.model.version ? 'Nº ' + this.model.numeroApresentacao : '(Novo)');
    },

    crudConfig: {
      criar: {
        urlFn: function urlFn() {
          return 'contrato/' + this.get('controllers.aquisicao/contrato').model.id + '/evento';
        },
        okFn: function okFn(opcao) {
          this.get('controllers.aquisicao/contrato').send('ler');
          if (opcao === 'CRIAR_E_PROXIMO') {
            //ação "borbulhará" para a rota
            this.send('refreshRota');
            return;
          }
          this.transitionToRoute('aquisicao/contrato');
        }
      },
      atualizar: {
        okFn: function okFn() {
          this.get('controllers.aquisicao/contrato').send('ler');
          this.transitionToRoute('aquisicao/contrato');
        }
      },
      excluir: {
        okFn: function okFn() {
          this.get('controllers.aquisicao/contrato').send('ler');
          this.transitionToRoute('aquisicao/contrato');
        }
      }
    },

    tiposEvento: [{ id: 0, nome: 'PRAZO_VIGENCIA_INICIAL', descricao: 'Novo prazo de vigência inicial' }, { id: 1, nome: 'PRAZO_VIGENCIA_FINAL', descricao: 'Novo prazo de vigência final' }, { id: 2, nome: 'PRAZO_EXECUCAO_INICIAL', descricao: 'Novo prazo de execução inicial' }, { id: 3, nome: 'PRAZO_EXECUCAO_FINAL', descricao: 'Novo prazo de execução final' }, { id: 4, nome: 'ASSINATURA_CONTRATO', descricao: 'Assinatura do contrato' }, { id: 5, nome: 'PUBLICACAO_CONTRATO', descricao: 'Publicação do contrato' }],

    getNomeLegivelTipoEvento: function getNomeLegivelTipoEvento(evento) {
      var tipoEvento = this.tiposEvento.find(function (tipo) {
        return tipo.nome === evento.tipo;
      });
      return tipoEvento.descricao;
    }

  });

});
define('webapp/pods/aquisicao/contrato/item/controller', ['exports', 'ember', 'webapp/mixins/crud-mixin', 'webapp/pods/aquisicao/constantes-aquisicao'], function (exports, Ember, CrudMixin, Constantes) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend(CrudMixin['default'], {

    needs: ['aquisicao/contrato'],

    crudConfig: {
      criar: {
        urlFn: function urlFn() {
          return 'contrato/' + this.get("controllers.aquisicao/contrato").model.id + '/item';
        },
        okFn: function okFn(opcao) {
          this.get('controllers.aquisicao/contrato').send("ler");
          if (opcao === 'CRIAR_E_PROXIMO') {
            //ação "borbulhará" para a rota
            this.send('refreshRota');
            return;
          }
          this.transitionToRoute('aquisicao/contrato');
        }
      },
      atualizar: {
        okFn: function okFn() {
          this.get('controllers.aquisicao/contrato').send("ler");
          this.transitionToRoute('aquisicao/contrato');
        }
      },
      excluir: {
        okFn: function okFn() {
          this.get('controllers.aquisicao/contrato').send('ler');
          this.transitionToRoute('aquisicao/contrato');
        }
      }
    },

    motivosCriacaoItens: Constantes['default'].motivosCriacaoItens,

    getNomeLegivelMotivoCriacaoItem: function getNomeLegivelMotivoCriacaoItem(item) {
      var motivoCriacao = this.motivosCriacaoItens.find(function (motivo) {
        return item.motivoCriacao === motivo.id;
      });
      return motivoCriacao.nome;
    },

    getPeriodicoDuracao: function getPeriodicoDuracao(item) {
      if (!item.periodico) return "não periódico";else return item.duracao;
    }

  });

});
define('webapp/pods/fornecedor/prova-regularidade/criar/route', ['exports', 'ember', 'webapp/pods/fornecedor/prova-regularidade/model'], function (exports, Ember, Prova) {

	'use strict';

	exports['default'] = Ember['default'].Route.extend({
		controllerName: 'fornecedor/prova-regularidade',
		setupController: function setupController(controller) {
			controller.set('model', Prova['default'].create({}));

			this.get('comunicador').leParaPropriedade(controller, 'classificacaoItem', 'classificacoesProdutos', 'Não foi possível obter as classificações financeiras dos produtos');
		},

		model: function model() {
			return Prova['default'].create();
		}
	});

});
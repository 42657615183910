define('webapp/components/painel-msg', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  // *** Mensagem apresentada como painel ***
  //Exemplo de uso:
  //    {{#painel-msg centralizado=true negrito=true cor="vermelho" discreto=true semPadding=true}}
  //          <elementos internos>
  //    {{/painel-msg}}
  //
  //Atributos opcionais:
  //  centralizar=<true (default)|false: indica se o conteúdo do painel deve ser centralizado>
  //  negrito=<true|false (default): indica se o conteúdo do painel deve ficar em negrito>
  //  cor=<verde|amarelo|vermelho (default)|azul: indica a cor do painel da mensagem>
  //  discreto=<true (default)|false: indica se o painel deve ser discreto (sem borda e sem
  //            padding acima ou abaixo do texto) ou normal (com borda, cantos arredondados e
  //            padding acima e abaixo do texto)>

  exports['default'] = Ember['default'].Component.extend({
    tagName: 'div',

    centralizado: true,
    negrito: false,
    discreto: false,

    classNameBindings: ['discreto:alertDiscreto:alert', 'tipoAlerta'],

    tipoAlerta: (function () {
      var coresVsTipos = {
        verde: 'alert-success',
        amarelo: 'alert-warning',
        vermelho: 'alert-danger',
        azul: 'alert-info'
      };

      return coresVsTipos[this.cor] ? coresVsTipos[this.cor] : 'alert-danger';
    }).property('cor'),

    attributeBindings: ['estilo:style'],
    estilo: (function () {
      return 'display: flex; align-items: center; ' + (this.centralizado ? ' justify-content: center;' : '') + (this.negrito ? ' font-weight: bold;' : '') + (this.semPadding ? ' padding: 0;' : '');
    }).property('centralizado', 'negrito')

  });

});
define('webapp/pods/usuario/listagem/controller', ['exports', 'ember', 'webapp/mixins/crud-mixin'], function (exports, Ember, CrudMixin) {

	'use strict';

	exports['default'] = Ember['default'].Controller.extend(CrudMixin['default'], {
			breadCrumbConfig: {
					nomeApresentacao: 'Usuários'
			},

			actions: {
					//------------------- PROVISÓRIO -----------------------------
					listar: function listar() {
							var _this = this;

							this.get('comunicador').servico('get', 'usuario/', {}, this).then(function (resultado) {
									_this.set("model", resultado);
							});
					}
					//------------------------------------------------------------
			}
	});

});
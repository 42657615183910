define('webapp/pods/aquisicao/contrato/apostilamento-reajuste/controller', ['exports', 'ember', 'webapp/mixins/crud-mixin', 'webapp/utils/util', 'npm:big.js'], function (exports, Ember, CrudMixin, Util, Big) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend(CrudMixin['default'], {
    needs: ['aquisicao/contrato'],

    breadCrumbConfig: {
      fnNomeApresentacao: 'fnNomeApresentacao'
    },

    fnNomeApresentacao: function fnNomeApresentacao() {
      return 'Apostilamento ' + (this.model.version ? 'Nº ' + this.model.numeroApresentacao : '(Novo)');
    },

    tiposValorBase: [{ id: 1, nomeTipo: 'INICIAL_MENSAL', descricao: 'Inicial Mensal' }, { id: 2, nomeTipo: 'INICIAL_UNICO', descricao: 'Inicial Único (Não-Mensal)' }, { id: 3, nomeTipo: 'INICIAL_TOTAL', descricao: 'Inicial Total' }, { id: 4, nomeTipo: 'INICIAL_ATUALIZADO_MENSAL', descricao: 'Inicial Mensal Atualizado' }, { id: 5, nomeTipo: 'INICIAL_ATUALIZADO_UNICO', descricao: 'Inicial Único (Não-Mensal) Atualizado' }, { id: 6, nomeTipo: 'INICIAL_ATUALIZADO_TOTAL', descricao: 'Inicial Total Atualizado' }, { id: 7, nomeTipo: 'ATUAL_MENSAL', descricao: 'Atual Mensal do Contrato' }, { id: 8, nomeTipo: 'ATUAL_UNICO', descricao: 'Atual Único do Contrato' }, { id: 9, nomeTipo: 'ATUAL_TOTAL', descricao: 'Atual Total do Contrato' }, { id: 10, nomeTipo: 'ARBITRADO', descricao: 'Passível de Reajuste' }],

    tiposTermoInicial: [{ id: 1, nomeTipo: 'APRESENTACAO_PROPOSTA', descricao: 'Apresentação da Proposta' }, { id: 2, nomeTipo: 'DATA_FINAL_ULTIMO_REAJUSTE', descricao: 'Data Final do Último Reajuste' }, { id: 3, nomeTipo: 'ASSINATURA_CONTRATO', descricao: 'Assinatura do Contrato' }, { id: 4, nomeTipo: 'OUTRO', descricao: 'Outro' }],

    crudConfig: {
      criar: {
        urlFn: function urlFn() {
          return 'contrato/' + this.get("controllers.aquisicao/contrato").model.id + '/apostilamento';
        },
        okFn: function okFn(opcao) {
          this.get('controllers.aquisicao/contrato').send("ler");
          if (opcao === 'CRIAR_E_PROXIMO') {
            //ação "borbulhará" para a rota
            this.send('refreshRota');
            return;
          }
          this.transitionToRoute('aquisicao/contrato');
        }
      },
      atualizar: {
        okFn: function okFn() {
          this.get('controllers.aquisicao/contrato').send("ler");
          this.transitionToRoute('aquisicao/contrato');
        }
      },
      excluir: {
        okFn: function okFn() {
          this.get('controllers.aquisicao/contrato').send('ler');
        }
      }
    },

    //true se o reajuste nunca tiver sido gravado no servidor
    ehReajusteNovo: (function () {
      return !this.model.version;
    }).property('model.version'),

    ehReajusteExistente: (function () {
      return this.model.version;
    }).property('model.version'),

    proibidoAlterarValorBase: (function () {
      return !this.get('ehReajusteNovo') || this.model.tipoValorBase !== 'ARBITRADO';
    }).property('ehReajusteNovo', 'model.tipoValorBase'),

    contratoPossuiItensPeriodicos: (function () {
      var itensContrato = this.get('controllers.aquisicao/contrato').model.itens;
      return itensContrato.some(function (item) {
        return item.periodico;
      });
    }).property('controllers.aquisicao/contrato.model.itens.@each'),

    sugerirValorBase: (function () {
      if (!this.get('ehReajusteNovo')) {
        return;
      }

      var modeloContrato = this.get('controllers.aquisicao/contrato.model');
      var sugestaoValorBase;

      switch (this.model.tipoValorBase) {
        case 'INICIAL_MENSAL':
          sugestaoValorBase = modeloContrato.valorInicialMensal;break;
        case 'INICIAL_UNICO':
          sugestaoValorBase = modeloContrato.valorInicialUnico;break;
        case 'INICIAL_TOTAL':
          sugestaoValorBase = modeloContrato.valorInicialTotal;break;
        case 'INICIAL_ATUALIZADO_MENSAL':
          sugestaoValorBase = modeloContrato.valorInicialAtualizadoMensal;break;
        case 'INICIAL_ATUALIZADO_UNICO':
          sugestaoValorBase = modeloContrato.valorInicialAtualizadoUnico;break;
        case 'INICIAL_ATUALIZADO_TOTAL':
          sugestaoValorBase = modeloContrato.valorInicialAtualizadoTotal;break;
        case 'ATUAL_MENSAL':
          sugestaoValorBase = modeloContrato.valorAtualMensal;break;
        case 'ATUAL_UNICO':
          sugestaoValorBase = modeloContrato.valorAtualUnico;break;
        case 'ATUAL_TOTAL':
          sugestaoValorBase = modeloContrato.valorAtualTotal;break;
        case 'ARBITRADO':
          sugestaoValorBase = 0;break;
        default:
          sugestaoValorBase = 0;
      }

      this.set('model.valorBase', Util['default'].parseFloatStringPtBR(sugestaoValorBase));
    }).observes('ehReajusteNovo', 'model.tipoValorBase'),

    sugerirValorFinal: (function () {
      if (!this.get('ehReajusteNovo')) {
        return;
      }

      var base = typeof this.model.valorBase === 'string' ? Util['default'].parseFloatStringPtBR(this.model.valorBase) : this.model.valorBase;

      var indice = typeof this.model.valorIndice === 'string' ? Util['default'].parseFloatStringPtBR(this.model.valorIndice) : this.model.valorIndice;

      if (!indice) {
        this.set('model.valorFinal', 0);
      } else {
        //this.set('model.valorFinal', base * (indice / 100));
        var valorFinal = new Big['default'](base).times(new Big['default'](indice).div(new Big['default'](100)));
        this.set('model.valorFinal', Util['default'].stringNumericaUSAparaBR(valorFinal.toString()));
      }

      //TODO: aqui é preciso observar "THIS.model.valorBase". Caso se observer apenas
      //"model.valorBase", a função não é chamada quando se altera o valor base. Descobrir o
      //porquê e consertar.
    }).observes('ehReajusteNovo', 'model.valorIndice', 'this.model.valorBase'),

    //Previsão do valor do contrato após o reajuste - não é gravado
    calculaValorReajustado: (function () {
      if (!this.get('ehReajusteNovo')) {
        return;
      }

      //var valorReajustado = parseFloat(this.model.valorBase) + parseFloat(this.model.valorFinal);
      var valorBaseFloat = Util['default'].parseFloatStringPtBR(this.model.valorBase);
      var valorReajusteFloat = Util['default'].parseFloatStringPtBR(this.model.valorFinal);
      var valorReajustadoFloatArredondado = (valorBaseFloat + valorReajusteFloat).toFixed(2);
      var valorReajustadoStringArredondado = Util['default'].stringNumericaUSAparaBR(valorReajustadoFloatArredondado.toString());
      this.set('model.valorReajustado', valorReajustadoStringArredondado);
      //this.set('model.valorReajustado', 100.22);
    }).observes('model.valorBase', 'model.valorFinal')

  });

});
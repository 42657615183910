define('webapp/pods/adm-outras-entidades/route', ['exports', 'ember', 'webapp/mixins/suprimir-templates-superiores-mixin'], function (exports, Ember, SuprimirTemplatesSuperioresMixin) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(SuprimirTemplatesSuperioresMixin['default'], {

    setupController: function setupController(controller) {
      controller.set('model', { paises: [], ufs: [], municipios: [], fundamentosLegais: [] });
      controller.preencheLocalidadesModel();
      controller.buscarFundamentosLegais();
      controller.buscarAmparosLegais();
    }

  });

});
define('webapp/pods/aquisicao/criar/route', ['exports', 'ember', 'webapp/utils/util', 'webapp/pods/aquisicao/model', 'webapp/mixins/suprimir-templates-superiores-mixin'], function (exports, Ember, Util, Aquisicao, SuprimirTemplatesSuperioresMixin) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(SuprimirTemplatesSuperioresMixin['default'], {
    controllerName: 'aquisicao',

    setupController: function setupController(controller) {
      controller.set('model', Aquisicao['default'].create());

      this.get('comunicador').leParaPropriedade(controller, 'orgao', 'orgaos', 'Não foi possível obter os orgãos externos!');

      this.get('comunicador').leParaPropriedade(controller, 'programaTrabalho', 'programasDeTrabalho', 'Não foi possível obter os programas de trabalho!');

      this.get('comunicador').leParaPropriedade(controller, 'tipoFiscalGestor', 'tiposFiscalGestor', "Não foi possível obter os tipos de fiscal/gestor!");

      this.get('comunicador').leParaPropriedade(controller, 'unidadeOrcamentaria/selecionavel/unidadeOrganizacionalCorrespondente', 'unidadesOrganizacionais', 'Não foi possível obter as unidades organizacionais.');

      this.get('comunicador').leParaPropriedade(controller, 'servidor', 'servidores', 'Não foi possível obter os servidores.');

      this.get('comunicador').leParaPropriedade(controller, 'fundamentoLegal/aquisicao', 'fundamentosLegais', 'Não foi possível obter os fundamentos legais');

      //fundamento.tipo pode ser penalidade ou uma modalidade de aquisição
      this.get('comunicador').servico('get', 'fundamentoLegal?apenasAtivos=true').then(function (fundamentosLegais) {
        var fundamentosLegaisPorModalidade = fundamentosLegais.reduce(function (arrayDeFundamentosLegaisPorModalidade_, fundamento) {
          if (!arrayDeFundamentosLegaisPorModalidade_[fundamento.tipo]) {
            arrayDeFundamentosLegaisPorModalidade_[fundamento.tipo] = [];
          }
          arrayDeFundamentosLegaisPorModalidade_[fundamento.tipo].pushObject(fundamento);
          return arrayDeFundamentosLegaisPorModalidade_;
        }, {});
        controller.set('arrayDeFundamentosLegaisPorModalidade', fundamentosLegaisPorModalidade);
      });

      this.get('comunicador').servico('get', 'modosDisputa').then(function (modosDeDisputa) {
        var modosDeDisputaPorModalidade = modosDeDisputa.reduce(function (arrayDeModosDeDisputaPorModalidade_, modoDeDisputa) {
          modoDeDisputa.modalidades.forEach(function (modalidade) {
            if (!arrayDeModosDeDisputaPorModalidade_[modalidade]) {
              arrayDeModosDeDisputaPorModalidade_[modalidade] = [];
            }
            var modoDeDisputaParaRadioGroup = {};
            modoDeDisputaParaRadioGroup.texto = modoDeDisputa.descricao;
            modoDeDisputaParaRadioGroup.valor = modoDeDisputa.codigoEnum;
            arrayDeModosDeDisputaPorModalidade_[modalidade].pushObject(modoDeDisputaParaRadioGroup);
          });
          return arrayDeModosDeDisputaPorModalidade_;
        }, {});
        controller.set('arrayDeModosDeDisputaPorModalidade', modosDeDisputaPorModalidade);
      });

      this.get('comunicador').servico('get', 'criteriosJulgamento').then(function (criteriosDeJulgamento) {
        var criteriosDeJulgamentoPorModalidade = criteriosDeJulgamento.reduce(function (arrayDeCriteriosDeJulgamentoPorModalidade_, criterioDeJulgamento) {
          criterioDeJulgamento.modalidades.forEach(function (modalidade) {
            if (!arrayDeCriteriosDeJulgamentoPorModalidade_[modalidade]) {
              arrayDeCriteriosDeJulgamentoPorModalidade_[modalidade] = [];
            }
            var criteriosDeJulgamentoParaRadioGroup = {};
            criteriosDeJulgamentoParaRadioGroup.texto = criterioDeJulgamento.descricao;
            criteriosDeJulgamentoParaRadioGroup.valor = criterioDeJulgamento.codigoEnum;
            arrayDeCriteriosDeJulgamentoPorModalidade_[modalidade].pushObject(criteriosDeJulgamentoParaRadioGroup);
          });
          return arrayDeCriteriosDeJulgamentoPorModalidade_;
        }, {});
        controller.set('arrayDeCriteriosDeJulgamentoPorModalidade', criteriosDeJulgamentoPorModalidade);
      }), this.get('comunicador').servico('get', 'parametroExibicao').then(function (resultado) {
        var obj = Util['default'].converteParametrosDeExibicao(resultado);
        controller.set('model.uasg', obj.UASG);
      });
    },

    model: function model() {
      return Aquisicao['default'].create();
    }
  });

});
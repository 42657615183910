define('webapp/pods/aquisicao/lote/model', ['exports', 'ember', 'webapp/pods/fornecedor/model'], function (exports, Ember, Fornecedor) {

	'use strict';

	exports['default'] = Ember['default'].Object.extend({
		id: null,
		numero: null,
		descricao: '',
		itens: [],
		cadastroReserva: false, //indica se há fornecedores interessados em substituir o adjudicado

		dataAdjudicacao: '',
		adjudicado: Fornecedor['default'].create(),
		resultado: '',
		dataResultado: '',

		inicializar: (function () {
			this.set('id', null);
			this.set('numero', null);
			this.set('descricao', '');
			this.set('itens', []);
			this.cadastroReserva = false;
			this.set('dataAdjudicacao', '');
			this.set('adjudicado', Fornecedor['default'].create());
			this.set('resultado', '');
			this.set('dataResultado', '');
			this.set('processoResultado', '');
		}).on('init'),

		findUrlFn: function findUrlFn(id) {
			return 'aquisicao/lote/' + id.toString();
		}
	});

});
define('webapp/instance-initializers/add-find-to-coreobject', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = {
    initialize: function initialize(instance) {

      var findGenerico = function findGenerico(comunicador, url) {
        return new Ember['default'].RSVP.Promise(function (resolve) {
          comunicador.servico('get', url, null).then(function (resultado) {
            resolve(resultado);
          });
        });
      };

      String.prototype.toLocaleCapCase = function (char) {
        var nova = this.charAt(0).toLocaleUpperCase() + this.substring(1).toLocaleLowerCase();
        return nova;
      };

      String.prototype.toCapCase = function (char) {
        var nova = this.charAt(0).toUpperCase() + this.substring(1).toLowerCase();
        return nova;
      };

      Ember['default'].CoreObject.reopenClass({
        find: function find(id) {
          var modelo = this.toString().split(':')[1];
          var aditivoOuApostila = '';
          var controllerContrato;
          if (modelo == "aquisicao/contrato/aditivo") {
            controllerContrato = instance.container.lookup('controller:aquisicao/contrato');
            aditivoOuApostila = controllerContrato.get('aditivoOuApostila');
            if (aditivoOuApostila == '') {
              // Se está vazio, significa que houve refresh da página, com isso a informação do campo aditivoOuApostila é perdido...
              aditivoOuApostila = JSON.parse(localStorage.getItem('aditivoOuApostila'));
              controllerContrato.set('aditivoOuApostila', aditivoOuApostila); // ... então é preciso buscá-lo do localStorage
            } else localStorage.setItem('aditivoOuApostila', JSON.stringify(aditivoOuApostila));
          }
          return findGenerico(instance.container.lookup('service:comunicador'), instance.container.lookup('model:' + modelo).findUrlFn(id, aditivoOuApostila), 'Erro de conexão com o servidor!');
        }
      });
    }
  };

});
define('webapp/pods/aquisicao/ata/item/model', ['exports', 'ember', 'webapp/pods/aquisicao/item/model', 'webapp/models/classificacao-financeira-item', 'webapp/models/unidade-medida'], function (exports, Ember, ItemAquisicao, ClassificacaoFinanceiraItem, UnidadeMedida) {

	'use strict';

	exports['default'] = Ember['default'].Object.extend({
			id: null,
			version: null,
			itemAquisicao: ItemAquisicao['default'].create({}),
			classificacao: ClassificacaoFinanceiraItem['default'].create({}),
			descricao: '',
			quantidade: undefined,
			quantidadeComprada: undefined,
			quantidadeAderida: undefined,
			cancelado: false, //true | false
			unidadeMedida: UnidadeMedida['default'].create({}),
			valorUnitario: undefined,
			valorTotal: undefined
	});

});
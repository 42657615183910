define('webapp/pods/aquisicao/lote/criar/route', ['exports', 'ember', 'webapp/pods/aquisicao/lote/model'], function (exports, Ember, Lote) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({
    controllerName: 'aquisicao/lote',
    setupController: function setupController(controller) {
      controller.set('model', Lote['default'].create({}));
    },

    model: function model() {
      return Lote['default'].create();
    }
  });

});
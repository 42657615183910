define('webapp/pods/aquisicao/contrato/aditivo/acrescimo-ou-supressao/item/route', ['exports', 'ember', 'webapp/utils/util'], function (exports, Ember, Util) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({
    setupController: function setupController(controller, model) {
      controller.set('model', Util['default'].clona(model));

      this.get('comunicador').leParaPropriedade(controller, 'classificacaoItem', 'classificacoesItens', 'Não foi possível obter as Classificações Financeiras!');

      this.get('comunicador').leParaPropriedade(controller, 'unidadeMedida', 'unidadesDeMedida', 'Não foi possível obter as Unidades de Medida!');
    }

  });

});
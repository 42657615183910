define('webapp/mixins/ajusta-id-interno-mixin', ['exports', 'ember', 'webapp/utils/id'], function (exports, Ember, UtilID) {

  'use strict';

  /* Adiciona ao componente uma propriedade _idInterno para ser utilizada como id no elemento
    interno principal do componente (um campo, um botão, ...). O valor é calculado a partir
    da propriedade tipoInterno, que deve ser definida no interior do componente.
  */

  exports['default'] = Ember['default'].Mixin.create({

    _idInterno: (function () {

      this._verificaTipoInternoDefinido();

      if (this.id) {
        return UtilID['default'].getIdModificado(this.id, 'tipo', this.tipoInterno);
      }
      return '';
    }).property('id'),

    _verificaTipoInternoDefinido: function _verificaTipoInternoDefinido() {
      if (!this.tipoInterno) {
        throw 'O componente ' + this.toString() + ' não tem a propriedade "tipoInterno" definida.';
      }
    }

  });

});
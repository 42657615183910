define('webapp/pods/requisicao/item/model', ['exports', 'ember', 'webapp/models/unidade-medida', 'webapp/models/classificacao-financeira-item'], function (exports, Ember, UnidadeMedida, ClassificacaoFinanceiraItem) {

	'use strict';

	exports['default'] = Ember['default'].Object.extend({
							id: null,
							numero: null,
							classificacao: ClassificacaoFinanceiraItem['default'].create({}),
							codigoCatalogo: '', //atualmente, do ComprasNet
							periodico: false, //true ou false
							descricao: '',
							quantidade: null,
							duracao: 1, //em meses
							unidadeMedida: UnidadeMedida['default'].create({}),
							tipoValorItem: '', //"EXATO", "MEDIA" ou "MEDIANA"
							//não pode ser setado um valor default aqui no model
							//caso contrário JSON.stringify ignora esse atributo
							valorUnitarioExato: 0,
							valorUnitarioMedio: 0,
							valorUnitarioMediano: 0,
							valorTotal: 0,
							orcamentos: [], //Orcamento
							naturezaItem: '', // "MATERIAL" ou "SERVIÇO"

							findUrlFn: function findUrlFn(id) {
													return 'requisicao/itemRequisicao/' + id.toString();
							}
	});

});
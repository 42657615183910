define('webapp/pods/aquisicao/contrato/aditivo/alteracao-gestor/model', ['exports', 'ember'], function (exports, Ember) {

	'use strict';

	exports['default'] = Ember['default'].Object.extend({
		id: null,

		novosGestores: [],
		descricao: '',

		inicializar: (function () {
			this.set('novosGestores', []);
		}).on('init')

	});

});
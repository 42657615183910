define('webapp/components/tag-radio-button', ['exports', 'ember', 'webapp/mixins/ajusta-id-interno-mixin', 'webapp/mixins/ajusta-tamanho-input-mixin', 'webapp/mixins/constroi-aria-label-mixin'], function (exports, Ember, AjustaIdInternoMixin, AjustaTamanhoInputMixin, ConstroiAriaLabelMixin) {

	'use strict';

	/* Exemplo de Uso

		{{tag-radio-button
			texto=<texto do label>
			valor=<valor associado ao input>
			atributoDestino=<referência à variável externa que armazenará o valor>
		 	labelAcessivel=<texto para ser lido por leitores automáticos de interface
				              para acessibilidade por pessoas com deficiência visual.
				              Default: o valor do atributo "texto" e a indicação de se
											o campo é obrigatório>
			tamanho=<largura do componente em termos do bootstrap - um número X
					      de 1 a 12 utilizado para montar a classe CSS "col-md-X".
						  O default é 12>		labelAcessivel="Âmbito de Incidência: Administração Pública"
			somenteLeitura=<true ou false, sem aspas>
			desabilitado=<true|false (default), indicando se é possível marcar o radio-button>
			labelNegrito=<true|false (default), indicando se o label deve aparecer negrito>
			id="radio||ambitoIncidencia-administracaoPublica|penalidade|identificacao"}}

	*/

	exports['default'] = Ember['default'].Component.extend(AjustaIdInternoMixin['default'], AjustaTamanhoInputMixin['default'], ConstroiAriaLabelMixin['default'], {
		valor: '',
		atributoDestino: '',
		labelNegrito: false,
		labelAcessivel: '',
		desabilitado: false,
		somenteLeitura: false,
		estiloCSS: '',
		estilo: '',
		attributeBindings: ['estilo:style'],

		insereIdPermissoesBotaoRadio: (function () {
			var botaoRadio = this.$('input')[0];
			botaoRadio.setAttribute('nome', this.get('_idRadio'));
		}).on('willInsertElement'),

		_idLabel: (function () {
			return this.id + '::label';
		}).property('id'),

		_idRadio: (function () {
			return this.id + '::input';
		}).property('id'),

		insereAriaLabel: (function () {
			var radioButton = this.$('input')[0];
			radioButton.setAttribute('aria-label', this.get('_ariaLabel'));
		}).on('willInsertElement'),

		insereEstilo: (function () {
			if (!_.isEmpty(this.get('estiloCSS'))) {
				this.set('estilo', this.get('estiloCSS'));
			}
		}).on('willInsertElement'),

		focalizar: (function () {
			if (this.autoFoco) {
				this.$('input')[0].focus();
			}
		}).on('didInsertElement')

	});

});
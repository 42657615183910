define('webapp/pods/requisicao/route', ['exports', 'ember', 'webapp/utils/util', 'webapp/mixins/suprimir-templates-superiores-mixin'], function (exports, Ember, Util, SuprimirTemplatesSuperioresMixin) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(SuprimirTemplatesSuperioresMixin['default'], {

    setupController: function setupController(controller, model) {
      var _this = this;

      controller.set('model', Util['default'].clona(model));

      this.get('comunicador').leParaPropriedade(controller, 'programaTrabalho', 'programasDeTrabalho', "Não foi possível obter os programas de trabalho.");

      this.get('comunicador').leParaPropriedade(controller, 'unidadeOrcamentaria/selecionavel/unidadeOrganizacionalCorrespondente', 'unidadesOrganizacionais', "Não foi possível obter as unidades organizacionais.");

      controller.set('resumoAquisicoesSelecionadas', []);
      model.idAquisicoes.forEach(function (idAquisicao) {
        _this.get('comunicador').servico('get', 'aquisicao/' + idAquisicao).then(
        //Necessário usar o pushObject para acionar os gatilhos de atualização dos valores nos templates
        function (aquisicao) {
          controller.resumoAquisicoesSelecionadas.pushObject({ id: aquisicao.id, numeroApresentacao: aquisicao.numeroApresentacao });
        });
      });
    },

    actions: {
      willTransition: function willTransition(transition) {
        this._preparaParametrosTransicaoRotaPesquisa(transition);
      }
    },

    //Se a transição for para a rota de pesquisa, envia um parâmetro indicando que é
    //necessário manter os dados da pesquisa anterior na tela
    _preparaParametrosTransicaoRotaPesquisa: function _preparaParametrosTransicaoRotaPesquisa(transition) {
      if (transition.targetName !== 'requisicao/listagem') {
        return true;
      }

      var parametros = transition.queryParams ? Object.keys(transition.queryParams) : [];
      if (parametros.includes('manterDadosPesquisaAnterior')) {
        return true;
      }

      transition.abort();
      this.transitionTo('requisicao/listagem', { queryParams: { manterDadosPesquisaAnterior: 'true' } });
    }

  });

});
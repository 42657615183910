define('webapp/models/uasg', ['exports', 'ember', 'webapp/models/orgao'], function (exports, Ember, Orgao) {

	'use strict';

	exports['default'] = Ember['default'].Object.extend({
			id: null,
			codUASG: '',
			orgao: Orgao['default'].create({})
	});

});
define('webapp/utils/id', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  /**
   *
   * !!! IMPORTANTE !!!!
   * O COMPONENTE SELECT2 ADICIONA AOS IDS UM PREFIXO QUE UTILIZA UNDERSCORE (s2id_).
   * ASSIM, NÃO SE PODE UTILIZAR '_' COMO SEPARADOR DAS SEÇÕES DOS IDS.

    ID é usado para identificar componentes nos templates.

  MODELO DE ID: <tipo de componente>|<acao>|<informacao>|<contexto>|<local>::<opcional>

  <tipo de componente>: tipo do componente (ex.: botao, link, campo)
  <acao>: o que o componente faz (ex.: criar, atualizar, pesquisar)
  <informacao>: o atributo que o componente representa (ex.: nome, quantidade, idade)
                ou a ação a que ele dá acesso (ex.: pesquisa, criacao)

  <contexto>: nome do contexto Ember (ou seja, caminho completo do pod - controller-rota-modelo)
              da entidade à qual o componente se refere ou com a qual o componente vai
              interagir (ex.: requisicao, fornecedor, aquisicao/contrato/aditivo).
  <local>: nome da tela ou região onde o componente é renderizado (ex.: tela inicial, menu)
  <opcional>: porção não-obrigatória que pode ser utilizada para, por exemplo,
              diferenciar botões com ids iguais que se repetem em uma lista
              (ex. botões de ação das tabelas)

  Ex.: botao|adicionar||fornecedor/penalidade|apresentacao-fornecedor (i.e. botão
        para inlcuir uma penalidade na lista de penalidades de um fornecedor,
        localizado na tela de apresentação do fornecedor)
  Ex.2: link|acessar|pesquisa|fornecedor|menu-lateral (i.e. link que redireciona para a tela de
        pesquisa de fornecedores e que está localizado no menu-lateral)
  Ex.3: botao|acessar||fornecedor/penalidade|apresentacao-fornecedor-tabela|linha1
        (i.e. botão para acessar a penalidade que está na primeira linha na lista de
        penalidades de um fornecedor, localizado na tela de apresentação do fornecedor)


  OBS.:
  => <tipo de componente>, <contexto> e <local> são obrigatórios;
  => <tipo de componente> e <acao> só podem ser preenchidos por determinados valores;
  => <acao> e <informacao> não são mutuamente exclusivos, mas geralmente apenas um é
  preenchido (por exemplo, um botão geralmente realiza uma <acao> mas não representa
  uma <informacao>, enquanto um campo geralmente representa uma <informacao> mas não
  tem uma <acao> especial). Em alguns casos, ambos podem ser omitidos ou ambos podem
  estar presentes;
  */

  exports['default'] = {

    _seccoes: ['tipo', 'acao', 'informacao', 'contexto', 'local'],
    _tiposValidos: ['accordion', 'accordion-item', 'bloco', 'botao', 'botao-interno', 'campo', 'checkbox', 'componente', 'crud', 'date-input', 'label', 'link', 'link-botao', 'modal', 'radio-button', 'select', 'tab', 'tab-list', 'tab-panel', 'tab-label', 'tabela', 'tag', 'tag-radio', 'textarea', 'text-input', 'view-select'],
    _acoesValidas: ['abrir-fechar', 'acessar', 'acessarAdjudicacao', 'adicionar', 'adjudicar', 'alterar', 'apresentar', 'atribuir', 'cancelar', 'clonar', 'corrigirAdjudicacao', 'criar', 'criarEProximo', 'entrar', 'excluir', 'gerar', 'limpar', 'listar', 'pesquisar', 'retirar', 'sair', 'simular', 'substituirAdjudicacao'],

    _separadorSeccoes: '|',
    _separadorPorcaoOpcional: '::',

    //prefixa seção indicada com "prefixo": "textoDaSeccao" -> "prefixo-textoDaSeccao"
    prefixaSeccao: function prefixaSeccao(prefixo, seccao, id) {
      Ember['default'].assert('Utils/id#prefixaSeccao: nome de seção (' + seccao + ') inválido. (ID: ' + id + ')', this._seccoes.indexOf(seccao) !== -1 || seccao === 'opcional');
      return this.constroi(seccao === 'tipo' ? prefixo + '-' + this.getSeccao(id, 'tipo') : this.getSeccao(id, 'tipo'), seccao === 'acao' ? prefixo + '-' + this.getSeccao(id, 'acao') : this.getSeccao(id, 'acao'), seccao === 'informacao' ? prefixo + '-' + this.getSeccao(id, 'informacao') : this.getSeccao(id, 'informacao'), seccao === 'contexto' ? prefixo + '-' + this.getSeccao(id, 'contexto') : this.getSeccao(id, 'contexto'), seccao === 'local' ? prefixo + '-' + this.getSeccao(id, 'local') : this.getSeccao(id, 'local'), seccao === 'opcional' ? prefixo + '-' + this.getSeccao(id, 'opcional') : this.getSeccao(id, 'opcional'));
    },

    constroi: function constroi(tipo, acao, informacao, contexto, local, opcional) {
      Ember['default'].assert('É preciso informar 5 seccoes para construir um id.', arguments.length >= this._seccoes.length);
      var s = this._separadorSeccoes;
      var op = this._separadorPorcaoOpcional;
      return '' + tipo + s + acao + s + informacao + s + contexto + s + local + (opcional ? op + opcional : '');
    },

    //TODO: validar se o idBase já não contém seção opcional, se a secção opcional
    //não contém "::" ou "|", se o id final tem um formato válido, ...
    incluiSeccaoOpcional: function incluiSeccaoOpcional(idBase, seccaoOpcional) {
      return idBase + '::' + seccaoOpcional;
    },

    //TODO: recusar se o novo valor incluir o separador de secções ou de porção opcional
    getIdModificado: function getIdModificado(id, seccao, novoValor) {
      if (novoValor === undefined || novoValor === null || novoValor === 'undefined' || novoValor === 'null') {
        throw '"null", "undefined", null e undefined não são valores válidos para as secções do ID. ' + ('(ID: ' + id + ')');
      }

      var arraySeccoesID = this.getIdBase(id).split(this._separadorSeccoes);
      arraySeccoesID[this._seccoes.indexOf(seccao)] = novoValor;
      return arraySeccoesID.join(this._separadorSeccoes);
      //return id.replace(this.getSeccao(id, seccao), novoValor);
    },

    //Retorna o id sem a seção opcional
    getIdBase: function getIdBase(id) {
      return id ? id.split(this._separadorPorcaoOpcional)[0] : '';
    },

    getPorcaoOpcionalId: function getPorcaoOpcionalId(id) {
      return id ? id.split(this._separadorPorcaoOpcional)[1] : '';
    },

    getSeccao: function getSeccao(id, seccao) {
      if (!id) {
        return '';
      }

      if (!this._seccoes.find(function (x) {
        return x === seccao;
      }) && seccao !== 'opcional') {
        Ember['default'].warn('"' + seccao + '" não é um nome de secção válido. (ID: ' + id + ')');
      }

      if (!this._formatoValido(id)) {
        Ember['default'].warn('O id "' + id + '" informado não tem um formato válido ' + ('(i.e. ' + this._seccoes.length + ' secções, separadas por \'' + this._separadorSeccoes + '\').'));
      }

      if (seccao === 'opcional') {
        return id.split(this._separadorPorcaoOpcional)[1];
      }

      return this.getIdBase(id).split(this._separadorSeccoes)[this._seccoes.indexOf(seccao)];
    },

    ehValido: function ehValido(id) {
      var retorno = this._formatoValido(id) && this._tipoValido(id) && this._acaoValida(id) && this._informacaoValida(id) && this._contextoValido(id) && this._localValido(id);
      return retorno;
    },

    //Verifica se o id é formado por 5 seccoes (strings) separadas pelo separador
    //definido e, no máximo, 1 seccao opcional, separada pelo separador opcional definido.
    //Pode ter seccoes vazias - ou seja, ''
    _formatoValido: function _formatoValido(id) {
      if (!id) {
        return false;
      }

      var s = this._separadorSeccoes;
      if (this.getIdBase(id).split(s).length !== this._seccoes.length) {
        Ember['default'].warn('O ID deve ter 5 seções (strings), separadas por \'' + this._separadorSeccoes + '\', ' + ('no formato <tipo_componente>' + s + '<ação>' + s + '<informação>' + s + '<contexto>' + s + '<local> ') + ('(ID: ' + id + ')'));
        return false;
      }

      var op = this._separadorPorcaoOpcional;
      var ocorrenciasSeparadorOpcional = (id.match(new RegExp(op, 'g')) || []).length;
      if (ocorrenciasSeparadorOpcional > 1) {
        Ember['default'].warn('O ID pode ter no máximo 1 seção opcional (separada por "' + op + '"), ' + ('no formato <tipo_componente>' + s + '<ação>' + s + '<informação>' + s + '<contexto>' + s + '<local>' + op + '<opcional> ') + ('(ID: ' + id + ')'));
        return false;
      }

      return true;
    },

    _tipoValido: function _tipoValido(id) {
      var tipo = this.getSeccao(id, 'tipo');
      if (this._tiposValidos.find(function (x) {
        return x === tipo;
      })) {
        return true;
      }

      Ember['default'].warn('O tipo de componente (' + tipo + ') descrito no ID (' + id + ') é inválido.\n                Utilize um dos seguintes valores:\n                ' + this._tiposValidos.toString().replace(/,/g, ', '));
      return false;
    },

    _acaoValida: function _acaoValida(id) {
      var acao = this.getSeccao(id, 'acao');
      if (acao === '') {
        return true;
      }

      if (this._acoesValidas.find(function (x) {
        return x === acao;
      })) {
        return true;
      }

      Ember['default'].warn('O tipo de ação (' + acao + ') descrito no ID (' + id + ') é inválido.\n                Utilize um dos seguintes valores:\n                ' + this._acoesValidas.toString().replace(/,/g, ', '));
      return false;
    },

    _informacaoValida: function _informacaoValida() {
      return true;
    },

    //Obrigatório ter um contexto
    _contextoValido: function _contextoValido(id) {
      var contexto = this.getSeccao(id, 'contexto');

      if (!contexto && contexto !== 0) {
        Ember['default'].warn('A secção indicando o contexto/entidade a que o componente ' + ('se refere não pode estar vazia. (ID: ' + id + ')'));
        return false;
      }

      if (contexto === 'undefined' || contexto === 'null') {
        Ember['default'].warn('"' + contexto + '" não é um valor válido para a secção indicando o ' + ('contexto (entidade) a que o componente se refere. (ID: ' + id + ')'));
        return false;
      }

      return true;
    },

    //Obrigatório ter um local
    _localValido: function _localValido(id) {
      var local = this.getSeccao(id, 'local');

      if (!local && local !== 0) {
        Ember['default'].warn('A secção indicando o local (' + local + ') em que o componente é apresentado. ' + ('(ID: ' + id + ')'));
        return false;
      }

      if (local === 'undefined' || local === 'null') {
        Ember['default'].warn('"' + local + '" não é um valor válido para a secção indicando o ' + ('local em que o componente é apresentado. (ID: ' + id + ')'));
        return false;
      }

      return true;
    }

  };

});
define('webapp/pods/aquisicao/lote/adjudicar/controller', ['exports', 'ember', 'webapp/utils/util'], function (exports, Ember, Util) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({

    needs: ['aquisicao'],

    breadCrumbConfig: {
      fnNomeApresentacao: 'fnNomeApresentacao'
    },

    fnNomeApresentacao: function fnNomeApresentacao() {
      return 'Adjudicação Grupo ' + (this.model.version ? this.model.numeroApresentacao : '(Novo)');
    },

    tiposResultadoPossiveis: [{ id: 0, nome: 'ADJUDICADO' }, { id: 1, nome: 'DESERTO' }, { id: 2, nome: 'FRACASSADO' }, { id: 3, nome: 'REVOGADO' }, { id: 4, nome: 'ANULADO' }, { id: 5, nome: 'CANCELADO' }],

    tiposResultado: (function () {
      if (this.resultadoDefinido && this.model.resultado !== 'ADJUDICADO') {
        return this.tiposResultadoPossiveis.filter(function (tipo) {
          return tipo.nome !== 'ADJUDICADO';
        });
      }
      return this.tiposResultadoPossiveis;
    }).property('resultadoDefinido'),

    resultadoEhAdjudicado: (function () {
      return this.model.resultado === 'ADJUDICADO';
    }).property('model.resultado'),

    //Para resultado adjudicado: ADJUDICAR, CORRIGIR_ADJUDICACAO,  SUBSTITUIR_ADJUDICACAO, DESFAZER_RESULTADO
    //Para outros resultados: DEFINIR_OUTRO_RESULTADO, CORRIGIR_OUTRO_RESULTADO, DESFAZER_RESULTADO
    operacaoPretendida: 'ALTERAR',
    reiniciaOpcaoOperacaoPretendida: (function () {
      if (this.resultadoDefinido) {
        if (this.loteFoiAdjudicado) {
          this.set('operacaoPretendida', 'CORRIGIR_ADJUDICACAO');
        } else {
          this.set('operacaoPretendida', 'CORRIGIR_OUTRO_RESULTADO');
        }
      } else {
        this.set('operacaoPretendida', 'ADJUDICAR');
      }
    }).observes('resultadoDefinido', 'loteFoiAdjudicado'),
    atualizaOpcaoOperacaoPretendida: (function () {
      if (this.resultadoDefinido) {
        return;
      }
      this.set('operacaoPretendida', this.model.resultado === 'ADJUDICADO' ? 'ADJUDICAR' : 'DEFINIR_OUTRO_RESULTADO');
    }).observes('model.resultado'),
    pretendeAdjudicar: (function () {
      return this.operacaoPretendida === 'ADJUDICAR';
    }).property('operacaoPretendida'),
    pretendeCorrigirAdjudicacao: (function () {
      return this.operacaoPretendida === 'CORRIGIR_ADJUDICACAO';
    }).property('operacaoPretendida'),
    pretendeSubstituirAdjudicacao: (function () {
      return this.operacaoPretendida === 'SUBSTITUIR_ADJUDICACAO';
    }).property('operacaoPretendida'),
    pretendeDefinirOutroResultado: (function () {
      return this.operacaoPretendida === 'DEFINIR_OUTRO_RESULTADO';
    }).property('operacaoPretendida'),
    pretendeCorrigirOutroResultado: (function () {
      return this.operacaoPretendida === 'CORRIGIR_OUTRO_RESULTADO';
    }).property('operacaoPretendida'),
    pretendeDesfazerResultado: (function () {
      //Desfazer qualquer resultado, inclusive ADJUDICACAO
      return this.operacaoPretendida === 'DESFAZER_RESULTADO';
    }).property('operacaoPretendida'),

    textoBotaoDesfazer: (function () {
      return this.get('resultadoEhAdjudicado') ? 'Desfazer Adjudicação' : 'Desfazer Resultado';
    }).property('resultadoEhAdjudicado'),

    sincronizaDataResultadolParaAdjudicacao: (function () {
      this.set('model.dataAdjudicacao', this.get('resultadoEhAdjudicado') ? this.model.dataResultado : '');
    }).observes('model.dataResultado'),

    adjudicacoesAnterioresAjustadasApresentacao: (function () {
      return this.model.adjudicacoesAnteriores.map(function (adjudicacao) {
        return {
          data: adjudicacao.data,
          nomeAdjudicado: adjudicacao.adjudicado.nome,
          cpfOuCnpj: adjudicacao.adjudicado.cpf ? adjudicacao.adjudicado.cpf : adjudicacao.adjudicado.cnpj
        };
      });
    }).property('model.adjudicacoesAnteriores'),

    actions: {

      adjudicar: function adjudicar() {
        var _this = this;

        this.get('comunicador').servico('put', 'aquisicao/lote/adjudica', this.model).then(function () {
          Util['default'].alerta('Grupo adjudicado.');
          _this.get('controllers.aquisicao').send('ler');
          _this.transitionToRoute('aquisicao');
        });
      },

      definirOutroResultado: function definirOutroResultado() {
        var _this2 = this;

        this.get('comunicador').servico('put', 'aquisicao/lote/fracassaLote', this.model).then(function () {
          Util['default'].alerta('Resultado "' + _this2.model.resultado + '" definido para o grupo.');
          _this2.get('controllers.aquisicao').send('ler');
          _this2.transitionToRoute('aquisicao');
        });
      },

      substituirAdjudicacao: function substituirAdjudicacao() {
        var _this3 = this;

        this.get('comunicador').servico('put', 'aquisicao/lote/substituiAdjudicacao', this.model).then(function () {
          Util['default'].alerta('Adjudicado Substituído.');
          _this3.get('controllers.aquisicao').send('ler');
          _this3.transitionToRoute('aquisicao');
        });
      },

      alterarAdjudicacao: function alterarAdjudicacao() {
        var _this4 = this;

        this.get('comunicador').servico('put', 'aquisicao/lote/alteraAdjudicacao', this.model).then(function () {
          Util['default'].alerta('Adjudicação Alterada.');
          _this4.get('controllers.aquisicao').send('ler');
          _this4.transitionToRoute('aquisicao');
        });
      },

      desfazerResultado: function desfazerResultado() {
        var _this5 = this;

        //this.get('comunicador').servico('put', 'aquisicao/lote/excluiAdjudicacao', this.model).then(
        this.get('comunicador').servico('put', 'aquisicao/lote/desfazResultado', this.model).then(function () {
          Util['default'].alerta('Resultado desfeito.');
          _this5.get('controllers.aquisicao').send('ler');
          _this5.transitionToRoute('aquisicao');
        });
      }

    }

  });

});
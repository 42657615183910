define('webapp/pods/login/route', ['exports', 'ember'], function (exports, Ember) {

	'use strict';

	exports['default'] = Ember['default'].Route.extend({
		usuarioLogado: Ember['default'].inject.service('usuario-logado'),
		model: function model() {
			return this.get('usuarioLogado').info;
		}
	});

});
define('webapp/utils/constantes', ['exports'], function (exports) {

    'use strict';

    exports['default'] = {
        ORDEM: {
            ASC: 1,
            DESC: -1
        }

    };

});
define('webapp/utils/form', ['exports'], function (exports) {

	'use strict';

	/* global $ */

	exports['default'] = {
		//habilita um input e desabilita e limpa o outro
		//Nota: id dos inputs no formato "#<id>"
		habilitaDesabilitaInputs: function habilitaDesabilitaInputs(idInputParaHabilitar, idInputParaDesabilitar) {
			$(idInputParaDesabilitar).val('');
			$(idInputParaDesabilitar).prop('disabled', true);
			$(idInputParaHabilitar).prop('disabled', false);
		}
	};

});
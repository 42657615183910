define('webapp/pods/fornecedor/route', ['exports', 'ember', 'webapp/utils/util', 'webapp/mixins/suprimir-templates-superiores-mixin'], function (exports, Ember, Util, SuprimirTemplatesSuperioresMixin) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(SuprimirTemplatesSuperioresMixin['default'], {
    setupController: function setupController(controller, model) {
      //Coloca um clone no model para que as alterações não ocorram
      //diretamente no fornecedor e seja possível desfazê-las
      controller.set('model', Util['default'].clona(model));

      this.get('comunicador').leParaPropriedade(controller, 'fornecedor/' + controller.model.id + '/fornecido', 'classificacoesFornecidas', "Não foi possível obter as categorias já fornecidas!");
    },

    actions: {
      willTransition: function willTransition(transition) {
        this._preparaParametrosTransicaoRotaPesquisa(transition);
      }
    },

    //Se a transição for para a rota de pesquisa, envia um parâmetro indicando que é
    //necessário manter os dados da pesquisa anterior na tela
    _preparaParametrosTransicaoRotaPesquisa: function _preparaParametrosTransicaoRotaPesquisa(transition) {
      if (transition.targetName !== 'fornecedor/listagem') {
        return true;
      }

      var parametros = transition.queryParams ? Object.keys(transition.queryParams) : [];
      if (parametros.includes('manterDadosPesquisaAnterior')) {
        return true;
      }

      transition.abort();
      this.transitionTo('fornecedor/listagem', { queryParams: { manterDadosPesquisaAnterior: 'true' } });
    }

  });

});
define('webapp/pods/requisicao/contato-entrega/controller', ['exports', 'ember', 'webapp/mixins/crud-mixin'], function (exports, Ember, CrudMixin) {

	'use strict';

	exports['default'] = Ember['default'].Controller.extend(CrudMixin['default'], {

		needs: ['requisicao'],

		breadCrumbConfig: {
			fnNomeApresentacao: 'fnNomeApresentacao'
		},

		fnNomeApresentacao: function fnNomeApresentacao() {
			return 'Contato de Entrega ' + (this.model.version ? this.model.numeroApresentacao : '(Novo)');
		},

		servidorAContatar: {},

		crudConfig: {
			criar: {
				urlFn: function urlFn() {
					return 'requisicao/' + this.get("controllers.requisicao").model.id + '/contatoEntrega';
				},
				okFn: function okFn(opcao) {
					this.get('controllers.requisicao').send('ler');
					if (opcao === 'CRIAR_E_PROXIMO') {
						//ação "borbulhará" para a rota
						this.send('refreshRota');
						return;
					}
					this.transitionToRoute('requisicao');
				}
			},
			atualizar: {
				okFn: function okFn() {
					this.get('controllers.requisicao').send('ler');
					this.transitionToRoute('requisicao');
				}
			},
			excluir: {
				okFn: function okFn() {
					this.get('controllers.requisicao').send('ler');
				}
			}
		},

		atualizaModeloConformeServidorAContatar: (function () {
			this.set('model.nome', this.servidorAContatar.nome);
			this.set('model.email', this.servidorAContatar.email);
		}).observes('servidorAContatar'),

		atualizaServidorAContatarConformeModelo: (function () {
			this.set('servidorAContatar.nome', this.model.nome);
			this.set('servidorAContatar.email', this.model.email);
		}).observes('model')

	});

});
define('webapp/pods/usuario/template', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      var child0 = (function() {
        return {
          isHTMLBars: true,
          revision: "Ember@1.12.1",
          blockParams: 0,
          cachedFragment: null,
          hasRendered: false,
          build: function build(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n	      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("fieldset");
            var el2 = dom.createTextNode("\n\n	        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","form-horizontal");
            var el3 = dom.createTextNode("\n	          ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n	          ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n	          ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n	          ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n	        ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n\n	        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            var el3 = dom.createTextNode("\n	          ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","col-lg-5");
            var el4 = dom.createTextNode("\n	            Papéis Disponíveis\n	            ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n	          ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n\n	          ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","col-lg-2");
            var el4 = dom.createTextNode("\n	            ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("br");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n              ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n              ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n	          ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n\n	          ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","col-lg-5");
            var el4 = dom.createTextNode("\n	            Papéis Atribuídos\n	            ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n	          ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n\n	        ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n\n	      ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          render: function render(context, env, contextualElement) {
            var dom = env.dom;
            var hooks = env.hooks, get = hooks.get, inline = hooks.inline;
            dom.detectNamespace(contextualElement);
            var fragment;
            if (env.useFragmentCache && dom.canClone) {
              if (this.cachedFragment === null) {
                fragment = this.build(dom);
                if (this.hasRendered) {
                  this.cachedFragment = fragment;
                } else {
                  this.hasRendered = true;
                }
              }
              if (this.cachedFragment) {
                fragment = dom.cloneNode(this.cachedFragment, true);
              }
            } else {
              fragment = this.build(dom);
            }
            var element0 = dom.childAt(fragment, [1]);
            var element1 = dom.childAt(element0, [1]);
            var element2 = dom.childAt(element0, [3]);
            var element3 = dom.childAt(element2, [3]);
            var morph0 = dom.createMorphAt(element1,1,1);
            var morph1 = dom.createMorphAt(element1,3,3);
            var morph2 = dom.createMorphAt(element1,5,5);
            var morph3 = dom.createMorphAt(element1,7,7);
            var morph4 = dom.createMorphAt(dom.childAt(element2, [1]),1,1);
            var morph5 = dom.createMorphAt(element3,3,3);
            var morph6 = dom.createMorphAt(element3,5,5);
            var morph7 = dom.createMorphAt(dom.childAt(element2, [5]),1,1);
            inline(env, morph0, context, "tag-input", [], {"texto": "Nome", "valor": get(env, context, "model.servidor.nome"), "somenteLeitura": true, "autoFoco": true, "id": "tag||nome|usuario|identificacao"});
            inline(env, morph1, context, "tag-input", [], {"texto": "CPF", "valor": get(env, context, "model.servidor.cpf"), "somenteLeitura": true, "mascara": "cpf", "id": "tag||cpf|usuario|identificacao"});
            inline(env, morph2, context, "tag-input", [], {"texto": "Matrícula", "valor": get(env, context, "model.servidor.codSof"), "somenteLeitura": true, "id": "tag||matricula|usuario|identificacao"});
            inline(env, morph3, context, "tag-input", [], {"texto": "Usuário", "valor": get(env, context, "model.servidor.username"), "somenteLeitura": true, "id": "tag||nome-usuario|usuario|identificacao"});
            inline(env, morph4, context, "view", ["select"], {"content": get(env, context, "this.papeisDisponiveis"), "optionLabelPath": "content.nome", "value": get(env, context, "papelAAtribuir"), "size": "5", "class": "col-lg-12", "id": "view-select||papeis-disponiveis|usuario|identificacao"});
            inline(env, morph5, context, "botao-geral", [], {"tipo": "primario", "iconeUiIcon": "arrow-right", "acao": "atribuirPapel", "parametroAcao": get(env, context, "papelAAtribuir"), "labelAcessivel": "Atribuir papel", "id": "botao|atribuir|papel|usuario|identificacao"});
            inline(env, morph6, context, "botao-geral", [], {"tipo": "primario", "iconeUiIcon": "arrow-left", "labelAcessivel": "Retirar papel", "acao": "retirarPapel", "parametroAcao": get(env, context, "papelARetirar"), "id": "botao|retirar|papel|usuario|identificacao"});
            inline(env, morph7, context, "view", ["select"], {"content": get(env, context, "model.roles"), "optionLabelPath": "content.nome", "value": get(env, context, "papelARetirar"), "size": "5", "class": "col-lg-12", "id": "view-select||papeis-atribuidos|usuario|identificacao"});
            return fragment;
          }
        };
      }());
      return {
        isHTMLBars: true,
        revision: "Ember@1.12.1",
        blockParams: 0,
        cachedFragment: null,
        hasRendered: false,
        build: function build(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","modal-footer centerButtons");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        render: function render(context, env, contextualElement) {
          var dom = env.dom;
          var hooks = env.hooks, block = hooks.block, inline = hooks.inline;
          dom.detectNamespace(contextualElement);
          var fragment;
          if (env.useFragmentCache && dom.canClone) {
            if (this.cachedFragment === null) {
              fragment = this.build(dom);
              if (this.hasRendered) {
                this.cachedFragment = fragment;
              } else {
                this.hasRendered = true;
              }
            }
            if (this.cachedFragment) {
              fragment = dom.cloneNode(this.cachedFragment, true);
            }
          } else {
            fragment = this.build(dom);
          }
          var element4 = dom.childAt(fragment, [3]);
          var morph0 = dom.createMorphAt(fragment,1,1,contextualElement);
          var morph1 = dom.createMorphAt(element4,1,1);
          var morph2 = dom.createMorphAt(element4,3,3);
          block(env, morph0, context, "tag-bloco", [], {"titulo": "Usuário"}, child0, null);
          inline(env, morph1, context, "botao-geral", [], {"tipo": "primario", "texto": "Salvar Alterações", "acao": "atualizar", "controlePermissao": true, "id": "botao|alterar||usuario|identificacao"});
          inline(env, morph2, context, "botao-geral", [], {"texto": "Cancelar", "enderecoLink": "usuario/listagem", "id": "botao|cancelar||usuario|identificacao"});
          return fragment;
        }
      };
    }());
    return {
      isHTMLBars: true,
      revision: "Ember@1.12.1",
      blockParams: 0,
      cachedFragment: null,
      hasRendered: false,
      build: function build(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      render: function render(context, env, contextualElement) {
        var dom = env.dom;
        var hooks = env.hooks, get = hooks.get, block = hooks.block;
        dom.detectNamespace(contextualElement);
        var fragment;
        if (env.useFragmentCache && dom.canClone) {
          if (this.cachedFragment === null) {
            fragment = this.build(dom);
            if (this.hasRendered) {
              this.cachedFragment = fragment;
            } else {
              this.hasRendered = true;
            }
          }
          if (this.cachedFragment) {
            fragment = dom.cloneNode(this.cachedFragment, true);
          }
        } else {
          fragment = this.build(dom);
        }
        var morph0 = dom.createMorphAt(fragment,0,0,contextualElement);
        dom.insertBoundary(fragment, null);
        dom.insertBoundary(fragment, 0);
        block(env, morph0, context, "tag-modal", [], {"tamanho": 4, "id": "modal|||usuario|identificacao", "controllerReferencia": get(env, context, "this"), "ativarModalNaCriacao": true, "rotaCancelamento": "usuario/listagem"}, child0, null);
        return fragment;
      }
    };
  }()));

});
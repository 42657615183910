define('webapp/pods/aquisicao/compra/item/route', ['exports', 'ember', 'webapp/utils/util'], function (exports, Ember, Util) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({
    setupController: function setupController(controller, model) {
      controller.set('model', Util['default'].clona(model));
      controller.set('itensAquisicaoAdjudicados', []);

      this.get('comunicador').servico('get', 'aquisicao/' + controller.get('controllers.aquisicao').model.id).then(function (aquisicao) {
        controller.set('itensAquisicaoAdjudicados', aquisicao.itens);
      });

      this.get('comunicador').leParaPropriedade(controller, 'classificacaoItem', 'classificacoesItem', "Não foi possível obter as Classificações Financeiras!");

      this.get('comunicador').leParaPropriedade(controller, 'unidadeMedida', 'unidadesDeMedida', "Não foi possível obter as Unidades de Medida!");

      this.get('comunicador').leParaPropriedade(controller, 'unidadeMedida/tempo', 'unidadesMedidaDuracao', "Não foi possível obter as Unidades de Medida de Duração!");
    }

  });

});
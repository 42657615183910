define('webapp/pods/aquisicao/andamento/model', ['exports', 'ember'], function (exports, Ember) {

	'use strict';

	exports['default'] = Ember['default'].Object.extend({
		id: null,
		data: '',
		descricao: '',

		findUrlFn: function findUrlFn(id) {
			return 'aquisicao/andamento/' + id.toString();
		}
	});

});
define('webapp/pods/publico/atas/controller', ['exports', 'ember', 'webapp/pods/publico/constantes-transparencia', 'webapp/pods/aquisicao/constantes-aquisicao', 'webapp/pods/transparencia/documento/constantes-documento', 'webapp/defs'], function (exports, Ember, Constantes, ConstantesAquisicao, ConstantesDocumentos, Defs) {

    'use strict';

    exports['default'] = Ember['default'].ArrayController.extend({
        queryParams: ['ano', 'status', 'objeto'],

        opcoesStatus: Constantes['default'].statusAta,
        modalidades: ConstantesAquisicao['default'].modalidades,
        retroagir: 5,

        anos: (function () {
            return Constantes['default'].opcoesAnos(this.retroagir);
        }).property('retroagir'),

        getUrlDownload: function getUrlDownload(documento) {
            return Defs['default'].enderecoServer + documento.urlDownload;
        },

        formataValorDate: function formataValorDate(date) {
            if (!date) {
                return "";
            }
            var opcoesFormatoData = { day: 'numeric', month: 'numeric', year: 'numeric' };
            if (date.getHours() || date.getMinutes() || date.getSeconds()) {
                opcoesFormatoData['hour'] = '2-digit';
                opcoesFormatoData['minute'] = '2-digit';
            }
            return date.toLocaleString('pt-BR', opcoesFormatoData);
        },

        getNomeLegivelModalidade: function getNomeLegivelModalidade(item) {
            var modalidadeDaAquisicao = this.modalidades.find(function (modalidade) {
                return item.modalidade === modalidade.id;
            });
            return modalidadeDaAquisicao.nome;
        },

        getDescricaoDocumento: function getDescricaoDocumento(item) {
            if (item.descricao) {
                return item.descricao;
            }

            for (var ind = 0; ind < ConstantesDocumentos['default'].tipos.length; ind++) {
                if (item.tipo == ConstantesDocumentos['default'].tipos[ind].id) return ConstantesDocumentos['default'].tipos[ind].nome;
            }

            return item.tipo;
        },

        converteStatus: function converteStatus(status) {
            for (var ind = 0; ind < this.opcoesStatus.length; ind++) {
                if (status == this.opcoesStatus[ind].id) return this.opcoesStatus[ind].nome;
            }
        },

        actions: {
            listar: function listar() {
                var _this = this;

                var criteriosPesquisa = this.getProperties('ano', 'status', 'objeto');

                this.get('comunicador').servico('post', 'transparencia/atas', criteriosPesquisa, this).then(function (resultado) {
                    _this.set('model', resultado);
                });
            },

            limparPesquisa: function limparPesquisa() {
                this.limparDadosPesquisa();
                this.set('model', []);
            }

        }
    });

});
define('webapp/pods/sobre/route', ['exports', 'ember', 'webapp/mixins/suprimir-templates-superiores-mixin'], function (exports, Ember, SuprimirTemplatesSuperioresMixin) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(SuprimirTemplatesSuperioresMixin['default'], {

    setupController: function setupController(controller) {
      this.get('comunicador').leParaPropriedade(controller, 'buildInfo', 'buildInfo', "Não foi possível obter as informações de versão");
    }
  });

});
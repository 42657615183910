define('webapp/pods/index/route', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({
    usuarioLogado: Ember['default'].inject.service('usuario-logado'),

    setupController: function setupController() {
      if (!this.get('usuarioLogado').estaLogado()) {
        this.transitionTo('login');
      }
    }
  });

});
define('webapp/pods/aquisicao/contrato/aditivo/prorrogacao-vigencia/controller', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({
    needs: ['aquisicao/contrato', 'aquisicao/contrato/aditivo'],

    ehAditivoExistente: (function () {
      return this.get('model').version;
    }).property('controllers.aquisicao/contrato/aditivo.model.version'),

    dataInicioVigenciaAtualizada: (function () {
      return this.get('controllers.aquisicao/contrato.model.dataInicioVigenciaAtualizada');
    }).property('controllers.aquisicao/contrato.model'),

    dataFimVigenciaAtualizada: (function () {
      return this.get('controllers.aquisicao/contrato.model.dataFimVigenciaAtualizada');
    }).property('controllers.aquisicao/contrato.model')
  });

});
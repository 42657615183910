define('webapp/models/fiscal', ['exports', 'ember', 'webapp/models/servidor', 'webapp/models/tipo-fiscal-gestor'], function (exports, Ember, Servidor, TipoFiscalGestor) {

	'use strict';

	exports['default'] = Ember['default'].Object.extend({
			id: null,
			servidor: Servidor['default'].create({}),
			tipo: TipoFiscalGestor['default'].create({}),
			emailSugerido: ''
	});

});
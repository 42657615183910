define('webapp/pods/aquisicao/contrato/aditivo/reajuste/controller', ['exports', 'ember', 'webapp/utils/util', 'npm:big.js'], function (exports, Ember, Util, Big) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({
    //Este controller deixa as ações "borbulharem" para o aditivo.
    needs: ['aquisicao/contrato/aditivo', 'aquisicao/contrato'],

    tiposValorBase: [{ id: 1, nomeTipo: 'INICIAL_MENSAL', descricao: 'Inicial Mensal' }, { id: 2, nomeTipo: 'INICIAL_UNICO', descricao: 'Inicial Único (Não-Mensal)' }, { id: 3, nomeTipo: 'INICIAL_TOTAL', descricao: 'Inicial Total' }, { id: 4, nomeTipo: 'INICIAL_ATUALIZADO_MENSAL', descricao: 'Inicial Mensal Atualizado' }, { id: 5, nomeTipo: 'INICIAL_ATUALIZADO_UNICO', descricao: 'Inicial Único (Não-Mensal) Atualizado' }, { id: 6, nomeTipo: 'INICIAL_ATUALIZADO_TOTAL', descricao: 'Inicial Total Atualizado' }, { id: 7, nomeTipo: 'ATUAL_MENSAL', descricao: 'Atual Mensal do Contrato' }, { id: 8, nomeTipo: 'ATUAL_UNICO', descricao: 'Atual Único do Contrato' }, { id: 9, nomeTipo: 'ATUAL_TOTAL', descricao: 'Atual Total do Contrato' }, { id: 10, nomeTipo: 'ARBITRADO', descricao: 'Passível de Reajuste' }],

    tiposTermoInicial: [{ id: 1, nomeTipo: 'APRESENTACAO_PROPOSTA', descricao: 'Apresentação da Proposta' }, { id: 2, nomeTipo: 'DATA_FINAL_ULTIMO_REAJUSTE', descricao: 'Data Final do Último Reajuste' }, { id: 3, nomeTipo: 'ASSINATURA_CONTRATO', descricao: 'Assinatura do Contrato' }, { id: 4, nomeTipo: 'OUTRO', descricao: 'Outro' }],

    //true se o reajuste nunca tiver sido gravado no servidor
    ehReajusteNovo: (function () {
      return !this.model.version;
    }).property('model.version'),

    ehReajusteExistente: (function () {
      return this.model.version;
    }).property('model.version'),

    proibidoAlterarValorBase: (function () {
      return this.get('ehReajusteExistente') || this.model.tipoValorBase !== 'ARBITRADO';
    }).property('ehReajusteExistente', 'model.tipoValorBase'),

    contratoPossuiItensPeriodicos: (function () {
      var itensContrato = this.get('controllers.aquisicao/contrato').model.itens;
      return itensContrato.some(function (item) {
        return item.periodico;
      });
    }).property('controllers.aquisicao/contrato.model.itens.@each'),

    sugerirValorBase: (function () {
      if (this.get('ehReajusteExistente')) {
        return;
      }

      if (!this.get('model.tipoValorBase')) {
        return;
      }

      var modeloContrato = this.get('controllers.aquisicao/contrato.model');
      var sugestaoValorBase;

      switch (this.model.tipoValorBase) {
        case 'INICIAL_MENSAL':
          sugestaoValorBase = modeloContrato.valorInicialMensal;break;
        case 'INICIAL_UNICO':
          sugestaoValorBase = modeloContrato.valorInicialUnico;break;
        case 'INICIAL_TOTAL':
          sugestaoValorBase = modeloContrato.valorInicialTotal;break;
        case 'INICIAL_ATUALIZADO_MENSAL':
          sugestaoValorBase = modeloContrato.valorInicialAtualizadoMensal;break;
        case 'INICIAL_ATUALIZADO_UNICO':
          sugestaoValorBase = modeloContrato.valorInicialAtualizadoUnico;break;
        case 'INICIAL_ATUALIZADO_TOTAL':
          sugestaoValorBase = modeloContrato.valorInicialAtualizadoTotal;break;
        case 'ATUAL_MENSAL':
          sugestaoValorBase = modeloContrato.valorAtualMensal;break;
        case 'ATUAL_UNICO':
          sugestaoValorBase = modeloContrato.valorAtualUnico;break;
        case 'ATUAL_TOTAL':
          sugestaoValorBase = modeloContrato.valorAtualTotal;break;
        case 'ARBITRADO':
          sugestaoValorBase = 0;break;
        default:
          alert('Tipo de valor base inválido.');
      }

      this.set('model.valorBase', Util['default'].parseFloatStringPtBR(sugestaoValorBase));
    }).observes('model.tipoValorBase'),

    sugerirValorFinal: (function () {
      if (this.get('ehReajusteExistente')) {
        return;
      }

      if (this.get('ehReajusteExistente') || !this.get('model.valorBase') || !this.get('model.valorIndice')) {
        this.set('model.valorFinal', null);
        return;
      }

      var base = typeof this.model.valorBase === 'string' ? Util['default'].parseFloatStringPtBR(this.model.valorBase) : this.model.valorBase;

      var indice = typeof this.model.valorIndice === 'string' ? Util['default'].parseFloatStringPtBR(this.model.valorIndice) : this.model.valorIndice;

      var valorFinal = new Big['default'](base).times(new Big['default'](indice).div(new Big['default'](100)));

      this.set('model.valorFinal', Util['default'].stringNumericaUSAparaBR(valorFinal.toFixed(2).toString()));
    }).observes('model.valorBase', 'model.valorIndice'),

    //Previsão do valor do contrato após o reajuste - não é gravado
    calculaValorReajustado: (function () {
      if (!this.get('ehReajusteNovo')) {
        return;
      }

      //var valorReajustado = parseFloat(this.model.valorBase) + parseFloat(this.model.valorFinal);
      var valorBaseFloat = Util['default'].parseFloatStringPtBR(this.model.valorBase);
      var valorReajusteFloat = Util['default'].parseFloatStringPtBR(this.model.valorFinal);
      var valorReajustadoFloatArredondado = (valorBaseFloat + valorReajusteFloat).toFixed(2);
      var valorReajustadoStringArredondado = Util['default'].stringNumericaUSAparaBR(valorReajustadoFloatArredondado.toString());
      this.set('model.valorReajustado', valorReajustadoStringArredondado);
      //this.set('model.valorReajustado', 100.22);
    }).observes('model.valorBase', 'model.valorFinal')

  });

});
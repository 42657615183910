define('webapp/services/usuario-logado', ['exports', 'ember', 'webapp/utils/id'], function (exports, Ember, UtilID) {

  'use strict';

  exports['default'] = Ember['default'].Service.extend({
    info: {},
    rota: null,

    _rotasPermitidas: (function () {
      var rotasPermitidas = [];
      this.info.permissoesTela.forEach(function (p) {
        if (p.tipo === 'rota') {
          rotasPermitidas.pushObject(
          //Reconstitui o nome da rota a partir da permissão. Exemplo:
          //'rota|criar||fornecedor|roteador' em fornecedor/criar ou
          //'rota||pesquisa|fornecedor|roteador' em fornecedor/pesquisa
          '' + p.contexto + (p.acao ? '/' + p.acao : '') + (p.informacao ? '/' + p.informacao : ''));
        }
      });
      return rotasPermitidas;
    }).property('info.permissoesTela'),

    _idsPermitidos: (function () {
      var _this = this;

      var idsPermitidos = [];

      if (this.info && this.info.permissoesTela) {
        this.info.permissoesTela.forEach(function (permissao) {
          idsPermitidos.pushObject(_this._transformaPermissaoEmID(permissao));
        });
      }

      return idsPermitidos;
    }).property('info.permissoesTela.@each'),

    _transformaPermissaoEmID: function _transformaPermissaoEmID(permissao) {
      return UtilID['default'].constroi(permissao.tipo ? permissao.tipo : '', permissao.acao ? permissao.acao : '', permissao.informacao ? permissao.informacao : '', permissao.contexto ? permissao.contexto : '', permissao.local ? permissao.local : '');
    },

    _atualizarInfoSessao: function _atualizarInfoSessao() {
      this.set('info', JSON.parse(localStorage.getItem('info')));
    },

    podeAcessar: function podeAcessar(rota) {
      this._atualizarInfoSessao();
      if (!this.info || !this.info.permissoesTela) {
        return false;
      }
      return this.get('_rotasPermitidas').contains(rota);
    },

    idPermitido: function idPermitido(id) {
      this._atualizarInfoSessao();
      if (!this.info || !this.info.permissoesTela) {
        return false;
      }
      var idBase = UtilID['default'].getIdBase(id);
      if (this.get('_idsPermitidos').contains(idBase)) {
        return true;
      } else {
        console.log('ID NÃO PERMITIDO: ' + id + '    Id Base: ' + idBase);
        return false;
      }
    },

    estaLogado: function estaLogado() {
      this._atualizarInfoSessao();
      return this.info && this.info.authToken ? true : false;
    },

    ehAdmin: function ehAdmin() {
      return this.get('info').roles.filter(function (e) {
        return e.nome === "ADMIN";
      }).length > 0;
    },

    entrar: function entrar(info) {
      localStorage.setItem('info', JSON.stringify(info));
      this.set('info', info);
      this.notifyPropertyChange('info');
    },

    sair: function sair() {
      localStorage.setItem('info', JSON.stringify({}));
      this.set('info', {});
      this.notifyPropertyChange('info');
    },

    acrescentaInfoTZsDifs: function acrescentaInfoTZsDifs(tZDifs) {
      if (this.estaLogado()) {
        this.set('this.info.tZsDifs', tZDifs);
        localStorage.setItem('info', JSON.stringify(this.info));
      }
    },

    //    As funções temRotaSalva(), salvaRota(), apagaRota() e recuperaRota() foram criadas para que um usuário que não esteja logado
    // possa ser redirecionado para a tela de login e se logar antes de chegar à rota pretendida (que ele só pode acessar estando logado) que
    // é a rota que é salva em this.rota.
    temRotaSalva: function temRotaSalva() {
      return this.rota;
    },
    salvaRota: function salvaRota(transition) {
      this.rota = transition;
    },
    apagaRota: function apagaRota() {
      this.rota = null;
    },
    recuperaRota: function recuperaRota() {
      return this.rota;
    }

  });

});
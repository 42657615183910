define('webapp/pods/aquisicao/ata/adesao/criar/route', ['exports', 'ember', 'webapp/pods/aquisicao/ata/adesao/model', 'webapp/mixins/suprimir-templates-superiores-mixin'], function (exports, Ember, AdesaoExterna, SuprimirTemplatesSuperioresMixin) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(SuprimirTemplatesSuperioresMixin['default'], {
    controllerName: 'aquisicao/ata/adesao',

    setupController: function setupController(controller) {
      controller.set('model', AdesaoExterna['default'].create({}));

      this.get('comunicador').leParaPropriedade(controller, 'orgao', 'orgaos', 'Não foi possível obter os Órgãos Externos!');
    },

    model: function model() {
      return AdesaoExterna['default'].create();
    }

  });

});
define('webapp/pods/aquisicao/compra/item/criar/route', ['exports', 'ember', 'webapp/pods/aquisicao/compra/item/model'], function (exports, Ember, Item) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({
    controllerName: 'aquisicao/compra/item',
    setupController: function setupController(controller) {
      controller.set('model', Item['default'].create());

      this.get('comunicador').leParaPropriedade(controller, 'classificacaoItem', 'classificacoesItem', "Não foi possível obter as Classificações Financeiras!");

      this.get('comunicador').leParaPropriedade(controller, 'unidadeMedida', 'unidadesDeMedida', "Não foi possível obter as Unidades de Medida!");

      this.get('comunicador').leParaPropriedade(controller, 'unidadeMedida/tempo', 'unidadesMedidaDuracao', "Não foi possível obter as Unidades de Medida de Duração!");
    },

    model: function model() {
      return Item['default'].create();
    },

    resetController: function resetController(controller, isExiting) {
      //reseta os queryParam tipoItem ao sair da rota
      if (isExiting) {
        controller.set('tipoItem', null);
      }
    }

  });

});
define('webapp/pods/aquisicao/ata/adesao/model', ['exports', 'ember', 'webapp/models/orgao'], function (exports, Ember, Orgao) {

	'use strict';

	exports['default'] = Ember['default'].Object.extend({
			id: null,
			data: '',
			orgao: Orgao['default'].create(),
			itens: [], //item de adesao

			findUrlFn: function findUrlFn(id) {
					return 'ata/adesaoExterna/' + id.toString();
			}
	});

});
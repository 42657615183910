define('webapp/pods/aquisicao/contrato/controller', ['exports', 'ember', 'webapp/mixins/crud-mixin', 'webapp/utils/util', 'npm:big.js'], function (exports, Ember, CrudMixin, Util, Big) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend(CrudMixin['default'], {

    needs: ['aquisicao'],

    aditivoOuApostila: '', // Serve para que no template e no controller de Aditivos seja possível
    // Admite os valores: 'Aditivo' ou 'Apostila'

    atribuiValoresDefault: function atribuiValoresDefault() {
      this.set('exibeModalRescisao', false);
      this.set('temReajuste', false);
      this.set('tiposFiscal', []);
      this.set('tiposGestor', []);
    },

    inicializaCampos: function inicializaCampos(model) {

      this.set('model', model);

      this.get('comunicador').leParaPropriedade(this, 'orgao', 'orgaos', 'Não foi possível obter os orgãos externos!');

      this.get('comunicador').leParaPropriedade(this, 'indiceReajuste', 'indicesReajuste', 'Não foi possível obter os índices de reajuste.');

      this.get('comunicador').leParaPropriedade(this, 'tipoFiscalGestor', 'tiposFiscalGestor', "Não foi possível obter os tipos de fiscal/gestor!");

      this.get('comunicador').leParaPropriedade(this, 'unidadeOrcamentaria/selecionavel/unidadeOrganizacionalCorrespondente', 'unidadesOrganizacionais', 'Não foi possível obter as unidades organizacionais.');

      this.get('comunicador').leParaPropriedade(this, 'contratoModalidade', 'modalidades', 'Não foi possível obter as modalidades de contrato.');

      //para período de divulgação índice reajuste
      this.set('meses', [{ id: 1, nome: 'Janeiro' }, { id: 2, nome: 'Fevereiro' }, { id: 3, nome: 'Março' }, { id: 4, nome: 'Abril' }, { id: 5, nome: 'Maio' }, { id: 6, nome: 'Junho' }, { id: 7, nome: 'Julho' }, { id: 8, nome: 'Agosto' }, { id: 9, nome: 'Setembro' }, { id: 10, nome: 'Outubro' }, { id: 11, nome: 'Novembro' }, { id: 12, nome: 'Dezembro' }]);
    },

    observaTiposFiscalGestor: (function () {
      var self = this;
      self.set('tiposFiscal', []);
      self.set('tiposGestor', []);
      this.get('tiposFiscalGestor').forEach(function (fiscalGestor) {
        if (fiscalGestor.tipo === 'F') self.get('tiposFiscal').push(fiscalGestor);else self.get('tiposGestor').push(fiscalGestor);
      });
    }).observes('tiposFiscalGestor'),

    observaModel: (function () {
      this.set('dados_aditivo.model', this.model.aditivos);
      this.set('dados_apostilamento.model', this.model.apostilas);
      if (this.contratoNovo()) {
        this.set('temReajuste', true);
      } else {
        this.set('temReajuste', this.verificaSeTemReajuste());
      };
    }).observes('model'),

    verificaSeTemReajuste: function verificaSeTemReajuste() {
      //    Ember.Logger.error('verificaSeTemReajuste: this.get("model.mesReajuste") = '+this.get("model.mesReajuste"));
      //    Ember.Logger.error('verificaSeTemReajuste: this.get("model.indiceReajuste") = '+this.get("model.indiceReajuste"));
      return this.temMesReajuste() || this.temIndiceReajuste();
    },

    temMesReajuste: function temMesReajuste() {
      return this.get("model.mesReajuste") != null && this.get("model.mesReajuste") > 0;
    },

    temIndiceReajuste: function temIndiceReajuste() {
      return this.get("model.indiceReajuste") != null && this.get("model.indiceReajuste.id") != null;
    },

    limpaCamposIndiceReajuste: (function () {
      if (this.model) {
        if (!this.temReajuste) {
          this.set("model.indiceReajuste", null);
          this.set("model.mesReajuste", null);
          //        Ember.Logger.error('limpaCamposIndiceReajuste: limpou');
        } else {
            //        Ember.Logger.error('limpaCamposIndiceReajuste: não limpou');
          }
      };
    }).observes('temReajuste'),

    atribuiTemReajusteAoModel: (function () {
      if (this.model) {
        this.set('model.temReajuste', this.temReajuste);
        //      Ember.Logger.error('atribuiTemReajusteAoModel: this.get("model.temReajuste") = ' + this.get('model.temReajuste'));
      };
    }).observes('temReajuste'),

    idAquisicao: (function () {
      return this.get('controllers.aquisicao').model.id;
    }).property('controllers.aquisicao.model.id'),

    breadCrumbConfig: {
      fnNomeApresentacao: 'fnNomeApresentacao'
    },

    fnNomeApresentacao: function fnNomeApresentacao() {
      return 'Contrato ' + (this.model.version ? 'Nº ' + this.model.numeroApresentacao : '(Novo)');
    },

    actions: {
      exibirModalRescisao: function exibirModalRescisao() {
        this.set("exibeModalRescisao", true);
      },
      cancelarModalRescisao: function cancelarModalRescisao() {
        this.set("exibeModalRescisao", false);
      },
      acaoAd: function acaoAd() {
        this.set("aditivoOuApostila", "Aditivo");
      },
      acaoAp: function acaoAp() {
        this.set("aditivoOuApostila", "Apostila");
      }
    },

    crudConfig: {
      criar: {
        urlFn: function urlFn() {
          return 'aquisicao/' + this.get("controllers.aquisicao").model.id + '/contrato';
        },
        okFn: function okFn(opcao) {
          this.get('controllers.aquisicao').send('ler');
          if (opcao === 'CRIAR_E_PROXIMO') {
            //ação "borbulhará" para a rota
            this.send('refreshRota');
            return;
          }
          this.transitionToRoute('aquisicao/contrato', this.model.id);
        }
      },
      atualizar: {
        okFn: function okFn() {
          this.get('controllers.aquisicao').send('ler');
        }
      },
      excluir: {
        okFn: function okFn() {
          this.get('controllers.aquisicao').send('ler');
        }
      }
    },

    contratoNovo: function contratoNovo() {
      return !this.model.version;
    },

    ehContratoNovo: (function () {
      return this.contratoNovo();
    }).property('model'),

    ehContratoRescindido: (function () {
      return this.fnEhContratoRescindido();
    }).property('model.rescisao.data'),

    ehContratoQueTemRescisaoFutura: (function () {
      var dtHojeSemHora = new Date().setHours(0, 0, 0, 0);
      return !!this.model.rescisao.data && this.model.rescisao.data !== '' && dtHojeSemHora < this.model.rescisao.data;
    }).property('model.rescisao.data'),

    ehContratoVigente: (function () {
      var dtHojeSemHora = new Date().setHours(0, 0, 0, 0);
      return !this.fnEhContratoRescindido() && !!this.model.dataInicioVigenciaAtualizada && this.model.dataInicioVigenciaAtualizada <= dtHojeSemHora && (!this.model.dataFimVigenciaAtualizada || this.model.dataFimVigenciaAtualizada >= dtHojeSemHora);
    }).property('model.rescisao.data', 'model.dataInicioVigencia', 'model.dataFimVigencia'),

    ehContratoEncerrado: (function () {
      var dtHojeSemHora = new Date().setHours(0, 0, 0, 0);
      return !this.fnEhContratoRescindido() && !!this.model.dataFimVigenciaAtualizada && this.model.dataFimVigenciaAtualizada < dtHojeSemHora;
    }).property('model.rescisao.data', 'model.dataInicioVigencia', 'model.dataFimVigencia'),

    fnEhContratoRescindido: function fnEhContratoRescindido() {
      var dtHojeSemHora = new Date().setHours(0, 0, 0, 0);
      return !!this.model.rescisao.data && this.model.rescisao.data !== '' && dtHojeSemHora >= this.model.rescisao.data;
    },

    prefixoCompra: (function () {
      var nroAquisicao = this.get('controllers.aquisicao').model.numeroApresentacao;
      return 'Aquisição ' + nroAquisicao + ' - Compra ';
    }).property('controllers.aquisicao.model.id'),

    //Identifica os fornecedores das compras e exclui as duplicadas
    contratados: (function () {
      var fornecedoresDasCompras = this.model.compras.map(function (compra) {
        return compra.fornecedor;
      });

      var fornecedoresSemRepeticao = [];
      fornecedoresDasCompras.forEach(function (fornecedorCandidato) {
        var fornecedorJahNaLista = fornecedoresSemRepeticao.find(function (fornecedorDaLista) {
          return fornecedorDaLista.id === fornecedorCandidato.id;
        });
        if (!fornecedorJahNaLista) {
          fornecedoresSemRepeticao.push(fornecedorCandidato);
        }
      });

      return fornecedoresSemRepeticao;
    }).property('model.compras.@each'),

    dataRescisaoFormatada: (function () {
      if (this.model.rescisao.data) {
        return Util['default'].formataData(this.model.rescisao.data);
      }
    }).property("model.rescisao.data"),

    comprasSemContrato: (function () {
      return this.get("controllers.aquisicao").model.compras.filter(function (compra) {
        return !compra.idContrato;
      });
    }).property('controllers.aquisicao.model.id'),

    tiposRescisao: [{ id: "AMIGAVEL", nome: "Amigável" }, { id: "UNILATERAL", nome: "Unilateral" }, { id: "JUDICIAL", nome: "Judicial" }],

    sugereCamposConformeAquisicao: (function () {
      var emailSugerido;
      if (!this.get('ehContratoNovo')) {
        return;
      }

      var aquisicao = this.get('controllers.aquisicao').model;
      this.set('model.objeto', aquisicao.objeto);
      this.set('model.unidadeRequisitante', aquisicao.unidadeRequisitante);
      this.set('model.numeroProcessoAdministrativo', aquisicao.numeroProcessoAdministrativo);

      if (Util['default'].compactaObjeto(aquisicao.fiscal)) {
        emailSugerido = aquisicao.emailFiscal ? aquisicao.emailFiscal : aquisicao.fiscal.email;
        this.get('model.fiscais').pushObject({ servidor: aquisicao.fiscal, tipo: aquisicao.tipoFiscal, emailSugerido: emailSugerido });
      }

      if (Util['default'].compactaObjeto(aquisicao.gestor)) {
        emailSugerido = aquisicao.emailGestor ? aquisicao.emailGestor : aquisicao.gestor.email;
        this.get('model.gestores').pushObject({ servidor: aquisicao.gestor, tipo: aquisicao.tipoGestor, emailSugerido: emailSugerido });
      }
    }).observes('model'),

    sugereValoresIniciais: (function () {
      if (!this.get('ehContratoNovo')) {
        return;
      }

      var somatorioUnitarioItensPeriodicos = 0;
      var somatorioUnitarioItensNaoPeriodicos = 0;
      var somatorioTotalCompras = 0;

      this.model.compras.forEach(function (compra) {
        compra.itens.forEach(function (item) {
          if (item.motivoCriacao !== 'ORIGINAL') {
            return;
          }

          var valorUnitarioItem = Util['default'].parseFloatStringPtBR(item.valorUnitario);
          var quantidadeItem = Util['default'].parseFloatStringPtBR(item.quantidade);
          var valorTotalItem = Util['default'].parseFloatStringPtBR(item.valorTotal);

          if (item.itemAquisicao.periodico) {
            var valorItemPeriodico = Number.parseFloat(new Big['default'](valorUnitarioItem).times(new Big['default'](quantidadeItem)));
            //somatorioUnitarioItensPeriodicos += valorItemPeriodico
            somatorioUnitarioItensPeriodicos = Number.parseFloat(new Big['default'](somatorioUnitarioItensPeriodicos).plus(new Big['default'](valorItemPeriodico)));
            //somatorioTotalCompras += valorTotalItem
            somatorioTotalCompras = Number.parseFloat(new Big['default'](somatorioTotalCompras).plus(new Big['default'](valorTotalItem)));
          } else {
            //somatorioUnitarioItensNaoPeriodicos += valorTotalItem
            somatorioUnitarioItensNaoPeriodicos = Number.parseFloat(new Big['default'](somatorioUnitarioItensNaoPeriodicos).plus(new Big['default'](valorTotalItem)));
            //somatorioTotalCompras += valorTotalItem
            somatorioTotalCompras = Number.parseFloat(new Big['default'](somatorioTotalCompras).plus(new Big['default'](valorTotalItem)));
          }
        });
      });

      //Arredondamentos
      somatorioUnitarioItensPeriodicos = Number.parseFloat(new Big['default'](somatorioUnitarioItensPeriodicos).round(3));
      somatorioUnitarioItensNaoPeriodicos = Number.parseFloat(new Big['default'](somatorioUnitarioItensNaoPeriodicos).round(3));
      somatorioTotalCompras = Number.parseFloat(new Big['default'](somatorioTotalCompras).round(3));

      this.set('model.valorInicialMensal', Util['default'].stringNumericaUSAparaBR(somatorioUnitarioItensPeriodicos.toString()));
      this.set('model.valorInicialUnico', Util['default'].stringNumericaUSAparaBR(somatorioUnitarioItensNaoPeriodicos.toString()));
      this.set('model.valorInicialTotal', Util['default'].stringNumericaUSAparaBR(somatorioTotalCompras.toString()));
    }).observes('ehContratoNovo', 'model.compras.@each'),

    descricaoRelatorios: (function () {
      return [{
        textoMenu: 'Extrato de Contrato',
        servico: 'relatorio/extratoDeContrato/' + this.get("model.id"),
        nomeArquivo: 'contrato_extrato_contrato',
        nomeParaID: 'extrato-contrato',
        controlePermissao: true
      }, {
        textoMenu: 'Relação de Aditivos',
        servico: 'relatorio/relacaoDeAditivos/' + this.get("model.id"),
        nomeArquivo: 'contrato_relacao_aditivos',
        nomeParaID: 'relacao-aditivos',
        controlePermissao: true
      }];
    }).property("model.id"),

    dados_aditivo: {
      idTabelaFormLeve_AditivoListagem: 'tabela|listar||aquisicao/contrato/aditivo|apresentacao-contrato',
      idBotaoGeral_AditivoListagem: 'botao|adicionar||aquisicao/contrato/aditivo|apresentacao-contrato',
      idLinkTo_AditivoListagem: "link||aditivo-criar|aquisicao/contrato|aditivos",
      model: null
    },

    dados_apostilamento: {
      idTabelaFormLeve_AditivoListagem: 'tabela|listar||aquisicao/contrato/apostilamento-reajuste|apresentacao-contrato',
      idBotaoGeral_AditivoListagem: 'botao|adicionar||aquisicao/contrato/apostilamento-reajuste|apresentacao-contrato',
      idLinkTo_AditivoListagem: 'link||apostilamento-criar|aquisicao/contrato|apostilamentos',
      model: null
    }

  });

});
define('webapp/initializers/json-parse-date', ['exports'], function (exports) {

  'use strict';

  /* global moment */
  //adiciona suporte à date na comunicação com o servidor
  exports['default'] = {
    name: 'AdicionaDateNoJSONParser',
    initialize: function initialize() {

      var dateParser = function dateParser(key, value) {
        var reISO = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*))(?:Z|(\+|-)([\d|:]*))?$/;
        var reMsAjax = /^\/Date\((d|-|.*)\)[\/|\\]$/;

        if (typeof value === 'string') {
          var a = reISO.exec(value);
          if (a) {
            return new Date(value);
          }
          a = reMsAjax.exec(value);
          if (a) {
            var b = a[1].split(/[-+,.]/);
            return new Date(b[0] ? +b[0] : 0 - +b[1]);
          }
        }
        return value;
      };

      var origParse = JSON.parse;
      JSON.parse = function (obj) {
        return origParse(obj, dateParser);
      };
    }

  };

  // 2016-10-18T00:00:00.000-0300
  Date.prototype.toJSON = function () {
    if (!moment) {
      return this;
    }
    var dataSemTZ = moment(this).format("YYYY-MM-DDTHH:mm:ss.SSS");
    var TZ = moment(this).format("Z");
    TZ = TZ.replace(":", "");

    return dataSemTZ + TZ;
  };

});
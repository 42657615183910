define('webapp/pods/aquisicao/contrato/simulacao/controller', ['exports', 'ember', 'webapp/utils/util'], function (exports, Ember, Util) {

	'use strict';

	exports['default'] = Ember['default'].ArrayController.extend({

		needs: ['aquisicao/contrato'],

		dataReferencia: '',
		situacaoSimuladaContrato: null,

		sugerirDataRefererncia: (function () {
			this.set('dataReferencia', new Date());
		}).on('init'),

		actions: {
			simular: function simular() {
				var _this = this;

				var dataReferencia = this.dataReferencia;
				this.set('situacaoSimuladaContrato', null);
				this.get('comunicador').servico('post', 'aquisicao/contrato/' + this.get('controllers.aquisicao/contrato').model.id + '/simulacao', { data: dataReferencia }).then(function (contrato) {
					_this.set('situacaoSimuladaContrato', contrato);
					Util['default'].alerta('Simulação realizada!');
				});
			}
		}
	});

});
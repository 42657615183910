define('webapp/components/tag-span', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    /* global $ */
    /*
     Exemplo de uso:
    {{tag-treeview
            fonte=operacoesDisponiveisTreeView
            controllerReferencia=this
            metodoObservadorSelecao="refazSelecao"
            atributoId="id"
            atributoTexto="texto"
            atributoValor="valor"
            atributoIdPai="idPai"
            atributoSelecionado="selecionado"
    }}

     // *** PARAMETROS ***
    - fonte: de onde vêm os dados
    - controllerReferencia: controller de referência para a chamada do método de observação
    - metodoObservadorSelecao: método do controller que será disparado no momento em que houver alteração na seleção da treeview
    - atributoId: atributo da fonte que serve como identificador do item da treeview. Padrão: "id"
    - atributoTexto: atributo da fonte que armazena o texto do item da treeview. Padrão: "texto"
    - atributoValor: atributo da fonte que indica o valor/objeto retornado quando o item da treeview estiver selecionado. Padrão: "valor"
                                                                (se não existir esse atributo na fonte, utiliza o próprio item da fonte)
    - atributoIdPai: atributo da fonte que serve como identificador do pai do item da treeview. Padrão: "idPai"
    - atributoSelecionado: atributo da fonte que indica se o item deve vir selecionado por padrão na renderização da treeview. Padrão: "selecionado"

    */

    exports['default'] = Ember['default'].Component.extend({
        tagName: 'span',

        cria: (function () {
            var campo = this.$()[0];

            var destaque = this.get('destacar');
            var funcaoDestacar = this.get('funcaoDestacar');

            if (funcaoDestacar) {
                var controller = this.get('controllerReferencia');
                destaque = controller[funcaoDestacar](this.get('parametroFuncaoDestacar'));
            }

            if (destaque) {
                if (campo.innerHTML.indexOf(destaque) >= 0) {
                    campo.innerHTML = campo.innerHTML.replace(destaque, "<span style='background:#F0E68C'>" + destaque + "</span>");
                }
            }
        }).on('didInsertElement'),

        getTexto: (function () {
            var texto = this.get('texto');
            var funcaoTexto = this.get('funcaoTexto');

            if (funcaoTexto) {
                var controller = this.get('controllerReferencia');
                texto = controller[funcaoTexto](this.get('parametroFuncaoTexto'));
            }

            return texto;
        }).property()

    });

});
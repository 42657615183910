define('webapp/models/procurador', ['exports', 'ember', 'webapp/models/endereco', 'webapp/models/contato'], function (exports, Ember, Endereco, Contato) {

	'use strict';

	exports['default'] = Ember['default'].Object.extend({
		id: null,
		endereco: Endereco['default'].create({}),
		contato: Contato['default'].create({}),
		poderes: '', //"g" = "genérico", "e" = "específicos" - valor default é "g"
		processoProcuracao: '',
		paginaProcuracao: '',
		linkProcuracao: '',
		observacao: '',

		inicializar: (function () {
			this.set('endereco', Endereco['default'].create());
			this.set('contato', Contato['default'].create());
		}).on('init')
	});

});
define('webapp/pods/requisicao/item/orcamento/criar/route', ['exports', 'ember', 'webapp/pods/requisicao/item/orcamento/model'], function (exports, Ember, Orcamento) {

	'use strict';

	exports['default'] = Ember['default'].Route.extend({
		controllerName: 'requisicao/item/orcamento',
		setupController: function setupController(controller) {
			controller.set('model', Orcamento['default'].create({}));
		},

		model: function model() {
			return Orcamento['default'].create({});
		}
	});

});
define('webapp/pods/aquisicao/contrato/garantia/criar/route', ['exports', 'ember', 'webapp/pods/aquisicao/contrato/garantia/model'], function (exports, Ember, Garantia) {

	'use strict';

	exports['default'] = Ember['default'].Route.extend({
		controllerName: 'aquisicao/contrato/garantia',
		setupController: function setupController(controller) {
			controller.set('model', Garantia['default'].create({}));
		},

		model: function model() {
			return Garantia['default'].create();
		}
	});

});
define('webapp/components/moeda-input', ['exports', 'ember', 'webapp/mixins/ajusta-id-interno-mixin', 'webapp/mixins/ajusta-tamanho-input-mixin', 'webapp/mixins/constroi-aria-label-mixin', 'webapp/utils/util'], function (exports, Ember, AjustaIdInternoMixin, AjustaTamanhoInputMixin, ConstroiAriaLabelMixin, Util) {

  'use strict';

  /*
  Exemplo de uso:
  {{modeda-input
  	 texto=<texto do label>
     labelAcessivel=<texto para ser lido por leitores automáticos de interface
  								    para acessibilidade por pessoas com deficiência visual.
  								    Se não for definido, será lido o texto do label do campo>
  	 valor=<valor associado ao input>
     tamanho=<largura do componente em termos do bootstrap - um número X
              de 1 a 12 utilizado para montar a classe CSS "col-md-X".
              O default é 12>
  	 classeLabel=<classe do label, default é "col-md-2 control-label">
  	 classeElementoInterno=<classe do input, default é "col-md-10">
  	 somenteLeitura=<true ou false, sem aspas>
  	 desabilitado=<true | false (default), indicando se o componente estará desabilitado>
     obrigatorio=<true ou false, sem aspas, indicando se o campo deve apresentar um asterisco
                  ao lado do seu label>
  }}

  Todo moeda-input com id  "tag_W_X_Y_Z"
  cria um componente de id "campo_W_X_Y_Z"

  O funcionamento de "tamanho", "tamanhoLabel" e "tamanhoCampo" é dependente do ajusta-tamanho-input-mixin.

  "valor" => valor efetivo do modelo em que o componente está pendurado
  "valorIntermediario" => valor baseado no valor do modelo, que será apresentado pelo componente
  */

  exports['default'] = Ember['default'].Component.extend(AjustaIdInternoMixin['default'], AjustaTamanhoInputMixin['default'], ConstroiAriaLabelMixin['default'], {
    desabilitado: false,
    somenteLeitura: false,
    valor: '', //string numérica em pt-br
    valorIntermediario: '', //número em en-us

    tipoInterno: 'campo',

    //No início, ajusta-se o valor intermediário conforme o valor de fato do modelo.
    //Caso o valor do modelo seja vazio ou represente zero, o valor intermediário
    //deve se tornar "0,00", caso contrário ele não será apresentado na tela (o input
    //do componente ficará simplesmente vazio)
    inicializaValores: (function () {
      if (!this.valor || this.valor === '0,00' || this.valor === '0,0' || this.valor === '0') {
        this.set('valorIntermediario', '0,00');
      } else {
        this.atualizaValorIntermediario();
      }
    }).on('init'),

    insereAriaLabel: (function () {
      var campo = this.$('input')[0];
      campo.setAttribute('aria-label', this.get('_ariaLabel'));
    }).on('willInsertElement'),

    atualizaValorIntermediario: (function () {
      if (this._valoresEquivalentes(this.valor, this.valorIntermediario)) {
        return;
      }
      this.set('valorIntermediario', Util['default'].parseFloatStringPtBR(this.valor));
    }).observes('valor'),

    atualizaValor: (function () {
      if (this._valoresEquivalentes(this.valor, this.valorIntermediario)) {
        return;
      }
      this.set('valor', this._converteParaStringBR(this.valorIntermediario));
    }).observes('valorIntermediario'),

    _valoresEquivalentes: function _valoresEquivalentes(br, usa) {
      if (!br && !usa) {
        return true;
      }
      if ((br === '0,00' || br === '0,0' || br === '0') && usa === 0) {
        return true;
      }
      if (br && !usa || !br && usa) {
        return false;
      }

      var strBR = br ? br.toString() : '';
      var strUSA = usa ? usa.toString() : '';
      return Util['default'].parseFloatStringPtBR(strBR) === parseFloat(strUSA);
    },

    _converteParaStringBR: function _converteParaStringBR(valorUSA) {
      var strUSA = valorUSA.toString();
      return Util['default'].stringNumericaUSAparaBR(strUSA);
    }

  });
  // observaValorTeste: function() {
  // 	console.log('VALOR: ' + this.valor);
  // }.observes('valor'),
  //
  // input: function() {
  // 	console.log('INPUT: ' + this.valor);
  // },
  //
  // change: function() {
  // 	console.log('CHANGE: ' + this.valor);
  // 	this.set('valor', this.valor.toString().replace(/,/g, '#').replace(/\./g, ',').replace(/#/g, '.'));
  // 	console.log('DEPOIS CHANGE: ' + this.valor);
  // },

  // focoNoInput: false,
  //
  // focusIn: function() {
  //   this.set('focoNoInput', true);
  // },
  //
  // //Ajusta o valor para apresentar duas casas decimais após alteração externa da variável
  // //associada ao input (ex. um input cujo valor é calculado a partir dos valores de outros campos).
  // //Ajuste não ocorre se se estiver digitando no campo (focus no input).
  // _ajustaAposAlteracaoExterna: function() {
  //   if(this.focoNoInput) { return; }
  //   var valorAjustado = this._completaCentavosValorMonetarioComZeros(this.valor);
  //   this._setValorEfetivoCampo(valorAjustado);
  //   this.set('valor', valorAjustado);
  // }.observes('valor'),
  //
  // //Ajusta o valor para apresentar duas casas decimais após alterações manuais do usuário (ex. digitando)
  // focusOut: function() {
  //   this.set('valor', this._completaCentavosValorMonetarioComZeros(this._getValorEfetivoCampo()));
  //   //this._setValorEfetivoCampo(this.valor);
  //   this.set('focoNoInput', false);
  // },
  //
  // //Recebe um valor monetário e retorna uma string com o mesmo valor, completando
  // //a parte dos centavos com zeros de forma que o valor sempre fique [0-9]+,[0-9]{2}.
  // //Ex.:
  // //-> 5 vira 5,00; (se não tem vírgula ganha ",00")
  // //-> 5, vira 5,00; (se tem vírgula mas não centavos ganha "00" no final)
  // //-> 5,3 vira 5,30; (se tem só uma casa depois da vírgula, ganha "0" final)
  // //-> ,1 ou ,10 vira 0,10 (se não tem casa antes da vírgula, ganha "0" no início)
  // //
  // //Não aceita valores não-numéricos
  // _completaCentavosValorMonetarioComZeros: function(valor) {
  //   if(valor !== 0 && !valor) {
  //     return; //se o valor for falsy e não for zero, não precisa completar
  //   }
  //
  //   valor = valor.toString(); //se já for string, esta operação não terá efeito
  //
  //   var temCaracterNaoNumerico = '[^0-9.,]';
  //   var naoTemVirgula = '^[^,]*$';
  //   var naoTemParteDecimal = '.*,$';
  //   var temApenasUmaCasaDecimal = '.*,[0-9]{1}$';
  //   var naoTemParteInteira = '^,.*';
  //
  //   if(valor.match(temCaracterNaoNumerico)) {
  //     return '';
  //   }
  //   if(valor.match(naoTemVirgula)) {
  //     return valor + ',00';
  //   }
  //   if(valor.match(naoTemParteDecimal)) {
  //     return valor + '00';
  //   }
  //   if(valor.match(temApenasUmaCasaDecimal)) {
  //     return valor + '0';
  //   }
  //   if(valor.match(naoTemParteInteira)) {
  //     return '0' + valor;
  //   }
  //   return valor;
  // },
  //
  // _getValorEfetivoCampo: function() {
  //   return this._getCampo().value;
  // },
  //
  // _setValorEfetivoCampo: function(valor) {
  //   this._getCampo().value = valor;
  // },
  //
  // //retorna o campo (input type=text) do componente
  // _getCampo: function() {
  //   /*var divDoCampo;
  //
  //   if(this.$().children().length > 1) {
  //     //Caso a apresentação do moeda-input seja um div com label e input
  //     divDoCampo = this.$().children().last();
  //   } else {
  //     //Caso a apresentação seja apenas o input
  //     divDoCampo = this.$();
  //   }
  //
  //   return divDoCampo.children()[0];*/
  //   return this.$("input")[0];
  // }

});
define('webapp/components/tag-treeview', ['exports', 'ember', 'webapp/mixins/ajusta-id-interno-mixin', 'webapp/mixins/ajusta-tamanho-input-mixin'], function (exports, Ember, AjustaIdInternoMixin, AjustaTamanhoInputMixin) {

    'use strict';

    /* global $ */
    /*
     Exemplo de uso:
    {{tag-treeview
            fonte=operacoesDisponiveisTreeView
            controllerReferencia=this
            metodoObservadorSelecao="refazSelecao"
            atributoId="id"
            atributoTexto="texto"
            atributoValor="valor"
            atributoIdPai="idPai"
            atributoSelecionado="selecionado"
    }}

     // *** PARAMETROS ***
    - fonte: de onde vêm os dados
    - controllerReferencia: controller de referência para a chamada do método de observação
    - metodoObservadorSelecao: método do controller que será disparado no momento em que houver alteração na seleção da treeview
    - atributoId: atributo da fonte que serve como identificador do item da treeview. Padrão: "id"
    - atributoTexto: atributo da fonte que armazena o texto do item da treeview. Padrão: "texto"
    - atributoValor: atributo da fonte que indica o valor/objeto retornado quando o item da treeview estiver selecionado. Padrão: "valor"
                                                                (se não existir esse atributo na fonte, utiliza o próprio item da fonte)
    - atributoIdPai: atributo da fonte que serve como identificador do pai do item da treeview. Padrão: "idPai"
    - atributoSelecionado: atributo da fonte que indica se o item deve vir selecionado por padrão na renderização da treeview. Padrão: "selecionado"

    */

    exports['default'] = Ember['default'].Component.extend(AjustaIdInternoMixin['default'], AjustaTamanhoInputMixin['default'], {
        contexto: "default",
        atributoId: "id",
        atributoTexto: "texto",
        atributoValor: "valor",
        atributoIdPai: "idPai",
        atributoSelecionado: "selecionado",

        idTreeView: (function () {
            return this.contexto + "-jstree";
        }).property('contexto'),

        criaTreeView: (function () {
            var _this = this;
            var divTreeView = $('#' + this.get("idTreeView"));

            // limpa o conteúdo da div
            divTreeView.jstree("destroy");

            // Cria a treeview
            divTreeView.jstree({
                'core': {
                    'data': this.jsonTreeview()
                },
                "plugins": ["wholerow", "checkbox"]
            });

            // adiciona evento ao alterar
            divTreeView.on("changed.jstree", function (e, data) {
                var eventoAlteracao = _this.get("metodoObservadorSelecao");
                var controller = _this.get("controllerReferencia");
                if (data && data.node && eventoAlteracao && controller) {
                    var idsSelecionadosSemPrefixo = [];
                    var prefixoIds = _this.get("idTreeView") + "-";
                    data.selected.forEach(function (item) {
                        idsSelecionadosSemPrefixo.push(item.replace(prefixoIds, ""));
                    });
                    controller[eventoAlteracao](data.action, data.node.data, idsSelecionadosSemPrefixo);
                }
            });
        }).on('didInsertElement').observes("fonte", "fonte.@each"),

        jsonTreeview: function jsonTreeview() {
            var retorno = [];
            var prefixoIds = this.get("idTreeView") + "-";
            var fonteTreeview = $(this.get("fonte"));

            var _this = this;
            fonteTreeview.each(function () {
                var objeto = {};
                objeto.id = prefixoIds + this[_this.atributoId];
                objeto.text = this[_this.atributoTexto];
                objeto.data = this[_this.atributoValor] ? this[_this.atributoValor] : this;

                if (this[_this.atributoIdPai]) {
                    objeto.parent = prefixoIds + this[_this.atributoIdPai];
                } else {
                    objeto.parent = "#";
                }

                if (this[_this.atributoSelecionado]) {
                    objeto.state = { selected: this[_this.atributoSelecionado] };
                }

                retorno.push(objeto);
            });

            return retorno;
        }

    });

});
define('webapp/pods/publico/licitacoes/route', ['exports', 'ember', 'webapp/utils/util', 'webapp/pods/publico/constantes-transparencia'], function (exports, Ember, Util, ConstantesTransparencia) {

    'use strict';

    exports['default'] = Ember['default'].Route.extend({
        setupController: function setupController(controller) {
            // Configura informações da tela
            this.get('comunicador').servico('get', 'parametroExibicao').then(function (resultado) {
                controller.set('infoTela', Util['default'].converteParametrosDeExibicao(resultado).TRANSP_INFO_LICITACAO);
                controller.set('exibeCabecalho', Util['default'].converteParametrosDeExibicao(resultado).TRANSP_EXIBE_CABECALHOS === "S");
                controller.set('urlLogo', Util['default'].converteParametrosDeExibicao(resultado).TRANSP_URL_LOGO);
                controller.set('retroagir', Util['default'].converteParametrosDeExibicao(resultado).TRANSP_QTDE_ANOS_RETROAGIR);
            });

            // Parâmetros de URL
            var queryParams = controller.get('queryParams');
            var temFiltroQuery = false;
            queryParams.forEach(function (param) {
                if (controller.get(param)) {
                    temFiltroQuery = true;
                }
            });
            // seta filtro padrão, caso não tenha sido filtrado pela query string
            if (!temFiltroQuery) {
                controller.set('status', 'ABERTA');
            }

            controller.send('listar');
        }
    });

});
define('webapp/pods/fornecedor/penalidade/route', ['exports', 'ember', 'webapp/utils/util'], function (exports, Ember, Util) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({
    //sempre vem do controller fornecedor.
    setupController: function setupController(controller, model) {
      controller.set('model', Util['default'].clona(model));

      controller.set('tiposPenalidadeSelecionados', model.tipos.map(function (tipo) {
        return {
          id: tipo.tipo,
          nome: controller.hashIdTipoPenalidadeVsNome[tipo.tipo]
        };
      }));

      this.get('comunicador').leParaPropriedade(controller, 'fundamentoLegal/penalidade', 'fundamentosPenalidade', "Não foi possível obter os fundamentos legais de penalidade!");
    }
  });

});
define('webapp/pods/fornecedor/controller', ['exports', 'ember', 'webapp/mixins/crud-mixin', 'webapp/utils/util'], function (exports, Ember, CrudMixin, Util) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend(CrudMixin['default'], {

    needs: ['fornecedor/listagem'],

    breadCrumbConfig: {
      fnNomeApresentacao: 'fnNomeApresentacao'
    },

    fnNomeApresentacao: function fnNomeApresentacao() {
      return 'Fornecedor ' + (this.model.version ? 'Nº ' + this.model.id : '(Novo)');
    },

    crudConfig: {
      criar: {
        okFn: function okFn(opcao) {
          if (opcao === 'CRIAR_E_PROXIMO') {
            //ação "borbulhará" para a rota
            this.send('refreshRota');
            return;
          }
          this.transitionToRoute('fornecedor', this.model.id);
        }
      },
      excluir: {
        okFn: function okFn() {
          this.get('controllers.fornecedor/listagem').send('pesquisar');
        }
      }
    },

    tituloTela: (function () {
      var nomeFornecedor = this.model.nome;
      var cpfOuCnpj = this.mostraCnpjOuCpf(this.model);
      return 'Fornecedor: ' + nomeFornecedor + ' - ' + cpfOuCnpj;
    }).property('model'),

    existemPenalidadesImpeditivasVigentes: (function () {
      var _this = this;

      return this.model.penalidades.find(function (penalidade) {
        return _this._ehPenalidadeImpeditiva(penalidade) && _this._ehPenalidadeVigente(penalidade);
      });
    }).property('model'),

    _ehPenalidadeImpeditiva: function _ehPenalidadeImpeditiva(penalidade) {
      return penalidade.tipos.find(function (tipoPenalidade) {
        return tipoPenalidade.tipo === 'DESCREDENCIAMENTO_SICAF' || tipoPenalidade.tipo === 'SUSPENSAO_TEMPORARIA_IMPEDIMENTO' || tipoPenalidade.tipo === 'IMPEDIMENTO_LEI_PREGAO';
      });
    },

    _ehPenalidadeVigente: function _ehPenalidadeVigente(penalidade) {
      //data atual com o horário zerado, assim como são as datas das provas de regularidade
      var dataAtualHorarioZero = new Date().setHours(0, 0, 0, 0);
      return (!penalidade.dataInicio || penalidade.dataInicio <= dataAtualHorarioZero) && (!penalidade.dataFim || penalidade.dataFim >= dataAtualHorarioZero);
    },

    desabilitarCBO: (function () {
      return this.model.cpfOuCnpj !== 'CPF';
    }).property('model.cpfOuCnpj'),

    desabilitarCNPJ: (function () {
      return this.model.cpfOuCnpj !== 'CNPJ';
    }).property('model.cpfOuCnpj'),

    cnpjObrigatorio: (function () {
      return !this.get("desabilitarCNPJ");
    }).property('desabilitarCNPJ'),

    desabilitarCPF: (function () {
      return this.model.cpfOuCnpj !== 'CPF';
    }).property('model.cpfOuCnpj'),

    cpfObrigatorio: (function () {
      return !this.get("desabilitarCPF");
    }).property('desabilitarCPF'),

    limpaIdentificadorNaoSelecionado: (function () {
      if (this.model.cpfOuCnpj === 'CPF') {
        this.set('model.cnpj', '');
      } else {
        this.set('model.cpf', '');
      }
    }).observes('model.cpfOuCnpj'),

    //No caso do tipo ser Atestado de Capacidade Técnica, o tipo é considerado como
    //Atestado de Capacidade Técnica E a classificação do produto do atestado.
    provasRegularidadeMaisRecentesPorTipo: (function () {
      var _this2 = this;

      var hashTipoVsProva = {};
      this.model.provasRegularidade.forEach(function (provaAtual) {
        var tipoAtual = provaAtual.tipo;

        // if(tipoAtual === 'ATESTADO_CAPACIDADE_TECNICA') {
        //   tipoAtual += provaAtual.classificacaoProduto.id;
        // }

        var provaNoHash = hashTipoVsProva[tipoAtual];
        if (provaNoHash) {
          hashTipoVsProva[tipoAtual] = _this2._escolheProvaRegularidadeMaisRecente(provaAtual, provaNoHash);
        } else {
          hashTipoVsProva[tipoAtual] = provaAtual;
        }
      });
      return Object.values(hashTipoVsProva);
    }).property('model.provasRegularidade'),

    //RETORNA A PROVA DE REGULARIDADE MAIS RECENTE
    //Critérios para julgar como mais recente
    //1º - Se ambas não tiverem data de expiração, prova com data emissão maior é a mais recente
    //2º - Prova que não tem data de expiração é mais recente que outra que tem
    //3º - Prova com data de expiração maior é mais recente
    //4º - Arbitrado: em caso de empate, sempre a prova2 é selecionada
    _escolheProvaRegularidadeMaisRecente: function _escolheProvaRegularidadeMaisRecente(prova1, prova2) {
      if (!prova1.dataExpiracao && !prova2.dataExpiracao) {
        if (prova1.dataEmissao > prova2.dataEmissao) {
          return prova1;
        }
      }
      if (!prova1.dataExpiracao && prova2.dataExpiracao) {
        return prova1;
      }
      if (prova1.dataExpiracao && !prova2.dataExpiracao) {
        return prova2;
      }
      if (prova1.dataExpiracao > prova2.dataExpiracao) {
        return prova1;
      }
      return prova2;
    },

    mostraCnpjOuCpf: function mostraCnpjOuCpf(fornecedor) {
      if (fornecedor.cnpj) {
        return Util['default'].incluiPontuacaoCnpj(fornecedor.cnpj);
      }
      if (fornecedor.cpf) {
        return Util['default'].incluiPontuacaoCpf(fornecedor.cpf);
      }
    },

    descricaoRelatorios: (function () {

      return [{
        textoMenu: 'Ficha Cadastral',
        servico: 'relatorio/fichaCadastral/' + this.get("model.id"),
        nomeArquivo: 'fornecedor_ficha_cadastral',
        nomeParaID: 'ficha-cadastral',
        controlePermissao: true //
      }, {
        textoMenu: 'Relação de Fornecimentos',
        servico: 'relatorio/relacaoDeFornecimentos/' + this.get("model.id"),
        nomeArquivo: 'relacao_de_fornecimento',
        nomeParaID: 'relacao-fornecimento',
        controlePermissao: true
      }, {
        textoMenu: 'Certificado de Registro Cadastral',
        servico: 'relatorio/certificadoDeRegistroCadastral/' + this.get("model.id"),
        nomeArquivo: 'fornecedor_certificado_de_registro_cadastral',
        nomeParaID: 'certificado-registro-cadastral',
        controlePermissao: true
      }, {
        textoMenu: 'Penalidades',
        servico: 'relatorio/penalidadesPorFornecedor/' + this.get("model.id"),
        nomeArquivo: 'fornecedor_penalidade_por_fornecedor',
        nomeParaID: 'penalidades-fornecedor',
        controlePermissao: true
      }];
    }).property('model.id')

  });

});
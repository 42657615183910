define('webapp/routes/bad-url', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({
    usuarioLogado: Ember['default'].inject.service('usuario-logado'),

    setupController: function setupController() {
      this.transitionTo('index');
    }
  });

});
define('webapp/pods/aquisicao/ata/controller', ['exports', 'ember', 'webapp/mixins/crud-mixin'], function (exports, Ember, CrudMixin) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend(CrudMixin['default'], {

    needs: ['aquisicao'],

    breadCrumbConfig: {
      fnNomeApresentacao: 'fnNomeApresentacao'
    },

    fnNomeApresentacao: function fnNomeApresentacao() {
      return 'Ata de Registro de Preços ' + (this.model.version ? this.model.numeroApresentacao : '(Nova)');
    },

    crudConfig: {
      criar: {
        urlFn: function urlFn() {
          return 'aquisicao/' + this.get("controllers.aquisicao").model.id + '/ata';
        },
        okFn: function okFn(opcao) {
          this.get('controllers.aquisicao').send('ler');
          if (opcao === 'CRIAR_E_PROXIMO') {
            //ação "borbulhará" para a rota
            this.send('refreshRota');
            return;
          }
          this.transitionToRoute('aquisicao/ata', this.model.id);
        }
      },
      atualizar: {
        okFn: function okFn() {
          this.get('controllers.aquisicao').send('ler');
        }
      },
      excluir: {
        okFn: function okFn() {
          this.get('controllers.aquisicao').send('ler');
        }
      }
    },

    ehAtaEmParticipacao: (function () {
      return this.get('controllers.aquisicao').model.modalidade === "PARTICIPACAO_REGISTRO_PRECOS";
    }).property('controllers.aquisicao.model'),

    ehAtaExistente: (function () {
      return this.model.id;
    }).property('model.id')

  });

});
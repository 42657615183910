define('webapp/pods/fornecedor/identificacao/template', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      var child0 = (function() {
        return {
          isHTMLBars: true,
          revision: "Ember@1.12.1",
          blockParams: 0,
          cachedFragment: null,
          hasRendered: false,
          build: function build(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("b");
            var el2 = dom.createTextNode("ATENÇÃO:");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode(" Este fornecedor possui uma ou mais ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("b");
            var el2 = dom.createTextNode("penalidades vigentes");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode(" que impedem sua contratação.\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          render: function render(context, env, contextualElement) {
            var dom = env.dom;
            dom.detectNamespace(contextualElement);
            var fragment;
            if (env.useFragmentCache && dom.canClone) {
              if (this.cachedFragment === null) {
                fragment = this.build(dom);
                if (this.hasRendered) {
                  this.cachedFragment = fragment;
                } else {
                  this.hasRendered = true;
                }
              }
              if (this.cachedFragment) {
                fragment = dom.cloneNode(this.cachedFragment, true);
              }
            } else {
              fragment = this.build(dom);
            }
            return fragment;
          }
        };
      }());
      return {
        isHTMLBars: true,
        revision: "Ember@1.12.1",
        blockParams: 0,
        cachedFragment: null,
        hasRendered: false,
        build: function build(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        render: function render(context, env, contextualElement) {
          var dom = env.dom;
          var hooks = env.hooks, block = hooks.block;
          dom.detectNamespace(contextualElement);
          var fragment;
          if (env.useFragmentCache && dom.canClone) {
            if (this.cachedFragment === null) {
              fragment = this.build(dom);
              if (this.hasRendered) {
                this.cachedFragment = fragment;
              } else {
                this.hasRendered = true;
              }
            }
            if (this.cachedFragment) {
              fragment = dom.cloneNode(this.cachedFragment, true);
            }
          } else {
            fragment = this.build(dom);
          }
          var morph0 = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, null);
          dom.insertBoundary(fragment, 0);
          block(env, morph0, context, "painel-msg", [], {"discreto": true}, child0, null);
          return fragment;
        }
      };
    }());
    var child1 = (function() {
      return {
        isHTMLBars: true,
        revision: "Ember@1.12.1",
        blockParams: 0,
        cachedFragment: null,
        hasRendered: false,
        build: function build(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("	");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","panel-body");
          var el2 = dom.createTextNode("\n		");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","row");
          var el3 = dom.createTextNode("\n			");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n		");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n		");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","row");
          var el3 = dom.createTextNode("\n			");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n		");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n		");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","row");
          var el3 = dom.createTextNode("\n			");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n		");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n		");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","row");
          var el3 = dom.createTextNode("\n\n			");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n\n			");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n		");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n		");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","row");
          var el3 = dom.createTextNode("\n			");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n\n			");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n		");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n		");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","row");
          var el3 = dom.createTextNode("\n			");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n\n			");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n		");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n		");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","row");
          var el3 = dom.createTextNode("\n			");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n		");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n	");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        render: function render(context, env, contextualElement) {
          var dom = env.dom;
          var hooks = env.hooks, get = hooks.get, inline = hooks.inline;
          dom.detectNamespace(contextualElement);
          var fragment;
          if (env.useFragmentCache && dom.canClone) {
            if (this.cachedFragment === null) {
              fragment = this.build(dom);
              if (this.hasRendered) {
                this.cachedFragment = fragment;
              } else {
                this.hasRendered = true;
              }
            }
            if (this.cachedFragment) {
              fragment = dom.cloneNode(this.cachedFragment, true);
            }
          } else {
            fragment = this.build(dom);
          }
          var element9 = dom.childAt(fragment, [1]);
          var element10 = dom.childAt(element9, [7]);
          var element11 = dom.childAt(element9, [9]);
          var element12 = dom.childAt(element9, [11]);
          var morph0 = dom.createMorphAt(dom.childAt(element9, [1]),1,1);
          var morph1 = dom.createMorphAt(dom.childAt(element9, [3]),1,1);
          var morph2 = dom.createMorphAt(dom.childAt(element9, [5]),1,1);
          var morph3 = dom.createMorphAt(element10,1,1);
          var morph4 = dom.createMorphAt(element10,3,3);
          var morph5 = dom.createMorphAt(element11,1,1);
          var morph6 = dom.createMorphAt(element11,3,3);
          var morph7 = dom.createMorphAt(element12,1,1);
          var morph8 = dom.createMorphAt(element12,3,3);
          var morph9 = dom.createMorphAt(dom.childAt(element9, [13]),1,1);
          inline(env, morph0, context, "tag-input", [], {"texto": "Nº", "valor": get(env, context, "model.numeroApresentacao"), "somenteLeitura": true, "tamanho": 3, "id": "tag||numeroApresentacao|fornecedor|identificacao"});
          inline(env, morph1, context, "tag-input", [], {"texto": "Nome", "valor": get(env, context, "model.nome"), "obrigatorio": true, "id": "tag||nome|fornecedor|identificacao"});
          inline(env, morph2, context, "tag-input", [], {"texto": "Nome Fantasia", "valor": get(env, context, "model.nomeFantasia"), "id": "tag||nome-fantasia|fornecedor|identificacao"});
          inline(env, morph3, context, "tag-inputmask-radio", [], {"grupoRadio": get(env, context, "model.cpfOuCnpj"), "texto": "CNPJ", "valorRadio": "CNPJ", "valorSemMascara": get(env, context, "model.cnpj"), "mascara": "cnpj", "obrigatorio": get(env, context, "cnpjObrigatorio"), "somenteLeitura": false, "desabilitado": get(env, context, "desabilitarCNPJ"), "tamanho": 6, "id": "tag-radio||cnpj|fornecedor|identificacao"});
          inline(env, morph4, context, "tag-inputmask-radio", [], {"grupoRadio": get(env, context, "model.cpfOuCnpj"), "texto": "CPF", "valorRadio": "CPF", "valorSemMascara": get(env, context, "model.cpf"), "mascara": "cpf", "obrigatorio": get(env, context, "cpfObrigatorio"), "somenteLeitura": false, "desabilitado": get(env, context, "desabilitarCPF"), "tamanho": 6, "id": "tag-radio||cpf|fornecedor|identificacao"});
          inline(env, morph5, context, "tag-input", [], {"texto": "Telefone", "valor": get(env, context, "model.telefone"), "obrigatorio": true, "tamanho": 6, "id": "tag||telefone|fornecedor|identificacao"});
          inline(env, morph6, context, "tag-input", [], {"texto": "Celular", "valor": get(env, context, "model.celular"), "tamanho": 6, "id": "tag||celular|fornecedor|identificacao"});
          inline(env, morph7, context, "tag-input", [], {"texto": "Site", "valor": get(env, context, "model.site"), "tamanho": 6, "id": "tag||site|fornecedor|identificacao"});
          inline(env, morph8, context, "tag-email", [], {"texto": "E-mail", "valor": get(env, context, "model.email"), "tamanho": 6, "id": "tag||email|fornecedor|identificacao"});
          inline(env, morph9, context, "tag-endereco", [], {"endereco": get(env, context, "model.endereco"), "id": "tag||endereco|fornecedor|identificacao"});
          return fragment;
        }
      };
    }());
    var child2 = (function() {
      return {
        isHTMLBars: true,
        revision: "Ember@1.12.1",
        blockParams: 0,
        cachedFragment: null,
        hasRendered: false,
        build: function build(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("	");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","panel-body");
          var el2 = dom.createTextNode("\n		");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","row");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n		");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n		");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","row");
          var el3 = dom.createTextNode("\n			");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n		");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n		");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","row");
          var el3 = dom.createTextNode("\n			");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n		");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n		");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","row");
          var el3 = dom.createTextNode("\n			");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n		");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n		");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","row");
          var el3 = dom.createTextNode("\n			");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n		");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n		");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","row");
          var el3 = dom.createTextNode("\n			");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n			");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n		");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n		");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","row");
          var el3 = dom.createTextNode("\n			");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n			");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n		");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n	");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        render: function render(context, env, contextualElement) {
          var dom = env.dom;
          var hooks = env.hooks, get = hooks.get, inline = hooks.inline;
          dom.detectNamespace(contextualElement);
          var fragment;
          if (env.useFragmentCache && dom.canClone) {
            if (this.cachedFragment === null) {
              fragment = this.build(dom);
              if (this.hasRendered) {
                this.cachedFragment = fragment;
              } else {
                this.hasRendered = true;
              }
            }
            if (this.cachedFragment) {
              fragment = dom.cloneNode(this.cachedFragment, true);
            }
          } else {
            fragment = this.build(dom);
          }
          var element6 = dom.childAt(fragment, [1]);
          var element7 = dom.childAt(element6, [11]);
          var element8 = dom.childAt(element6, [13]);
          var morph0 = dom.createMorphAt(dom.childAt(element6, [1]),1,1);
          var morph1 = dom.createMorphAt(dom.childAt(element6, [3]),1,1);
          var morph2 = dom.createMorphAt(dom.childAt(element6, [5]),1,1);
          var morph3 = dom.createMorphAt(dom.childAt(element6, [7]),1,1);
          var morph4 = dom.createMorphAt(dom.childAt(element6, [9]),1,1);
          var morph5 = dom.createMorphAt(element7,1,1);
          var morph6 = dom.createMorphAt(element7,3,3);
          var morph7 = dom.createMorphAt(element8,1,1);
          var morph8 = dom.createMorphAt(element8,3,3);
          inline(env, morph0, context, "busca-cbo", [], {"atributoDestino": get(env, context, "model.cbo"), "id": "componente|busca|cbo|fornecedor|identificacao"});
          inline(env, morph1, context, "tag-input", [], {"texto": "UG", "valor": get(env, context, "model.ug"), "mascara": "999999", "id": "tag||ug|fornecedor|identificacao"});
          inline(env, morph2, context, "tag-input", [], {"texto": "Gestão", "valor": get(env, context, "model.gestao"), "mascara": "99999", "id": "tag||gestao|fornecedor|identificacao"});
          inline(env, morph3, context, "tag-input", [], {"texto": "INSS", "valorSemMascara": get(env, context, "model.inss"), "mascara": "inss", "id": "tag||inss|fornecedor|identificacao"});
          inline(env, morph4, context, "tag-input", [], {"texto": "PIS/PASEP", "valorSemMascara": get(env, context, "model.pisPasep"), "mascara": "pisPasep", "id": "tag||pis-pasep|fornecedor|identificacao"});
          inline(env, morph5, context, "tag-input", [], {"texto": "Inscrição Estadual", "valor": get(env, context, "model.inscricaoEstadual"), "comprimentoMaximo": "20", "tamanho": 6, "id": "tag||inscricaoEstadual|fornecedor|identificacao"});
          inline(env, morph6, context, "tag-input", [], {"texto": "UF", "valor": get(env, context, "model.ufInscricaoEstadual"), "tamanho": 6, "id": "tag||uf-inscricaoEstadual|fornecedor|identificacao"});
          inline(env, morph7, context, "tag-input", [], {"texto": "Inscrição Municipal", "valor": get(env, context, "model.issqn"), "comprimentoMaximo": "20", "tamanho": 6, "id": "tag||numero-issqn|fornecedor|identificacao"});
          inline(env, morph8, context, "tag-input", [], {"texto": "Município", "valor": get(env, context, "model.cidadeIssqn"), "tamanho": 6, "id": "tag||cidade-issqn|fornecedor|identificacao"});
          return fragment;
        }
      };
    }());
    var child3 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          var child0 = (function() {
            return {
              isHTMLBars: true,
              revision: "Ember@1.12.1",
              blockParams: 0,
              cachedFragment: null,
              hasRendered: false,
              build: function build(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("a");
                dom.setAttribute(el1,"href","javascript:void(0)");
                var el2 = dom.createTextNode(" Endereço para Correspondência ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              render: function render(context, env, contextualElement) {
                var dom = env.dom;
                dom.detectNamespace(contextualElement);
                var fragment;
                if (env.useFragmentCache && dom.canClone) {
                  if (this.cachedFragment === null) {
                    fragment = this.build(dom);
                    if (this.hasRendered) {
                      this.cachedFragment = fragment;
                    } else {
                      this.hasRendered = true;
                    }
                  }
                  if (this.cachedFragment) {
                    fragment = dom.cloneNode(this.cachedFragment, true);
                  }
                } else {
                  fragment = this.build(dom);
                }
                return fragment;
              }
            };
          }());
          var child1 = (function() {
            return {
              isHTMLBars: true,
              revision: "Ember@1.12.1",
              blockParams: 0,
              cachedFragment: null,
              hasRendered: false,
              build: function build(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("a");
                dom.setAttribute(el1,"href","javascript:void(0)");
                var el2 = dom.createTextNode(" Contato Financeiro ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              render: function render(context, env, contextualElement) {
                var dom = env.dom;
                dom.detectNamespace(contextualElement);
                var fragment;
                if (env.useFragmentCache && dom.canClone) {
                  if (this.cachedFragment === null) {
                    fragment = this.build(dom);
                    if (this.hasRendered) {
                      this.cachedFragment = fragment;
                    } else {
                      this.hasRendered = true;
                    }
                  }
                  if (this.cachedFragment) {
                    fragment = dom.cloneNode(this.cachedFragment, true);
                  }
                } else {
                  fragment = this.build(dom);
                }
                return fragment;
              }
            };
          }());
          var child2 = (function() {
            return {
              isHTMLBars: true,
              revision: "Ember@1.12.1",
              blockParams: 0,
              cachedFragment: null,
              hasRendered: false,
              build: function build(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("a");
                dom.setAttribute(el1,"href","javascript:void(0)");
                var el2 = dom.createTextNode(" Contato Comercial ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              render: function render(context, env, contextualElement) {
                var dom = env.dom;
                dom.detectNamespace(contextualElement);
                var fragment;
                if (env.useFragmentCache && dom.canClone) {
                  if (this.cachedFragment === null) {
                    fragment = this.build(dom);
                    if (this.hasRendered) {
                      this.cachedFragment = fragment;
                    } else {
                      this.hasRendered = true;
                    }
                  }
                  if (this.cachedFragment) {
                    fragment = dom.cloneNode(this.cachedFragment, true);
                  }
                } else {
                  fragment = this.build(dom);
                }
                return fragment;
              }
            };
          }());
          var child3 = (function() {
            return {
              isHTMLBars: true,
              revision: "Ember@1.12.1",
              blockParams: 0,
              cachedFragment: null,
              hasRendered: false,
              build: function build(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("a");
                dom.setAttribute(el1,"href","javascript:void(0)");
                var el2 = dom.createTextNode(" Outro Contato ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              render: function render(context, env, contextualElement) {
                var dom = env.dom;
                dom.detectNamespace(contextualElement);
                var fragment;
                if (env.useFragmentCache && dom.canClone) {
                  if (this.cachedFragment === null) {
                    fragment = this.build(dom);
                    if (this.hasRendered) {
                      this.cachedFragment = fragment;
                    } else {
                      this.hasRendered = true;
                    }
                  }
                  if (this.cachedFragment) {
                    fragment = dom.cloneNode(this.cachedFragment, true);
                  }
                } else {
                  fragment = this.build(dom);
                }
                return fragment;
              }
            };
          }());
          var child4 = (function() {
            return {
              isHTMLBars: true,
              revision: "Ember@1.12.1",
              blockParams: 0,
              cachedFragment: null,
              hasRendered: false,
              build: function build(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("a");
                dom.setAttribute(el1,"href","javascript:void(0)");
                var el2 = dom.createTextNode(" Procurador ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              render: function render(context, env, contextualElement) {
                var dom = env.dom;
                dom.detectNamespace(contextualElement);
                var fragment;
                if (env.useFragmentCache && dom.canClone) {
                  if (this.cachedFragment === null) {
                    fragment = this.build(dom);
                    if (this.hasRendered) {
                      this.cachedFragment = fragment;
                    } else {
                      this.hasRendered = true;
                    }
                  }
                  if (this.cachedFragment) {
                    fragment = dom.cloneNode(this.cachedFragment, true);
                  }
                } else {
                  fragment = this.build(dom);
                }
                return fragment;
              }
            };
          }());
          return {
            isHTMLBars: true,
            revision: "Ember@1.12.1",
            blockParams: 0,
            cachedFragment: null,
            hasRendered: false,
            build: function build(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            render: function render(context, env, contextualElement) {
              var dom = env.dom;
              var hooks = env.hooks, block = hooks.block;
              dom.detectNamespace(contextualElement);
              var fragment;
              if (env.useFragmentCache && dom.canClone) {
                if (this.cachedFragment === null) {
                  fragment = this.build(dom);
                  if (this.hasRendered) {
                    this.cachedFragment = fragment;
                  } else {
                    this.hasRendered = true;
                  }
                }
                if (this.cachedFragment) {
                  fragment = dom.cloneNode(this.cachedFragment, true);
                }
              } else {
                fragment = this.build(dom);
              }
              var morph0 = dom.createMorphAt(fragment,0,0,contextualElement);
              var morph1 = dom.createMorphAt(fragment,1,1,contextualElement);
              var morph2 = dom.createMorphAt(fragment,2,2,contextualElement);
              var morph3 = dom.createMorphAt(fragment,3,3,contextualElement);
              var morph4 = dom.createMorphAt(fragment,4,4,contextualElement);
              dom.insertBoundary(fragment, null);
              dom.insertBoundary(fragment, 0);
              block(env, morph0, context, "em-tab", [], {"id": "tab-label||enderecoCorrespondencia|fornecedor|identificacao"}, child0, null);
              block(env, morph1, context, "em-tab", [], {"id": "tab-label||contatoFinanceiro|fornecedor|identificacao"}, child1, null);
              block(env, morph2, context, "em-tab", [], {"id": "tab-label||contatoComercial|fornecedor|identificacao"}, child2, null);
              block(env, morph3, context, "em-tab", [], {"id": "tab-label||outroContato|fornecedor|identificacao"}, child3, null);
              block(env, morph4, context, "em-tab", [], {"id": "tab-label||procurador|fornecedor|identificacao"}, child4, null);
              return fragment;
            }
          };
        }());
        var child1 = (function() {
          return {
            isHTMLBars: true,
            revision: "Ember@1.12.1",
            blockParams: 0,
            cachedFragment: null,
            hasRendered: false,
            build: function build(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","panel-body");
              var el2 = dom.createTextNode("\n      			");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","row");
              var el3 = dom.createTextNode("\n      				");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n      			");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n          ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            render: function render(context, env, contextualElement) {
              var dom = env.dom;
              var hooks = env.hooks, get = hooks.get, inline = hooks.inline;
              dom.detectNamespace(contextualElement);
              var fragment;
              if (env.useFragmentCache && dom.canClone) {
                if (this.cachedFragment === null) {
                  fragment = this.build(dom);
                  if (this.hasRendered) {
                    this.cachedFragment = fragment;
                  } else {
                    this.hasRendered = true;
                  }
                }
                if (this.cachedFragment) {
                  fragment = dom.cloneNode(this.cachedFragment, true);
                }
              } else {
                fragment = this.build(dom);
              }
              var morph0 = dom.createMorphAt(dom.childAt(fragment, [1, 1]),1,1);
              inline(env, morph0, context, "tag-endereco", [], {"endereco": get(env, context, "model.enderecoCorrespondencia"), "labelLogradouro": "Logradouro", "labelAcessivel": "Endereço para Correspondência", "id": "tag||endereco-correspondencia|fornecedor|identificacao"});
              return fragment;
            }
          };
        }());
        var child2 = (function() {
          return {
            isHTMLBars: true,
            revision: "Ember@1.12.1",
            blockParams: 0,
            cachedFragment: null,
            hasRendered: false,
            build: function build(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","panel-body");
              var el2 = dom.createTextNode("\n			      ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","row");
              var el3 = dom.createTextNode("\n      				");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n      			");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n\n      			");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","row");
              var el3 = dom.createTextNode("\n      				");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n      			");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n\n      			");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","row");
              var el3 = dom.createTextNode("\n      				");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n      			");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n\n      			");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","row");
              var el3 = dom.createTextNode("\n      				");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n      			");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n          ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            render: function render(context, env, contextualElement) {
              var dom = env.dom;
              var hooks = env.hooks, get = hooks.get, inline = hooks.inline;
              dom.detectNamespace(contextualElement);
              var fragment;
              if (env.useFragmentCache && dom.canClone) {
                if (this.cachedFragment === null) {
                  fragment = this.build(dom);
                  if (this.hasRendered) {
                    this.cachedFragment = fragment;
                  } else {
                    this.hasRendered = true;
                  }
                }
                if (this.cachedFragment) {
                  fragment = dom.cloneNode(this.cachedFragment, true);
                }
              } else {
                fragment = this.build(dom);
              }
              var element5 = dom.childAt(fragment, [1]);
              var morph0 = dom.createMorphAt(dom.childAt(element5, [1]),1,1);
              var morph1 = dom.createMorphAt(dom.childAt(element5, [3]),1,1);
              var morph2 = dom.createMorphAt(dom.childAt(element5, [5]),1,1);
              var morph3 = dom.createMorphAt(dom.childAt(element5, [7]),1,1);
              inline(env, morph0, context, "tag-input", [], {"texto": "Nome", "valor": get(env, context, "model.contatoFinanceiro.nome"), "id": "tag||nome-contato-financeiro|fornecedor|identificacao"});
              inline(env, morph1, context, "tag-input", [], {"texto": "Telefone", "valor": get(env, context, "model.contatoFinanceiro.telefone"), "id": "tag||telefone-contato-financeiro|fornecedor|identificacao"});
              inline(env, morph2, context, "tag-email", [], {"texto": "E-mail", "valor": get(env, context, "model.contatoFinanceiro.email"), "id": "tag||email-contato-financeiro|fornecedor|identificacao"});
              inline(env, morph3, context, "tag-input", [], {"texto": "Obs.", "valor": get(env, context, "model.contatoFinanceiro.observacao"), "aria-labelledby": "Observação", "id": "tag||observacao-contato-financeiro|fornecedor|identificacao"});
              return fragment;
            }
          };
        }());
        var child3 = (function() {
          return {
            isHTMLBars: true,
            revision: "Ember@1.12.1",
            blockParams: 0,
            cachedFragment: null,
            hasRendered: false,
            build: function build(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","panel-body");
              var el2 = dom.createTextNode("\n      			");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","row");
              var el3 = dom.createTextNode("\n      				");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n      			");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n\n      			");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","row");
              var el3 = dom.createTextNode("\n      				");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n      			");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n\n      			");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","row");
              var el3 = dom.createTextNode("\n      				");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n      			");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n\n      			");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","row");
              var el3 = dom.createTextNode("\n      				");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n      			");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n          ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            render: function render(context, env, contextualElement) {
              var dom = env.dom;
              var hooks = env.hooks, get = hooks.get, inline = hooks.inline;
              dom.detectNamespace(contextualElement);
              var fragment;
              if (env.useFragmentCache && dom.canClone) {
                if (this.cachedFragment === null) {
                  fragment = this.build(dom);
                  if (this.hasRendered) {
                    this.cachedFragment = fragment;
                  } else {
                    this.hasRendered = true;
                  }
                }
                if (this.cachedFragment) {
                  fragment = dom.cloneNode(this.cachedFragment, true);
                }
              } else {
                fragment = this.build(dom);
              }
              var element4 = dom.childAt(fragment, [1]);
              var morph0 = dom.createMorphAt(dom.childAt(element4, [1]),1,1);
              var morph1 = dom.createMorphAt(dom.childAt(element4, [3]),1,1);
              var morph2 = dom.createMorphAt(dom.childAt(element4, [5]),1,1);
              var morph3 = dom.createMorphAt(dom.childAt(element4, [7]),1,1);
              inline(env, morph0, context, "tag-input", [], {"texto": "Nome", "valor": get(env, context, "model.contatoComercial.nome"), "id": "tag||nome-contato-comercial|fornecedor|identificacao"});
              inline(env, morph1, context, "tag-input", [], {"texto": "Telefone", "valor": get(env, context, "model.contatoComercial.telefone"), "id": "tag||telefone-contato-comercial|fornecedor|identificacao"});
              inline(env, morph2, context, "tag-email", [], {"texto": "E-mail", "valor": get(env, context, "model.contatoComercial.email"), "id": "tag||email-contato-comercial|fornecedor|identificacao"});
              inline(env, morph3, context, "tag-input", [], {"texto": "Obs.", "valor": get(env, context, "model.contatoComercial.observacao"), "aria-labelledby": "Observação", "id": "tag||observacao-contato-comercial|fornecedor|identificacao"});
              return fragment;
            }
          };
        }());
        var child4 = (function() {
          return {
            isHTMLBars: true,
            revision: "Ember@1.12.1",
            blockParams: 0,
            cachedFragment: null,
            hasRendered: false,
            build: function build(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","panel-body");
              var el2 = dom.createTextNode("\n      			");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","row");
              var el3 = dom.createTextNode("\n      				");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n      			");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n\n      			");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","row");
              var el3 = dom.createTextNode("\n      				");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n      			");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n\n      			");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","row");
              var el3 = dom.createTextNode("\n      				");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n      			");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n\n      			");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","row");
              var el3 = dom.createTextNode("\n      				");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n      			");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n          ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            render: function render(context, env, contextualElement) {
              var dom = env.dom;
              var hooks = env.hooks, get = hooks.get, inline = hooks.inline;
              dom.detectNamespace(contextualElement);
              var fragment;
              if (env.useFragmentCache && dom.canClone) {
                if (this.cachedFragment === null) {
                  fragment = this.build(dom);
                  if (this.hasRendered) {
                    this.cachedFragment = fragment;
                  } else {
                    this.hasRendered = true;
                  }
                }
                if (this.cachedFragment) {
                  fragment = dom.cloneNode(this.cachedFragment, true);
                }
              } else {
                fragment = this.build(dom);
              }
              var element3 = dom.childAt(fragment, [1]);
              var morph0 = dom.createMorphAt(dom.childAt(element3, [1]),1,1);
              var morph1 = dom.createMorphAt(dom.childAt(element3, [3]),1,1);
              var morph2 = dom.createMorphAt(dom.childAt(element3, [5]),1,1);
              var morph3 = dom.createMorphAt(dom.childAt(element3, [7]),1,1);
              inline(env, morph0, context, "tag-input", [], {"texto": "Nome", "valor": get(env, context, "model.outroContato.nome"), "id": "tag||nome-outro-contato|fornecedor|identificacao"});
              inline(env, morph1, context, "tag-input", [], {"texto": "Telefone", "valor": get(env, context, "model.outroContato.telefone"), "id": "tag||telefone-outro-contato|fornecedor|identificacao"});
              inline(env, morph2, context, "tag-email", [], {"texto": "E-mail", "valor": get(env, context, "model.outroContato.email"), "id": "tag||email-outro-contato|fornecedor|identificacao"});
              inline(env, morph3, context, "tag-input", [], {"texto": "Obs.", "valor": get(env, context, "model.outroContato.observacao"), "aria-labelledby": "Observação", "id": "tag||observacao-outro-contato|fornecedor|identificacao"});
              return fragment;
            }
          };
        }());
        var child5 = (function() {
          return {
            isHTMLBars: true,
            revision: "Ember@1.12.1",
            blockParams: 0,
            cachedFragment: null,
            hasRendered: false,
            build: function build(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("\n          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","panel-body");
              var el2 = dom.createTextNode("\n    		    ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","row");
              var el3 = dom.createTextNode("\n      				");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n    			  ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n\n      			");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","row");
              var el3 = dom.createTextNode("\n      				");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n      			");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n\n      			");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","row");
              var el3 = dom.createTextNode("\n      				");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n      			");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n\n      			");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","row");
              var el3 = dom.createTextNode("\n      				");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n            ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n\n      			");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","row");
              var el3 = dom.createTextNode("\n      				");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("fieldset");
              dom.setAttribute(el3,"style","margin-top: 1em;");
              var el4 = dom.createTextNode("\n      					");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("legend");
              var el5 = dom.createTextNode("Poderes");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n\n                ");
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n\n                ");
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n\n      				");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n            ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n\n            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","row");
              var el3 = dom.createTextNode("\n      				");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("fieldset");
              dom.setAttribute(el3,"style","margin-top: 1em");
              var el4 = dom.createTextNode("\n      				  ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("legend");
              var el5 = dom.createTextNode("Procuração");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n\n      				  ");
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n\n      				  ");
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n\n      				  ");
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n\n      				  ");
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n\n      				");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n            ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n          ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            render: function render(context, env, contextualElement) {
              var dom = env.dom;
              var hooks = env.hooks, get = hooks.get, inline = hooks.inline;
              dom.detectNamespace(contextualElement);
              var fragment;
              if (env.useFragmentCache && dom.canClone) {
                if (this.cachedFragment === null) {
                  fragment = this.build(dom);
                  if (this.hasRendered) {
                    this.cachedFragment = fragment;
                  } else {
                    this.hasRendered = true;
                  }
                }
                if (this.cachedFragment) {
                  fragment = dom.cloneNode(this.cachedFragment, true);
                }
              } else {
                fragment = this.build(dom);
              }
              var element0 = dom.childAt(fragment, [1]);
              var element1 = dom.childAt(element0, [9, 1]);
              var element2 = dom.childAt(element0, [11, 1]);
              var morph0 = dom.createMorphAt(dom.childAt(element0, [1]),1,1);
              var morph1 = dom.createMorphAt(dom.childAt(element0, [3]),1,1);
              var morph2 = dom.createMorphAt(dom.childAt(element0, [5]),1,1);
              var morph3 = dom.createMorphAt(dom.childAt(element0, [7]),1,1);
              var morph4 = dom.createMorphAt(element1,3,3);
              var morph5 = dom.createMorphAt(element1,5,5);
              var morph6 = dom.createMorphAt(element2,3,3);
              var morph7 = dom.createMorphAt(element2,5,5);
              var morph8 = dom.createMorphAt(element2,7,7);
              var morph9 = dom.createMorphAt(element2,9,9);
              inline(env, morph0, context, "tag-input", [], {"texto": "Nome", "valor": get(env, context, "model.procurador.contato.nome"), "id": "tag||nome-procurador|fornecedor|identificacao"});
              inline(env, morph1, context, "tag-input", [], {"texto": "Telefone", "valor": get(env, context, "model.procurador.contato.telefone"), "id": "tag||telefone-procurador|fornecedor|identificacao"});
              inline(env, morph2, context, "tag-email", [], {"texto": "E-mail", "valor": get(env, context, "model.procurador.contato.email"), "id": "tag||email-procurador|fornecedor|identificacao"});
              inline(env, morph3, context, "tag-endereco", [], {"endereco": get(env, context, "model.procurador.endereco"), "labelLogradouro": "Logradouro", "labelAcessivel": "Endereço Procurador", "id": "tag||endereco-procurador|fornecedor|identificacao"});
              inline(env, morph4, context, "tag-radio-button", [], {"texto": "Genéricos", "valor": "g", "atributoDestino": get(env, context, "model.procurador.poderes"), "labelNegrito": true, "tamanho": 6, "id": "radio||poderes-genericos-procurador|fornecedor|identificacao"});
              inline(env, morph5, context, "tag-radio-button", [], {"texto": "Específicos", "valor": "e", "atributoDestino": get(env, context, "model.procurador.poderes"), "labelNegrito": true, "tamanho": 6, "id": "radio||poderes-especificos-procurador|fornecedor|identificacao"});
              inline(env, morph6, context, "tag-input", [], {"texto": "Processo", "valor": get(env, context, "model.procurador.processoProcuracao"), "classeElementoInterno": "input-group-coluna-dupla input-group-coluna-dupla-esquerda", "tamanho": 6, "id": "tag||processo-procuracao|fornecedor|identificacao"});
              inline(env, morph7, context, "tag-input", [], {"texto": "Pág.", "valor": get(env, context, "model.procurador.paginaProcuracao"), "classeElementoInterno": "input-group-coluna-dupla input-group-coluna-dupla-direita", "tamanho": 6, "id": "tag||pagina-processo-procuracao|fornecedor|identificacao"});
              inline(env, morph8, context, "tag-input", [], {"texto": "Link", "valor": get(env, context, "model.procurador.linkProcuracao"), "id": "tag||link-procuracao|fornecedor|identificacao"});
              inline(env, morph9, context, "tag-textarea", [], {"texto": "Observação", "numeroLinhas": "3", "valor": get(env, context, "model.procurador.observacao"), "textoPlaceHolder": "...", "id": "tag||observacaoProcurador|fornecedor|identificacao"});
              return fragment;
            }
          };
        }());
        return {
          isHTMLBars: true,
          revision: "Ember@1.12.1",
          blockParams: 0,
          cachedFragment: null,
          hasRendered: false,
          build: function build(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          render: function render(context, env, contextualElement) {
            var dom = env.dom;
            var hooks = env.hooks, block = hooks.block;
            dom.detectNamespace(contextualElement);
            var fragment;
            if (env.useFragmentCache && dom.canClone) {
              if (this.cachedFragment === null) {
                fragment = this.build(dom);
                if (this.hasRendered) {
                  this.cachedFragment = fragment;
                } else {
                  this.hasRendered = true;
                }
              }
              if (this.cachedFragment) {
                fragment = dom.cloneNode(this.cachedFragment, true);
              }
            } else {
              fragment = this.build(dom);
            }
            var morph0 = dom.createMorphAt(fragment,0,0,contextualElement);
            var morph1 = dom.createMorphAt(fragment,2,2,contextualElement);
            var morph2 = dom.createMorphAt(fragment,4,4,contextualElement);
            var morph3 = dom.createMorphAt(fragment,6,6,contextualElement);
            var morph4 = dom.createMorphAt(fragment,8,8,contextualElement);
            var morph5 = dom.createMorphAt(fragment,10,10,contextualElement);
            dom.insertBoundary(fragment, 0);
            block(env, morph0, context, "em-tab-list", [], {"id": "tab-list||informacoesAdicionais|fornecedor|identificacao"}, child0, null);
            block(env, morph1, context, "em-tab-panel", [], {"id": "tab-panel||enderecoCorrespondencia|fornecedor|identificacao"}, child1, null);
            block(env, morph2, context, "em-tab-panel", [], {"id": "tab-panel||contato-financeiro|fornecedor|identificacao"}, child2, null);
            block(env, morph3, context, "em-tab-panel", [], {"id": "tab-panel||contato-comercial|fornecedor|identificacao"}, child3, null);
            block(env, morph4, context, "em-tab-panel", [], {"id": "tab-panel||outro-contato|fornecedor|identificacao"}, child4, null);
            block(env, morph5, context, "em-tab-panel", [], {"id": "tab-panel||procurador|fornecedor|identificacao"}, child5, null);
            return fragment;
          }
        };
      }());
      return {
        isHTMLBars: true,
        revision: "Ember@1.12.1",
        blockParams: 0,
        cachedFragment: null,
        hasRendered: false,
        build: function build(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("fieldset");
          var el2 = dom.createTextNode("\n\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        render: function render(context, env, contextualElement) {
          var dom = env.dom;
          var hooks = env.hooks, block = hooks.block;
          dom.detectNamespace(contextualElement);
          var fragment;
          if (env.useFragmentCache && dom.canClone) {
            if (this.cachedFragment === null) {
              fragment = this.build(dom);
              if (this.hasRendered) {
                this.cachedFragment = fragment;
              } else {
                this.hasRendered = true;
              }
            }
            if (this.cachedFragment) {
              fragment = dom.cloneNode(this.cachedFragment, true);
            }
          } else {
            fragment = this.build(dom);
          }
          var morph0 = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
          block(env, morph0, context, "em-tabs", [], {"configName": "bs", "id": "tab||informacoesAdicionais|fornecedor|identificacao"}, child0, null);
          return fragment;
        }
      };
    }());
    return {
      isHTMLBars: true,
      revision: "Ember@1.12.1",
      blockParams: 0,
      cachedFragment: null,
      hasRendered: false,
      build: function build(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","col-md-5 form-horizontal");
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","col-md-3 form-horizontal");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","col-md-4 form-horizontal");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      render: function render(context, env, contextualElement) {
        var dom = env.dom;
        var hooks = env.hooks, get = hooks.get, block = hooks.block;
        dom.detectNamespace(contextualElement);
        var fragment;
        if (env.useFragmentCache && dom.canClone) {
          if (this.cachedFragment === null) {
            fragment = this.build(dom);
            if (this.hasRendered) {
              this.cachedFragment = fragment;
            } else {
              this.hasRendered = true;
            }
          }
          if (this.cachedFragment) {
            fragment = dom.cloneNode(this.cachedFragment, true);
          }
        } else {
          fragment = this.build(dom);
        }
        var morph0 = dom.createMorphAt(fragment,0,0,contextualElement);
        var morph1 = dom.createMorphAt(dom.childAt(fragment, [2]),1,1);
        var morph2 = dom.createMorphAt(dom.childAt(fragment, [4]),1,1);
        var morph3 = dom.createMorphAt(dom.childAt(fragment, [6]),1,1);
        dom.insertBoundary(fragment, 0);
        block(env, morph0, context, "if", [get(env, context, "existemPenalidadesImpeditivasVigentes")], {}, child0, null);
        block(env, morph1, context, "tag-bloco", [], {"titulo": "Identificação"}, child1, null);
        block(env, morph2, context, "tag-bloco", [], {"titulo": "Outros Cadastros"}, child2, null);
        block(env, morph3, context, "tag-bloco", [], {"titulo": "Informações Adicionais"}, child3, null);
        return fragment;
      }
    };
  }()));

});
define('webapp/models/endereco', ['exports', 'ember', 'webapp/models/municipio', 'webapp/models/uf', 'webapp/models/pais'], function (exports, Ember, Municipio, Uf, Pais) {

	'use strict';

	exports['default'] = Ember['default'].Object.extend({
			id: null,
			logradouro: '',
			bairro: '',
			municipio: Municipio['default'].create({}),
			uf: Uf['default'].create({}),
			pais: Pais['default'].create({}),
			cep: '',

			inicializar: function inicializar() {
					this.set('municipio', Municipio['default'].create({}));
					this.set('uf', Uf['default'].create());
					this.set('pais', Pais['default'].create());
			}
	});

});
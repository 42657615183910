define('webapp/pods/fornecedor/prova-regularidade/model', ['exports', 'ember', 'webapp/models/classificacao-financeira-item'], function (exports, Ember, ClassificacaoFinanceiraItem) {

	'use strict';

	exports['default'] = Ember['default'].Object.extend({
		id: null,
		tipo: '',
		numero: 0,
		data: '',
		orgaoEmissor: '',
		dataEmissao: '',
		dataExpiracao: '',
		codigoVerificador: '',
		numeroApresentacao: '',
		patrimonioLiquido: '',
		liquidezGeral: '',
		liquidezCorrente: '',
		solvenciaGeral: '',
		capitalCirculanteLiquido: '',
		quantitativosCapacidadeTecnica: '',

		inicializar: (function () {
			this.set('classificacaoProduto', ClassificacaoFinanceiraItem['default'].create({}));
		}).on('init'),

		findUrlFn: function findUrlFn(id) {
			return 'fornecedor/provaRegularidade/' + id.toString();
		}
	});

});
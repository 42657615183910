define('webapp/pods/aquisicao/model', ['exports', 'ember', 'webapp/models/programa-trabalho', 'webapp/models/unidade-organizacional', 'webapp/models/tipo-fiscal-gestor', 'webapp/models/servidor', 'webapp/models/orgao'], function (exports, Ember, ProgramaTrabalho, UnidadeOrganizacional, TipoFiscalGestor, Servidor, Orgao) {

	'use strict';

	exports['default'] = Ember['default'].Object.extend({

		id: null,
		modalidade: '', //ADESAO, LICITACAO_PREGAO_ELETRONICO, LICITACAO_PREGAO_PRESENCIAL,
		//LICITACAO_CONVITE, LICITACAO_TOMADA_PRECOS, LICITACAO_CONCORRENCIA, LICITACAO_CONCORRENCIA_ELETRONICA
		//INEXIGIBILIDADE, DISPENSA, PARTICIPACAO_REGISTRO_PRECOS, SUPRIMENTO_FUNDOS, OUTROS
		registroPreco: null, //indica se a aquisição vai ter registro de preços
		criterioSustentabilidade: null, //indica se a aquisição vai ter critério de sustentabilidade
		requisicoes: [], //Requisicao
		numeroAta: null, //ADESAO
		criterioJulgamento: '', // MENOR_PRECO, MAIOR_DESCONTO, TECNICA_E_PRECO, MAIOR_LANCE, MAIOR_RETORNO_ECONOMICO, NAO_SE_APLICA, MELHOR_TECNICA, CONTEUDO_ARTISTICO
		inicioVigenciaAta: '', //ADESAO
		fimVigenciaAta: '', //ADESAO
		objeto: '',
		uasg: '', //opção: UASG.create({}),
		valorEstimado: null,
		programaDeTrabalho: ProgramaTrabalho['default'].create({}),
		numeroProcessoAdministrativo: null,
		observacoes: '',
		unidadeRequisitante: UnidadeOrganizacional['default'].create({}),
		pregoeiro: null, //LICITACAO-PREGAO
		gestor: Servidor['default'].create({}),
		tipoGestor: TipoFiscalGestor['default'].create({}),
		orgaoGerenciador: Orgao['default'].create({}), //ADESAO
		emailGestor: '',
		fiscal: Servidor['default'].create({}),
		tipoFiscal: TipoFiscalGestor['default'].create({}),
		emailFiscal: '',
		itens: [],
		atas: [], //LICITACAO-REGISTRO, LICITACAO-PREGAO
		eventos: [], //LICITACAO-REGISTRO, LICITACAO-PREGAO
		andamentos: [],
		lotes: [],
		compras: [],
		numeroApresentacao: '',
		numeroFisico: '',
		contratos: [],
		dataCricao: null, //Data de que? Refere-se ao campo 'De:' do template
		fundamentosLegais: [],
		valorTotal: null,

		enviaPNCP: null,
		urlPNCP: null,
		pncpano: null,
		pncpseq: null,

		situacaoAquisicao: null,
		dataSituacaoAquisicao: null,

		modoDisputa: null,

		justificativaPresencial: null,

		pendenteEnvioAoPNCP: null,

		inicializar: (function () {
			this.set('fundamentosLegais', []);
			this.set('requisicoes', []);
			this.set('programaDeTrabalho', ProgramaTrabalho['default'].create({}));
			this.set('unidadeRequisitante', UnidadeOrganizacional['default'].create({}));
			this.set('gestor', Servidor['default'].create({}));
			this.set('tipoGestor', TipoFiscalGestor['default'].create({}));
			this.set('fiscal', Servidor['default'].create({}));
			this.set('tipoFiscal', TipoFiscalGestor['default'].create({}));
			this.set('itens', []);
			this.set('lotes', []);
			this.set('andamentos', []);
			this.set('compras', []);

			this.set('orgaoGerenciador', Orgao['default'].create({})); //ADESAO

			this.set('atas', []); //LICITACAO-REGISTRO, LICITACAO-PREGAO
			this.set('eventos', []); //LICITACAO-REGISTRO, LICITACAO-PREGAO

			this.set('pregoeiro', Servidor['default'].create({})); //LICITACAO-PREGAO
		}).on('init'),

		findUrlFn: function findUrlFn(id) {
			return 'aquisicao/' + id.toString();
		}
	});

});
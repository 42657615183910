define('webapp/pods/requisicao/criar/route', ['exports', 'ember', 'webapp/pods/requisicao/model', 'webapp/mixins/suprimir-templates-superiores-mixin'], function (exports, Ember, Requisicao, SuprimirTemplatesSuperioresMixin) {

	'use strict';

	exports['default'] = Ember['default'].Route.extend(SuprimirTemplatesSuperioresMixin['default'], {
		controllerName: 'requisicao',
		//sempre vem do controller fornecedor.
		setupController: function setupController(controller) {
			controller.set('model', Requisicao['default'].create({}));

			this.get('comunicador').leParaPropriedade(controller, 'programaTrabalho', 'programasDeTrabalho', 'Não foi possível obter os programas de trabalho.');

			this.get('comunicador').leParaPropriedade(controller, 'unidadeOrcamentaria/selecionavel/unidadeOrganizacionalCorrespondente', 'unidadesOrganizacionais', 'Não foi possível obter as unidades organizacionais.');
		},

		model: function model() {
			return Requisicao['default'].create({});
		}
	});

});
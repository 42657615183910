define('webapp/initializers/idx-accordion-config', ['exports', 'ember', 'ember-idx-utils/config'], function (exports, Em, IdxConfig) {

    'use strict';

    exports['default'] = {
        name: 'ember-idx-accordion',
        initialize: function initialize() {
            var Config = Em['default'].IdxConfig = Em['default'].IdxConfig ? Em['default'].IdxConfig : IdxConfig['default'].create();

            var defaultConfig = Config.getConfig('default');
            if (!defaultConfig) {
                Config.addConfig('default');
                defaultConfig = Config.getConfig('default');
            }

            //Bootstrap
            var bsConfig = Config.getConfig('bs');
            if (!bsConfig) {
                Config.addConfig('bs');
                bsConfig = Config.getConfig('bs');
            }
            bsConfig['accordion'] = {
                classes: ['panel-group'],
                itemClasses: ['panel', 'panel-default'],
                itemSelectedClasses: ['active'],
                panelHeaderClasses: ['panel-heading'],
                panelTitleClasses: ['panel-title'],
                panelTogglerClasses: ['accordion-toggle'],
                panelBodyContainerClasses: ['panel-collapse', 'collapse'],
                panelBodyClasses: ['panel-body']
            };
        }
    };

});
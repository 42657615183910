define('webapp/pods/adm-outras-entidades/controller', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({

    amparosLegais: [],

    breadCrumbConfig: {
      fnNomeApresentacao: 'fnNomeApresentacao'
    },

    fnNomeApresentacao: function fnNomeApresentacao() {
      return 'Cadastros Complementares';
    },

    preencheLocalidadesModel: function preencheLocalidadesModel() {
      this.buscarPaisesExistentes();
    },

    buscarFundamentosLegais: function buscarFundamentosLegais() {
      this.get('comunicador').leParaPropriedade(this, 'fundamentoLegal?distintos=true', 'model.fundamentosLegais', 'Não foi possível obter os fundamentos legais existentes', true);
    },

    buscarAmparosLegais: function buscarAmparosLegais() {
      this.get('comunicador').leParaPropriedade(this, 'amparoLegal?apenasAtivos=false', 'amparosLegais', 'Não foi possível obter os amparos legais existentes', true);
    },

    buscarPaisesExistentes: function buscarPaisesExistentes() {
      this.get('comunicador').leParaPropriedade(this, 'pais', 'model.paises', 'Não foi possível obter os países existentes', true);
    },

    buscarUFsExistentes: (function () {
      var _this = this;

      var cumulativoUFs = [];
      var qtdPaisesVarridos = 0;
      this.get('model.paises').forEach(function (pais, indice, paises) {
        _this.get('comunicador').servico('get', 'pais/' + pais.id + '/uf').then(function (ufs) {
          _this.setPaisEmUFs(pais, ufs);
          cumulativoUFs.pushObjects(ufs);
          qtdPaisesVarridos++;
          if (qtdPaisesVarridos === paises.length) {
            _this.set('model.ufs', cumulativoUFs);
          }
        });
      });
    }).observes('model.paises'),

    buscarMunicipios: (function () {
      var _this2 = this;

      var cumulativoMunicipios = [];
      var qtdUFsVarridas = 0;
      this.get('model.ufs').forEach(function (uf, indice, ufs) {
        _this2.get('comunicador').servico('get', 'uf/' + uf.id + '/municipio').then(function (municipios) {
          _this2.setUFemMunicipios(uf, municipios);
          cumulativoMunicipios.pushObjects(municipios);
          qtdUFsVarridas++;
          if (qtdUFsVarridas === ufs.length) {
            _this2.set('model.municipios', cumulativoMunicipios);
          }
        });
      });
    }).observes('model.ufs'),

    setUFemMunicipios: function setUFemMunicipios(uf, municipios) {
      municipios.forEach(function (municipio) {
        municipio.uf = uf;
      });
    },

    setPaisEmUFs: function setPaisEmUFs(pais, ufs) {
      ufs.forEach(function (uf) {
        uf.pais = pais;
      });
    },

    actions: {
      adicionar: function adicionar() {
        alert('criar');
      }
    }

  });

});
define('webapp/initializers/comunicador', ['exports', 'webapp/services/comunicador'], function (exports, ComunicadorServico) {

  'use strict';

  // import Ember from 'ember';
  // import Defs from '../defs';

  exports['default'] = {
    name: 'ComunicadorServicoInitializador',
    initialize: function initialize(container, application) {

      application.register('comunicador:service', ComunicadorServico['default'], { singleton: true });
      application.inject('controller', 'comunicador', 'comunicador:service');
      application.inject('route', 'comunicador', 'comunicador:service');
      application.inject('model', 'comunicador', 'comunicador:service');
      application.inject('service:cache', 'comunicador', 'comunicador:service');

      /*
          if(Defs.enderecoServer !== 'mockup') {return;}
          ComunicadorServico.reopen({
      
            servico: function(metodo, servico) {
              var retorno = `Serviço ${servico} não implementado`;
      
              var hashServicos = {};
      
              switch(metodo) {
              case 'post': hashServicos = hashPost; break;
              case 'get': hashServicos = hashGet; break;
              case 'put': hashServicos = hashPut; break;
              case 'delete': hashServicos = {}; break;
              default:
                Ember.assert(`${metodo} não é um método http válido.`,false);
              }
      
              Object.keys(hashServicos).some((urlServico) => {
                retorno = hashServicos[urlServico];
                var regexUrlServico = new RegExp(urlServico);
                return regexUrlServico.test(servico);
              });
      
              return new Ember.RSVP.Promise(function(resolve) {
                resolve(retorno);
              });
      
            }
      
          });
       */
    }
  };

});
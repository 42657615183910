define('webapp/pods/requisicao/item/route', ['exports', 'ember', 'webapp/utils/util', 'webapp/mixins/suprimir-templates-superiores-mixin'], function (exports, Ember, Util, SuprimirTemplatesSuperioresMixin) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(SuprimirTemplatesSuperioresMixin['default'], {
    setupController: function setupController(controller, model) {
      controller.set('model', Util['default'].clona(model));

      this.get('comunicador').servico('get', 'classificacaoItem').then(function (resultado) {
        controller.set('classificacoesFinanceiras', resultado);
      });

      this.get('comunicador').servico('get', 'unidadeMedida').then(function (resultado) {
        controller.set('unidadesDeMedida', resultado);
      });

      // Configura informações da tela
      this.get('comunicador').servico('get', 'parametroExibicao').then(function (resultado) {
        controller.set('habilitaCodigoCatalogo', Util['default'].converteParametrosDeExibicao(resultado).HABILITA_CODIGO_CATALOGO === "S");
      });
    }

  });

});
define('webapp/pods/fornecedor/model', ['exports', 'ember', 'webapp/models/endereco', 'webapp/models/cbo', 'webapp/models/contato', 'webapp/models/procurador', 'webapp/pods/fornecedor/dado-bancario/model', 'webapp/pods/fornecedor/prova-regularidade/model', 'webapp/pods/fornecedor/penalidade/model', 'webapp/pods/fornecedor/fornecivel/model', 'webapp/pods/fornecedor/evento/model'], function (exports, Ember, Endereco, Cbo, Contato, Procurador, DadoBancario, ProvaRegularidade, Penalidade, Fornecivel, Evento) {

  'use strict';

  exports['default'] = Ember['default'].Object.extend({
    id: null,
    nome: '',
    nomeFantasia: '',
    endereco: Endereco['default'].create({}),
    cnpj: '',
    cpf: '',
    ug: '',
    gestao: '',
    site: '',
    email: '',
    telefone: '',
    celular: '',
    cbo: Cbo['default'].create({}),
    pisPasep: '',
    inss: '',
    inscricaoEstadual: '',
    ufInscricaoEstadual: '',
    issqn: '',
    cidadeIssqn: '',
    enderecoCorrespondencia: Endereco['default'].create({}),
    contatoFinanceiro: Contato['default'].create({}),
    contatoComercial: Contato['default'].create({}),
    outroContato: Contato['default'].create({}),
    provasRegularidade: [],
    penalidades: [],
    dadosBancarios: [],
    procurador: Procurador['default'].create({}),
    eventosFornecedor: [],
    forneciveis: [],
    cpfOuCnpj: '', // "CNPJ" ou "CPF"
    numeroApresentacao: '',

    inicializar: (function () {
      this.set('endereco', Endereco['default'].create({}));
      this.set('cbo', Cbo['default'].create({}));
      this.set('enderecoCorrespondencia', Endereco['default'].create({}));
      this.set('contatoFinanceiro', Contato['default'].create({}));
      this.set('contatoComercial', Contato['default'].create({}));
      this.set('outroContato', Contato['default'].create({}));
      this.set('procurador', Procurador['default'].create({}));
      this.set('provasRegularidade', []);
      this.set('penalidades', []);
      this.set('dadosBancarios', []);
      this.set('eventosFornecedor', []);
      this.set('forneciveis', []);
    }).on('init'),

    nomeDeCampoAmigavel: function nomeDeCampoAmigavel(campo) {
      if (campo === 'enderecoCorrespondencia') {
        return 'Endereço de Correspondência';
      } else if (campo === 'provasRegularidade') {
        return 'Provas de Regularidade';
      } else if (campo === 'nomeFantasia') {
        return 'Nome Fantasia';
      } else {
        return campo;
      }
    },

    objDoArrayDoCampo: function objDoArrayDoCampo(metadado) {
      if (metadado.key === 'provasRegularidade') {
        return ProvaRegularidade['default'].create({});
      } else if (metadado.key === 'penalidades') {
        return Penalidade['default'].create({});
      } else if (metadado.key === 'dadosBancarios') {
        return DadoBancario['default'].create({});
      } else if (metadado.key === 'eventosFornecedor') {
        return Evento['default'].create({});
      } else if (metadado.key === 'fornecivel') {
        return Fornecivel['default'].create({});
      } else {
        return metadado.valor;
      }
    },

    findUrlFn: function findUrlFn(id) {
      return 'fornecedor/' + id.toString();
    }
  });

});
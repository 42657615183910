define('webapp/pods/aquisicao/item/quantidade-participacao/identificacao/template', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    return {
      isHTMLBars: true,
      revision: "Ember@1.12.1",
      blockParams: 0,
      cachedFragment: null,
      hasRendered: false,
      build: function build(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","col-md-3");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      render: function render(context, env, contextualElement) {
        var dom = env.dom;
        var hooks = env.hooks, get = hooks.get, inline = hooks.inline;
        dom.detectNamespace(contextualElement);
        var fragment;
        if (env.useFragmentCache && dom.canClone) {
          if (this.cachedFragment === null) {
            fragment = this.build(dom);
            if (this.hasRendered) {
              this.cachedFragment = fragment;
            } else {
              this.hasRendered = true;
            }
          }
          if (this.cachedFragment) {
            fragment = dom.cloneNode(this.cachedFragment, true);
          }
        } else {
          fragment = this.build(dom);
        }
        var morph0 = dom.createMorphAt(fragment,1,1,contextualElement);
        var morph1 = dom.createMorphAt(fragment,3,3,contextualElement);
        var morph2 = dom.createMorphAt(dom.childAt(fragment, [5]),1,1);
        inline(env, morph0, context, "tag-input", [], {"texto": "Nº", "valor": get(env, context, "model.numeroApresentacao"), "autoFoco": true, "tamanho": 2, "somenteLeitura": true});
        inline(env, morph1, context, "tag-select", [], {"texto": "Órgão Participante", "obrigatorio": true, "conteudo": get(env, context, "orgaos"), "opcaoLabel": "nome", "valor": get(env, context, "model.orgao"), "textopadrao": "Selecione um órgão", "tamanho": 7, "id": "select||orgaoParticipante|aquisicao/item/quantidade-participacao|identificacao"});
        inline(env, morph2, context, "decimal-input", [], {"texto": "Quantidade", "valor": get(env, context, "model.quantidade"), "obrigatorio": true, "tamanhoLabel": 5});
        return fragment;
      }
    };
  }()));

});
define('webapp/pods/aquisicao/contrato/aditivo/alteracao-teor-clausula/controller', ['exports', 'ember', 'webapp/mixins/crud-mixin'], function (exports, Ember, CrudMixin) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend(CrudMixin['default'], {

    needs: ['aquisicao/contrato/aditivo'],

    breadCrumbConfig: {
      fnNomeApresentacao: 'fnNomeApresentacao'
    },

    fnNomeApresentacao: function fnNomeApresentacao() {
      return 'Alteração de Teor de Cláusula ' + (this.model.version ? 'Nº ' + this.model.numeroApresentacao : '(Nova)');
    },

    crudConfig: {
      criar: {
        urlFn: function urlFn() {
          return 'aditivo/' + this.get("controllers.aquisicao/contrato/aditivo").model.id + '/alteracaoTeorClausula';
        },
        okFn: function okFn(opcao) {
          this.get('controllers.aquisicao/contrato/aditivo').send('ler');
          if (opcao === 'CRIAR_E_PROXIMO') {
            //ação "borbulhará" para a rota
            this.send('refreshRota');
            return;
          }
          this.transitionToRoute('aquisicao/contrato/aditivo');
        }
      },
      atualizar: {
        okFn: function okFn() {
          this.get('controllers.aquisicao/contrato/aditivo').send('ler');
          this.transitionToRoute('aquisicao/contrato/aditivo');
        }
      },
      excluir: {
        okFn: function okFn() {
          this.get('controllers.aquisicao/contrato/aditivo').send('ler');
          this.transitionToRoute('aquisicao/contrato/aditivo');
        }
      }
    }

  });

});
define('webapp/pods/aquisicao/contrato/andamento/model', ['exports', 'ember'], function (exports, Ember) {

	'use strict';

	exports['default'] = Ember['default'].Object.extend({
		id: null,
		data: '',
		descricao: '',
		detalhes: '',
		numeroApresentacao: '',

		findUrlFn: function findUrlFn(id) {
			return 'contrato/andamento/' + id.toString();
		}
	});

});
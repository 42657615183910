define('webapp/pods/fornecedor/evento/model', ['exports', 'ember'], function (exports, Ember) {

	'use strict';

	exports['default'] = Ember['default'].Object.extend({
		id: null,
		data: '',
		descricao: '',
		detalhes: '',

		findUrlFn: function findUrlFn(id) {
			return 'fornecedor/evento/' + id.toString();
		}
	});

});
define('webapp/pods/aquisicao/item/model', ['exports', 'ember', 'webapp/models/classificacao-financeira-item', 'webapp/models/unidade-medida', 'webapp/pods/fornecedor/model'], function (exports, Ember, ClassificacaoFinanceiraItem, UnidadeMedida, Fornecedor) {

	'use strict';

	exports['default'] = Ember['default'].Object.extend({
		id: null,
		numeroApresentacao: null,
		motivoCriacao: '', //ORIGINAL, ACRESCIMO_QUANTITATIVO, ACRESCIMO_QUALITATIVO, SUPRESSAO, COTA, PARCELA_PRINCIPAL_COTA
		itemCotaPrincipal: null,
		numeroLote: null,
		idLote: null,
		classificacao: ClassificacaoFinanceiraItem['default'].create({}),
		codigoCatalogo: '',
		periodico: false, //true ou false, indicando se o item é de fornecimento continuado, como um serviço
		descricao: '',
		unidadeMedida: UnidadeMedida['default'].create({}),

		adjudicado: Fornecedor['default'].create({}),
		valorUnitario: null,
		valorTotal: null,
		dataAdjudicacao: '',

		quantidadePropria: null, //quantidade desejada pelo próprio tribunal
		participacoes: [], //private List<ItemAquisicaoParticipacao>
		quantidadeTotal: null,
		quantidadeComprada: null,
		quantidadeDisponivelCompra: null,

		duracao: 1, //duração do fornecimento continuado, em meses

		orcamentoSigiloso: 0,

		//resultado: '',
		//dataResultado: '',
		//processoResultado: '',

		//Tudo o que deve ser inicializado com um valor não-vazio (ou seja, diferente de
		//null, undefined e '' - false, como é um valor significativo, não é vazio), deve
		//ser inicializado aqui.
		//Caso se precise inicializar com o valor false, não se pode usar this.set(),
		//devendo-se usar atribuição simples ("="). Caso se use this.set(), o atributo
		//não será enumerable e, assim, será ignorado por funções como JSON.stringfy(),
		//Object.keys() ou _.each(), que são usadas no comunicador e no Util.compactaObjeto().
		//Assim, essas propriedades acabam não sendo enviadas para o servidor.
		//Atributos que sejam objetos ou arrays podem ser iniciados com "=" ou com this.set
		//(EXPLICAÇÃO SOBRE ATRIBUTOS "ENUMERABLE" EM:
		//https://developer.mozilla.org/it/docs/Web/JavaScript/Enumerability_and_ownership_of_properties)
		//
		inicializar: (function () {
			//this.set('classificacao', ClassificacaoFinanceiraItem.create({}));
			this.classificacao = ClassificacaoFinanceiraItem['default'].create({});
			this.periodico = false;
			//this.set('unidadeMedida', UnidadeMedida.create({}));
			this.unidadeMedida = UnidadeMedida['default'].create({});
			//this.set('adjudicado', Fornecedor.create({}));
			this.adjudicado = Fornecedor['default'].create({});
			//this.set('participacoes', []);
			this.participacoes = [];
			this.orcamentoSigiloso = 0;
		}).on('init'),

		findUrlFn: function findUrlFn(id) {
			return 'aquisicao/item/' + id.toString();
		}
	});

});
define('webapp/templates/components/botao-excluir', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    return {
      isHTMLBars: true,
      revision: "Ember@1.12.1",
      blockParams: 0,
      cachedFragment: null,
      hasRendered: false,
      build: function build(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createTextNode("	");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("button");
        dom.setAttribute(el1,"class","btn btn-default");
        dom.setAttribute(el1,"title","Excluir");
        var el2 = dom.createTextNode("\n		");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n		");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n	");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      render: function render(context, env, contextualElement) {
        var dom = env.dom;
        var hooks = env.hooks, get = hooks.get, attribute = hooks.attribute, element = hooks.element, inline = hooks.inline;
        dom.detectNamespace(contextualElement);
        var fragment;
        if (env.useFragmentCache && dom.canClone) {
          if (this.cachedFragment === null) {
            fragment = this.build(dom);
            if (this.hasRendered) {
              this.cachedFragment = fragment;
            } else {
              this.hasRendered = true;
            }
          }
          if (this.cachedFragment) {
            fragment = dom.cloneNode(this.cachedFragment, true);
          }
        } else {
          fragment = this.build(dom);
        }
        var element0 = dom.childAt(fragment, [1]);
        var morph0 = dom.createMorphAt(element0,1,1);
        var morph1 = dom.createMorphAt(element0,3,3);
        var attrMorph0 = dom.createAttrMorph(element0, 'id');
        var attrMorph1 = dom.createAttrMorph(element0, 'aria-label');
        var attrMorph2 = dom.createAttrMorph(element0, 'tabindex');
        attribute(env, attrMorph0, element0, "id", get(env, context, "id"));
        attribute(env, attrMorph1, element0, "aria-label", get(env, context, "textoAcessivel"));
        attribute(env, attrMorph2, element0, "tabindex", get(env, context, "tabindex"));
        element(env, element0, context, "action", ["excluir", get(env, context, "elementoId")], {});
        inline(env, morph0, context, "ui-icon", [], {"icon": "trash-o"});
        inline(env, morph1, context, "if", [get(env, context, "exibirTexto"), get(env, context, "texto")], {});
        return fragment;
      }
    };
  }()));

});
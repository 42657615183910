define('webapp/pods/fornecedor/penalidade/criar/route', ['exports', 'ember', 'webapp/pods/fornecedor/penalidade/model'], function (exports, Ember, Penalidade) {

	'use strict';

	exports['default'] = Ember['default'].Route.extend({
		controllerName: 'fornecedor/penalidade',
		setupController: function setupController(controller) {
			controller.set('model', Penalidade['default'].create({}));

			controller.set('tiposPenalidadeSelecionados', []);

			this.get('comunicador').leParaPropriedade(controller, 'fundamentoLegal/penalidade?distintos=true&apenasAtivos=true', 'fundamentosPenalidade',
			// this.get('comunicador').leParaPropriedade(controller, 'fundamentoLegal/penalidade', 'fundamentosPenalidade',
			"Não foi possível obter os fundamentos legais de penalidade!");
		},

		model: function model() {
			return Penalidade['default'].create();
		}
	});

});
define('webapp/pods/usuario/route', ['exports', 'ember', 'webapp/utils/util'], function (exports, Ember, Util) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({
    setupController: function setupController(controller, model) {
      controller.set('model', Util['default'].clona(model));

      this.get('comunicador').servico('get', 'role').then(function (resultado) {
        controller.set('todosOsPapeis', resultado);
      });
    }
  });

});
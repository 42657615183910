define('webapp/pods/papel/controller', ['exports', 'ember', 'webapp/mixins/crud-mixin'], function (exports, Ember, CrudMixin) {

    'use strict';

    exports['default'] = Ember['default'].Controller.extend(CrudMixin['default'], {
        needs: ['papel/listagem'],

        breadCrumbConfig: {
            fnNomeApresentacao: 'fnNomeApresentacao'
        },

        fnNomeApresentacao: function fnNomeApresentacao() {
            return 'Papel ' + (this.model.version ? this.model.nome : '(Novo)');
        },

        buscaOperacoesExistentes: (function () {
            this.get('comunicador').leParaPropriedade(this, 'operacao', 'todasAsOperacoes', 'Não foi possível obter as entidades existentes', true);
        }).on('init'),

        crudConfig: {
            criar: {
                okFn: function okFn() {
                    this.transitionToRoute('papel', this.model.id);
                    this.get('controllers.papel/listagem').send('listar');
                }
            },
            atualizar: {
                okFn: function okFn() {
                    this.get('controllers.papel/listagem').send('listar');
                    this.transitionToRoute('papel/listagem');
                }
            },
            excluir: {
                okFn: function okFn() {
                    this.get('controllers.papel/listagem').send('listar');
                }
            }
        },

        // tree view
        operacoesDisponiveisTreeView: (function () {
            var retorno = [];
            var todos = this.get('todasAsOperacoes');

            if (todos) {
                var operacoesAtribuidas = this.get('model.operacoes');

                // Adiciona os módulos
                var listaModulos = [];
                todos.forEach(function (item) {
                    // Um módulo pode conter submódulos (separados por "|"). Portanto, precisa varrer os submódulos e adicioná-los.
                    var listaSubModulos = item.modulo ? item.modulo.split("|") : [];
                    var idModulo = null;
                    var moduloPai = null;
                    for (var i = 0; i < listaSubModulos.length; i++) {
                        // Incrementa o id do módulo com o submódulo atual
                        if (!idModulo) {
                            idModulo = listaSubModulos[i];
                        } else {
                            idModulo = idModulo + "|" + listaSubModulos[i];
                        }

                        // se o submódulo ainda não está no retorno, insere
                        if (listaModulos.indexOf(idModulo) < 0) {
                            listaModulos.push(idModulo);

                            retorno.push({
                                id: idModulo,
                                idPai: moduloPai,
                                texto: listaSubModulos[i],
                                valor: null
                            });
                        }

                        // Seta o novo moduloPai com o id do submódulo atual
                        moduloPai = idModulo;
                    }
                });

                // Adiciona as operações nos módulos
                todos.forEach(function (item) {
                    var operacaoAtribuida = operacoesAtribuidas && operacoesAtribuidas.find(function (op) {
                        return op.id === item.id;
                    }) != null;
                    retorno.push({
                        id: item.id,
                        idPai: item.modulo,
                        texto: item.nome,
                        selecionado: operacaoAtribuida,
                        valor: item
                    });
                });
            }

            return retorno;
        }).property('todasAsOperacoes.@each', 'model.operacoes.@each'),

        refazSelecao: function refazSelecao(acao, valor, idsSelecionados) {
            var todos = this.get('todasAsOperacoes');
            var operacoesSelecionadas = [];
            idsSelecionados.forEach(function (item) {
                var operacao = todos.find(function (obj) {
                    return obj.id && obj.id.toString() === item;
                });
                if (operacao) {
                    operacoesSelecionadas.push(operacao);
                }
            });

            this.set("model.operacoes", operacoesSelecionadas);
            /*if(acao == "select_node") {
                if(!this.model.operacoes.find(function(item){ return item.id == valor.id })) {
                    this.model.operacoes.addObject(valor);
                }
            }
            else if(acao == "deselect_node") {
                this.model.operacoes = this.model.operacoes.filter(function(item) { return item.id != valor.id });
            }*/
        }

    });

});
define('webapp/pods/requisicao/identificacao/template', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        isHTMLBars: true,
        revision: "Ember@1.12.1",
        blockParams: 0,
        cachedFragment: null,
        hasRendered: false,
        build: function build(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n	");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","panel-body");
          var el2 = dom.createTextNode("\n		");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col-md-2");
          var el3 = dom.createTextNode("\n			");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n			");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n			");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n		");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n		");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col-md-6");
          var el3 = dom.createTextNode("\n\n			");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n\n			");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n\n      		");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n\n			");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n\n		");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n\n		");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col-md-4");
          var el3 = dom.createTextNode("\n\n			");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n\n			");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n\n			");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n\n			");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n\n		");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n	");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        render: function render(context, env, contextualElement) {
          var dom = env.dom;
          var hooks = env.hooks, get = hooks.get, inline = hooks.inline;
          dom.detectNamespace(contextualElement);
          var fragment;
          if (env.useFragmentCache && dom.canClone) {
            if (this.cachedFragment === null) {
              fragment = this.build(dom);
              if (this.hasRendered) {
                this.cachedFragment = fragment;
              } else {
                this.hasRendered = true;
              }
            }
            if (this.cachedFragment) {
              fragment = dom.cloneNode(this.cachedFragment, true);
            }
          } else {
            fragment = this.build(dom);
          }
          var element0 = dom.childAt(fragment, [1]);
          var element1 = dom.childAt(element0, [1]);
          var element2 = dom.childAt(element0, [3]);
          var element3 = dom.childAt(element0, [5]);
          var morph0 = dom.createMorphAt(element1,1,1);
          var morph1 = dom.createMorphAt(element1,3,3);
          var morph2 = dom.createMorphAt(element1,5,5);
          var morph3 = dom.createMorphAt(element2,1,1);
          var morph4 = dom.createMorphAt(element2,3,3);
          var morph5 = dom.createMorphAt(element2,5,5);
          var morph6 = dom.createMorphAt(element2,7,7);
          var morph7 = dom.createMorphAt(element3,1,1);
          var morph8 = dom.createMorphAt(element3,3,3);
          var morph9 = dom.createMorphAt(element3,5,5);
          var morph10 = dom.createMorphAt(element3,7,7);
          inline(env, morph0, context, "tag-input", [], {"texto": "Requisição Nº", "labelAcessivel": "Número da Requisição", "somenteLeitura": true, "valor": get(env, context, "model.numeroApresentacao"), "id": "tag||numero|requisicao|identificacao"});
          inline(env, morph1, context, "date-input", [], {"texto": "Data Criação", "somenteLeitura": true, "valor": get(env, context, "model.dataCriacao"), "id": "tag||dataCriacao|requisicao|identificacao"});
          inline(env, morph2, context, "moeda-input", [], {"texto": "Valor Total", "somenteLeitura": true, "valor": get(env, context, "model.valorTotal"), "id": "tag||valorTotal|requisicao|identificacao"});
          inline(env, morph3, context, "tag-select", [], {"texto": "Programa de Trabalho", "obrigatorio": true, "conteudo": get(env, context, "programasDeTrabalho"), "opcaoLabel": "nome", "valor": get(env, context, "model.programaDeTrabalho"), "textopadrao": "Selecione o programa de trabalho", "permiteLimpar": true, "id": "select||programaDeTrabalho|requisicao|identificacao", "desabilitado": get(env, context, "proibidoAlterar")});
          inline(env, morph4, context, "tag-textarea", [], {"texto": "Objeto", "obrigatorio": true, "numeroLinhas": "4", "valor": get(env, context, "model.objeto"), "textoPlaceHolder": "...", "id": "tag||objeto|requisicao|identificacao", "somenteLeitura": get(env, context, "proibidoAlterar")});
          inline(env, morph5, context, "busca-pa", [], {"texto": "Processo Administrativo", "atributoDestino": get(env, context, "model.numeroProcessoAdministrativo"), "obrigatorio": true, "labelAcessivel": "Número Processo Administrativo", "controllerDoContexto": get(env, context, "this"), "id": "tag||numeroProcessoAdministrativo|requisicao|identificacao"});
          inline(env, morph6, context, "tag-input", [], {"texto": "Observação", "valor": get(env, context, "model.observacao"), "somenteLeitura": get(env, context, "proibidoAlterar"), "id": "tag||observacao|requisicao|identificacao"});
          inline(env, morph7, context, "tag-select", [], {"texto": "Unidade Requisitante", "obrigatorio": true, "conteudo": get(env, context, "unidadesOrganizacionais"), "opcaoLabel": "nome", "valor": get(env, context, "model.unidadeRequisitante"), "textopadrao": "Selecione a unidade requisitante", "permiteLimpar": true, "id": "select||unidadeRequisitante|requisicao|identificacao", "desabilitado": get(env, context, "proibidoAlterar")});
          inline(env, morph8, context, "tag-input", [], {"texto": "E-mail da unidade", "somenteLeitura": true, "valor": get(env, context, "model.unidadeRequisitante.email"), "id": "tag||emailUnidade|requisicao|identificacao"});
          inline(env, morph9, context, "tag-input", [], {"texto": "Local de Entrega", "valor": get(env, context, "model.localEntrega"), "somenteLeitura": get(env, context, "proibidoAlterar"), "id": "tag||localEntrega|requisicao|identificacao"});
          inline(env, morph10, context, "tag-input", [], {"texto": "Prazo de Entrega", "valor": get(env, context, "model.prazoEntrega"), "somenteLeitura": get(env, context, "proibidoAlterar"), "id": "tag||prazoEntrega|requisicao|identificacao"});
          return fragment;
        }
      };
    }());
    return {
      isHTMLBars: true,
      revision: "Ember@1.12.1",
      blockParams: 0,
      cachedFragment: null,
      hasRendered: false,
      build: function build(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      render: function render(context, env, contextualElement) {
        var dom = env.dom;
        var hooks = env.hooks, block = hooks.block;
        dom.detectNamespace(contextualElement);
        var fragment;
        if (env.useFragmentCache && dom.canClone) {
          if (this.cachedFragment === null) {
            fragment = this.build(dom);
            if (this.hasRendered) {
              this.cachedFragment = fragment;
            } else {
              this.hasRendered = true;
            }
          }
          if (this.cachedFragment) {
            fragment = dom.cloneNode(this.cachedFragment, true);
          }
        } else {
          fragment = this.build(dom);
        }
        var morph0 = dom.createMorphAt(fragment,0,0,contextualElement);
        dom.insertBoundary(fragment, null);
        dom.insertBoundary(fragment, 0);
        block(env, morph0, context, "tag-bloco", [], {"titulo": "Identificação"}, child0, null);
        return fragment;
      }
    };
  }()));

});
define('webapp/models/permissao', ['exports', 'ember'], function (exports, Ember) {

	'use strict';

	exports['default'] = Ember['default'].Object.extend({
		id: null,
		nome: ''

	});
	//TODO: VERIFICAR SE ESTÁ SENDO CHAMADO E REMOVER CASO CONTRÁRIO
	/*
	find: function(id) {
		var permissoes =  App.storage.fornecedores.filter(
			function(f) { return f.get('id') === id; }
		);
		return permissoes[0];
	}
	*/

});
define('webapp/pods/publico/contratos/route', ['exports', 'ember', 'webapp/utils/util'], function (exports, Ember, Util) {

    'use strict';

    exports['default'] = Ember['default'].Route.extend({
        setupController: function setupController(controller) {
            // Configura informações da tela
            this.get('comunicador').servico('get', 'parametroExibicao').then(function (resultado) {
                controller.set('infoTela', Util['default'].converteParametrosDeExibicao(resultado).TRANSP_INFO_CONTRATO);
                controller.set('exibeCabecalho', Util['default'].converteParametrosDeExibicao(resultado).TRANSP_EXIBE_CABECALHOS == "S");
                controller.set('urlLogo', Util['default'].converteParametrosDeExibicao(resultado).TRANSP_URL_LOGO);
                controller.set('retroagir', Util['default'].converteParametrosDeExibicao(resultado).TRANSP_QTDE_ANOS_RETROAGIR);
                controller.set('exibeApostilas', Util['default'].converteParametrosDeExibicao(resultado).TRANSP_EXIBE_APOSTILAS === "S");
                controller.set('exibeAditivos', Util['default'].converteParametrosDeExibicao(resultado).TRANSP_EXIBE_ADITIVOS === "S");
            });

            // Parâmetros de URL
            var queryParams = controller.get('queryParams');
            var temFiltroQuery = false;
            var temFiltroDeTipoNaQuery = false;
            var filtroTipoNaQuery = "";
            queryParams.forEach(function (param) {
                if (controller.get(param)) {
                    temFiltroQuery = true;
                    if (param == "tipo") {
                        temFiltroDeTipoNaQuery = true;
                        filtroTipoNaQuery = controller.get(param);
                    }
                }
            });
            // seta filtro padrão, caso não tenha sido filtrado pela query string
            if (!temFiltroQuery) {
                var dataAtual = new Date();
                var mesAnterior = dataAtual.getMonth();
                if (mesAnterior == 0) {
                    // Casos de janeiro, daí o filtro padrão deve ser dezembro do ano anterior
                    controller.set('anoAssinatura', dataAtual.getFullYear() - 1);
                    controller.set('mesAssinatura', "12");
                } else {
                    controller.set('anoAssinatura', dataAtual.getFullYear());
                    controller.set('mesAssinatura', mesAnterior < 10 ? "0" + mesAnterior : mesAnterior); // mês anterior propositalmente
                }
                controller.set('chkContratos', true);
                controller.set('chkAditivos', true);
            } else {
                // tratamento para o TIPO
                if (temFiltroDeTipoNaQuery) {
                    if (filtroTipoNaQuery == "CONTRATO") {
                        controller.set('chkContratos', true);
                        controller.set('chkAditivos', false);
                    } else if (filtroTipoNaQuery == "ADITIVO") {
                        controller.set('chkContratos', false);
                        controller.set('chkAditivos', true);
                    } else {
                        controller.set('chkContratos', true);
                        controller.set('chkAditivos', true);
                    }
                } else {
                    controller.set('chkContratos', true);
                    controller.set('chkAditivos', true);
                }
            }

            controller.send('listar');
        }
    });

});
define('webapp/pods/fornecedor/listagem/controller', ['exports', 'ember'], function (exports, Ember) {

	'use strict';

	exports['default'] = Ember['default'].ArrayController.extend({
		queryParams: ['manterDadosPesquisaAnterior'],
		manterDadosPesquisaAnterior: false,

		breadCrumbConfig: {
			nomeApresentacao: 'Fornecedores'
		},

		_gerenciarDadosPesquisaAnterior: function _gerenciarDadosPesquisaAnterior() {
			if (!this.manterDadosPesquisaAnterior) {
				this.limparDadosPesquisa();
			}

			this.set('manterDadosPesquisaAnterior', false);
		},

		limparDadosPesquisa: function limparDadosPesquisa() {
			this.limparCamposPesquisa();
			this.limparTabelaResultados();
		},

		limparCamposPesquisa: function limparCamposPesquisa() {
			//limpa campos de pesquisa
			this.set('nome', undefined);
			this.set('nomeFantasia', undefined);
			this.set('municipio', undefined);
			this.set('cnpj', undefined);
			this.set('cpf', undefined);
			this.set('fornecido', undefined);
			this.set('fornecivel', undefined);
		},

		limparTabelaResultados: function limparTabelaResultados() {
			//limpa tabela de resultados
			this.set('mostraTabelaResultadoPesquisa', false);
			this.set('mostraMensagemPesquisaSemResultado', false);
		},

		actions: {
			vaiParaFornecedor: function vaiParaFornecedor(informacaoPesquisa) {
				this.transitionToRoute('fornecedor', informacaoPesquisa.id);
			},

			pesquisar: function pesquisar() {
				var _this = this;

				this.set('mostrarResultados', false);

				this.get('comunicador').servico('post', 'fornecedor/pesquisa', this.getProperties('nome', 'nomeFantasia', 'municipio', 'cnpj', 'cpf', 'fornecido', 'fornecivel'), this).then(function (resultado) {
					_this.set("model", resultado);
					var retornouItems = _this.get('model').length > 0;
					_this.set('mostraTabelaResultadoPesquisa', retornouItems);
					_this.set('mostraMensagemPesquisaSemResultado', !retornouItems);
					_this.set('mostrarResultados', true);
				});
			},

			limparPesquisa: function limparPesquisa() {
				this.limparDadosPesquisa();
				this.set('model', []);
			}
		},

		descricaoRelatorios: (function () {

			return [{
				textoMenu: 'Relatório Anual de Penalidades',
				configChamada: {
					parametros: [{ nome: 'ano', nomeLegivel: 'Ano' }],
					getUrlServico: function getUrlServico(objetoParametros) {
						return 'relatorio/penalidadesPorAno/' + objetoParametros.ano;
					}
				},
				metodo: 'get',
				dados: function dados() {
					return null;
				},
				nomeArquivo: 'penalidades_por_ano',
				nomeParaID: 'anual-penalidades',
				controlePermissao: true
			}];
		}).property('model')

	});

});
define('webapp/components/tag-select', ['exports', 'ember', 'webapp/mixins/ajusta-id-interno-mixin', 'webapp/mixins/ajusta-tamanho-input-mixin', 'webapp/utils/id'], function (exports, Ember, AjustaIdInternoMixin, AjustaTamanhoInputMixin, UtilID) {

	'use strict';

	exports['default'] = Ember['default'].Component.extend(AjustaIdInternoMixin['default'], AjustaTamanhoInputMixin['default'], {
		tipoInterno: 'campo',
		multiplo: false,
		formatoTabela: false,
		desabilitado: false,

		insereAriaLabelPos: (function () {
			var elementoFocalizavel = null;
			var ariaLabel = this.labelAcessivel ? this.labelAcessivel : this.texto;
			if (this.obrigatorio) {
				ariaLabel += ', campo obrigatório';
			}

			if (this.somenteLeitura) {

				ariaLabel += ', somente leitura. ';
				elementoFocalizavel = this.$()[0].children[1];
				// if(this.multiplo) {
				// 	elementoFocalizavel = this.$('textarea')[0];
				// } else {
				// 	elementoFocalizavel = this.$('input')[0];
				// }
			} else {

					ariaLabel += this.multiplo ? '. Seleção múltipla.' : '. Selecione. ';

					var div = this.$()[0];
					//div.setAttribute('tabindex', '0');
					var divInterno = div.getElementsByTagName('div')[0];

					if (this.multiplo) {
						var ul = divInterno.children[0];
						var input = ul.getElementsByTagName('input')[0];
						// var li = ul.children[0];
						// var input = li.children[1];
						elementoFocalizavel = input;
					} else {
						var ancora = divInterno.children[0];
						var span = ancora.children[0];
						elementoFocalizavel = span;
					}
				}

			var valoresApresentar = this.get('_valorApresentarSomenteLeitura');
			if (this.multiplo) {
				ariaLabel += valoresApresentar ? 'Valores selecionados: ' + valoresApresentar : 'Nenhum valor selecionado';
			} else {
				ariaLabel += valoresApresentar ? 'Valor selecionado: ' + valoresApresentar : 'Nenhum valor selecionado';
			}

			elementoFocalizavel.setAttribute('aria-label', ariaLabel);
		}).on('didInsertElement').observes('_valorApresentarSomenteLeitura'),

		focalizar: (function () {
			if (this.autoFoco) {
				this.$('input')[0].focus();
			}
		}).on('didInsertElement'),

		_idLabel: (function () {
			if (!this.id) {
				return '';
			};

			var porcaoAdicionalIdComponente = UtilID['default'].getPorcaoOpcionalId(this.id);
			return porcaoAdicionalIdComponente ? UtilID['default'].prefixaSeccao('label', 'opcional', this.id) : UtilID['default'].incluiSeccaoOpcional(this.id, 'label');
		}).property('id'),

		_idInput: (function () {
			if (!this.id) {
				return '';
			};

			var porcaoAdicionalIdComponente = UtilID['default'].getPorcaoOpcionalId(this.id);
			return porcaoAdicionalIdComponente ? UtilID['default'].prefixaSeccao('input', 'opcional', this.id) : UtilID['default'].incluiSeccaoOpcional(this.id, 'input');
		}).property('id'),

		estiloCampo: (function () {
			return this.multiplo && this.formatoTabela ? 'height:auto;' : '';
		}).property('multiplo'),

		ehTextoVazio: (function () {
			return this.texto === '';
		}).property('texto'),

		exibeTexto: (function () {
			return this.texto !== undefined;
		}).property('texto'),

		_habilitado: (function () {
			return !this.desabilitado;
		}).property('desabilitado'),

		_ajustaCursor: (function () {
			this.$().find('*').css('cursor', this.desabilitado ? 'not-allowed' : 'default');
		}).on('didInsertElement').observes('desabilitado'),

		_valorApresentarSomenteLeitura: (function () {
			return this.multiplo ? this.get('_valorSomenteLeituraMultiplo') : this.get('_valorSomenteLeituraSimples');
		}).property('valor'),

		_valorSomenteLeituraMultiplo: (function () {
			var _this = this;

			if (!this.valor || !this.valor.length) {
				return '';
			}

			return this.valor.map(function (valorAtual) {
				return valorAtual[_this.opcaoLabel];
			}).reduce(function (valoresConcatenados, valorAtual) {
				return valoresConcatenados + '; ' + valorAtual;
			});
		}).property('valor'),

		_valorSomenteLeituraSimples: (function () {
			var _this2 = this;

			var opcaoSelecionada = null;

			if (this.opcaoValor) {

				if (!this.conteudo) {
					return;
				}

				opcaoSelecionada = this.conteudo.find(function (item) {
					return item[_this2.opcaoValor] === _this2.valor;
				});
			} else {
				opcaoSelecionada = this.valor;
			}

			if (!opcaoSelecionada) {
				return;
			}

			var atributoLabel = this.opcaoLabelSelecionada ? this.opcaoLabelSelecionada : this.opcaoLabel;

			return opcaoSelecionada[atributoLabel];
		}).property('valor')

	});

});
define('webapp/pods/aquisicao/andamento/controller', ['exports', 'ember', 'webapp/mixins/crud-mixin'], function (exports, Ember, CrudMixin) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend(CrudMixin['default'], {

    needs: ['aquisicao'],

    breadCrumbConfig: {
      fnNomeApresentacao: 'fnNomeApresentacao'
    },

    fnNomeApresentacao: function fnNomeApresentacao() {
      return 'Andamento ' + (this.model.version ? this.model.numeroApresentacao : '(Novo)');
    },

    crudConfig: {
      criar: {
        urlFn: function urlFn() {
          return 'aquisicao/' + this.get("controllers.aquisicao").model.id + '/andamento';
        },
        okFn: function okFn(opcao) {
          this.get('controllers.aquisicao').send('ler');
          if (opcao === 'CRIAR_E_PROXIMO') {
            //ação "borbulhará" para a rota
            this.send('refreshRota');
            return;
          }
          this.transitionToRoute('aquisicao');
        }
      },
      atualizar: {
        okFn: function okFn() {
          this.get('controllers.aquisicao').send('ler');
          this.transitionToRoute('aquisicao');
        }
      },
      excluir: {
        okFn: function okFn() {
          this.get('controllers.aquisicao').send('ler');
        }
      }
    }

  });

});
define('webapp/pods/aquisicao/item/identificacao/template', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      var child0 = (function() {
        return {
          isHTMLBars: true,
          revision: "Ember@1.12.1",
          blockParams: 0,
          cachedFragment: null,
          hasRendered: false,
          build: function build(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("				");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          render: function render(context, env, contextualElement) {
            var dom = env.dom;
            var hooks = env.hooks, get = hooks.get, inline = hooks.inline;
            dom.detectNamespace(contextualElement);
            var fragment;
            if (env.useFragmentCache && dom.canClone) {
              if (this.cachedFragment === null) {
                fragment = this.build(dom);
                if (this.hasRendered) {
                  this.cachedFragment = fragment;
                } else {
                  this.hasRendered = true;
                }
              }
              if (this.cachedFragment) {
                fragment = dom.cloneNode(this.cachedFragment, true);
              }
            } else {
              fragment = this.build(dom);
            }
            var morph0 = dom.createMorphAt(fragment,1,1,contextualElement);
            inline(env, morph0, context, "decimal-input", [], {"texto": "Duração (meses)", "valor": get(env, context, "model.duracao"), "mascara": "numeros", "obrigatorio": true, "somenteLeitura": true, "id": "tag||duracao|aquisicao/item|identificacao"});
            return fragment;
          }
        };
      }());
      var child1 = (function() {
        return {
          isHTMLBars: true,
          revision: "Ember@1.12.1",
          blockParams: 0,
          cachedFragment: null,
          hasRendered: false,
          build: function build(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("		");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","row");
            var el2 = dom.createTextNode("\n		\n			");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n	\n		");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          render: function render(context, env, contextualElement) {
            var dom = env.dom;
            var hooks = env.hooks, get = hooks.get, inline = hooks.inline;
            dom.detectNamespace(contextualElement);
            var fragment;
            if (env.useFragmentCache && dom.canClone) {
              if (this.cachedFragment === null) {
                fragment = this.build(dom);
                if (this.hasRendered) {
                  this.cachedFragment = fragment;
                } else {
                  this.hasRendered = true;
                }
              }
              if (this.cachedFragment) {
                fragment = dom.cloneNode(this.cachedFragment, true);
              }
            } else {
              fragment = this.build(dom);
            }
            var morph0 = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
            inline(env, morph0, context, "tag-input", [], {"texto": "Código Catálogo", "labelAcessivel": "Código Catálogo", "valor": get(env, context, "model.codigoCatalogo"), "tamanho": 3, "somenteLeitura": get(env, context, "itemAdjudicado"), "id": "tag||codigoCatalogo|aquisicao/item|identificacao"});
            return fragment;
          }
        };
      }());
      return {
        isHTMLBars: true,
        revision: "Ember@1.12.1",
        blockParams: 0,
        cachedFragment: null,
        hasRendered: false,
        build: function build(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("	");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","row");
          var el2 = dom.createTextNode("\n		");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n		");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col-md-3");
          dom.setAttribute(el2,"style","padding-top:23px;");
          var el3 = dom.createTextNode("\n			");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n			");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          dom.setAttribute(el3,"class","control-label");
          var el4 = dom.createTextNode("Item Periódico");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n		");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n	");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n	");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","row");
          var el2 = dom.createTextNode("\n		");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n		");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n		");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n	");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n	");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","row");
          var el2 = dom.createTextNode("\n		");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col-md-3 paddingZero");
          var el3 = dom.createTextNode("\n			");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("		");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n		");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col-md-3");
          var el3 = dom.createTextNode("\n			");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","panel panel-default panel-caixa");
          dom.setAttribute(el3,"style","margin-left: 0px; padding: 0px 2px 10px 0px; border: 0px; box-shadow: 0 0px 0px rgb(0 0 0 / 5);");
          var el4 = dom.createTextNode("\n\n			");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("fieldset");
          dom.setAttribute(el4,"style","font-weight: bold;");
          var el5 = dom.createTextNode("\n				");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("legend");
          dom.setAttribute(el5,"style","margin: 0px 10px 5px 0px; font-size: 14px");
          var el6 = dom.createTextNode("Natureza");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n\n				");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n				");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n			");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n			\n			");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("fieldset");
          dom.setAttribute(el4,"style","font-weight: bold");
          var el5 = dom.createTextNode("\n 					");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("legend");
          dom.setAttribute(el5,"style","margin-top: 10px; margin-bottom: 5px; font-size: 14px");
          var el6 = dom.createTextNode("Orçamento Sigiloso");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n	            ");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n	            ");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n			");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n\n			");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n		");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n		");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n	");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        render: function render(context, env, contextualElement) {
          var dom = env.dom;
          var hooks = env.hooks, get = hooks.get, inline = hooks.inline, block = hooks.block;
          dom.detectNamespace(contextualElement);
          var fragment;
          if (env.useFragmentCache && dom.canClone) {
            if (this.cachedFragment === null) {
              fragment = this.build(dom);
              if (this.hasRendered) {
                this.cachedFragment = fragment;
              } else {
                this.hasRendered = true;
              }
            }
            if (this.cachedFragment) {
              fragment = dom.cloneNode(this.cachedFragment, true);
            }
          } else {
            fragment = this.build(dom);
          }
          var element0 = dom.childAt(fragment, [1]);
          var element1 = dom.childAt(fragment, [3]);
          var element2 = dom.childAt(fragment, [5]);
          var element3 = dom.childAt(element2, [1]);
          var element4 = dom.childAt(element2, [3, 1]);
          var element5 = dom.childAt(element4, [1]);
          var element6 = dom.childAt(element4, [3]);
          var morph0 = dom.createMorphAt(element0,1,1);
          var morph1 = dom.createMorphAt(dom.childAt(element0, [3]),1,1);
          var morph2 = dom.createMorphAt(element1,1,1);
          var morph3 = dom.createMorphAt(element1,3,3);
          var morph4 = dom.createMorphAt(element1,5,5);
          var morph5 = dom.createMorphAt(element3,1,1);
          var morph6 = dom.createMorphAt(element3,3,3);
          var morph7 = dom.createMorphAt(element5,3,3);
          var morph8 = dom.createMorphAt(element5,5,5);
          var morph9 = dom.createMorphAt(element6,3,3);
          var morph10 = dom.createMorphAt(element6,5,5);
          var morph11 = dom.createMorphAt(element2,5,5);
          var morph12 = dom.createMorphAt(fragment,7,7,contextualElement);
          inline(env, morph0, context, "tag-input", [], {"texto": "Nº", "labelAcessivel": "Número do item", "valor": get(env, context, "model.numeroApresentacao"), "mascara": "numeros", "somenteLeitura": true, "tamanho": 3, "id": "tag||numero|ad-item|identificacao"});
          inline(env, morph1, context, "input", [], {"type": "checkbox", "name": "ehPeriodico", "checked": get(env, context, "model.periodico"), "somenteLeitura": get(env, context, "ehItemAquisicaoExistente"), "id": "checkbox||periodico|aquisicao/item|identificacao"});
          inline(env, morph2, context, "decimal-input", [], {"texto": "Quantidade Própria", "labelAcessivel": "Quantidade própria", "valor": get(env, context, "model.quantidadePropria"), "obrigatorio": true, "somenteLeitura": true, "tamanho": 3, "id": "tag||quantidadePropria|ad-item|identificacao"});
          inline(env, morph3, context, "decimal-input", [], {"texto": "Quantidade Total", "labelAcessivel": "Quantidade total", "valor": get(env, context, "model.quantidadeTotal"), "somenteLeitura": true, "tamanho": 3, "id": "tag||quantidadeTotal|ad-item|identificacao"});
          inline(env, morph4, context, "tag-select", [], {"texto": "Classificação", "obrigatorio": true, "conteudo": get(env, context, "classificacoesItem"), "opcaoLabel": "nome", "valor": get(env, context, "model.classificacao"), "textopadrao": "Selecione", "permiteLimpar": true, "id": "select||classificacaoItem|ad-item|identificacao", "desabilitado": get(env, context, "itemAdjudicado"), "tamanho": 6});
          inline(env, morph5, context, "tag-select", [], {"texto": "Unidade", "obrigatorio": true, "conteudo": get(env, context, "unidadesDeMedida"), "opcaoLabel": "nomeExibicao", "valor": get(env, context, "model.unidadeMedida"), "textopadrao": "Selecione", "permiteLimpar": true, "id": "select||unidadeMedidaItem|ad-item|identificacao", "desabilitado": true});
          block(env, morph6, context, "if", [get(env, context, "model.periodico")], {}, child0, null);
          inline(env, morph7, context, "tag-radio-button", [], {"texto": "Material", "labelAcessivel": "Material", "autoFoco": true, "labelNegrito": true, "tamanho": 6, "colSm": true, "valor": "MATERIAL", "atributoDestino": get(env, context, "model.naturezaItem"), "desabilitado": true, "id": "radio||naturezaMaterial|aquisicao/item|identificacao"});
          inline(env, morph8, context, "tag-radio-button", [], {"texto": "Serviço", "labelAcessivel": "Serviço", "labelNegrito": true, "tamanho": 6, "colSm": true, "valor": "SERVICO", "atributoDestino": get(env, context, "model.naturezaItem"), "desabilitado": true, "id": "radio||naturezaServico|aquisicao/item|identificacao"});
          inline(env, morph9, context, "tag-radio-button", [], {"texto": "Sim", "valor": true, "atributoDestino": get(env, context, "model.orcamentoSigiloso"), "labelNegrito": true, "tamanho": 6, "colSm": true, "somenteLeitura": get(env, context, "itemAdjudicado"), "id": "radio||tem-orcamento-sigiloso|aquisicao/item|identificacao"});
          inline(env, morph10, context, "tag-radio-button", [], {"texto": "Não", "valor": false, "atributoDestino": get(env, context, "model.orcamentoSigiloso"), "labelNegrito": true, "tamanho": 6, "colSm": true, "somenteLeitura": get(env, context, "itemAdjudicado"), "id": "radio||nao-tem-orcamento-sigiloso|aquisicao/item|identificacao"});
          inline(env, morph11, context, "tag-textarea", [], {"texto": "Descrição", "obrigatorio": true, "numeroLinhas": "6", "valor": get(env, context, "model.descricao"), "somenteLeitura": get(env, context, "itemAdjudicado"), "id": "campo||detalhes|ad-item|identificacao", "tamanho": 6});
          block(env, morph12, context, "if", [get(env, context, "habilitarCatalogo")], {}, child1, null);
          return fragment;
        }
      };
    }());
    return {
      isHTMLBars: true,
      revision: "Ember@1.12.1",
      blockParams: 0,
      cachedFragment: null,
      hasRendered: false,
      build: function build(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      render: function render(context, env, contextualElement) {
        var dom = env.dom;
        var hooks = env.hooks, block = hooks.block;
        dom.detectNamespace(contextualElement);
        var fragment;
        if (env.useFragmentCache && dom.canClone) {
          if (this.cachedFragment === null) {
            fragment = this.build(dom);
            if (this.hasRendered) {
              this.cachedFragment = fragment;
            } else {
              this.hasRendered = true;
            }
          }
          if (this.cachedFragment) {
            fragment = dom.cloneNode(this.cachedFragment, true);
          }
        } else {
          fragment = this.build(dom);
        }
        var morph0 = dom.createMorphAt(fragment,0,0,contextualElement);
        dom.insertBoundary(fragment, null);
        dom.insertBoundary(fragment, 0);
        block(env, morph0, context, "tag-bloco", [], {"titulo": "Identificação do Item"}, child0, null);
        return fragment;
      }
    };
  }()));

});
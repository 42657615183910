define('webapp/models/banco', ['exports', 'ember'], function (exports, Ember) {

	'use strict';

	exports['default'] = Ember['default'].Object.extend({
		id: null,
		codigo: '',
		nome: '',
		cnpj: '',

		findUrlFn: function findUrlFn(id) {
			return 'banco/' + id.toString();
		}
	});

});
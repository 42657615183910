define('webapp/pods/papel/criar/route', ['exports', 'ember', 'webapp/pods/papel/model'], function (exports, Ember, Papel) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({
    controllerName: 'papel',
    setupController: function setupController(controller) {
      controller.set('model', Papel['default'].create({}));
    }
  });

});
define('webapp/tests/unit/controllers/requisicao-test', ['ember-qunit', 'ember', 'webapp/mixins/crud-mixin'], function (ember_qunit, Ember, CrudMixin) {

  'use strict';

  ember_qunit.moduleFor('controller:requisicao', {
    needs: ['controller:requisicao/listagem']
  });

  var crudMixinTeste = CrudMixin['default'];

  ember_qunit.test('Controller de "requisicao" controller deve ter ações de CRUD', function (assert) {

    var controller = this.subject();
    var crudMixin = crudMixinTeste;

    assert.ok(crudMixin.detect(controller), 'Controller de "requisicao" deve implementar o CrudMixin');
    assert.ok(controller._actions.criar, 'Controller de "requisicao" precisa ter uma ação "criar"');
    assert.ok(controller._actions.ler, 'Controller de "requisicao" precisa ter uma ação "ler"');
    assert.ok(controller._actions.atualizar, 'Controller de "requisicao" precisa ter uma ação "atualizar"');
    assert.ok(controller._actions.excluir, 'Controller de "requisicao" precisa ter uma ação "excluir"');
  });

});
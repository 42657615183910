define('webapp/components/tabela-form-leve', ['exports', 'ember', 'webapp/utils/id', 'webapp/utils/util', 'webapp/defs'], function (exports, Ember, UtilID, Util, Defs) {

  'use strict';

  /*
   Exemplo de uso:
   {{tabela-form-leve
   fonte=this.dadosBancarios
   colunas='[{"titulo": "Nº", "atributo": "banco.numero", "tipo": "numero", "tituloAcessivel": "Número do Banco" },
             {"titulo": "Cod.", "atributo": "banco.codigo", "tipo": "numero", "tituloAcessivel": "Código do Banco" },
             {"titulo": "Nome Do Banco", "atributo": "banco.nome" },
             {"titulo": "Agência", "atributo": "agencia" },
             {"titulo": "Conta", "atributo": "conta" },
             {"titulo": "link", "metodoApresentacao": "montaLink", "metodoLink": "getLink", "tituloLink": "download", "abreLinkEmNovaJanela": "true" },
             {"titulo": "Exemplo de info composta", "metodoApresentacao": "<nomeDoMetodo>" }]'
   ordemPadrao=<valor do campo 'atributo' do objeto colunas | valor do campo 'metodoApresentacao' do objeto colunas>
   tipoOrdemPadrao=asc|desc
   atributoID='codSof'
   funcaoEscolhaCorCelula="nomeFuncao"
   metodosExibicaoFonteColunas='{"agencia": "mostraAgencia"}'
   colunasNaoEscapar='["agencia"]'
   contexto="dadoBancario" //opcionalmente pode se passar this (ver NOTA 2)
   funcaoIdsRota="nomeFuncao"
   rota="nome/da/rota"
   permiteAcessar=<true|false (default)>
   testeParaOcultarBotaoAcessar="errosEnvio.dataHoraTentativa == null"  --> O campo a ser testado (errosEnvio - que tem uma propriedade dataHoraTentativa - neste exemplo) DEVE pertencer ao objeto apontado pelo atributo "fonte" (this.dadosBancarios, neste exemplo)
                                                                            O teste fará com que o botão "Acessar" fique oculto nas linhas em que retornar true.
   acessoSemRota=<true|false (default)>  --> Quando for determinada uma ação do controller para ser chamada quando o botão "Acessar" for clicado, essa propriedade deve ser true
   permiteExcluir=<true|false (default)>
   mostraTextoBotoes=<true|false (default)>
   paginacao=<true|false (default)>
   exibeControlesSuperiores=<true (default)|false, indicando se devem ser mostrados os controles
                              na parte de cima da tabela, como a pesquisa, paginação, itens por página, ...>
   exibeControlesInferiores=<true (default)|false, indicando se devem ser mostrados os controles
                              na parte de baixo da tabela, como paginação, total de registros da tabela, ...>
   acaoCustomizada='{
                     "nomeNoController":"selecionarServidor",
                     "nomeAcaoParaID":"selecionar",
                     "nomeLegivel": "Selecionar",
                     "icone": <texto que represente ícone do Font Awesome - http://gkoberger.github.io/Font-Awesome/icons/>,
                     "tooltip": <texto para aparecer quando passar o mouse no botão;
                                  o default é o texto do nomeLegivel>
                    }'
   controlePermissao=<true|false (default), indicando se deve haver controle de permissão
                      para a tabela como um todo - se ela deve ou não aparecer>
   controlePermissaoAcessar=<true|false (default), indicando se deve haver controle de permissão
                      para os botões de acessar da tabela>
   controlePermissaoExcluir=<true|false (default), indicando se deve haver controle de permissão
                      para os botões de excluir da tabela>
   controlePermissaoAcaoCustomizada=<true|false (default), indicando se deve haver controle de
                                     permissão para os botões de ação customizada da tabela>
  }}

   // *** PARAMETROS ***
  - colunas: objeto contendo informações sobre a coluna, tais como título, atributo correspondente e
    tipo (importante para orientar a ordenação da coluna). Inclui opção de título acessível para os
    casos em que o cabeçalho da tabela apresentem valores difíceis de serem lidos por leitores
    automáticos de tela (ex. "Nº").
        - Também inclui a opção "metodoApresentacao", possibilitando a indicação de um método (que deve estar
        no controller do contexto) para apresentar informações que não estejam descritas diretamente como
        atributo dos objetos que estão sendo listados (por exemplo, em um objeto pessoa com atributos "nome",
        "sobrenome" e "idade", isso poderia ser utilizado para apresentar o nome completo da pessoa (nome + sobrenome)
        ou a faixa etária (jovem, adulto, idoso). O método deve receber como parâmetro o item da lista, por exemplo
            nomeCompleto: function(pessoa) {
              return pessoa.nome + ' ' + pessoa.sobrenome;
            }
        - Também inclui:
           . a opção "metodoLink", para que se possa definir um método que devolverá uma url que será chamada quando
             clicarmos no campo;
           . a opção "tituloLink" para, opcionalmente, darmos um valor para o atributo html title do link.
           . a opção "abreLinkEmNovaJanela" para, opcionalmente, abrir o link em uma nova janela caso tenha o valor "true".
             Também gera um ícone à direita do link para uma indicação visual deste comportamento.
             Obs.: Caso esta opção seja "true" e a opção "tituloLink" não seja definida, o título do link terá o valor
                   default "Abre o link em uma nova janela".
           . a opção "textoAcessivel" para, opcionalmente, informarmos qual será o nome do método dentro do controller 
             de contexto que deverá fornecer o texto para ser lido pelos leitores de tela. Esse método irá receber os 
             seguintes argumentos: 
                  1. valor: o valor que será mostrado na coluna;
                  2. tituloColuna: o título ou o titulo acessivel da coluna, caso este último tenha sido declarado;
                  3. textoAcessivelDefaultDoComponente: Como o nome diz, é aquele texto que o Helper mostra-celula monta
                     quando nenhum texto acessível é declarado para a coluna.
             e deverá retornar o texto acessível que, de fato, será lido pelos leitores de tela. P.ex.:
                  getTextoAcessivel: function (valor, tituloColuna, textoAcessivelDefaultDoComponente) {
                       return textoAcessivelDefaultDoComponente + ", download";  
                  },

  - ordemPadrao: Indica qual coluna será classificada em ordem ascendente (ou descendent, conforme 'tipoOrdemPadrao')
  - fonte: de onde vêm os dados
  - fonteColunas: informação de 'fonte' para cada coluna
  - atributoID: informação de 'fonte'  que vai ser usada como id do objeto da linha, caso se queira
   utilizar um campo diferente de 'id'.
  - funcaoEscolhaCorCelula: string representando o nome da função do controller que será chamada
   para decidir qual será a cor do conteúdo da célula
  - metodosExibicaoFonteColunas: string com objeto onde cada chave representa uma coluna em
   fonteColunas e o valor é um string com o nome de um método no controller do contexto para transformar
   os dados em valores para visualização. O método deve receber o objeto inteiro da linha da tabela e
   devolver o valor que se deseja para a coluna indicada (mesmo que esse valor seja baseado em mais de
   um atributo do objeto). O nome da coluna, nesses casos, não precisa ser o nome de um atributo específico,
   podendo ser um nome representativo do valor que se obterá na coluna.
  - colunasNaoEscapar: string com um array dos nomes das propriedades que representam colunas cujo
   valor deve ser interpretado como código HTML (por exemplo, para usar CSS de algum caractere especial)
  - contexto: controller relacionado à tabela
  - funcaoIdsRota: função que recebe o objeto inteiro da linha da tabela e devolve um array com os ids
    necessários para que se consiga acessar a rota representada pelo contexto, na ordem em que eles devem
    aparecer na rota. Isso é necessário quando as rotas e controllers necessários para chegar ao último
    nodo da rota do contexto ainda não estão instanciadas (por exemplo, se se estiver na rota "/" e se
    quiser acessar diretamente "/rotaA/12/rotaB/141/rotaC/9"). Caso não seja informada essa função, o link
    será inferido automaticamente pelo Ember dado o contexto informado e o apenas o id da entidade a ser
    acessada.
  - rota: caso se queira utilizar uma rota alternativa para acessar os itens da tabela (que tenha um nome
    diferente do contexto passado para o componente), deve-se usar essa opção (ex. "rotaA/rotaB")
  - permiteAcessar: mostra botão "Acessar"
  - permiteExcluir: mostra botão "Excluir"
  - paginacao: se true, apresenta as linhas de forma paginada; se false (default), os resultados são
    apresentados, com uma barra de rolagem.
  - acaoCustomizada: cria um botão com a ação descrita por "nomeNoController", com o texto "nomeLegivel".

   -- INFO --
  Toda tabela com id "tabela|W|X|Y|Z"
  cria botões com id "botao|excluir|X|Y|tabela-linha<nro linha>"     ===> caso permiteExcluir=true
  e links com id "link|ler|X|Y|tabela-linha<nro linha>"              ===> caso permiteAcessar=true

   NOTA: As colunas são um array de strings dentro de um string (ex. '["coluna1", "coluna2"]']),
   que será "parsed" pelo JSON. Isso é necessário porque não conseguimos passar o array javascript
   diretamente.

   NOTA 2: caso o contexto seja um controller, basta indicar seu nome no contexto.
   Caso seja um componente externo, é necessário que se passe a própria instância do controller
   para que, ao chamar ações do contexto do componente externo, se tenha acesso às variáveis
   internas do componente externo. NESSES CASOS HAVERÁ PROBLEMAS DE ACESSO AOS ITENS DA LISTA,
   JÁ QUE ELE É BASEADO EM UM ENDEREÇO REPRESENTADO PELO NOME DO CONTEXTO .
   */

  exports['default'] = Ember['default'].Component.extend({
      paginaAtual: 1,
      registrosPorPagina: null, // se não setado pelo usuário, buscará padrão no defs
      quantidadePaginas: null, // se não setado pelo usuário, buscará padrão no defs
      controleRefazerTabela: false,
      filaOrdenacoes: [],
      arrayColunas: null,
      fonteFiltrada: [],
      valorFiltroPesquisa: null,
      campoFiltroPesquisa: "[*]",
      tituloCampoFiltroPesquisa: "",
      ordemPadrao: null,
      _qtdItensInfinitos: 999999999,

      // Propriedades de exibição de controles
      comPaginacao: true,
      comFiltro: true,
      exibeControlesSuperiores: true,
      exibeControlesInferiores: true,
      exibePaginacaoSuperior: false,
      exibePaginacaoInferior: true,

      tagName: 'table',
      classNameBindings: ['classText'],
      classText: 'containerTabela table table-striped table-hover dataTable',
      atributoID: 'id', //indica o atributo que identifica os itens da fonte - o default é 'id'
      permiteExcluir: false, //indica se mostra o botão de excluir nos itens da tabela
      permiteAcessar: false, //indica se mostra o botão de acessar nos itens da tabela
      acessoSemRota: false, // indica se a tela para o acesso deve ou não ter uma rota
      mostraTextoBotoes: false, //indica se os botões da tabela terão texto;
      //se for falso, aparecerão apenas os ícones
      table: null, //armazena o objeto dataTables

      metodosExibicaoFonteColunas: '{}', //inicialização de parâmetro
      colunasNaoEscapar: '[]', //inicialização de parâmetro
      scroll: false,

      tooltipAcaoCustomizada: (function () {
          return this.get('acaoCustomizadaParse').tooltip || this.get('acaoCustomizadaParse').nomeLegivel;
      }).property('acaoCustomizada'),

      _verificaContexto: (function () {
          var temAcaoDefinida = this.permiteExcluir || this.permiteAcessar || this.acaoCustomizada;
          Ember['default'].assert('Erro. Não é possível atributir ações (excluir, acessar, ...) sem definir um contexto para os itens da tabela.', !(temAcaoDefinida && !this.contexto));
      }).on('willInsertElement'),

      mostraColunaAcoes: (function () {
          var temAcaoParaMostrar = this.permiteAcessar || this.permiteExcluir || this.acaoCustomizada;
          return temAcaoParaMostrar && this.contexto;
      }).property('contexto', 'permiteAcessar', 'permiteExcluir', 'acaoCustomizada'),

      controllerDoContexto: (function () {
          if (typeof this.contexto === 'string') {
              return this.container.lookup("controller:" + this.contexto);
          }

          return this.contexto;
      }).property('contexto'),

      /* Paginação */
      itensPorPagina: (function () {
          if (!this.comPaginacao) {
              return this._qtdItensInfinitos;
          }
          if (this.registrosPorPagina) {
              return this.registrosPorPagina;
          } else if (Defs['default'] && Defs['default'].paginacao && Defs['default'].paginacao.registrosPorPagina) {
              return Defs['default'].paginacao.registrosPorPagina;
          } else {
              return 10;
          }
      }).property("registrosPorPagina", "controleRefazerTabela"),

      textoItensPorPagina: (function () {
          var qtdItens = this.get("itensPorPagina");

          return qtdItens === this._qtdItensInfinitos ? "Todos" : '' + qtdItens;
      }).property("itensPorPagina"),

      opcoesRegistrosPorPagina: (function () {
          var arrayRetorno = [];
          var registrosPorPagina = this.get("itensPorPagina");
          var opcoes = [10, 20, 50, 100];

          opcoes.forEach(function (item) {
              arrayRetorno.push({ valor: item, texto: item, selecionado: item === registrosPorPagina });
          });

          arrayRetorno.push({ valor: this._qtdItensInfinitos, texto: "Todos", selecionado: this._qtdItensInfinitos === registrosPorPagina });

          return arrayRetorno;
      }).property("itensPorPagina"),

      quantidadePaginasExibir: (function () {
          if (this.quantidadePaginas) {
              return this.quantidadePaginas;
          } else if (Defs['default'] && Defs['default'].paginacao && Defs['default'].paginacao.quantidadePaginas) {
              return Defs['default'].paginacao.quantidadePaginas;
          } else {
              return 5;
          }
      }).property("quantidadePaginas"),

      //Fonte Paginada com informações adicionais, tais como ids para os botões de ação
      //(acessar, excluir, ação customizada)
      fontePaginadaAnotada: (function () {
          // DICA: Obtém dados da variável passada ao atributo "fonte" deste componente pelo template das telas que usam este componente,
          //       pois o valor dessa variável é passado à variável "fonteFiltrada" pelo método _atualizafonteFiltrada(),
          //       que está mais abaixo e que é do tipo on('init').
          if (!this.fonteFiltrada) {
              return;
          }

          var arrayRetorno = [];
          var indice;
          var totalRegistrosPorPagina = this.get("itensPorPagina");

          for (var i = 0; i < totalRegistrosPorPagina; i++) {
              indice = totalRegistrosPorPagina * (this.paginaAtual - 1) + i;

              if (indice > this.fonteFiltrada.length - 1) {
                  break;
              }

              if (this.fonteFiltrada[indice]) {
                  var itemAnotado = {};
                  itemAnotado.item = this.fonteFiltrada[indice];

                  var testeParaOcultarBotaoAcessar = this.get("testeParaOcultarBotaoAcessar");
                  if (testeParaOcultarBotaoAcessar != null) {
                      var condicao = "itemAnotado.item." + testeParaOcultarBotaoAcessar;
                      itemAnotado.desabilitarBotaoDeAcesso = eval(condicao);
                  }

                  var contextoIdTabela = this.id ? UtilID['default'].getSeccao(this.id, 'contexto') : '';
                  var informacaoIdTabela = this.id ? UtilID['default'].getSeccao(this.id, 'informacao') : '';
                  var local = this.id ? UtilID['default'].getSeccao(this.id, 'local') : '';
                  itemAnotado.idBotaoAcessar = UtilID['default'].incluiSeccaoOpcional('botao|acessar|' + informacaoIdTabela + '|' + contextoIdTabela + '|' + local + '-tabela', 'linha' + indice);
                  itemAnotado.idBotaoExcluir = UtilID['default'].incluiSeccaoOpcional('botao|excluir|' + informacaoIdTabela + '|' + contextoIdTabela + '|' + local + '-tabela', 'linha' + indice);

                  if (this.acaoCustomizada) {
                      var descritorAcao = this.get('acaoCustomizadaParse');
                      var nomeAcao = descritorAcao.nomeAcaoParaID ? descritorAcao.nomeAcaoParaID : descritorAcao.nomeLegivel;
                      itemAnotado.idBotaoAcaoCustomizada = UtilID['default'].incluiSeccaoOpcional('botao|' + nomeAcao + '|' + informacaoIdTabela + '|' + contextoIdTabela + '|' + local + '-tabela', 'linha' + indice);
                  }

                  arrayRetorno.push(itemAnotado);
              }
          }

          return arrayRetorno;
      }).property("fonteFiltrada", "fonteFiltrada.@each", "paginaAtual", "itensPorPagina", "controleRefazerTabela"),

      // fontePaginada : function() {
      //     if(!this.fonteFiltrada) { return; }
      //
      //     var arrayRetorno = [];
      //     var indice;
      //     var totalRegistrosPorPagina = this.get("itensPorPagina");
      //
      //     for (var i = 0; i < totalRegistrosPorPagina; i++) {
      //        indice = (totalRegistrosPorPagina * (this.paginaAtual - 1)) + i;
      //
      //        if(indice > this.fonteFiltrada.length -1) {
      //            break;
      //        }
      //
      //        if(this.fonteFiltrada[indice]) {
      //            arrayRetorno.push(this.fonteFiltrada[indice]);
      //        }
      //     }
      //
      //     return arrayRetorno;
      //
      // }.property("fonteFiltrada","fonteFiltrada.@each", "paginaAtual", "itensPorPagina", "controleRefazerTabela"),

      possuiRegistros: (function () {
          return this.get("fonteFiltrada") && this.get("fonteFiltrada").length > 0;
      }).property("fonteFiltrada", "controleRefazerTabela"),

      necessitaPaginacao: (function () {
          return this.get("fonteFiltrada") && this.get("fonteFiltrada").length > this.get("itensPorPagina");
      }).property("fonteFiltrada", "controleRefazerTabela"),

      proximaPagina: (function () {
          if (this.fonteFiltrada && this.fonteFiltrada.length > this.paginaAtual * this.get("itensPorPagina")) {
              return this.paginaAtual + 1;
          } else {
              return null;
          }
      }).property("paginaAtual", "fonteFiltrada.length", "itensPorPagina"),

      paginaAnterior: (function () {
          return this.paginaAtual > 1 ? this.paginaAtual - 1 : null;
      }).property("paginaAtual"),

      totalPaginas: (function () {
          if (!this.fonteFiltrada) {
              return 1;
          }
          var retorno = Math.ceil(this.fonteFiltrada.length / this.get("itensPorPagina"));
          return retorno !== 0 ? retorno : 1; // se não tem nenhum registro, deve dizer que tem uma página.
      }).property("paginaAtual", "fonteFiltrada.length", "itensPorPagina"),

      totalColunasTabela: (function () {
          return this.get("colunasParse").length + (this.get("mostraColunaAcoes") ? 1 : 0);
      }).property('colunasParse.length', 'mostraColunaAcoes'),

      totalColunasTabelaDesconsiderandoAcoes: (function () {
          return this.get("colunasParse").length;
      }).property('totalColunasTabela'),

      /* Casos de teste: Texto a apresentar na primeira página (mostrando 10 itens por página)
        9 itens no array -> mostrar "1 a 9"
        10 itens no array -> mostrar "1 a 10"
        11 itens no array -> mostrar "1 a 11"
      */
      mensagemPaginacao: (function () {
          var registroInicial = 0;
          var registroFinal = 0;
          var totalRegistros = 0;

          if (this.fonteFiltrada && this.fonteFiltrada.length > 0) {
              registroInicial = this.get("itensPorPagina") * (this.paginaAtual - 1) + 1;
              registroFinal = registroInicial + this.get("itensPorPagina") <= this.fonteFiltrada.length ? registroInicial + this.get("itensPorPagina") - 1 : this.fonteFiltrada.length;
              totalRegistros = this.fonteFiltrada.length;
          }

          return 'Exibindo registros ' + registroInicial + ' a ' + registroFinal + ' de ' + totalRegistros;
      }).property("fonteFiltrada", "fonteFiltrada.@each", "paginaAtual", "controleRefazerTabela"),

      paginasMostrarPaginacao: (function () {
          var arrayPaginas = [];
          var qtdPaginas = this.get("quantidadePaginasExibir");
          var ultimaPagina = this.get("totalPaginas");
          var completouProcedimento = false;

          if (this.paginaAtual > ultimaPagina) {
              this.set("paginaAtual", 1);
          }

          //adiciona páginas anteriores à atual
          var qtdPaginasEsquerda = Math.trunc(qtdPaginas / 2);
          for (var i = qtdPaginasEsquerda; i > 0; i--) {
              if (this.paginaAtual - i > 0) {
                  arrayPaginas.push({ nroPagina: this.paginaAtual - i, atual: false });
              }
          }

          //adiciona página atual
          arrayPaginas.push({ nroPagina: this.paginaAtual, atual: true });

          // adiciona páginas posteriores até fechar 5 páginas no array ou chegar ao fim das páginas.
          for (i = 1; i <= qtdPaginas; i++) {
              if (arrayPaginas.length === qtdPaginas || this.paginaAtual + i > ultimaPagina) {
                  completouProcedimento = true;
              }
              if (!completouProcedimento) {
                  arrayPaginas.push({ nroPagina: this.paginaAtual + i, atual: false });
              }
          }

          // se array ainda não completo, tenta adicionar mais páginas para a esquerda
          var qtdPaginasFaltantes = qtdPaginas - arrayPaginas.length;
          if (qtdPaginasFaltantes >= 0) {
              for (i = 1; i <= qtdPaginasFaltantes; i++) {
                  if (this.paginaAtual - qtdPaginasEsquerda - i > 0) {
                      arrayPaginas.unshift({ nroPagina: this.paginaAtual - qtdPaginasEsquerda - i, atual: false });
                  }
              }
          }

          return arrayPaginas;
      }).property("paginaAtual", "totalPaginas", "controleRefazerTabela"),

      /* Ordenação*/

      atributoOrdenacao: (function () {
          return this.filaOrdenacoes[0].atributo;
      }).property("filaOrdenacoes"),

      ordemOrdenacao: (function () {
          return this.filaOrdenacoes[0].ordem;
      }).property("filaOrdenacoes"),

      incluiOrdemPadrao: (function () {
          var _this = this;

          var colunas = this.get("colunasParse");
          if (colunas && colunas.length > 0) {
              if (this.ordemPadrao) {
                  var colunaOrdenar = colunas.find(function (item) {
                      return item.atributo === _this.ordemPadrao || item.metodoApresentacao === _this.ordemPadrao;
                  });
                  if (this.tipoOrdemPadrao) this.ordenaResultados(colunaOrdenar, this.tipoOrdemPadrao);else this.ordenaResultados(colunaOrdenar, 'asc');
              } else {
                  this.ordenaResultados(colunas[0], 'asc');
              }
          }
      }).on('willInsertElement').observes('fonteFiltrada'),

      ordenaResultados: function ordenaResultados(coluna, novaOrdem) {
          var _this2 = this;

          if (!this.arrayColunas) {
              return;
          }

          // Temporariamente, está deixando apenas 1 elemento na fila

          //// Se já está na fila, remove
          //if(this.filaOrdenacoes.contains(coluna.atributo)) {
          //    this.filaOrdenacoes.splice(this.filaOrdenacoes.indexOf(coluna.atributo));
          //}
          this.filaOrdenacoes = [];

          //ORDENÇÃO PADRÃO É ASCENDENTE
          novaOrdem = novaOrdem ? novaOrdem : 'asc';
          coluna.ordem = novaOrdem;

          //Ajusta ascs e descs da coluna - CONSIDERANDO APENAS UMA COLUNA
          this.arrayColunas.forEach(function (objColuna) {
              if (objColuna.atributo === coluna.atributo) {
                  Ember['default'].set(objColuna, 'ordem', novaOrdem ? novaOrdem : 'asc');
                  Ember['default'].set(objColuna, 'ordemAsc', novaOrdem === 'asc');
                  Ember['default'].set(objColuna, 'ordemDesc', novaOrdem === 'desc');
              }
          });

          //FORMA ANTIGA
          // Ajusta ascs e descs da coluna
          // this.arrayColunas.forEach(objColuna => {
          //         if(objColuna.atributo === coluna.atributo) {
          //             var novaOrdem = (objColuna.ordem !== 'asc') ? 'asc' : 'desc';
          //             Ember.set(objColuna, 'ordem', novaOrdem);
          //             Ember.set(objColuna, 'ordemAsc', (novaOrdem === 'asc'));
          //             Ember.set(objColuna, 'ordemDesc', (novaOrdem === 'desc'));
          //         }
          // });

          // Inclui no fim da fila
          this.filaOrdenacoes.push(coluna.atributo ? coluna.atributo : coluna.metodoApresentacao);

          var atributo = null;
          novaOrdem = null;
          var tipoColuna = null;
          var metodoApresentacao = null;

          this.filaOrdenacoes.forEach(function (itemFila) {
              atributo = itemFila;

              _this2.arrayColunas.forEach(function (objColuna) {
                  if (objColuna.atributo === atributo || objColuna.metodoApresentacao && objColuna.metodoApresentacao === atributo) {
                      novaOrdem = objColuna.ordem;
                      tipoColuna = objColuna.tipo;
                      metodoApresentacao = objColuna.metodoApresentacao;
                  }

                  if (!_this2.filaOrdenacoes.contains(objColuna.atributo) && !_this2.filaOrdenacoes.contains(objColuna.metodoApresentacao)) {
                      Ember['default'].set(objColuna, "ordem", null);
                      Ember['default'].set(objColuna, "ordemAsc", false);
                      Ember['default'].set(objColuna, "ordemDesc", false);
                  }
              });

              if (!_this2.fonteFiltrada || !Array.isArray(_this2.fonteFiltrada)) {
                  return;
              }

              _this2.fonteFiltrada.sort(function (a, b) {
                  var valorAtributoA = null;
                  var valorAtributoB = null;

                  if (metodoApresentacao) {
                      valorAtributoA = _this2.get("controllerDoContexto")[metodoApresentacao](a);
                      valorAtributoB = _this2.get("controllerDoContexto")[metodoApresentacao](b);
                  } else {
                      valorAtributoA = Ember['default'].get(a, atributo);
                      valorAtributoB = Ember['default'].get(b, atributo);
                  }

                  // Passa para minúsculo para não influenciar na ordenação
                  if (valorAtributoA && valorAtributoA.toLowerCase) {
                      valorAtributoA = valorAtributoA.toLowerCase();
                  }
                  if (valorAtributoB && valorAtributoB.toLowerCase) {
                      valorAtributoB = valorAtributoB.toLowerCase();
                  }

                  var retornoAMaior = novaOrdem === "asc" ? 1 : -1;
                  var retornoBMaior = novaOrdem === "asc" ? -1 : 1;

                  // As strings de NÚMEROS precisam dessa conversão
                  if (tipoColuna === "numero") {
                      valorAtributoA = parseInt(valorAtributoA);
                      valorAtributoB = parseInt(valorAtributoB);
                  }

                  // As strings de DATAS já vêm do back-end no formato que o JS converte automaticamente para data, portanto não precisamos de tratamento especial

                  // As strings de numero/ano precisam de um tratamento especial
                  if (tipoColuna === "numero/ano") {
                      var numeroA = parseInt(valorAtributoA.split('/')[0]);
                      var anoA = parseInt(valorAtributoA.split('/')[1]);

                      var numeroB = parseInt(valorAtributoB.split('/')[0]);
                      var anoB = parseInt(valorAtributoB.split('/')[1]);

                      // Não numéricos ficam no fim da ordenação
                      if (isNaN(anoA) && !isNaN(anoB) || anoA > anoB) {
                          return retornoAMaior;
                      } else if (isNaN(anoB) && !isNaN(anoA) || anoB > anoA) {
                          return retornoBMaior;
                      } else {
                          if (isNaN(numeroA) || numeroA > numeroB) {
                              return retornoAMaior;
                          } else if (isNaN(numeroB) || numeroB > numeroA) {
                              return retornoBMaior;
                          } else {
                              return 0;
                          }
                      }
                  }

                  // tratamento padrão de string
                  if (valorAtributoA > valorAtributoB) {
                      return retornoAMaior;
                  }
                  if (valorAtributoA < valorAtributoB) {
                      return retornoBMaior;
                  }
                  // a must be equal to b
                  return 0;
              });
          });

          this.set("controleRefazerTabela", !this.controleRefazerTabela);
      },

      // TESTE: function() {
      //   alert(this.fonteFiltrada.length);
      // }.observes('fonteFiltrada'),
      //
      /* Filtro */

      _atualizafonteFiltrada: (function () {
          this.set("fonteFiltrada", this.fonte);
      }).on('init').observes("fonte", "fonte.@each"),

      filtrarPesquisa: function filtrarPesquisa() {
          var _this3 = this;

          var arrayFiltrado;

          if (this.valorFiltroPesquisa) {
              var valorFiltroPesquisaUpper = this.valorFiltroPesquisa.toString().toUpperCase();

              var colunasPesquisaveis = this.get("colunasParse").filter(function (item) {
                  return item.pesquisavel && (_this3.campoFiltroPesquisa === "[*]" || item.atributo === _this3.campoFiltroPesquisa);
              });

              arrayFiltrado = this.fonte.filter(function (linhaTabela) {
                  return colunasPesquisaveis.some(function (coluna) {
                      var valor;
                      if (coluna.metodoApresentacao) {
                          valor = _this3.get("controllerDoContexto")[coluna.metodoApresentacao](linhaTabela);
                      } else {
                          valor = Ember['default'].get(linhaTabela, coluna.atributo);
                      }

                      // Formata data
                      if (valor instanceof Date) {
                          valor = _this3.formataValorDate(valor);
                      }

                      if (valor && valor.toString().toUpperCase().includes(valorFiltroPesquisaUpper)) {
                          return true;
                      }
                  });
              });
          } else {
              arrayFiltrado = this.fonte;
          }

          if (this.paginaAtual > this.get("totalPaginas")) {
              this.set("paginaAtual", 1);
          }

          this.set("fonteFiltrada", arrayFiltrado);
          this.set("controleRefazerTabela", !this.controleRefazerTabela);
      },

      formataValorDate: function formataValorDate(date) {
          var opcoesFormatoData = { day: 'numeric', month: 'numeric', year: 'numeric' };
          if (date.getHours() || date.getMinutes() || date.getSeconds()) {
              opcoesFormatoData['hour'] = '2-digit';
              opcoesFormatoData['minute'] = '2-digit';
          }
          return date.toLocaleString('pt-BR', opcoesFormatoData);
      },

      // 	TODO: Fazer IDs das linhas e cor por célula (comentário abaixo)
      //   //Define a cor a ser utilizada no texto das células de uma linha, quando for o caso
      //   _defineCorCelula: function(linha) {
      //     if(this.funcaoEscolhaCorCelula) {
      //       var controller = this.get('controllerDoContexto');
      //       return controller[this.funcaoEscolhaCorCelula].call(controller, linha);
      //     }
      //   },

      colunasParse: (function () {
          if (!this.arrayColunas) {
              this.arrayColunas = JSON.parse(this.colunas);
              this.arrayColunas.forEach(function (item) {
                  // Se a propriedade "ordenavel" não foi informada, considera como true.
                  if (!item.hasOwnProperty("ordenavel")) {
                      item.ordenavel = true;
                  }

                  // Se a propriedade "pesquisavel" não foi informada, considera como true.
                  if (!item.hasOwnProperty("pesquisavel")) {
                      item.pesquisavel = true;
                  }

                  // Se a propriedade "largura" foi informada, ajusta a propriedade "estiloHeader".
                  if (item.hasOwnProperty("largura")) {
                      item.estiloHeader = 'width:' + item.largura + ';';
                  }

                  if (!item.tituloLink) {
                      if (item.abreLinkEmNovaJanela) {
                          item.tituloLink = "Abre o link em uma nova janela";
                      }
                  }
              });
          }
          return this.arrayColunas;
      }).property("arrayColunas", "controleRefazerTabela"),

      acaoCustomizadaParse: (function () {
          return JSON.parse(this.acaoCustomizada);
      }).property(),

      _enviaAcaoParaControllerDoContexto: function _enviaAcaoParaControllerDoContexto(nomeAcao, objeto) {
          this.get('controllerDoContexto').send(nomeAcao, objeto);
      },

      actions: {
          excluirFonte: function excluirFonte(objeto) {
              this._enviaAcaoParaControllerDoContexto('excluir', objeto);
          },
          acaoCustomizada: function acaoCustomizada(objeto) {
              this._enviaAcaoParaControllerDoContexto(this.get('acaoCustomizadaParse').nomeNoController, objeto);
          },
          acessar: function acessar(objetoLinha) {
              if (this.acessoSemRota) this._enviaAcaoParaControllerDoContexto('acessar', objetoLinha);else {
                  var controller = this.get('controllerDoContexto');
                  var idsRota = this.funcaoIdsRota ? controller[this.funcaoIdsRota](objetoLinha) : idsRota = [objetoLinha[this.atributoID]];
                  var argumentos = [this.rota ? this.rota : this.contexto].concat(idsRota);
                  controller.transitionToRoute.apply(controller, argumentos);
              }
          },

          vaiParaPagina: function vaiParaPagina(novaPagina) {
              this.set("paginaAtual", novaPagina);
          },

          ordenarPorColuna: function ordenarPorColuna(coluna, novaOrdem) {
              this.ordenaResultados(coluna, novaOrdem);
          },

          atualizarPesquisaFiltro: function atualizarPesquisaFiltro() {
              this.filtrarPesquisa();
          },

          atualizarColunaFiltro: function atualizarColunaFiltro(atributo, titulo) {
              this.set("campoFiltroPesquisa", atributo);
              this.set("tituloCampoFiltroPesquisa", ' em "' + titulo + '"');

              this.filtrarPesquisa();
          },

          atualizarQuantidadeItensPagina: function atualizarQuantidadeItensPagina(valor) {
              this.set("registrosPorPagina", valor);
              this.set("controleRefazerTabela", !this.controleRefazerTabela);
          },

          cliqueURL: function cliqueURL(coluna, registroEmQueOcorreuOClique) {
              // Ação exclusiva do componente {{link-nova-janela}}
              var url = this.get("controllerDoContexto")[coluna.metodoLink](registroEmQueOcorreuOClique);
              this.abrirLink(url);
          }

      },

      abrirLink: function abrirLink(url) {
          if (window.screen.width > 1300) {
              // Se a tela for grande, abre o link em uma nova janela
              var left = window.screen.width / 2;
              var width = window.screen.width / 2;
              var height = window.screen.height;
              var novaJanela = window.open(url, "novaJanela", 'left=' + left + ',top=0,width=' + width + ',height=' + height);
              novaJanela.focus();
          } else {
              // Em telas pequenas, abre na mesma aba
              window.location.href = url;
          }
      }

  });

});
define('webapp/pods/aquisicao/evento/criar/route', ['exports', 'ember', 'webapp/pods/aquisicao/evento/model'], function (exports, Ember, Evento) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({
    controllerName: 'aquisicao/evento',
    setupController: function setupController(controller) {
      controller.set('model', Evento['default'].create());

      this.get('comunicador').leParaPropriedade(controller, 'tipoEventoAquisicao', 'tiposEvento', "Não foi possível obter os tipos de eventos de aquisição.");
    },

    model: function model() {
      return Evento['default'].create();
    }
  });

});
define('webapp/pods/aquisicao/listagem/controller', ['exports', 'ember', 'webapp/pods/aquisicao/constantes-aquisicao', 'webapp/utils/util'], function (exports, Ember, Constantes, Util) {

	'use strict';

	exports['default'] = Ember['default'].ArrayController.extend({

		queryParams: ['manterDadosPesquisaAnterior'],
		manterDadosPesquisaAnterior: false,
		criteriosPesquisaBackup: null, // Guarda os critérios da última pesquisa, para que, ao clicarmos
		// nos relatórios, esses critérios possam ser enviados exatamente
		// como estavam no momento da pesquisa.
		fundamentosLegais: {},
		pesquisarPorCriterioDeSustentabilidade: false,
		considerarFundamentosAtivos: true,
		considerarFundamentosInativos: false,
		considerarModalidadesAtivas: true,
		considerarModalidadesInativas: false,

		inicializaCampos: function inicializaCampos() {
			this.limparCamposPesquisa();
		},

		breadCrumbConfig: {
			nomeApresentacao: 'Aquisições'
		},

		opcoesBooleanasComDescricao: [{ id: 0, valor: true, descricao: 'Sim' }, { id: 1, valor: false, descricao: 'Não' }],

		opcoesBooleanasComDescricaoAta: [{ id: 0, valor: 1, descricao: 'Vigente' }, { id: 1, valor: 0, descricao: 'Encerrada' }],

		observaModalidade: (function () {
			console.log(this.get('modalidade'));
		}).observes('modalidade'),

		registroPreco: (function () {
			if (!this.temRegistroPreco && this.temRegistroPreco !== false) {
				return undefined;
			} else {
				return this.temRegistroPreco.valor;
			}
		}).property('temRegistroPreco'),

		ataRegistroPreco: (function () {
			if (!this.temAtaRegistroPreco && this.temAtaRegistroPreco !== false) {
				return undefined;
			} else {
				return this.temAtaRegistroPreco.valor;
			}
		}).property('temAtaRegistroPreco'),

		criterioSustentabilidade: (function () {
			if (!this.temCriterioSustentabilidade && this.temCriterioSustentabilidade !== false) {
				return undefined;
			} else {
				return this.temCriterioSustentabilidade.valor;
			}
		}).property('temCriterioSustentabilidade'),

		pesquisaNaoEnvolveCompras: (function () {
			if (this.periodoDeComprasNaoInformado()) {
				this._limparCheckboxesContratosCompras();
			}
			return this.periodoDeComprasNaoInformado();
		}).property('dataInicialCompras', 'dataFinalCompras'),
		/*
	 	desabilitarPesquisaPorCriterioDeSustentabilidade: function() {
	 		var desabilitar = !this.get('pesquisarPorCriterioDeSustentabilidade');
	 		if (desabilitar){
	 			this.set('criterioSustentabilidade', undefined);
	 		}
	 		return desabilitar;
	 	}.property('pesquisarPorCriterioDeSustentabilidade'),
	 */
		periodoDeComprasNaoInformado: function periodoDeComprasNaoInformado() {
			return !this.dataInicialCompras && !this.dataFinalCompras;
		},

		modalidadesDecoradasParaApresentacao: (function () {
			var modalidades = [];

			if (this.considerarModalidadesAtivas) {
				modalidades.push({ text: 'Ativas', children: this.get('modalidadesAtivas') });
			}

			if (this.considerarModalidadesInativas) {
				modalidades.push({ text: 'Inativas', children: this.get('modalidadesInativas') });
			}

			return modalidades;
		}).property('modalidadesAtivas', 'modalidadesInativas', 'considerarModalidadesAtivas', 'considerarModalidadesInativas'),

		modalidadesAtivas: (function () {
			if (!this.modalidades.filter) {
				return [];
			}

			return this.modalidades.filter(function (modalidade) {
				return modalidade.ativo;
			});
		}).property('modalidades'),

		modalidadesInativas: (function () {
			if (!this.modalidades.filter) {
				return [];
			}

			return this.modalidades.filter(function (modalidade) {
				return !modalidade.ativo;
			});
		}).property('modalidades'),

		fundamentosDecoradosParaApresentacao: (function () {
			var fundamentos = [];

			if (this.considerarFundamentosAtivos) {
				fundamentos.push({ text: 'Ativos', children: this.get('fundamentosAtivos') });
			}

			if (this.considerarFundamentosInativos) {
				fundamentos.push({ text: 'Inativos', children: this.get('fundamentosInativos') });
			}

			return fundamentos;
		}).property('fundamentosAtivos', 'fundamentosInativos', 'considerarFundamentosAtivos', 'considerarFundamentosInativos'),

		fundamentosAtivos: (function () {
			if (!this.fundamentosLegais.filter) {
				return [];
			}

			return this.fundamentosLegais.filter(function (fundamento) {
				return fundamento.ativo == 'S';
			});
		}).property('fundamentosLegais'),

		fundamentosInativos: (function () {
			if (!this.fundamentosLegais.filter) {
				return [];
			}

			return this.fundamentosLegais.filter(function (fundamento) {
				return fundamento.ativo == 'N';
			});
		}).property('fundamentosLegais'),

		modalidades: Constantes['default'].modalidades,

		_gerenciarDadosPesquisaAnterior: function _gerenciarDadosPesquisaAnterior() {
			if (!this.manterDadosPesquisaAnterior) {
				this.limparDadosPesquisa();
			}

			this.set('manterDadosPesquisaAnterior', false);
		},

		_limparDatasCompras: function _limparDatasCompras() {
			this.set('dataInicialCompras', undefined);
			this.set('dataFinalCompras', undefined);
		},

		limparDadosPesquisa: function limparDadosPesquisa() {
			this.limparCamposPesquisa();
			this.limparTabelaResultados();
		},

		_limparCheckboxesContratosCompras: function _limparCheckboxesContratosCompras() {
			this.set('considerarComprasComContrato', undefined);
			this.set('considerarComprasSemContrato', undefined);
		},

		limparCamposPesquisa: function limparCamposPesquisa() {
			this.set('modalidade', undefined);
			this.set('numero', undefined);
			this.set('ano', undefined);
			this.set('dataInicial', undefined);
			this.set('dataFinal', undefined);
			this.set('objeto', undefined);
			this.set('requisitante', undefined);
			this.set('descricaoItem', undefined);
			this.set('numeroProcessoAdministrativo', undefined);
			this.set('fundamentoLegal', []);
			this.set('temCriterioSustentabilidade', undefined);
			this._limparDatasCompras();
			this.set('temAtaRegistroPreco', undefined);
			this.set('fornecedorAdjudicado', undefined);
			this._limparCheckboxesContratosCompras();
			this.set('pesquisarPorCriterioDeSustentabilidade', undefined);
			this.set('classificacoesItens', []);
		},

		limparTabelaResultados: function limparTabelaResultados() {
			this.set('mostraTabelaResultadoPesquisa', false);
			this.set('mostraMensagemPesquisaSemResultado', false);
		},

		actions: {
			vaiParaAquisicao: function vaiParaAquisicao(informacaoPesquisa) {
				var _this = this;

				this.get('comunicador').servico('get', 'adesao/' + informacaoPesquisa.id, null).then(function (resultado) {
					_this.transitionToRoute('adesao', resultado);
				}, function (erro) {
					_this.trataErro(erro, "Não foi possível acessar a adesão.");
				});
			},
			adicionar: function adicionar() {
				this.transitionToRoute('adesao/criar');
			},

			pesquisar: function pesquisar() {
				var _this2 = this;

				this.set('mostrarResultados', false);

				var criteriosPesquisa = this.getProperties('modalidade', 'numero', 'ano', 'dataInicial', 'dataFinal', 'objeto', 'requisitante', 'numeroProcessoAdministrativo', 'descricaoItem', 'fundamentoLegal', 'criterioSustentabilidade', 'dataInicialCompras', 'dataFinalCompras', 'ataRegistroPreco', 'fornecedorAdjudicado', 'considerarComprasComContrato', 'considerarComprasSemContrato', 'classificacoesItens');

				var criteriosPesquisaComprasDiretas = this.getProperties('modalidade', 'ano', 'fundamentoLegal', 'classificacoesItens');

				this.criteriosPesquisaBackup = Util['default'].clona(criteriosPesquisa);
				this.criteriosPesquisaBackupComprasDiretas = Util['default'].clona(criteriosPesquisaComprasDiretas);

				this.get('comunicador').servico('post', 'aquisicao/pesquisa', criteriosPesquisa).then(function (resultado) {
					_this2.set("model", resultado);
					var retornouItems = _this2.get('model').length > 0;
					_this2.set('mostraTabelaResultadoPesquisa', retornouItems);
					_this2.set('mostraMensagemPesquisaSemResultado', !retornouItems);
					_this2.set('mostrarResultados', true);
				}, function (erro) {
					_this2.trataErro(erro, "Pesquisa falhou!");
				});
			},

			limparPesquisa: function limparPesquisa() {
				this.limparDadosPesquisa();
				this.set('model', []);
			}

		},

		descricaoRelatorios: (function () {
			var _this3 = this;

			return [{
				textoMenu: 'Relação de Aquisições',
				servico: 'relatorio/relacaoDeAquisicoes',
				nomeArquivo: 'aquisicao_relacao_de_aquisicoes',
				nomeParaID: 'relacao-aquisicoes',
				metodo: 'post',
				dados: function dados() {
					return _this3.criteriosPesquisaBackup;
				},
				testaHabilitado: function testaHabilitado() {
					return !_this3.get('mostraMensagemPesquisaSemResultado') && _this3.get('mostraTabelaResultadoPesquisa');
				},
				controlePermissao: true
			}, {
				textoMenu: 'Relação de Compras Diretas',
				servico: 'relatorio/relacaoDeComprasDiretas',
				nomeArquivo: 'aquisicao_relacao_de_compras_diretas',
				nomeParaID: 'relacao-compras-diretas',
				metodo: 'post',
				dados: function dados() {
					return _this3.criteriosPesquisaBackupComprasDiretas;
				},
				testaHabilitado: function testaHabilitado() {
					return true;
				},
				controlePermissao: true
			}];
		}).property('model', 'mostraMensagemPesquisaSemResultado')

	});

});
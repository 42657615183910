define('webapp/pods/aquisicao/compra/empenho/criar/route', ['exports', 'ember', 'webapp/pods/aquisicao/compra/empenho/model'], function (exports, Ember, Empenho) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({
    controllerName: 'aquisicao/compra/empenho',
    setupController: function setupController(controller) {
      controller.set('model', Empenho['default'].create());
    },

    model: function model() {
      return Empenho['default'].create();
    }
  });

});
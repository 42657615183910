define('webapp/pods/alerta/listagem/controller', ['exports', 'ember', 'webapp/mixins/crud-mixin'], function (exports, Ember, CrudMixin) {

	'use strict';

	exports['default'] = Ember['default'].Controller.extend(CrudMixin['default'], {

		alertas_service: Ember['default'].inject.service('alertas_service'),
		usuarioLogado: Ember['default'].inject.service('usuario-logado'),

		needs: ['application'],

		breadCrumbConfig: {
			nomeApresentacao: 'Alertas'
		},

		diasConsiderados: (function () {
			return 5;
		}).property(),

		actions: {
			listar: function listar() {
				this.get('alertas_service').refreshAlertas(this);
			},
			enviarAlertas: function enviarAlertas() {
				this.get('alertas_service').enviarAlertas(this);
			}
		},

		todosAlertasEnviados: (function () {
			return !this.get('alertas_service').temAlertaNaoIdentificadoOuNaoEnviado();
		}).property('usuarioLogado.info', 'alertas_service.alertas')

	});

});
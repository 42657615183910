define('webapp/pods/aquisicao/contrato/aditivo/acrescimo-ou-supressao/item/model', ['exports', 'ember', 'webapp/pods/aquisicao/contrato/item/model'], function (exports, Ember, ContratoItem) {

  'use strict';

  exports['default'] = Ember['default'].Object.extend({

    id: null,
    quantidade: '',
    valorUnitario: '',
    valorTotal: '',
    contratoItem: ContratoItem['default'].create({}),
    motivoCriacao: '', //ORIGINAL, ACRESCIMO, SUPRESSAO
    numero: 0,
    data: '',
    valorTotalInformado: '',
    numeroApresentacao: '',
    percentual: '',
    descricao: '',
    //itemAquisicao: ItemAquisicao.create({}),

    inicializar: (function () {
      this.set('id', null);
      this.set('numero', null);
      this.set('data', null);
      this.set('motivoCriacao', '');
      this.set('quantidade', null);
      this.set('valorUnitario', null);
      this.set('valorTotal', null);
      //this.set('itemAquisicao', ItemAquisicao.create({}));
      this.set('contratoItem', ContratoItem['default'].create({}));
    }).on('init'),

    findUrlFn: function findUrlFn(id) {
      return 'aditivo/acrescimoSupressaoItem/' + id.toString();
    }

  });

});
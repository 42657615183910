define('webapp/pods/aquisicao/contrato/aditivo/revisao/model', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Object.extend({
    id: null,
    novoValorTotal: '',
    dataInicio: '',
    motivo: '',
    numeroApresentacao: '',
    descricao: '',

    findUrlFn: function findUrlFn(id) {
      return 'aditivo/revisaoFinanceira/' + id.toString() + '/';
    }

  });

});
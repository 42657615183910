define('webapp/pods/publico/contratos/controller', ['exports', 'ember', 'webapp/pods/aquisicao/constantes-aquisicao', 'webapp/pods/publico/constantes-transparencia', 'webapp/pods/transparencia/documento/constantes-documento', 'webapp/defs'], function (exports, Ember, ConstantesAquisicao, Constantes, ConstantesDocumentos, Defs) {

    'use strict';

    exports['default'] = Ember['default'].ArrayController.extend({
        queryParams: ['numeroContrato', 'anoContrato', 'anoAssinatura', 'mesAssinatura', 'objeto'],

        modalidades: ConstantesAquisicao['default'].modalidades,
        opcoesStatus: Constantes['default'].statusLicitacao,
        meses: Constantes['default'].opcoesMeses,
        retroagir: 5,

        anos: (function () {
            return Constantes['default'].opcoesAnos(this.retroagir);
        }).property('retroagir'),

        getUrlDownload: function getUrlDownload(documento) {
            return Defs['default'].enderecoServer + documento.urlDownload;
        },

        formataValorDate: function formataValorDate(date) {
            if (!date) {
                return "";
            }
            var opcoesFormatoData = { day: 'numeric', month: 'numeric', year: 'numeric' };
            if (date.getHours() || date.getMinutes() || date.getSeconds()) {
                opcoesFormatoData['hour'] = '2-digit';
                opcoesFormatoData['minute'] = '2-digit';
            }
            return date.toLocaleString('pt-BR', opcoesFormatoData);
        },

        formataValor: function formataValor(valor) {
            if (!valor) {
                return "";
            }
            var formatoAmericano = valor.replace(',', '.');
            var stringComSeparador = Number(formatoAmericano).toLocaleString('pt', { minimumFractionDigits: 2 });
            return stringComSeparador === 'NaN' ? valor : stringComSeparador;
        },

        getNomeLegivelModalidade: function getNomeLegivelModalidade(item) {
            var modalidadeDaAquisicao = this.modalidades.find(function (modalidade) {
                return item.modalidadeAquisicao === modalidade.id;
            });
            return modalidadeDaAquisicao.nome;
        },

        getAditivoOuApostila: function getAditivoOuApostila(item) {
            if (item.apostila == "S") {
                return "Apostila";
            } else {
                return "Aditivo";
            }
        },

        getDescricaoDocumento: function getDescricaoDocumento(item) {
            if (item.descricao) {
                return item.descricao;
            }

            for (var ind = 0; ind < ConstantesDocumentos['default'].tipos.length; ind++) {
                if (item.tipo == ConstantesDocumentos['default'].tipos[ind].id) return ConstantesDocumentos['default'].tipos[ind].nome;
            }

            return item.tipo;
        },

        getDestaque: function getDestaque() {
            var mes = this.get("mesAssinatura");
            var ano = this.get("anoAssinatura");
            var destaque = "";
            if (ano) {
                destaque = ano;
            }

            if (mes) {
                if (ano) {
                    destaque = mes + "/" + destaque;
                } else {
                    destaque = "/" + mes + "/";
                }
            }

            return destaque;
        },

        actions: {
            listar: function listar() {
                var _this = this;

                if (!this.chkContratos && !this.chkAditivos) {
                    alert('Você precisa selecionar pelo menos "Contrato" ou "Aditivos".');
                    return;
                }

                if (this.chkContratos && !this.chkAditivos) {
                    this.set('tipo', 'CONTRATO');
                } else if (!this.chkContratos && this.chkAditivos) {
                    this.set('tipo', 'ADITIVO');
                } else {
                    this.set('tipo', '');
                }

                var criteriosPesquisa = this.getProperties('numeroContrato', 'anoContrato', 'anoAssinatura', 'mesAssinatura', 'tipo', 'objeto');

                this.get('comunicador').servico('post', 'transparencia/contratos', criteriosPesquisa, this).then(function (resultado) {
                    var contratosDistinct = [];
                    var listaContratos = [];
                    resultado.forEach(function (item, indice, array) {
                        var numeroContrato = item.numeroAnualContrato + "/" + item.anoContrato;
                        if (listaContratos.indexOf(numeroContrato) < 0) {
                            contratosDistinct.push(item);
                            listaContratos.push(numeroContrato);
                        }
                    });
                    _this.set('model', contratosDistinct);
                });
            },

            limparPesquisa: function limparPesquisa() {
                this.limparDadosPesquisa();
                this.set('model', []);
            }

        }
    });

});
define('webapp/pods/aquisicao/contrato/aditivo/acrescimo-ou-supressao/item/controller', ['exports', 'ember', 'webapp/mixins/crud-mixin', 'webapp/pods/aquisicao/contrato/item/model', 'webapp/pods/aquisicao/constantes-aquisicao'], function (exports, Ember, CrudMixin, ContratoItem, Constantes) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend(CrudMixin['default'], {

    queryParams: ['tipoItem'],
    tipoItem: null,

    needs: ['aquisicao/contrato', 'aquisicao/contrato/aditivo'],

    breadCrumbConfig: {
      fnNomeApresentacao: 'fnNomeApresentacao'
    },

    fnNomeApresentacao: function fnNomeApresentacao() {
      return 'Item Aditivo Acréscimo/Supressão Contrato ' + (this.model.version ? 'Nº ' + this.model.numeroApresentacao : '(Novo)');
    },

    crudConfig: {
      criar: {
        urlFn: function urlFn() {
          return 'aditivo/' + this.get("controllers.aquisicao/contrato/aditivo").model.id + '/acrescimoSupressaoItem';
        },
        okFn: function okFn(opcao) {
          this.get('controllers.aquisicao/contrato').send('ler');
          this.get('controllers.aquisicao/contrato/aditivo').send('ler');
          if (opcao === 'CRIAR_E_PROXIMO') {
            //ação "borbulhará" para a rota
            this.send('refreshRota');
            return;
          }
          this.transitionToRoute('aquisicao/contrato/aditivo');
        }
      },
      atualizar: {
        okFn: function okFn() {
          this.get('controllers.aquisicao/contrato').send('ler');
          this.get('controllers.aquisicao/contrato/aditivo').send('ler');
          this.transitionToRoute('aquisicao/contrato/aditivo');
        }
      },
      excluir: {
        okFn: function okFn() {
          this.get('controllers.aquisicao/contrato').send('ler');
          this.get('controllers.aquisicao/contrato/aditivo').send('ler');
        }
      }
    },

    motivosCriacaoItens: Constantes['default'].motivosCriacaoItens,

    itensElegiveisContrato: (function () {
      var itensContrato = this.get('controllers.aquisicao/contrato').model.itens;
      return itensContrato.filter(function (item) {
        return item.motivoCriacao === 'ORIGINAL';
      });
    }).property('controllers.aquisicao/contrato.model'),

    tituloTela: (function () {
      var tipo = '';
      switch (this.model.motivoCriacao) {
        case 'ACRESCIMO_QUANTITATIVO':
          tipo = 'Acréscimo Quantitativo';break;
        case 'ACRESCIMO_QUALITATIVO':
          tipo = 'Acréscimo Qualitativo';break;
        case 'SUPRESSAO':
          tipo = 'Supressão';break;
      }

      if (this.model.numeroApresentacao) {
        return tipo + ' ' + this.model.numeroApresentacao;
      } else {
        return tipo + ' ' + (tipo === 'Supressão' ? '(Nova)' : '(Novo)');
      }
    }).property('model.motivoCriacao'),

    configurarItemNovoConformeTipoItem: (function () {
      if (!this.model || !this.get('ehItemNovo')) {
        return;
      }

      switch (this.tipoItem) {
        case 'ORIGINAL':
          this.set('model.motivoCriacao', 'ORIGINAL');break;
        case 'ACRESCIMO':
          this.set('model.motivoCriacao', 'ACRESCIMO_QUANTITATIVO');break;
        case 'SUPRESSAO':
          this.set('model.motivoCriacao', 'SUPRESSAO');break;
      }
    }).observes('tipoItem', 'model'),

    ehItemNovo: (function () {
      return !this.model.version;
    }).property('model.version'),

    ehItemExistente: (function () {
      return this.model.version;
    }).property('model.version'),

    ehAcrescimo: (function () {
      return this.model.motivoCriacao === 'ACRESCIMO_QUANTITATIVO' || this.model.motivoCriacao === 'ACRESCIMO_QUALITATIVO';
    }).property('model.motivoCriacao'),

    ehSupressao: (function () {
      return this.model.motivoCriacao === 'SUPRESSAO';
    }).property('model.motivoCriacao'),

    ehAcrescimoQuantitativo: (function () {
      return this.model.motivoCriacao === 'ACRESCIMO_QUANTITATIVO';
    }).property('model.motivoCriacao'),

    //limpa o contratoItem do acrescimoSupressaoItem caso o acrescimoSupressaoItem
    //seja de acréscimo qualitativo e esteja sendo criado
    limpaContratoItem: (function () {
      if (this.get('ehItemNovo') && this.model.motivoCriacao === 'ACRESCIMO_QUALITATIVO') {
        this.set('model.contratoItem', ContratoItem['default'].create({}));
      }
    }).observes('model.motivoCriacao'),

    proibidoEscolherItemContrato: (function () {
      return !this.get('ehItemNovo') || this.model.motivoCriacao === 'ACRESCIMO_QUALITATIVO';
    }).property('ehItemNovo', 'model.motivoCriacao'),

    proibidoAlterarDadosItemContrato: (function () {
      return !this.get('ehItemNovo') || this.model.motivoCriacao !== 'ACRESCIMO_QUALITATIVO';
    }).property('ehItemNovo', 'model.motivoCriacao'),

    permitidoAlterarDadosItemContrato: (function () {
      return this.get('ehItemNovo') && this.model.motivoCriacao === 'ACRESCIMO_QUALITATIVO';
    }).property('ehItemNovo', 'model.motivoCriacao'),

    necessarioEscolherItemAcrescerSuprimir: (function () {
      return this.get('ehAcrescimoQuantitativo') || this.get('ehSupressao');
    }).property('ehAcrescimo', 'ehAcrescimoQuantitativo'),

    textoItemAcrescerSuprimir: (function () {
      return this.get('ehAcrescimo') ? 'Item a Acrescer' : 'Item a Suprimir';
    }).property('ehAcrescimo', 'ehAcrescimoQuantitativo'),

    tituloDescricaoAcrescimoSupressao: (function () {
      return this.get('ehAcrescimo') ? 'Descrição do Acréscimo' : 'Descrição da Supressão';
    }).property('ehAcrescimo'),

    //Durante a criação do item de acréscimo/supressão (ehItemNovo), sempre que mudar
    //o contratoItem, sugerir seu valor unitário como valor unitário do item acréscimo/supressão.
    atualizarValorUnitario: (function () {
      if (!this.get('ehItemNovo')) {
        return;
      }

      this.set('model.valorUnitario', this.get('model.contratoItem.valorUnitario'));
    }).observes('model.contratoItem'),

    atualizarValorUnitarioDoContratoItem: (function () {
      this.set('model.contratoItem.valorUnitario', this.get('model.valorUnitario'));
    }).observes('model.valorUnitario'),

    contratoItemNaoPeriodico: (function () {
      return !this.get('model').contratoItem.periodico;
    }).property('model.contratoItem.periodico'),

    getNomeLegivelMotivoCriacaoItem: function getNomeLegivelMotivoCriacaoItem(item) {
      var motivoCriacao = this.motivosCriacaoItens.find(function (motivo) {
        return item.motivoCriacao === motivo.id;
      });
      return motivoCriacao.nome;
    },

    limpaCamposQueDevemSerEnviadosVazios: function limpaCamposQueDevemSerEnviadosVazios() {
      if (this.get('model.contratoItem.periodico')) {
        this.set('model.quantidade', null);
      } else {
        this.set('model.valorTotalInformado', null);
      };
    },

    actions: {
      encaminharCriacao: function encaminharCriacao(parametro) {
        this.limpaCamposQueDevemSerEnviadosVazios();
        this.send('criar', parametro);
      },
      encaminharAlteracao: function encaminharAlteracao() {
        this.limpaCamposQueDevemSerEnviadosVazios();
        this.send('atualizar');
      }
    }

  });

});
define('webapp/pods/adm-outras-entidades/uasg/controller', ['exports', 'ember', 'webapp/mixins/crud-mixin'], function (exports, Ember, CrudMixin) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(CrudMixin['default'], {
    comunicador: Ember['default'].inject.service(),

    uasgs: [],
    mostraModalCadastro: false,

    crudConfig: {
      criar: {
        urlFn: function urlFn() {
          return 'uasg';
        },
        modelFn: function modelFn() {
          return this.get('model');
        },
        okFn: function okFn(opcao) {
          this.buscarUASGs();
          this.set('model', {});
          this.set('mostraModalCadastro', opcao === 'CRIAR_E_PROXIMO');
        }
      },
      excluir: {
        urlFn: function urlFn(idParaConcatenarNaUrl) {
          return 'uasg/' + idParaConcatenarNaUrl;
        },
        okFn: function okFn() {
          this.buscarUASGs();
        }
      }
    },

    buscarOrgaosExistentes: (function () {
      this.get('comunicador').leParaPropriedade(this, 'orgao', 'orgaos', 'Não foi possível obter as entidades existentes', true);
    }).on('init'),

    buscarUASGs: (function () {
      this.get('comunicador').leParaPropriedade(this, 'uasg', 'uasgs', 'Não foi possível obter as entidades existentes', true);
    }).on('init'),

    actions: {
      adicionar: function adicionar() {
        this.set('model', {});
        this.set('mostraModalCadastro', true);
      },
      cancelarModal: function cancelarModal() {
        this.set('model', {});
        this.set('mostraModalCadastro', false);
      }
    }
  });

});
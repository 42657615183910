define('webapp/initializers/route', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = {
    name: 'RouteInicializador',
    initialize: function initialize() {

      Ember['default'].Route.reopen({

        /*
              rotaSuperior: Ember.computed(function() {
        
                var nomesRotasOrdemAbertura =
                  this.router.router.state.handlerInfos.map((info) => {return info.handler.routeName;});
        
                var nomeRotaSuperior;
        
                for(var i=0; i < nomesRotasOrdemAbertura.length; i++) {
                  if(nomesRotasOrdemAbertura[i] === this.routeName) {
                    nomeRotaSuperior = nomesRotasOrdemAbertura[i - 1];
                    break;
                  }
                }
        
                return this.container.lookup('route:' + nomeRotaSuperior);
              }),
        
              controllerSuperior: Ember.computed(function() {
                var rotaSuperior = this.get('rotaSuperior');
                return rotaSuperior ? rotaSuperior.controller : undefined;
              }),
        */

        //Por enquanto, parece necessário usar aqui "_action" em lugar de "actions"
        //para que a lista de ações apareça nas rotas.
        //Acompanhar a issue: https://github.com/emberjs/ember.js/issues/5394
        _actions: {
          refreshRota: function refreshRota() {
            this.refresh();
          }
        }

      });
    }
  };

});
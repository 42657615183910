define('webapp/instance-initializers/controla-transacoes-spinner', ['exports', 'ember', 'webapp/defs'], function (exports, Ember, Defs) {

    'use strict';

    /* global $ */

    var ControleSpinner = {
        config: {
            timeoutSpinner: 30000 // tempo de timeout do spinner em milisegundos
        },

        totalTransacoesAtivas: 0,
        dataUltimaExibicao: null,

        empilharTransacao: function empilharTransacao() {
            ControleSpinner.totalTransacoesAtivas++;
            ControleSpinner.controlaExibicaoSpinner();
        },

        desempilharTransacao: function desempilharTransacao() {
            ControleSpinner.totalTransacoesAtivas--;
            ControleSpinner.controlaExibicaoSpinner();
        },

        estaExibindoSpinner: function estaExibindoSpinner() {
            return $('#ajaxBusy').css('display') === 'block';
        },

        // Função que realiza o controle das exibições do spinner conforme o total de transações ativas no momento
        controlaExibicaoSpinner: function controlaExibicaoSpinner() {
            if (ControleSpinner.totalTransacoesAtivas <= 0) {
                ControleSpinner.totalTransacoesAtivas = 0;
                $('#ajaxBusy').hide();
            } else if (ControleSpinner.totalTransacoesAtivas === 1 && !ControleSpinner.estaExibindoSpinner()) {
                ControleSpinner.dataUltimaExibicao = new Date();
                $('#ajaxBusy').show();

                // inclui um timeout para remover o spinner caso alguma transação não retorne
                try {
                    if (Defs['default'] && Defs['default'].spinner && Defs['default'].spinner.timeOut) {
                        ControleSpinner.config.timeoutSpinner = Defs['default'].spinner.timeOut;
                    }

                    window.setTimeout(function () {
                        if (ControleSpinner.estaExibindoSpinner() && new Date() - ControleSpinner.dataUltimaExibicao >= ControleSpinner.config.timeoutSpinner) {
                            console.log("Removendo spinner por timeout. Início da exibição em: '" + ControleSpinner.dataUltimaExibicao + "' e fim em '" + new Date() + "'. Tempo total: " + (new Date() - ControleSpinner.dataUltimaExibicao));
                            ControleSpinner.totalTransacoesAtivas = 0;
                            $('#ajaxBusy').hide();
                        }
                    }, ControleSpinner.config.timeoutSpinner);
                } catch (e) {}
            }
        }
    };

    exports['default'] = {
        initialize: function initialize() {

            $('body').append('<div id="ajaxBusy"></div>');

            Ember['default'].Component.reopen({
                ControleSpinner: ControleSpinner
            });

            Ember['default'].Router.reopen({
                terminouRota: (function () {
                    ControleSpinner.desempilharTransacao();
                }).on('didTransition'),

                iniciouRota: (function () {
                    ControleSpinner.empilharTransacao();
                }).on('willTransition')
            });

            Ember['default'].Route.reopen({
                renderTemplate: function renderTemplate() {
                    ControleSpinner.empilharTransacao();
                    this.render();

                    var finalizouRenderizacao = function finalizouRenderizacao() {
                        ControleSpinner.desempilharTransacao();
                    };

                    Ember['default'].run.scheduleOnce('afterRender', finalizouRenderizacao);
                }
            });

            $(document).ajaxStart(function () {
                ControleSpinner.empilharTransacao();
            }).ajaxStop(function () {
                ControleSpinner.desempilharTransacao();
            });
        }
    };

});
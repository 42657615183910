define('webapp/pods/aquisicao/contrato/rescisao/model', ['exports', 'ember'], function (exports, Ember) {

	'use strict';

	exports['default'] = Ember['default'].Object.extend({
		id: null,
		tipo: '',
		data: ''
	});

});
define('webapp/pods/usuario/criar/route', ['exports', 'ember', 'webapp/pods/usuario/model'], function (exports, Ember, Usuario) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({
    controllerName: 'usuario',
    setupController: function setupController(controller) {
      controller.set('model', Usuario['default'].create({}));

      this.get('comunicador').servico('get', 'servidor').then(function (resultado) {
        controller.set('servidores', resultado);
      });
    }
  });

});
define('webapp/services/alertas_service', ['exports'], function (exports) {

    'use strict';

    exports['default'] = Ember.Service.extend({
        comunicador: Ember.inject.service(),

        estadoDaGravacao: null,
        alertas: null,

        buscaEstadoDaGravacaoDosAlertas: function buscaEstadoDaGravacaoDosAlertas() {
            self = this;
            this.get('comunicador').servico('get', 'alerta/estadoDaGravacao', {}, this).then(function (resultado) {
                if (resultado && resultado.trim().length > 0) self.set('estadoDaGravacao', eval(resultado));else self.set('estadoDaGravacao', null);
            });
        },

        refreshAlertas: function refreshAlertas(controller) {
            self = this;
            this.get('comunicador').servico('get', 'alerta', {}, this).then(function (resultado) {
                self.set('alertas', resultado);
                controller.set('model', resultado);
                self.buscaEstadoDaGravacaoDosAlertas();
            });
        },

        enviarAlertas: function enviarAlertas(controller) {
            self = this;
            this.get('comunicador').servico('put', 'alerta', {}, this).then(function (resultado) {
                self.set('alertas', resultado);
                controller.set('model', resultado);
                self.buscaEstadoDaGravacaoDosAlertas();
            });
        },

        excecaoNaIdentificacaoOuNoEnvioDoAlerta: function excecaoNaIdentificacaoOuNoEnvioDoAlerta() {
            return !this.get('estadoDaGravacao');
        },

        temAlertaNaoIdentificadoOuNaoEnviado: function temAlertaNaoIdentificadoOuNaoEnviado() {
            var retorno = false;
            if (this.get('alertas')) {
                this.get('alertas').forEach(function (item) {
                    if (!item.dataEnvio) retorno = true;
                });
            }
            return retorno;
        }

    });

});
define('webapp/tests/unit/json-date-parse-test', ['ember-qunit'], function (ember_qunit) {

	'use strict';

	/* global ok, _ */

	ember_qunit.test('JSON parse com datas - igual', function () {

		var a = { "a": new Date("06/01/2015") }; //Mon Jun 01 2015 00:00:00 GMT-0300 (BRT)
		var b = JSON.stringify(a); //"{"a":"2015-06-01T03:00:00.000Z"}"
		var c = JSON.parse(b);

		ok(_.isEqual(JSON.parse(JSON.stringify(a.a)), JSON.parse(JSON.stringify(c.a))));
	});

	ember_qunit.test('JSON parse com datas - diferente', function () {

		var a = { "a": new Date("06/01/2015") }; //Mon Jun 01 2015 00:00:00 GMT-0300 (BRT)
		var a1 = { "a": new Date("07/01/2015") }; //Mon Jul 01 2015 00:00:00 GMT-0300 (BRT)	
		var b = JSON.stringify(a1); //"{"a":"2015-07-01T03:00:00.000Z"}"
		var c = JSON.parse(b);

		ok(!_.isEqual(JSON.parse(JSON.stringify(a.a)), JSON.parse(JSON.stringify(c.a))));
	});

});
define('webapp/initializers/controller', ['exports'], function (exports) {

  'use strict';

  //import Ember from 'ember';

  exports['default'] = {
    name: 'ControllerInicializador',
    initialize: function initialize() {
      /*
          Ember.Controller.reopen({
      
            breadCrumb: Ember.computed(function() {
      
              var rotasEmOrdemAbertura =
                this.target.router.state.handlerInfos.map((info) => {return info.handler;});
      
              var breadCrumbArray = rotasEmOrdemAbertura.map((rota) => {
                if(rota.routeName.includes('.index')) {
                  return '';
                }
      
                var nomeController = rota.controller.get('nomeController');
                var nome = nomeController ? nomeController.split('/').pop() : rota.routeName;
                var id = rota.controller.model ? rota.controller.model.id : '';
                return `/ ${nome} ${id} `;
              });
      
              return breadCrumbArray.join('');
            }),
      
            nomeController: Ember.computed(function() {
              //Controller.toString() retorna algo como
              //"<webapp@controller:aquisicao/item/quantidade-participacao::ember740>"
              return this.toString().split(':')[1];
            }),
      
            rotaSuperior: Ember.computed(function() {
      
              var nomesRotasOrdemAbertura =
                this.target.router.state.handlerInfos.map((info) => {return info.handler.routeName;});
      
              var nomeRotaAtual = this.get('nomeController');
              var nomeRotaSuperior;
      
              for(var i=0; i < nomesRotasOrdemAbertura.length; i++) {
                if(nomesRotasOrdemAbertura[i] === nomeRotaAtual) {
                  nomeRotaSuperior = nomesRotasOrdemAbertura[i - 1];
                  break;
                }
              }
      
              return this.container.lookup('route:' + nomeRotaSuperior);
            }),
      
            controllerSuperior: Ember.computed(function() {
              var rotaSuperior = this.get('rotaSuperior');
              return rotaSuperior ? rotaSuperior.controller : undefined;
            })
      
          });
      
      */
    }
  };

});
define('webapp/models/papel', ['exports', 'ember'], function (exports, Ember) {

	'use strict';

	var PapelObj = function PapelObj() {
		this.id = null;
		this.nome = '';
		this.descricao = '';
		this.version = '';
	};

	exports['default'] = Ember['default'].Object.extend(new PapelObj());

});
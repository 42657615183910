define('webapp/pods/aquisicao/contrato/aditivo/repactuacao/model', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Object.extend({
    id: null,
    novoValorTotal: '', //TODO isso deve virar "novoValorMensal" no servidor
    dataInicio: '',
    sindicato: '',
    normaColetiva: '',
    numeroApresentacao: '',
    descricao: '',

    findUrlFn: function findUrlFn(id) {
      return 'aditivo/repactuacao/' + id.toString() + '/';
    }

  });

});
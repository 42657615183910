define('webapp/pods/aquisicao/contrato/aditivo/controller', ['exports', 'ember', 'webapp/utils/util', 'webapp/mixins/crud-mixin', 'webapp/pods/aquisicao/contrato/aditivo/reajuste/model', 'webapp/pods/aquisicao/contrato/aditivo/prorrogacao-vigencia/model', 'webapp/pods/aquisicao/contrato/aditivo/prorrogacao-execucao/model', 'webapp/pods/aquisicao/contrato/aditivo/alteracao-tipo-societario/model', 'webapp/pods/aquisicao/contrato/aditivo/alteracao-sede-social/model', 'webapp/pods/aquisicao/contrato/aditivo/alteracao-fiscal/model', 'webapp/pods/aquisicao/contrato/aditivo/alteracao-gestor/model'], function (exports, Ember, Util, CrudMixin, Reajuste, ProrrogacaoVigencia, ProrrogacaoExecucao, AlteracaoTipoSocietario, AlteracaoSedeSocial, AlteracaoFiscal, AlteracaoGestor) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend(CrudMixin['default'], {

    needs: ['aquisicao/contrato'],

    breadCrumbConfig: {
      fnNomeApresentacao: 'fnNomeApresentacao'
    },

    fnNomeApresentacao: function fnNomeApresentacao() {
      return this.get("controllers.aquisicao/contrato").get('aditivoOuApostila').toLocaleCapCase() + ' ' + (this.model.version ? 'Nº ' + this.model.numeroApresentacao : '(Novo)');
    },

    titulo: (function () {
      return this.get("controllers.aquisicao/contrato").get('aditivoOuApostila').toLocaleCapCase() + ' ' + this.model.numeroApresentacao + ' - Contrato ' + this.get("controllers.aquisicao/contrato").model.numeroApresentacao;
    }).property("model.numero"),

    crudConfig: {
      criar: {
        urlFn: function urlFn() {
          return 'contrato/' + this.get("controllers.aquisicao/contrato").model.id + '/' + this.get("controllers.aquisicao/contrato").get('aditivoOuApostila').toLocaleLowerCase();
        },
        okFn: function okFn(opcao) {
          this.registraTiposGravadosNoBanco();
          this.get('controllers.aquisicao/contrato').send("ler");
          if (opcao === 'CRIAR_E_PROXIMO') {
            //ação "borbulhará" para a rota
            this.send('refreshRota');
            return;
          }
          this.transitionToRoute('aquisicao/contrato/aditivo', this.model.id);
        }
      },
      atualizar: {
        okFn: function okFn() {
          this.registraTiposGravadosNoBanco();
          this.get('controllers.aquisicao/contrato').send("ler");
          this.transitionToRoute('aquisicao/contrato/aditivo');
        }
      },
      excluir: {
        okFn: function okFn() {
          this.get('controllers.aquisicao/contrato').send('ler');
          this.transitionToRoute('aquisicao/contrato');
        }
      }
    },

    registraTiposGravadosNoBanco: function registraTiposGravadosNoBanco() {
      this.set('aditivoTipoACRESCIMO_SUPRESSAO_Gravado', this.get('aditivoTipoACRESCIMO_SUPRESSAO'));
      this.set('aditivoTipoALTERACAO_TEOR_CLAUSULA_Gravado', this.get('aditivoTipoALTERACAO_TEOR_CLAUSULA'));
      this.set('aditivoTipoREPACTUACAO_Gravado', this.get('aditivoTipoREPACTUACAO'));
      this.set('aditivoTipoREVISAO_FINANCEIRA_Gravado', this.get('aditivoTipoREVISAO_FINANCEIRA'));
    },

    //TODO: A CONDIÇÃO "TIPOS VAZIOS" É TEMPORÁRIA, APENAS ATÉ ALTERAREM OS ADITIVOS INCLUÍDOS VAZIOS EMERGENCIALMENTE
    naoPodeAlterarTipos: (function () {
      return !this.get('ehAditivoNovo') && !this.get('ehUltimoAditivoContrato') && !this.get('ehAditivoVazioMigracao');
    }).property('ehAditivoNovo', 'ehAditivoVazioMigracao'),

    //TODO: A CONDIÇÃO "TIPOS VAZIOS" É TEMPORÁRIA, APENAS ATÉ ALTERAREM OS ADITIVOS INCLUÍDOS VAZIOS EMERGENCIALMENTE
    permitidaQualquerAlteracao: (function () {
      return this.get('ehAditivoNovo') || this.get('ehUltimoAditivoContrato') || this.get('ehAditivoVazioMigracao');
    }).property('ehAditivoNovo', 'ehAditivoVazioMigracao'),

    ehUltimoAditivoContrato: (function () {
      var outrosAditivosApostilas = this.get('controllers.aquisicao/contrato').model.aditivos.concat(this.get('controllers.aquisicao/contrato').model.apostilas);
      var esteAditivoApostila = this.model;

      return outrosAditivosApostilas.every(function (outroAditivoApostila) {
        return esteAditivoApostila.numeroInterno >= outroAditivoApostila.numeroInterno;
      });
    }).property('model', 'model.version'),

    ehAditivo: (function () {
      return this.get("controllers.aquisicao/contrato").get('aditivoOuApostila').toLocaleLowerCase() === 'aditivo';
    }).property('model', 'model.version'),

    ehAditivoNovo: (function () {
      return !this.model.version;
    }).property('model', 'model.version'),

    ehAditivoExistente: (function () {
      return this.model.version;
    }).property('model', 'model.version'),

    atualizaTipoREAJUSTE: (function () {
      this._atualizaTipo('REAJUSTE');
    }).observes('aditivoTipoREAJUSTE'),

    atualizaTipoPRORROGACAO_VIGENCIA: (function () {
      this._atualizaTipo('PRORROGACAO_VIGENCIA');
    }).observes('aditivoTipoPRORROGACAO_VIGENCIA'),

    atualizaTipoPRORROGACAO_EXECUCAO: (function () {
      this._atualizaTipo('PRORROGACAO_EXECUCAO');
    }).observes('aditivoTipoPRORROGACAO_EXECUCAO'),

    atualizaTipoACRESCIMO_SUPRESSAO: (function () {
      this._atualizaTipo('ACRESCIMO_SUPRESSAO');
    }).observes('aditivoTipoACRESCIMO_SUPRESSAO'),

    atualizaTipoALTERACAO_TEOR_CLAUSULA: (function () {
      this._atualizaTipo('ALTERACAO_TEOR_CLAUSULA');
    }).observes('aditivoTipoALTERACAO_TEOR_CLAUSULA'),

    atualizaTipoREPACTUACAO: (function () {
      this._atualizaTipo('REPACTUACAO');
    }).observes('aditivoTipoREPACTUACAO'),

    atualizaTipoREVISAO_FINANCEIRA: (function () {
      this._atualizaTipo('REVISAO_FINANCEIRA');
    }).observes('aditivoTipoREVISAO_FINANCEIRA'),

    atualizaTipoALTERACAO_TIPO_SOCIETARIO: (function () {
      this._atualizaTipo('ALTERACAO_TIPO_SOCIETARIO');
    }).observes('aditivoTipoALTERACAO_TIPO_SOCIETARIO'),

    atualizaTipoALTERACAO_SEDE_SOCIAL: (function () {
      this._atualizaTipo('ALTERACAO_SEDE_SOCIAL');
    }).observes('aditivoTipoALTERACAO_SEDE_SOCIAL'),

    atualizaTipoALTERACAO_FISCAL: (function () {
      this._atualizaTipo('ALTERACAO_FISCAL');
    }).observes('aditivoTipoALTERACAO_FISCAL'),

    atualizaTipoALTERACAO_GESTOR: (function () {
      this._atualizaTipo('ALTERACAO_GESTOR');
    }).observes('aditivoTipoALTERACAO_GESTOR'),

    _atualizaTipo: function _atualizaTipo(nomeDoTipo) {
      if (this._tipoMarcado(nomeDoTipo)) {
        this._colocaTipoNaLista(nomeDoTipo);
        if (this.get('permitidaQualquerAlteracao') && !this._tipoEstaNoModelo(nomeDoTipo)) {
          this._colocaTipoNoModelo(nomeDoTipo);
        }
      } else {
        this._retiraTipoDaLista(nomeDoTipo);
        this._retiraTipoDoModelo(nomeDoTipo);
      }
    },

    _tipoMarcado: function _tipoMarcado(nomeDoTipo) {
      return this['aditivoTipo' + nomeDoTipo];
    },

    //Se o tipo ainda não estiver na lista, coloca-o
    _colocaTipoNaLista: function _colocaTipoNaLista(nomeTipo) {
      if (!this._tipoEstaPresenteNaLista(nomeTipo)) {
        this.model.tipos.push({ tipo: nomeTipo });
      }
    },

    //Se o tipo estiver na lista, retira-o
    _retiraTipoDaLista: function _retiraTipoDaLista(nomeTipo) {
      var posicaoTipoNaLista = this._encontraPosicaoTipoNaLista(nomeTipo);
      if (posicaoTipoNaLista >= 0) {
        this.model.tipos.splice(posicaoTipoNaLista, 1);
      }
    },

    _tipoEstaPresenteNaLista: function _tipoEstaPresenteNaLista(nomeTipo) {
      return this._encontraPosicaoTipoNaLista(nomeTipo) >= 0;
    },

    _encontraPosicaoTipoNaLista: function _encontraPosicaoTipoNaLista(nomeTipo) {
      return this.model.tipos.findIndex(function (tipo) {
        return tipo.tipo === nomeTipo;
      });
    },

    _tipoEstaNoModelo: function _tipoEstaNoModelo(nomeTipo) {
      switch (nomeTipo) {
        case 'REAJUSTE':
          return this.get('model.reajuste');
        case 'PRORROGACAO_VIGENCIA':
          return this.get('model.prorrogacaoVigencia');
        case 'PRORROGACAO_EXECUCAO':
          return this.get('model.prorrogacaoExecucao');
        case 'ACRESCIMO_SUPRESSAO':
          return this.get('model.itensAcrescimoSupressao');
        case 'ALTERACAO_TEOR_CLAUSULA':
          return this.get('model.alteracoesTeorClausula');
        case 'REPACTUACAO':
          return this.get('model.repactuacoes');
        case 'REVISAO_FINANCEIRA':
          return this.get('model.revisoes');
        case 'ALTERACAO_TIPO_SOCIETARIO':
          return this.get('model.alteracaoTipoSocietario');
        case 'ALTERACAO_SEDE_SOCIAL':
          return this.get('model.alteracaoSedeSocial');
        case 'ALTERACAO_FISCAL':
          return this.get('model.alteracaoFiscal');
        case 'ALTERACAO_GESTOR':
          return this.get('model.alteracaoGestor');
        default:
          alert('Tipo de aditivo inexistente');
          return false;

      }
    },

    _colocaTipoNoModelo: function _colocaTipoNoModelo(nomeTipo) {
      switch (nomeTipo) {
        case 'REAJUSTE':
          this.set('model.reajuste', Reajuste['default'].create({}));break;
        case 'PRORROGACAO_VIGENCIA':
          this.set('model.prorrogacaoVigencia', ProrrogacaoVigencia['default'].create({}));break;
        case 'PRORROGACAO_EXECUCAO':
          this.set('model.prorrogacaoExecucao', ProrrogacaoExecucao['default'].create({}));break;
        case 'ACRESCIMO_SUPRESSAO':
          this.set('model.itensAcrescimoSupressao', []);break;
        case 'ALTERACAO_TEOR_CLAUSULA':
          this.set('model.alteracoesTeorClausula', []);break;
        case 'REPACTUACAO':
          this.set('model.repactuacoes', []);break;
        case 'REVISAO_FINANCEIRA':
          this.set('model.revisoes', []);break;
        case 'ALTERACAO_TIPO_SOCIETARIO':
          this.set('model.alteracaoTipoSocietario', AlteracaoTipoSocietario['default'].create({}));break;
        case 'ALTERACAO_SEDE_SOCIAL':
          this.set('model.alteracaoSedeSocial', AlteracaoSedeSocial['default'].create({}));break;
        case 'ALTERACAO_FISCAL':
          this.set('model.alteracaoFiscal', AlteracaoFiscal['default'].create({}));break;
        case 'ALTERACAO_GESTOR':
          this.set('model.alteracaoGestor', AlteracaoGestor['default'].create({}));break;
        default:
          alert('Tipo de aditivo inexistente');break;
      }
    },

    //Cria uma cópia vazia do modelo, apenas para garantir que as chamadas a atributos do modelo não quebrem por nullpointer exception
    _retiraTipoDoModelo: function _retiraTipoDoModelo(nomeTipo) {
      switch (nomeTipo) {
        case 'REAJUSTE':
          this.set('model.reajuste', Reajuste['default'].create({}));break;
        case 'PRORROGACAO_VIGENCIA':
          //this.set('model.prorrogacaoVigencia', ProrrogacaoVigencia.create({})); break;
          this.set('model.prorrogacaoVigencia', null);break;
        case 'PRORROGACAO_EXECUCAO':
          this.set('model.prorrogacaoExecucao', ProrrogacaoExecucao['default'].create({}));break;
        case 'ACRESCIMO_SUPRESSAO':
          this.set('model.itensAcrescimoSupressao', []);break;
        case 'ALTERACAO_TEOR_CLAUSULA':
          this.set('model.alteracoesTeorClausula', []);break;
        case 'REPACTUACAO':
          this.set('model.repactuacoes', []);break;
        case 'REVISAO_FINANCEIRA':
          this.set('model.revisoes', []);break;
        case 'ALTERACAO_TIPO_SOCIETARIO':
          this.set('model.alteracaoTipoSocietario', AlteracaoTipoSocietario['default'].create({}));break;
        case 'ALTERACAO_SEDE_SOCIAL':
          this.set('model.alteracaoSedeSocial', AlteracaoSedeSocial['default'].create({}));break;
        case 'ALTERACAO_FISCAL':
          this.set('model.alteracaoFiscal', AlteracaoFiscal['default'].create({}));break;
        case 'ALTERACAO_GESTOR':
          this.set('model.alteracaoGestor', AlteracaoGestor['default'].create({}));break;
      }
    },

    ajustaSelecaoTiposAditivosConformeListaNoModelo: function ajustaSelecaoTiposAditivosConformeListaNoModelo() {
      this.set('aditivoTipoREAJUSTE', this._tipoEstaPresenteNaLista('REAJUSTE'));
      this.set('aditivoTipoPRORROGACAO_VIGENCIA', this._tipoEstaPresenteNaLista('PRORROGACAO_VIGENCIA'));
      this.set('aditivoTipoPRORROGACAO_EXECUCAO', this._tipoEstaPresenteNaLista('PRORROGACAO_EXECUCAO'));
      this.set('aditivoTipoACRESCIMO_SUPRESSAO', this._tipoEstaPresenteNaLista('ACRESCIMO_SUPRESSAO'));
      this.set('aditivoTipoALTERACAO_TEOR_CLAUSULA', this._tipoEstaPresenteNaLista('ALTERACAO_TEOR_CLAUSULA'));
      this.set('aditivoTipoREPACTUACAO', this._tipoEstaPresenteNaLista('REPACTUACAO'));
      this.set('aditivoTipoREVISAO_FINANCEIRA', this._tipoEstaPresenteNaLista('REVISAO_FINANCEIRA'));
      this.set('aditivoTipoALTERACAO_TIPO_SOCIETARIO', this._tipoEstaPresenteNaLista('ALTERACAO_TIPO_SOCIETARIO'));
      this.set('aditivoTipoALTERACAO_SEDE_SOCIAL', this._tipoEstaPresenteNaLista('ALTERACAO_SEDE_SOCIAL'));
      this.set('aditivoTipoALTERACAO_FISCAL', this._tipoEstaPresenteNaLista('ALTERACAO_FISCAL'));
      this.set('aditivoTipoALTERACAO_GESTOR', this._tipoEstaPresenteNaLista('ALTERACAO_GESTOR'));
    },

    mostraTiposNome: function mostraTiposNome(aditivo) {
      //Converte os tipos para exibição na tabela
      //Exemplo: NOME_TIPO -> Nome Tipo
      return aditivo.tipos.map(function (tipoObj) {
        switch (tipoObj.tipo) {
          case 'REAJUSTE':
            return 'Reajuste';
          case 'PRORROGACAO_VIGENCIA':
            return 'Prorrogação de Vigência';
          case 'PRORROGACAO_EXECUCAO':
            return 'Prorrogação de Execução';
          case 'ACRESCIMO_SUPRESSAO':
            return 'Acréscimo/Supressão';
          case 'ALTERACAO_TEOR_CLAUSULA':
            return 'Alteração de Teor de Cláusula';
          case 'REPACTUACAO':
            return 'Repactuação';
          case 'REVISAO_FINANCEIRA':
            return 'Revisão/Reequilíbrio Econômico Financeiro';
          case 'ALTERACAO_TIPO_SOCIETARIO':
            return 'Alteração de Tipo Societário';
          case 'ALTERACAO_SEDE_SOCIAL':
            return 'Alteração de Sede Social';
          case 'ALTERACAO_FISCAL':
            return 'Alteração de Fiscal';
          case 'ALTERACAO_GESTOR':
            return 'Alteração de Gestor';
          default:
            return ' ' + Util['default'].primeiraLetraEmMaiuscula(tipoObj.tipo);
        }
      });
    },

    limparModeloParaEnvio: function limparModeloParaEnvio() {
      if (!this._tipoEstaPresenteNaLista('REAJUSTE')) {
        this.set('model.reajuste', null);
      }
      if (!this._tipoEstaPresenteNaLista('PRORROGACAO_VIGENCIA')) {
        this.set('model.prorrogacaoVigencia', null);
      }
      if (!this._tipoEstaPresenteNaLista('PRORROGACAO_EXECUCAO')) {
        this.set('model.prorrogacaoExecucao', null);
      }
      if (!this._tipoEstaPresenteNaLista('ACRESCIMO_SUPRESSAO')) {
        this.set('model.itensAcrescimoSupressao', null);
      }
      if (!this._tipoEstaPresenteNaLista('ALTERACAO_TEOR_CLAUSULA')) {
        this.set('model.alteracoesTeorClausula', null);
      }
      if (!this._tipoEstaPresenteNaLista('REPACTUACAO')) {
        this.set('model.repactuacoes', null);
      }
      if (!this._tipoEstaPresenteNaLista('REVISAO_FINANCEIRA')) {
        this.set('model.revisoes', null);
      }
      if (!this._tipoEstaPresenteNaLista('ALTERACAO_TIPO_SOCIETARIO')) {
        this.set('model.alteracaoTipoSocietario', null);
      }
      if (!this._tipoEstaPresenteNaLista('ALTERACAO_SEDE_SOCIAL')) {
        this.set('model.alteracaoSedeSocial', null);
      }
      if (!this._tipoEstaPresenteNaLista('ALTERACAO_FISCAL')) {
        this.set('model.alteracaoFiscal', null);
      }
      if (!this._tipoEstaPresenteNaLista('ALTERACAO_GESTOR')) {
        this.set('model.alteracaoGestor', null);
      }
    },

    aditivoAlteracaoTeorClausulaNaoGravNoBco: (function () {
      return !this.get('aditivoTipoALTERACAO_TEOR_CLAUSULA_Gravado');
    }).property('aditivoTipoALTERACAO_TEOR_CLAUSULA_Gravado'),

    aditivoRepactuacaoNaoGravNoBco: (function () {
      return !this.get('aditivoTipoREPACTUACAO_Gravado');
    }).property('aditivoTipoREPACTUACAO_Gravado'),

    aditivoRevisaoFinanceiraNaoGravNoBco: (function () {
      return !this.get('aditivoTipoREVISAO_FINANCEIRA_Gravado');
    }).property('aditivoTipoREVISAO_FINANCEIRA_Gravado'),

    aditivoAcrescimoSupressaoNaoGravNoBco: (function () {
      return !this.get('aditivoTipoACRESCIMO_SUPRESSAO_Gravado');
    }).property('aditivoTipoACRESCIMO_SUPRESSAO_Gravado'),

    actions: {
      encaminharCriacao: function encaminharCriacao(opcao) {
        this.limparModeloParaEnvio();
        this.send('criar', opcao);
      },
      encaminharAlteracao: function encaminharAlteracao(opcao) {
        this.limparModeloParaEnvio();
        this.send('atualizar', opcao);
      }
    }

  });

});
define('webapp/components/busca-cbo', ['exports', 'ember', 'webapp/utils/id'], function (exports, Ember, UtilID) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({

    comunicador: Ember['default'].inject.service(),

    mostrarBuscaCBOs: false,
    mostrarResultados: false,
    cbosPesquisados: [],
    cboSelecionado: {}, //para uso interno do componente (de onde serão
    //buscados os dados a serem apresentados, etc.)

    texto: 'CBO',

    codigoAPesquisar: '',
    nomeAPesquisar: '',

    _contextoComponente: (function () {
      return UtilID['default'].getSeccao(this.id, 'contexto');
    }).property('id'),

    _localComponente: (function () {
      return UtilID['default'].getSeccao(this.id, 'local');
    }).property('id'),

    _idBotaoAbrirPesquisa: (function () {
      return UtilID['default'].constroi('botao', 'abrir-pesquisa', 'cbo', this.get('_contextoComponente'), this.get('_localComponente'));
    }).property('_contextoComponente', '_localComponente'),

    _idExcluirCBO: (function () {
      return UtilID['default'].constroi('botao', 'excluir', 'cbo', this.get('_contextoComponente'), this.get('_localComponente'));
    }).property('_contextoComponente', '_localComponente'),

    _idCampoNome: (function () {
      return UtilID['default'].constroi('campo', 'nome', 'cbo', this.get('_contextoComponente'), this.get('_localComponente'));
    }).property('_contextoComponente', '_localComponente'),

    _idCampoCodigo: (function () {
      return UtilID['default'].constroi('campo', 'codigo', 'cbo', this.get('_contextoComponente'), this.get('_localComponente'));
    }).property('_contextoComponente', '_localComponente'),

    _idBotaoBuscar: (function () {
      return UtilID['default'].constroi('botao', 'buscar', 'cbo', this.get('_contextoComponente'), this.get('_localComponente'));
    }).property('_contextoComponente', '_localComponente'),

    _idBotaoCancelar: (function () {
      return UtilID['default'].constroi('botao', 'cancelar-pesquisa', 'cbo', this.get('_contextoComponente'), this.get('_localComponente'));
    }).property('_contextoComponente', '_localComponente'),

    _idTabelaResultados: (function () {
      return UtilID['default'].constroi('tabela', 'listar-resultado-pesquisa', 'cbo', this.get('_contextoComponente'), this.get('_localComponente'));
    }).property('_contextoComponente', '_localComponente'),

    desabilitarBotoes: (function () {
      return this.desabilitado || this.somenteLeitura;
    }).property('desabilitado', 'somenteLeitura'),

    inicializar: (function () {
      this.inicializarCBOSelecionado();
    }).on('init'),

    inicializarCBOSelecionado: (function () {
      this.set('cboSelecionado', this.atributoDestino);
    }).observes('atributoDestino'),

    labelAcessivelCampo: (function () {
      return this.labelAcessivel;
    }).property('labelAcessivel'),

    instrucoesAdicionaisCampo: (function () {
      return this.get('desabilitarBotoes') ? 'Botões para alteração desabilitados' : 'Use os botões que vêm a seguir para alterar o CBO.';
    }).property('desabilitarBotoes'),

    labelAcessivelBotaoPesquisar: (function () {
      return 'Iniciar busca de ' + (this.labelAcessivel ? this.labelAcessivel : this.texto ? this.texto : 'CBO');
    }).property('labelAcessivel'),

    labelAcessivelBotaoExcluir: (function () {
      return 'Limpar ' + (this.labelAcessivel ? this.labelAcessivel : 'CBO') + ' selecionado';
    }).property('labelAcessivel'),

    actions: {

      excluir: function excluir() {
        this.set('atributoDestino', null);
        this.set('cboSelecionado', null);
      },

      iniciarBusca: function iniciarBusca() {
        this.set('codigoAPesquisar', '');
        this.set('nomeAPesquisar', '');

        this.set('mostrarBuscaCBOs', true);
      },

      pesquisarCBO: function pesquisarCBO() {
        var _this = this;

        var parametrosPesquisaCBO = {
          nome: this.nomeAPesquisar,
          codigo: parseInt(this.codigoAPesquisar)
        };

        this.set('mostrarResultados', false);

        this.get('comunicador').servico('post', 'cbo/pesquisa', parametrosPesquisaCBO).then(function (resultado) {
          _this.set('cbosPesquisados', resultado);
          _this.set('mostrarResultados', true);
        });
      },

      selecionarCBO: function selecionarCBO(cbo) {
        this.set('atributoDestino', cbo);
        this.set('cboSelecionado', cbo);
        this.set('mostrarBuscaCBOs', false);
      },

      cancelarBusca: function cancelarBusca() {
        this.set('mostrarBuscaCBOs', false);
      }

    }

  });

});
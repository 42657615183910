define('webapp/models/execucao', ['exports', 'ember', 'webapp/models/unidade-medida'], function (exports, Ember, UnidadeMedida) {

  'use strict';

  exports['default'] = Ember['default'].Object.extend({
    id: null,
    dataInicioPrazo: '',
    eventoInicioPrazo: '', //Quando o início não for definido por uma data; Será preenchido quando dataInicioPrazo não for
    marcoInicioPrazo: '', //'ACEITE_EMPENHO', 'RECEBIMENTO_PEDIDO_OU_OS',
    //'DATA_ESPECIFICA' ou 'EVENTO_ESPECIFICO'
    duracao: undefined,
    unidadeMedidaDuracao: UnidadeMedida['default'].create(),
    terminoEstimadoPrazo: ''
  });

});
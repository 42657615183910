define('webapp/pods/manutencao/route', ['exports', 'ember', 'webapp/utils/util'], function (exports, Ember, Util) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({
    beforeModel: function beforeModel() {
      var _this = this;

      this.get('comunicador').servico('get', 'parametroExibicao').then(function (resultado) {
        if (Util['default'].converteParametrosDeExibicao(resultado).EM_MANUTENCAO !== 'S') {
          _this.transitionTo('login');
        }
      });
    }

  });

});
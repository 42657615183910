define('webapp/mixins/suprimir-templates-superiores-mixin', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Mixin.create({

    actions: {
      didTransition: function didTransition() {
        this.send('suprimirTemplatesSuperiores');
        this.controller.set('mostrarTemplate', true);
      },

      suprimirTemplatesSuperiores: function suprimirTemplatesSuperiores() {
        this.controller.set('mostrarTemplate', false);
        try {
          //borbulha para o controller chamador. A última rota (para cima) é a última que inclui o mixin.
          return true;
        } catch (e) {}
        return false;
      }

    }

  });

});
define('webapp/pods/aquisicao/constantes-aquisicao', ['exports'], function (exports) {

  'use strict';

  exports['default'] = {

    modalidades: [{ id: 'ADESAO', nome: 'Adesão a Registro de Preços', ativo: true, presencial: false }, { id: 'LICITACAO_PREGAO_ELETRONICO', nome: 'Licitação (pregão eletrônico)', ativo: true, presencial: false }, { id: 'LICITACAO_PREGAO_PRESENCIAL', nome: 'Licitação (pregão presencial)', ativo: true, presencial: true }, { id: 'LICITACAO_CONVITE', nome: 'Licitação (convite)', ativo: false, presencial: false }, { id: 'LICITACAO_TOMADA_PRECOS', nome: 'Licitação (tomada de preços)', ativo: true, presencial: false }, { id: 'LICITACAO_CONCORRENCIA', nome: 'Licitação (concorrência presencial)', ativo: true, presencial: true }, { id: 'LICITACAO_CONCORRENCIA_ELETRONICA', nome: 'Licitação (concorrência eletrônica)', ativo: true, presencial: false }, { id: 'CONCURSO', nome: 'Concurso', ativo: true, presencial: false }, { id: 'CREDENCIAMENTO', nome: 'Credenciamento', ativo: true, presencial: false }, { id: 'DIALOGO_COMPETITIVO', nome: 'Diálogo Competitivo', ativo: true, presencial: false }, { id: 'LEILAO_ELETRONICO', nome: 'Leilão - Eletrônico', ativo: true, presencial: false }, { id: 'LEILAO_PRESENCIAL', nome: 'Leilão - Presencial', ativo: true, presencial: true }, { id: 'MANIFESTACAO_DE_INTERESSE', nome: 'Manifestação de Interesse', ativo: true, presencial: false }, { id: 'PRE_QUALIFICACAO', nome: 'Pré-qualificação', ativo: true, presencial: false }, { id: 'PARTICIPACAO_REGISTRO_PRECOS', nome: 'Participação em Registro de Preços', ativo: true, presencial: false }, { id: 'INEXIGIBILIDADE', nome: 'Inexigibilidade de Licitação', ativo: true, presencial: false }, { id: 'DISPENSA', nome: 'Dispensa de Licitação', ativo: true, presencial: false }, { id: 'SUPRIMENTO_FUNDOS', nome: 'Suprimento de Fundos', ativo: true, presencial: false }, { id: 'INAPLICABILIDADE_DA_LICITACAO', nome: 'Inaplicabilidade da Licitação', ativo: true, presencial: false }, { id: 'OUTROS', nome: 'Outros', ativo: true, presencial: false }],

    motivosCriacaoItens: [{ id: 'ORIGINAL', nome: 'Pedido Original' }, { id: 'ACRESCIMO_QUANTITATIVO', nome: 'Acréscimo Quantitativo' }, { id: 'ACRESCIMO_QUALITATIVO', nome: 'Acréscimo Qualitativo' }, { id: 'SUPRESSAO', nome: 'Supressão' }, { id: 'COTA', nome: 'Cota Exclusiva ME/EPP' }, { id: 'PARCELA_PRINCIPAL_COTA', nome: 'Parcela Principal (Cota)' }, { id: 'PARTICIPACAO_EXCLUSIVA_PARA_ME_EPP', nome: 'Participação exclusiva para ME/EPP' }]

  };

});
define('webapp/pods/aquisicao/contrato/item/model', ['exports', 'ember'], function (exports, Ember) {

	'use strict';

	exports['default'] = Ember['default'].Object.extend({
		id: null,
		classificacao: null, //aqui vai o objeto ClassificacaoItem
		descricao: '',
		quantidade: '',
		unidadeMedida: null, //aqui vai o objeto UnidadeMedida
		valorUnitario: '',
		valorTotal: '',
		motivoCriacao: '',
		numeroApresentacao: '',
		duracao: 1, //duracao do fornecimento, em meses
		periodico: false, //true ou false, indicando se o item é de fornecimento periódico, como um serviço

		findUrlFn: function findUrlFn(id) {
			return 'contrato/item/' + id.toString();
		}
	});

});
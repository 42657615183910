define('webapp/models/empenho', ['exports', 'ember'], function (exports, Ember) {

	'use strict';

	exports['default'] = Ember['default'].Object.extend({
			id: null,
			numero: undefined,
			prazoEfetivo: '', //DATA OU NÚMERO DE DIAS?
			prazoLimite: '', //DATA OU NÚMERO DE DIAS?
			aceite: '', //DATA?
			entregaObjeto: '', //DATA?
			diferenca: '' //DIFERENÇA EM DIAS? ENTRE QUAIS DATAS?
	});

});
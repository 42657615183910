define('webapp/components/tag-situacao-aquisicao', ['exports', 'ember', 'webapp/utils/id'], function (exports, Ember, UtilID) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({

    comunicador: Ember['default'].inject.service(),

    mostrarModalAjusteSituacao: false,
    entidadeEmAnalise: {}, //para uso interno do componente (de onde serão
    //buscados os dados a serem apresentados, etc.)

    tiposSituacao: [{ id: "ATIVA", nome: "Ativa", codigo: 1, cor: "verde" }, { id: "REVOGADA", codigo: 2, nome: "Revogada", cor: "vemelho" }, { id: "ANULADA", codigo: 3, nome: "Anulada", cor: "vemelho" }, { id: "SUSPENSA", codigo: 4, nome: "Suspensa", cor: "vemelho" }],
    situacaoAquisicaoTemp: "",
    dataSituacaoAquisicaoTemp: "",
    mensagemDescricaoSituacao: "",

    //----------------------------------- IDs -----------------------------------

    _contextoComponente: (function () {
      return UtilID['default'].getSeccao(this.id, 'contexto');
    }).property('id'),

    _localComponente: (function () {
      return UtilID['default'].getSeccao(this.id, 'local');
    }).property('id'),

    _idBotaoAbrirModal: (function () {
      return UtilID['default'].constroi('botao', 'abrir-modal-ajustes', 'situacao-aquisicao', this.get('_contextoComponente'), this.get('_localComponente'));
    }).property('_contextoComponente', '_localComponente'),

    _idCampoTipo: (function () {
      return UtilID['default'].constroi('select', 'tipo', 'situacao-aquisicao', this.get('_contextoComponente'), this.get('_localComponente'));
    }).property('_contextoComponente', '_localComponente'),

    _idCampoData: (function () {
      return UtilID['default'].constroi('date-input', 'data', 'situacao-aquisicao', this.get('_contextoComponente'), this.get('_localComponente'));
    }).property('_contextoComponente', '_localComponente'),

    _idBotaoSalvarAjsutes: (function () {
      return UtilID['default'].constroi('botao', 'salvar-ajustes', 'situacao-aquisicao', this.get('_contextoComponente'), this.get('_localComponente'));
    }).property('_contextoComponente', '_localComponente'),

    _idBotaoCancelar: (function () {
      return UtilID['default'].constroi('botao', 'cancelar-ajustes', 'situacao-aquisicao', this.get('_contextoComponente'), this.get('_localComponente'));
    }).property('_contextoComponente', '_localComponente'),

    //---------------------------------------------------------------------------

    desabilitarBotoes: (function () {
      return this.desabilitado || this.somenteLeitura;
    }).property('desabilitado', 'somenteLeitura'),

    descricaoSituacaoAquisicao: (function () {
      var _this = this;

      this.set('mensagemDescricaoSituacao', this.tiposSituacao.find(function (tipo) {
        return tipo.id === _this.aquisicao.situacaoAquisicao;
      }) ? this.tiposSituacao.find(function (tipo) {
        return tipo.id === _this.aquisicao.situacaoAquisicao;
      }).nome : "Ativa");

      if (this.aquisicao.enviaPNCP && this.aquisicao.pendenteEnvioAoPNCP) {
        this.set('mensagemDescricaoSituacao', this.get('mensagemDescricaoSituacao') + " (Pendente de Envio ao PNCP)");
      }

      if (this.aquisicao.enviaPNCP && !this.aquisicao.pendenteEnvioAoPNCP) {
        this.set('mensagemDescricaoSituacao', this.get('mensagemDescricaoSituacao') + " (Divulgada no PNCP)");
      }

      return this.get('mensagemDescricaoSituacao');
    }).property('aquisicao.situacaoAquisicao'),

    // VERIFICAR CONDIÇÕES PARA MOSTRAR BOTÃO AJUSTAR
    mostrarBotaoAjustar: (function () {
      if (this.aquisicao.situacaoAquisicao === 'ANULADA' || this.aquisicao.situacaoAquisicao === 'REVOGADA') {
        return false;
      }

      if (this.aquisicao.enviaPNCP && !this.get('aquisicaoEnviadaAoPNCP')) {
        return false;
      }

      return true;
    }).property('aquisicao.situacaoAquisicao', 'this.aquisicao.enviaPNCP'),

    aquisicaoEnviadaAoPNCP: (function () {
      return this.aquisicao.pncpano || this.aquisicao.pncpseq || this.aquisicao.urlPNCP;
    }).property('aquisicao.pncpano', 'aquisicao.pncpseq', 'aquisicao.urlPNCP'),

    corPainel: (function () {
      var _this2 = this;

      return this.tiposSituacao.find(function (tipo) {
        return tipo.id === _this2.aquisicao.situacaoAquisicao;
      }) ? this.tiposSituacao.find(function (tipo) {
        return tipo.id === _this2.aquisicao.situacaoAquisicao;
      }).cor : "verde";
    }).property('aquisicao.situacaoAquisicao'),

    mostrarLinkPNCP: (function () {
      return this.aquisicao.enviaPNCP && this.aquisicao.urlPNCP;
    }).property('aquisicao.urlPNCP', 'aquisicao.enviaPNCP'),

    mostrarBotaoEnviarAgora: (function () {
      return this.aquisicao.enviaPNCP;
    }).property('aquisicao.enviaPNCP'),

    buscarSituacaoAquisicao: function buscarSituacaoAquisicao() {
      this.get('comunicador').leParaPropriedade(this, 'situacaoContratacao/' + this.aquisicao.id, 'situacaoAquisicao', "Não foi possível obter a situação da aquisição.");
    },

    actions: {

      abrirModalAjustes: function abrirModalAjustes() {
        this.buscarSituacaoAquisicao();
        this.set('situacaoAquisicaoTemp', this.aquisicao.situacaoAquisicao);
        this.set('dataSituacaoAquisicaoTemp', this.aquisicao.dataSituacaoAquisicao);
        this.set('mostrarModalAjusteSituacao', true);
      },

      atualizarSituacao: function atualizarSituacao() {
        var _this3 = this;

        //CHARAM O SERVIÇO PARA SALVAR ...
        //PEDIR PARA ATUALIZAR A AQUISIÇÃO...
        this.situacaoAquisicao.novaSituacaoContratacao = this.situacaoAquisicaoTemp;
        this.situacaoAquisicao.novaDataSituacaoAquisicao = this.dataSituacaoAquisicaoTemp;
        this.get('comunicador').servico('put', "aquisicao/" + this.aquisicao.id + "/alteraSituacaoAquisicao", this.situacaoAquisicao).then(function () {
          _this3.set('mostrarModalAjusteSituacao', false);
          _this3.set('situacaoAquisicaoTemp', "");
          _this3.set('dataSituacaoAquisicaoTemp', "");
          window.location.reload();
        });
      },

      cancelarAtualizacaoSituacao: function cancelarAtualizacaoSituacao() {
        this.set('mostrarModalAjusteSituacao', false);
        this.set('situacaoAquisicaoTemp', "");
        this.set('dataSituacaoAquisicaoTemp', "");
      },

      enviarAgora: function enviarAgora() {
        this.get('comunicador').servico('post', 'aquisicao/' + this.aquisicao.id + '/enviaPNCP', null).then(function () {
          window.location.reload();
        });
      }

    }

  });

});
define('webapp/models/servidor', ['exports', 'ember'], function (exports, Ember) {

	'use strict';

	exports['default'] = Ember['default'].Object.extend({
		codSof: '',
		cpf: '',
		nome: '',
		username: '',
		email: '',

		inicializar: (function () {
			this.set('codSof', '');
			this.set('cpf', '');
			this.set('nome', '');
			this.set('username', '');
			this.set('email', '');
		}).on('init')
	});

});
define('webapp/pods/requisicao/controller', ['exports', 'ember', 'webapp/defs', 'webapp/utils/util', 'webapp/mixins/crud-mixin'], function (exports, Ember, Defs, Util, CrudMixin) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend(CrudMixin['default'], {

    mostrarRequisicao: true,

    needs: ['requisicao/listagem'],

    breadCrumbConfig: {
      fnNomeApresentacao: 'fnNomeApresentacao'
    },

    fnNomeApresentacao: function fnNomeApresentacao() {
      return 'Requisição ' + (this.model.version ? 'Nº ' + this.model.numeroApresentacao : '(Nova)');
    },

    crudConfig: {
      criar: {
        okFn: function okFn(opcao) {
          if (opcao === 'CRIAR_E_PROXIMO') {
            //ação "borbulhará" para a rota
            this.send('refreshRota');
            return;
          }
          this.transitionToRoute('requisicao', this.model.id);
        }
      },
      excluir: {
        okFn: function okFn() {
          this.get('controllers.requisicao/listagem').send('pesquisar');
        }
      }
    },

    temAquisicoesRelacionadas: (function () {
      return this.model.idAquisicoes.length;
    }).property('model.idAquisicoes.@each'),

    proibidoAlterar: (function () {
      return this.get('temAquisicoesRelacionadas');
    }).property('temAquisicoesRelacionadas'),

    permitidoAlterar: (function () {
      return !this.get('proibidoAlterar');
    }).property('proibidoAlterar'),

    haItemPeriodico: (function () {
      return this.model.itens.some(function (item) {
        return item.periodico;
      });
    }).property('model.itens.@each'),

    actions: {
      clonar: function clonar() {
        if (!this.requisicaoModelo) {
          Util['default'].alertaErro('Uma requisição modelo deve ser selecionada para realizar a clonagem!');
          return;
        }

        var self = this;
        this.get('comunicador').servico('post', 'requisicao/clona/' + this.requisicaoModelo.id).then(function (resultado) {
          self.transitionToRoute('requisicao', resultado);
          Util['default'].alerta('Operação realizada com sucesso!');
        });
      }

    },

    /*
        acessarProcesso: function() {
          this.get('comunicador').servico('get','parametroExibicao').then(
            resultado => {
              var acaoUrlProcessoEletronico = Util.converteParametrosDeExibicao(resultado).ACAO_URL_PROCESSO_ELETRONICO;
              var saveData = (function () {
                  var a = document.createElement("a");
                  document.body.appendChild(a);
                  a.style = "display: none";
                  return function (data, fileName) {
                      var blob = new Blob([data], {type: "application/pdf"}),
                          url = window.URL.createObjectURL(blob);
                      a.href = url;
                      a.download = fileName;
                      a.click();
                      window.URL.revokeObjectURL(url);
                  };
              }());
    
              var xhttp = new XMLHttpRequest();
              xhttp.onloadend = () => {
                if (xhttp.readyState === 4) {
                  if(xhttp.status === 200) {
                    if(acaoUrlProcessoEletronico === "BAIXAR_ARQUIVO") {
                      saveData(xhttp.response, `processo_${this.model.numeroProcessoAdministrativo}.pdf`);
                    }
                    else {
                      var url = (xhttp.response.includes("http://") || xhttp.response.includes("https://"))? xhttp.response : "http://" + xhttp.response;
                      window.open(url, '_blank');
                    }
    
                  }
                  else {
                    Util.alertaErro("Erro: processo não encontrado");
                  }
                }
    
              };
              xhttp.open('get', Defs.enderecoServer + "pa/download/" + encodeURI(this.model.numeroProcessoAdministrativo), true);
              xhttp.setRequestHeader('authToken', this.get('comunicador').get('usuarioLogado').estaLogado() ? this.get('comunicador').get('usuarioLogado').info.authToken : '');
              xhttp.setRequestHeader('TimeZone', this.get('comunicador').get('usuarioLogado').estaLogado() ? JSON.stringify(new Date()).substr(24,5) : '');
              xhttp.responseType = (acaoUrlProcessoEletronico === "BAIXAR_ARQUIVO")? "arraybuffer" : "text";
              xhttp.send();
            });
        }
    */
    descricaoRelatorios: (function () {
      return [{
        textoMenu: 'Relação de Requisições',
        servico: 'relatorio/relacaoDeRequisicoes/' + this.get("model.id"),
        nomeArquivo: 'relacao_de_requisicoes',
        nomeParaID: 'relacao-requisicoes',
        controlePermissao: true
      }];
    }).property("model.id")

  });

});
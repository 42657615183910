define('webapp/initializers/usuario-logado-service', ['exports', 'webapp/services/usuario-logado'], function (exports, UsuarioLogadoServico) {

  'use strict';

  exports['default'] = {
    name: 'usuario-logado-service-initializer',
    initialize: function initialize(container, application) {
      application.register('service:usuario-logado', UsuarioLogadoServico['default'], { singleton: true });
      application.inject('controller', 'usuarioLogado', 'service:usuario-logado');
      application.inject('router', 'usuarioLogado', 'service:usuario-logado');
      application.inject('service:cache', 'usuarioLogado', 'service:usuario-logado');
    }
  };

});
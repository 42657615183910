define('webapp/pods/aquisicao/evento/model', ['exports', 'ember'], function (exports, Ember) {

	'use strict';

	exports['default'] = Ember['default'].Object.extend({
		id: null,
		data: '',
		tipo: '',

		findUrlFn: function findUrlFn(id) {
			return 'aquisicao/eventoAquisicao/' + id.toString();
		}
	});

});
define('webapp/pods/usuario/controller', ['exports', 'ember', 'webapp/mixins/crud-mixin'], function (exports, Ember, CrudMixin) {

  'use strict';

  /* global _ */

  exports['default'] = Ember['default'].Controller.extend(CrudMixin['default'], {

    needs: ['usuario/listagem'],

    breadCrumbConfig: {
      fnNomeApresentacao: 'fnNomeApresentacao'
    },

    fnNomeApresentacao: function fnNomeApresentacao() {
      return 'Usuário ' + (this.model.version ? '- ' + this.model.servidor.nome : '(Novo)');
    },

    crudConfig: {
      criar: {
        okFn: function okFn() {
          this.transitionToRoute('usuario', this.model.id);
          this.get('controllers.usuario/listagem').send('listar');
        }
      },
      atualizar: {
        okFn: function okFn() {
          this.get('controllers.usuario/listagem').send('listar');
          this.transitionToRoute('usuario/listagem');
        }
      },
      excluir: {
        okFn: function okFn() {
          this.get('controllers.usuario/listagem').send('listar');
        }
      }
    },

    papelAAtribuir: {},
    papelARetirar: {},
    todosOsPapeis: [],

    papeisDisponiveis: (function () {
      var todos = this.get('todosOsPapeis');
      var atribuidos = this.model.roles;

      return todos.reject(function (papel) {
        return _.findWhere(atribuidos, { id: papel.id });
      });
    }).property('todosOsPapeis.@each', 'model.roles.@each'),

    papeisAtribuidos: (function () {
      return this.get('roles');
    }).property('model.roles.@each'),

    actions: {
      atribuirPapel: function atribuirPapel(papel) {
        if (!papel) {
          return;
        }
        this.model.roles.addObject(papel);
        this.set('papelAAtribuir', {});
      },

      retirarPapel: function retirarPapel(papel) {
        if (!papel) {
          return;
        }
        this.model.roles.removeObject(papel);
        this.set('papelARetirar', {});
      }
    }
  });

});
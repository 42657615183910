define('webapp/components/busca-aquisicao', ['exports', 'ember', 'webapp/mixins/ajusta-tamanho-input-mixin', 'webapp/pods/aquisicao/constantes-aquisicao'], function (exports, Ember, AjustaTamanhoInputMixin, Constantes) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(AjustaTamanhoInputMixin['default'], {

    comunicador: Ember['default'].inject.service(),

    mostrarBuscaAquisicao: false,
    mostrarResultados: false,
    aquisicoes: [],
    aquisicaoSelecionada: {}, //para uso interno do componente (de onde serão
    //buscados os dados a serem apresentados, etc.)

    texto: 'Aquisição',

    numero: undefined,
    ano: undefined,
    dataInicial: undefined,
    dataFinal: undefined,
    programa: undefined,
    objeto: undefined,
    requisitante: undefined,
    classificacoesItens: undefined,
    descricaoItem: undefined,
    mostraTabelaResultadoPesquisa: false,
    mostraMensagemPesquisaSemResultado: false,

    desabilitarBotoes: (function () {
      return this.desabilitado || this.somenteLeitura;
    }).property('desabilitado', 'somenteLeitura'),

    labelAcessivelCampo: (function () {
      return this.labelAcessivel;
    }).property('labelAcessivel'),

    instrucoesAdicionaisCampo: (function () {
      return this.get('desabilitarBotoes') ? 'Botões para alteração desabilitados.' : 'Use os botões que vêm a seguir para alterar a aquisição.';
    }).property('desabilitarBotoes'),

    labelAcessivelBotaoPesquisar: (function () {
      return 'Iniciar busca de ' + (this.labelAcessivel ? this.labelAcessivel : this.texto ? this.texto : 'aquisição');
    }).property('labelAcessivel'),

    labelAcessivelBotaoExcluir: (function () {
      return 'Limpar ' + (this.labelAcessivel ? this.labelAcessivel : 'aquisição') + ' selecionada';
    }).property('labelAcessivel'),

    labelDescricaoQuandoSelecionado: (function () {
      if (!this.aquisicaoSelecionada) {
        return "";
      }

      return this.aquisicaoSelecionada.numeroApresentacao + ' (' + this.getNomeLegivelModalidade(this.aquisicaoSelecionada) + ')';
    }).property('aquisicaoSelecionada'),

    inicializar: (function () {
      this.inicializarAquisicaoSelecionada();
    }).on('init'),

    inicializarAquisicaoSelecionada: (function () {
      this.set('aquisicaoSelecionada', this.atributoDestino);
    }).observes('objetoPai'),
    //---------

    _labelAcessivelBotao: (function () {
      return 'Iniciar busca de aquisição, ' + this.texto;
    }).property('texto'),

    modalidades: Constantes['default'].modalidades,

    getNomeLegivelModalidade: function getNomeLegivelModalidade(aquisicao) {
      var modalidadeDaAquisicao = this.modalidades.find(function (modalidade) {
        return aquisicao.modalidade === modalidade.id;
      });
      return modalidadeDaAquisicao.nome;
    },

    actions: {

      excluir: function excluir() {
        this.set('aquisicaoSelecionada', null);
        this.set('atributoDestino', null);
      },

      iniciarBusca: function iniciarBusca() {

        this.set('numero', undefined);
        this.set('ano', undefined);

        this.set('mostraTabelaResultadoPesquisa', false);
        this.set('mostraMensagemPesquisaSemResultado', false);
        this.set('mostrarBuscaAquisicao', true);
      },

      pesquisarAquisicao: function pesquisarAquisicao() {
        var _this = this;

        if (!this.numero && !this.ano) {
          return;
        }

        var parametrosPesquisaAquisicao = {
          numero: this.numero,
          ano: this.ano
        };

        this.set('mostrarResultados', false);

        this.get('comunicador').servico('post', 'aquisicao/pesquisa', parametrosPesquisaAquisicao).then(function (resultado) {
          _this.set('aquisicoesPesquisadas', resultado);
          _this.set('mostrarResultados', true);
        });
      },

      selecionarAquisicao: function selecionarAquisicao(aquisicao) {
        this.set('atributoDestino', aquisicao);
        this.set('aquisicaoSelecionada', aquisicao);
        this.set('mostrarBuscaAquisicao', false);
      },

      cancelarBusca: function cancelarBusca() {
        this.set('mostrarBuscaAquisicao', false);
      }

    }

  });

});
define('webapp/tests/unit/utils/id-test', function () {

  'use strict';

  /* global ok, _ */

  /* *** NÃO ESTÁ FUNCIONANDO --- CORRIGIR OU REMOVER --- ***
  import {test} from 'ember-qunit';
  import UtilID from '../../../utils/id';

  var seccoes = ['tipo', 'acao', 'informacao', 'contexto', 'local'];
  var idsComFormatoValido = [
      "____",
      "tipo_acao_info_contexto_local",
      "0_0_0_0_0",
      "t0_a-a_I-i_c.c.c_L1",
      "_a_a_a_",
      "_a__a_"
  ];

  var idsComFormatoInvalido = [
      "",
      "tipoAcaoInfoContextoLocal",
      "tipo_AcaoInfoContextoLocal",
      "tipo_Acao_InfoContextoLocal",
      "tipo_Acao_Info_ContextoLocal",
      "tipo_Acao_Info_Contexto_Local_",
      "_tipo_Acao_Info_Contexto_Local",
      "tipoAcao_Info_Contexto_Local",
      "tipoAcaoInfo_Contexto_Local",
      "tipoAcaoInfoContexto_Local"
  ];

  test(`getIdModificado() deve retornar um ID idêntico àquele recebido,
        exceto pela secção indicada, que deve apresentar o novo valor informado`,
        function(assert) {
          var id = seccoes.toString().replace(/,/g, '_');
          var novoValorSeccao = 'xxx';

          seccoes.forEach((nomeSeccao) => {
            var idModificadoEsperado = id.replace(nomeSeccao, novoValorSeccao);
            assert.equal(UtilID.getIdModificado(id, nomeSeccao, novoValorSeccao), idModificadoEsperado,
                        `Se a função getIdModificado() receber o ID "${id}" para modificar o valor da secção
                        "${nomeSeccao}" para "${novoValorSeccao}" o retorno deve ser ${idModificadoEsperado}`
            );
          }

          );
        }
  );

  test('getIdModificado() levanta uma exceção se o ID informado não tiver um formato válido',
        function(assert) {
          idsComFormatoInvalido.forEach((id) => {
            seccoes.forEach((nomeSeccao) => {
              assert.throws(
                () => { UtilID.getIdModificado(id, nomeSeccao, 'xxxx'); },
                new RegExp(`não tem um formato válido`),
                `A função getIdModificado() deveria levantar uma exceção ao receber "${id}" como id.`
              );
            });
            }
          );
        }
  );

  test(`getIdModificado() levanta uma exceção se o segundo parâmetro não for o nome de uma
        das seções esperadas (tipo, acao, informacao, contexto e local) ou se estiver vazio`,
        function(assert) {

    var nomesSeccaoInvalidos = [
      'teste',
      '',
      null,
      undefined,
      99999,
      {},
      []
    ];

    nomesSeccaoInvalidos.forEach((nomeSeccaoInvalido) => {
        assert.throws(
          () => { UtilID.getIdModificado('x_x_x_x_x', nomeSeccaoInvalido, 'xxx'); },
          new RegExp(`não é um nome de secção válido`),
          `Quando for passado um tipo de seccao inválido (ou seja, diferente de tipo, ação,
          informação, contexto e local - tal como "${nomeSeccaoInvalido}") para o método
          getIdModificado(), uma exceção deve ser levantada.`
        );
    });

  });

  test(`getIdModificado() levanta uma exceção se o terceiro parâmetro (o novo valor para
        a seccao) for "null" ou "undefined" (tanto as strings quanto os efetivos valores
        'falsy'), e aceita os demais possíveis valores (incluindo "")`,
        function(assert) {

          var valoresInvalidos = [
            null,
            undefined,
            "null",
            "undefined"
          ];

          seccoes.forEach((nomeSeccao) => {
            valoresInvalidos.forEach((valorInvalido) => {
              assert.throws(
                () => { UtilID.getIdModificado('x_x_x_x_x', nomeSeccao, valorInvalido); },
                '"null", "undefined", null e undefined não são valores válidos para as secções do ID.',
                `Quando "${valorInvalido}" for passado como novo valor para a função
                getIdModificado(), uma exceção deve ser levantada`
              );
              }
            );
          });

        }
  );

  //------------------------------------------------------------------------------
  //------------------------------------------------------------------------------
  //------------------------------------------------------------------------------

  test('O método getSeccao() retorna corretamente o valor da seccao indicada', function(assert) {
    var idComSeccoesAlfabeticas = "tipoTeste_acaoTeste_informacaoTeste_contextoTeste_localTeste";
    var idComSeccoesAlfaNumericas = "tipo99_acao99_informacao99_contexto99_local99";
    var idComSeccoesNumericas = "0_1_2_3_4";
    var idComSeccoesComTracos = "tipo-teste_acao-teste_informacao-teste_contexto-teste_local-teste";
    var idComSeccoesVazias = "____";

    seccoes.forEach((nomeSeccao) => {

      assert.equal(UtilID.getSeccao(idComSeccoesAlfabeticas, nomeSeccao), `${nomeSeccao}Teste`,
                    `Dado o ID ${idComSeccoesAlfabeticas}, a função getSeccao() deveria
                     ter retornado "${nomeSeccao}Teste" como valor da secção ${nomeSeccao}`);

      assert.equal(UtilID.getSeccao(idComSeccoesAlfaNumericas, nomeSeccao), `${nomeSeccao}99`,
                    `Dado o ID ${idComSeccoesAlfaNumericas}, a função getSeccao() deveria
                     ter retornado "${nomeSeccao}99" como valor da secção ${nomeSeccao}`);

      assert.equal(UtilID.getSeccao(idComSeccoesNumericas, nomeSeccao), `${seccoes.indexOf(nomeSeccao)}`,
                    `Dado o ID ${idComSeccoesNumericas}, a função getSeccao() deveria
                     ter retornado "${seccoes.indexOf(nomeSeccao)}" como valor da secção ${nomeSeccao}`);

      assert.equal(UtilID.getSeccao(idComSeccoesComTracos, nomeSeccao), `${nomeSeccao}-teste`,
                    `Dado o ID ${idComSeccoesComTracos}, a função getSeccao() deveria
                     ter retornado "${nomeSeccao}-teste" como valor da secção ${nomeSeccao}`);

      assert.equal(UtilID.getSeccao(idComSeccoesVazias, nomeSeccao), '',
                    `Dado o ID ${idComSeccoesVazias}, a função getSeccao() deveria
                     ter retornado "" como valor da secção ${nomeSeccao}`);

    });

  });

  test(`O método getSeccao() levanta uma exceção se o segundo parâmetro não for o nome de uma
        das seções esperadas (tipo, acao, informacao, contexto e local) ou se estiver vazio`,
        function(assert) {

    var nomesSeccaoInvalidos = [
      'teste',
      '',
      null,
      undefined,
      99999,
      {},
      []
    ];

    nomesSeccaoInvalidos.forEach(
      (nomeSeccaoInvalido) => {
        assert.throws(
          (nomeSeccaoInvalido) => { UtilID.getSeccao('x_x_x_x_x', nomeSeccaoInvalido); },
          new RegExp(`não é um nome de secção válido`),
          `Quando for passado um tipo de seccao inválido (ou seja, diferente de tipo, ação,
          informação, contexto e local - tal como "${nomeSeccaoInvalido}") para o método getSeccao(),
          uma exceção deve ser levantada.`
        );
      }
    );

  });

  test('A função getSeccao() levanta uma exceção se o ID informado não tiver um formato válido',
        function(assert) {

    idsComFormatoInvalido.forEach(
      (id) => {
        seccoes.forEach(
          (nomeSeccao) => {
            assert.throws(
              () => {
                UtilID.getSeccao(id, nomeSeccao);
              },
              new RegExp(`não tem um formato válido`),
              `A função getSeccao() deveria levantar uma exceção ao receber "${id}" como id.`
            );
          }
        );

      }
    );


  });

  test('temFormatoValido() aceita apenas strings com formato válido',
      function(assert) {

    idsComFormatoValido.forEach((id) => {
      ok(UtilID.temFormatoValido(id), `Deve-se aceitar ${id} como um ID com formato válido.`);
    });

    idsComFormatoInvalido.forEach((id) => {
      ok(!UtilID.temFormatoValido(id), `O ID ${id} não deveria ser aceito como tendo um formato válido.`);
    });

  });

  test(`temTipoValido() aceita como válidos apenas "botao", "link",
        "tabela", "campo", "select", "checkbox" e "radio"`, function(assert) {

    assert.expect(9);

    var exemplosIdTipoComponenteValido = [
      "botao_xxx_xxx_xxx_xxx",
      "link_xxx_xxx_xxx_xxx",
      "tabela_xxx_xxx_xxx_xxx",
      "campo_xxx_xxx_xxx_xxx",
      "select_xxx_xxx_xxx_xxx",
      "checkbox_xxx_xxx_xxx_xxx",
      "radio_xxx_xxx_xxx_xxx",
    ];

    exemplosIdTipoComponenteValido.forEach((id) => {
      ok(UtilID.temTipoValido(id),
          `temTipoComponenteValido() deve aceitar "${UtilID.getSeccao(id, 'tipo')}"
          como um tipo de componente válido em um ID`);
    });

    var exemplosIdTipoComponenteInvalido = [
      "outro_xxx_xxx_xxx_xxx",
      "undefined_xxx_xxx_xxx_xxx",
    ];

    exemplosIdTipoComponenteInvalido.forEach((id) => {
      ok(!UtilID.temTipoValido(id),
          `temTipoComponenteValido() não deve aceitar "${UtilID.getSeccao(id, 'tipo')}"
          como um tipo de componente válido em um ID`);
    });

  });

  test('Todo ID deve ter um tipo de componente definido', function(assert) {
    ok(!UtilID.temTipoValido('_xxx_xxx_xxx_xxx'),
       'temTipoValido() não deve aceitar uma string vazia como tipo de componente');
  });

  test(`Os únicos tipos de ação válidos em um ID são "ler", "criar", "atualizar",
        "excluir" e "pesquisar"`, function(assert) {

    assert.expect(7);

    var exemplosIdTipoAcaoValido = [
      'xxx_ler_xxx_xxx_xxx',
      'xxx_criar_xxx_xxx_xxx',
      'xxx_atualizar_xxx_xxx_xxx',
      'xxx_excluir_xxx_xxx_xxx',
      'xxx_pesquisar_xxx_xxx_xxx'
    ];

    exemplosIdTipoAcaoValido.forEach((id) => {
      ok(UtilID.temAcaoValida(id),
        `temAcaoValida() deve aceitar "${UtilID.getSeccao(id, 'acao')}" como
        um valor válido para a secção de tipo de ação em um ID`);
    });

    var exemplosIdTipoAcaoInvalido = [
      'xxx_qualquer_xxx_xxx_xxx',
      'xxx_undefined_xxx_xxx_xxx',
    ];

    exemplosIdTipoAcaoInvalido.forEach((id) => {
      ok(!UtilID.temAcaoValida(id),
        `temAcaoValida() não deve aceitar "${UtilID.getSeccao(id, 'acao')}" como
        um valor válido para a secção de tipo de ação em um ID`);
    });
  });

  test('Não é necessário definir um tipo de ação num ID - pode-se deixá-lo como ""',
        function(assert) {
    ok(UtilID.temAcaoValida("xxx__xxx_xxx_xxx"),
       'temAcaoValida() deve aceitar que a secção de ação num ID esteja vazia');
  });

  test(`Todo ID deve ter uma secção não-vazia (exceto "null" e "undefined")
        indicando seu contexto`, function(assert) {
    assert.expect(4);

    ok(UtilID.temContextoValido('xxx_xxx_xxx_teste_xxx'),
        'temConstextoValido() deve aceitar "teste" como um valor válido para a secção de contexto do id');

    ok(!UtilID.temContextoValido('xxx_xxx_xxx__xxx'),
        'temConstextoValido() não deve aceitar string vazia como um valor válido para a seção de contexto do id');

    ok(!UtilID.temContextoValido('xxx_xxx_xxx_null_xxx'),
        'temConstextoValido() não deve aceitar "null" como um valor válido para a seção de contexto do id');

    ok(!UtilID.temContextoValido('xxx_xxx_xxx_undefined_xxx'),
        'temConstextoValido() não deve aceitar "undefined" como um valor válido para a seção de contexto do id');
  });

  test(`Todo ID deve ter uma secção não-vazia (exceto "null" e "undefined")
        indicando o local onde é apresentado`, function(assert) {
    assert.expect(4);

    ok(UtilID.temLocalValido('xxx_xxx_xxx_xxx_teste'),
        'temLocalValido() deve aceitar "teste" como um valor válido para preencher a secção de local do id');

    ok(!UtilID.temLocalValido('xxx_xxx_xxx_xxx_'),
        'temLocalValido() não deve aceitar string vazia como um valor válido para preencher a secção de local do id');

    ok(!UtilID.temLocalValido('xxx_xxx_xxx_xxx_null'),
      'temLocalValido() não deve aceitar "null" como um valor válido para preencher a secção de local do id');

    ok(!UtilID.temLocalValido('xxx_xxx_xxx_xxx_undefined'),
      'temLocalValido() não deve aceitar "undefined" como um valor válido para preencher a secção de local do id');
  });
  */

});
define('webapp/pods/aquisicao/contrato/route', ['exports', 'ember', 'webapp/mixins/suprimir-templates-superiores-mixin', 'webapp/utils/util'], function (exports, Ember, SuprimirTemplatesSuperioresMixin, Util) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(SuprimirTemplatesSuperioresMixin['default'], {
    setupController: function setupController(controller, model) {
      controller.atribuiValoresDefault();
      controller.inicializaCampos(Util['default'].clona(model));
    },

    actions: {
      willTransition: function willTransition(transition) {
        this._preparaParametrosTransicaoRotaPesquisa(transition);
      }
    },

    //Se a transição for para a rota de pesquisa, envia um parâmetro indicando que é
    //necessário manter os dados da pesquisa anterior na tela
    _preparaParametrosTransicaoRotaPesquisa: function _preparaParametrosTransicaoRotaPesquisa(transition) {
      if (transition.targetName !== 'pesquisa-contratos') {
        return true;
      }

      var parametros = transition.queryParams ? Object.keys(transition.queryParams) : [];
      if (parametros.includes('manterDadosPesquisaAnterior')) {
        return true;
      }

      transition.abort();
      this.transitionTo('pesquisa-contratos', { queryParams: { manterDadosPesquisaAnterior: 'true' } });
    }

  });

});
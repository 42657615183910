define('webapp/pods/aquisicao/lote/adjudicar/route', ['exports', 'ember', 'webapp/utils/util'], function (exports, Ember, Util) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({
    setupController: function setupController(controller, model) {
      //Coloca um clone no model para que as alterações não ocorram
      //diretamente no fornecedor e seja possível desfazê-las
      controller.set('model', Util['default'].clona(model));

      if (model.resultado) {
        controller.set('resultadoDefinido', model.resultado);
        controller.set('loteFoiAdjudicado', model.resultado === 'ADJUDICADO');
      } else {
        controller.set('resultadoDefinido', null);
        controller.set('loteFoiAdjudicado', false);
        controller.set('model.resultado', 'ADJUDICADO');
        controller.set('model.dataAdjudicacao', new Date());
        controller.set('model.dataResultado', new Date());
      }
    }
  });

});
define('webapp/pods/aquisicao/contrato/aditivo/criar/route', ['exports', 'ember', 'webapp/pods/aquisicao/contrato/aditivo/model', 'webapp/mixins/suprimir-templates-superiores-mixin'], function (exports, Ember, Aditivo, SuprimirTemplatesSuperioresMixin) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(SuprimirTemplatesSuperioresMixin['default'], {
    controllerName: 'aquisicao/contrato/aditivo',
    setupController: function setupController(controller) {
      var aditivo = Aditivo['default'].create({});
      aditivo.apostila = controller.get("controllers.aquisicao/contrato").get('aditivoOuApostila').toLocaleCapCase() === "Apostila" ? "S" : "N";
      controller.set('model', aditivo);
      controller.ajustaSelecaoTiposAditivosConformeListaNoModelo();
    },

    model: function model() {
      return Aditivo['default'].create();
    }
  });

});
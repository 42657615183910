define('webapp/pods/aquisicao/contrato/criar/route', ['exports', 'ember', 'webapp/pods/aquisicao/contrato/model', 'webapp/mixins/suprimir-templates-superiores-mixin'], function (exports, Ember, Contrato, SuprimirTemplatesSuperioresMixin) {

	'use strict';

	exports['default'] = Ember['default'].Route.extend(SuprimirTemplatesSuperioresMixin['default'], {
		controllerName: 'aquisicao/contrato',

		setupController: function setupController(controller) {
			controller.atribuiValoresDefault();
			controller.inicializaCampos(Contrato['default'].create({}));
		},

		model: function model() {
			return Contrato['default'].create();
		}

	});

});
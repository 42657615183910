define('webapp/components/bread-crumbs-fixos', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({

    removeRotasIndesejadas: function removeRotasIndesejadas(arrayHandlersRotas) {
      return arrayHandlersRotas.filter(function (rota) {
        return !rota.routeName.includes('index') && !rota.routeName.includes('application') && !rota.routeName.includes('login');
      });
    },

    nodosEmOrdemAbertura: Ember['default'].computed('controllerReferencia.currentPath', function () {

      var rotasEmOrdemAbertura = this.controllerReferencia.target.router.state.handlerInfos.map(function (info) {
        return info.handler;
      });

      var rotasRelevantesEmOrdem = this.removeRotasIndesejadas(rotasEmOrdemAbertura);

      if (!rotasRelevantesEmOrdem) {
        return [];
      }

      var breadCrumbArray = rotasRelevantesEmOrdem.map(function (rota) {

        var idRota = rota.controller.model ? rota.controller.model.id : '';

        var nodo = { nome: rota.routeName + ' ' + (idRota ? idRota : ''), rota: rota.routeName, id: idRota };

        var controller = rota.controller;

        if (!controller.breadCrumbConfig) {
          return nodo;
        }

        if (controller.breadCrumbConfig.fnNomeApresentacao) {
          nodo.nome = controller[controller.breadCrumbConfig.fnNomeApresentacao].call(controller);
        } else if (controller.breadCrumbConfig.nomeApresentacao) {
          nodo.nome = controller.breadCrumbConfig.nomeApresentacao + ' ' + (idRota ? idRota : '');
        }

        return nodo;
      });

      if (breadCrumbArray[breadCrumbArray.length - 1]) {
        breadCrumbArray[breadCrumbArray.length - 1].classe = 'ativo';
      }

      return breadCrumbArray;
    })

  });

});
define('webapp/pods/aquisicao/contrato/aditivo/alteracao-tipo-societario/controller', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({

    needs: ['aquisicao/contrato', 'aquisicao/contrato/aditivo'],

    fornecedoresAtuaisContrato: (function () {
      return this.get('controllers.aquisicao/contrato').model.fornecedoresAtuais;
    }).property('controllers.aquisicao/contrato.model.fornecedoresAtuais'),

    podeAlterar: (function () {
      return this.get('controllers.aquisicao/contrato/aditivo').get('permitidaQualquerAlteracao');
    }).property('controllers.aquisicao/contrato/aditivo.ehAditivoNovo')

  });

});
define('webapp/mixins/constroi-aria-label-mixin', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  /* Adiciona ao componente uma propriedade _ariaLabel contendo um label acessível,
     para ser lido por leitores automáticos de tela. O valor dessa propriedade
     deve ser atribuído, pelo componente, ao atributo "aria-label" da tag HTML
     adequada para o caso.

     Propriedades que o controller deve ter para utilizar o mixin:
     "obrigatorio": <true | false> indicando se o preenchimento do campo é obrigatório
     "somenteLeitura": <true | false> indicando se o componente deve permitir a leitura do
                        valor, mas não sua modificação
  */

  exports['default'] = Ember['default'].Mixin.create({

    _ariaLabel: (function () {
      var label = this.labelAcessivel ? this.labelAcessivel : this.texto;
      var complementoCampoObrigatorio = this.obrigatorio ? ', campo obrigatório' : '';
      var complementoSomenteLeitura = this.somenteLeitura ? ', somente leitura' : '';
      var complementoComentario = this.comentario ? ', ' + this.comentario : '';
      return '' + label + complementoCampoObrigatorio + complementoSomenteLeitura + complementoComentario;
    }).property('labelAcessivel', 'texto', 'obrigatorio', 'somenteLeitura')

  });

});
define('webapp/models/orgao', ['exports', 'ember'], function (exports, Ember) {

	'use strict';

	exports['default'] = Ember['default'].Object.extend({
		id: null,
		nome: '',
		email: '',

		findUrlFn: function findUrlFn(id) {
			return 'orgao/' + id.toString();
		}
	});

});
define('webapp/pods/aquisicao/lote/controller', ['exports', 'ember', 'webapp/utils/util', 'webapp/mixins/crud-mixin'], function (exports, Ember, Util, CrudMixin) {

  'use strict';

  /*global _*/
  exports['default'] = Ember['default'].Controller.extend(CrudMixin['default'], {

    needs: ['aquisicao'],

    naoAdjudicado: true,
    verificarAdjudicacao: (function () {
      this.set('naoAdjudicado', !this.model.dataAdjudicacao);
    }).observes('model'),

    itensSelecionadosParaAdicionarAoLote: [],
    itensSelecionadosParaAdicionarAoLoteOrdenados: (function () {
      return this.itensSelecionadosParaAdicionarAoLote.sortBy('numeroLote', 'numeroApresentacao');
    }).property('itensSelecionadosParaAdicionarAoLote.@each'),

    itensDeLotesSemResultado: [],
    itensDeLotesSemResultadoOrdenados: (function () {
      return this.itensDeLotesSemResultado.sortBy('numeroLote', 'numeroApresentacao');
    }).property('itensDeLotesSemResultado.@each'),

    aquisicaoTemRegistroPrecos: (function () {
      return this.get('controllers.aquisicao').model.registroPreco;
    }).property('controllers.aquisicao.model.registroPreco'),

    inicializarListaItensParaSelecao: (function () {
      var _this = this;

      this.set('itensSelecionadosParaAdicionarAoLote', []);
      this.set('itensDeLotesSemResultado', []);

      var outrosLotesSemResultado = _.reject(this.get('lotesDaAquisicao'), function (lote) {
        //exclui o lote em alteração e os lotes com resultado
        //return lote.id === this.model.id || lote.dataAdjudicacao;
        return lote.id === _this.model.id || lote.resultado;
      }, this);

      outrosLotesSemResultado.forEach(function (lote) {
        _this.itensDeLotesSemResultado.pushObjects(lote.itens);
      });
    }).observes('lotesDaAquisicao', 'model'),

    lotesDaAquisicao: (function () {
      return this.get('controllers.aquisicao').model.lotes;
    }).property('controllers.aquisicao.model.lotes'),

    breadCrumbConfig: {
      fnNomeApresentacao: 'fnNomeApresentacao'
    },

    fnNomeApresentacao: function fnNomeApresentacao() {
      return 'Lote ' + (this.model.version ? this.model.numeroApresentacao : '(Novo)');
    },

    crudConfig: {
      criar: {
        urlFn: function urlFn() {
          return 'aquisicao/' + this.get("controllers.aquisicao").model.id + '/lote';
        },
        okFn: function okFn(opcao) {
          this.get('controllers.aquisicao').send('ler');
          if (opcao === 'CRIAR_E_PROXIMO') {
            //ação "borbulhará" para a rota
            this.send('refreshRota');
            return;
          }
          this.transitionToRoute('aquisicao');
        }
      },
      atualizar: {
        okFn: function okFn() {
          this.get('controllers.aquisicao').send('ler');
          this.transitionToRoute('aquisicao');
        }
      },
      excluir: {
        okFn: function okFn() {
          this.get('controllers.aquisicao').send('ler');
        }
      }
    },

    mostraCpfOuCnpjAdjudicado: function mostraCpfOuCnpjAdjudicado(adjudicado) {
      if (!adjudicado) {
        return;
      }
      return adjudicado.cpf ? adjudicado.cpf : adjudicado.cnpj;
    },

    mostraNumerosItens: function mostraNumerosItens(lote) {
      //Extrai o id dos itens para exibição na tabela de lotes
      return lote.itens.map(function (item) {
        return ' ' + item.numero;
      });
    },

    concatenaNomeAdjudicadoCpfCnpj: function concatenaNomeAdjudicadoCpfCnpj(lote) {
      if (!lote.adjudicado.id) {
        return;
      }
      var adjudicado = lote.adjudicado;
      var docIdentificacao = adjudicado.cpf ? Util['default'].incluiPontuacaoCpf(adjudicado.cpf) : Util['default'].incluiPontuacaoCnpj(adjudicado.cnpj);
      return adjudicado.nome + ' (' + docIdentificacao + ')';
    },

    actions: {

      selecionarItem: function selecionarItem(item) {
        if (_.findWhere(this.model.itens, { id: item.id })) {
          return;
        }
        this.itensSelecionadosParaAdicionarAoLote.addObject(item);

        this.itensDeLotesSemResultado.removeObject(item);
      },

      removerItem: function removerItem(item) {
        this.itensSelecionadosParaAdicionarAoLote.removeObject(item);
        this.itensDeLotesSemResultado.pushObject(item);
      },

      encaminharCriacao: function encaminharCriacao() {
        this.model.itens.pushObjects(this.itensSelecionadosParaAdicionarAoLote);
        this.send('criar');
      },

      encaminharAtualizacao: function encaminharAtualizacao() {
        this.model.itens.pushObjects(this.itensSelecionadosParaAdicionarAoLote);
        this.send('atualizar');
      },

      abrirModalAdjudicacao: function abrirModalAdjudicacao(lote) {
        this.transitionToRoute('aquisicao/lote/adjudicar', lote.id);
      }

    }

  });

});
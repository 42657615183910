define('webapp/pods/fornecedor/dado-bancario/model', ['exports', 'ember', 'webapp/models/banco'], function (exports, Ember, Banco) {

	'use strict';

	exports['default'] = Ember['default'].Object.extend({
		id: null,
		banco: Banco['default'].create({}),
		agencia: '',
		conta: '',
		principal: false,
		numeroApresentacao: '',

		inicializar: (function () {
			this.set('banco', Banco['default'].create({}));
		}).on('init'),

		findUrlFn: function findUrlFn(id) {
			return 'fornecedor/dadoBancario/' + id.toString();
		}
	});

});
define('webapp/components/datetime-input', ['exports', 'ember', 'webapp/mixins/ajusta-id-interno-mixin', 'webapp/mixins/ajusta-tamanho-input-mixin', 'webapp/mixins/constroi-aria-label-mixin'], function (exports, Ember, AjustaIdInternoMixin, AjustaTamanhoInputMixin, ConstroiAriaLabelMixin) {

  'use strict';

  /*
  Exemplo de uso:
  {{datetime-input
  	 valor=<valor associado ao input>
     labelAcessivel=<texto para ser lido por leitores automáticos de interface
                  para acessibilidade por pessoas com deficiência visual.
                  Se não for definido, será lido o texto do label do campo>
     tamanho=<largura do componente em termos do bootstrap - um número X
              de 1 a 12 utilizado para montar a classe CSS "col-md-X".
              O default é 12>
  	 classeLabel=<classe do label, default é "col-md-2 control-label">
  	 classeElementoInterno=<classe do input, default é "col-md-10">
     somenteLeitura=<true ou false, sem aspas>
  	 desabilitado=<true ou false, sem aspas>
     obrigatorio=<true ou false, sem aspas, indicando se o campo deve apresentar um asterisco
                  ao lado do seu label>
  }}

  Todo date-input com id    "tag_W_X_Y_Z"
  cria um componente de id  "campo_W_X_Y_Z"

  O funcionamento de "tamanho", "tamanhoLabel" e "tamanhoCampo" é dependente do ajusta-tamanho-input-mixin.
  */

  exports['default'] = Ember['default'].Component.extend(AjustaIdInternoMixin['default'], AjustaTamanhoInputMixin['default'], ConstroiAriaLabelMixin['default'], {
    disabled: false,
    somenteLeitura: false,

    icones: { close: 'glyphicon glyphicon-chevron-up' },

    tipoInterno: 'campo',

    insereAriaLabel: (function () {
      var campo = this.$('input')[0];
      campo.setAttribute('aria-label', this.get('_ariaLabel'));
    }).on('willInsertElement'),

    change: (function () {
      var campo = this.$('input')[0];

      var valorEfetivoCampo = campo.value;
      this.set('dataValida', this._ehDataNoFormatoCorreto(valorEfetivoCampo));
      this.set('dataVazia', !valorEfetivoCampo);

      //this._atualizaValor(valorEfetivoCampo);
    }).observes('valor'),

    //Verifica se a data está exatamente no formato dd/mm/aaaa
    //Ou se é completamente em branco
    _ehDataNoFormatoCorreto: function _ehDataNoFormatoCorreto(data) {
      if (data === '' || data === undefined || data === null) {
        return true;
      }
      return data.match(/\d{2}\/\d{2}\/\d{4} \d{2}:\d{2}$/gi);
    },

    //Se a data não for válida (não estiver exatamente no formato dd/mm/aaaa), apaga
    //o que estiver no componente
    focusOut: function focusOut() {
      if (!this.dataValida || this.dataVazia) {
        this.set('valor', '');
      }
    },

    _atualizaValor: function _atualizaValor(valorEfetivoCampo) {
      var dataSelecionada = this._geraDateAPartirStringDDMMAAAA_HHMM(valorEfetivoCampo);

      if (this.valor && dataSelecionada && this.valor.valueOf() !== dataSelecionada.valueOf()) {
        this.set('valor', dataSelecionada);
      }
    },

    _geraDateAPartirStringDDMMAAAA_HHMM: function _geraDateAPartirStringDDMMAAAA_HHMM(stringDataHora) {
      var ano = this._extraiNumeroCorrespondenteAnoDaData(stringDataHora);
      var mes = this._extraiNumeroCorrespondenteMesDaData(stringDataHora);
      var dia = this._extraiNumeroCorrespondenteDiaDaData(stringDataHora);
      var horas = this._extraiNumeroCorrespondenteHorasDaData(stringDataHora);
      var minutos = this._extraiNumeroCorrespondenteMinutosDaData(stringDataHora);

      if (!ano || !mes || !dia || !horas && horas !== 0 || !minutos && minutos !== 0) {
        return null;
      }

      return new Date(ano, mes, dia, horas, minutos);
    },

    //Aceita datas no formato "DD/MM/AAAA HH:mm"
    _extraiNumeroCorrespondenteMinutosDaData: function _extraiNumeroCorrespondenteMinutosDaData(stringDataHora) {
      var minutos = this._extraiPorcaoHorario(stringDataHora).split(':')[1];
      var numeroMinutos = Number(minutos);

      if (numeroMinutos < 0 || numeroMinutos > 59) {
        return null;
      }

      return numeroMinutos;
    },

    //Aceita datas no formato "DD/MM/AAAA HH:mm"
    _extraiNumeroCorrespondenteHorasDaData: function _extraiNumeroCorrespondenteHorasDaData(stringDataHora) {
      var horas = this._extraiPorcaoHorario(stringDataHora).split(':')[0];
      var numeroHoras = Number(horas);

      if (numeroHoras < 0 || numeroHoras > 23) {
        return null;
      }

      return numeroHoras;
    },

    //Aceita datas no formato "DD/MM/AAAA HH:mm"
    _extraiPorcaoHorario: function _extraiPorcaoHorario(stringDataHora) {
      return stringDataHora.split(' ')[1];
    },

    //Aceita datas no formato "DD/MM/AAAA HH:mm"
    //Se a string não tiver um dia válido (por exemplo, um dia "0"), retorna null
    _extraiNumeroCorrespondenteDiaDaData: function _extraiNumeroCorrespondenteDiaDaData(stringDataHora) {
      var stringDia = this._extraiPorcaoData(stringDataHora).split('/')[0];
      var numeroDia = Number(stringDia);

      if (!numeroDia || numeroDia < 1 || numeroDia > 31) {
        return null;
      }

      return numeroDia;
    },

    //Se a string não tiver um mês válido (por exemplo, um mês > 12), retorna null
    //Janeiro = 0; Dezembro = 12
    _extraiNumeroCorrespondenteMesDaData: function _extraiNumeroCorrespondenteMesDaData(stringDataHora) {
      var stringMes = this._extraiPorcaoData(stringDataHora).split('/')[1];
      var numeroMesDigitado = Number(stringMes);

      if (!numeroMesDigitado || numeroMesDigitado < 1 || numeroMesDigitado > 12) {
        return null;
      }

      //Meses vão de Janeiro = 0 a Dezembro = 12
      return numeroMesDigitado - 1;
    },

    //Se a string não tiver um ano válido (por exemplo, um ano > 0), retorna null
    _extraiNumeroCorrespondenteAnoDaData: function _extraiNumeroCorrespondenteAnoDaData(stringDataHora) {
      var stringAno = this._extraiPorcaoData(stringDataHora).split('/')[2];
      var numeroAno = Number(stringAno);

      if (!numeroAno || numeroAno < 0) {
        return null;
      }

      return numeroAno;
    },

    _extraiPorcaoData: function _extraiPorcaoData(stringDataHora) {
      return stringDataHora.split(' ')[0];
    }

  });

});
define('webapp/components/busca-pa', ['exports', 'ember', 'webapp/defs', 'webapp/mixins/ajusta-tamanho-input-mixin', 'webapp/mixins/constroi-aria-label-mixin', 'webapp/utils/id', 'webapp/utils/util'], function (exports, Ember, Defs, AjustaTamanhoInputMixin, ConstroiAriaLabelMixin, UtilID, Util) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(AjustaTamanhoInputMixin['default'], ConstroiAriaLabelMixin['default'], {

    comunicador: Ember['default'].inject.service(),

    mostrarBuscaPas: false,
    mostrarResultados: false,
    pas: [],
    paSelecionado: {}, //para uso interno do componente (de onde serão
    //buscados os dados a serem apresentados, etc.)

    texto: 'Número',

    numeroAPesquisar: '',
    resumoAPesquisar: '',
    anoAPesquisar: '',
    procAdm: [],

    _resumo: '',
    _situacaoAtualDoProcesso: '',
    _mensagemErro: '',

    resumo: (function () {
      return this._resumo;
    }).property('_resumo'),

    mensagemErro: (function () {
      return this._mensagemErro;
    }).property('_mensagemErro'),

    situacaoAtualDoProcesso: (function () {
      return this._situacaoAtualDoProcesso;
    }).property('_situacaoAtualDoProcesso'),

    desabilitarBotoes: (function () {
      return this.desabilitado || this.somenteLeitura;
    }).property('desabilitado', 'somenteLeitura'),

    inicializar: (function () {
      this.inicializarPASelecionado();
    }).on('init'),

    insereElemento: (function () {
      if (typeof this.get('mostrarDetalhes') === 'undefined') {
        this.set('mostrarDetalhes', true);
      }
    }).on('didInsertElement'),

    inicializarPASelecionado: (function () {
      var _this = this;

      this.set('paSelecionado', this.atributoDestino);

      this.set('_mensagemErro', '');

      if (this.atributoDestino !== ' ' && this.atributoDestino !== null) {
        this.set('atributoDestino', this.atributoDestino.trim());
        this.procAdm = Util['default'].decompoeProAdm(this.atributoDestino);
        if (this.procAdm.length === 2) {
          this.procAdmNro = this.procAdm[0];
          this.procAdmAno = this.procAdm[1];

          var parametrosPesquisaPa = {
            numero: this.procAdmNro,
            ano: this.procAdmAno
          };

          this.get('comunicador').servico('post', 'pa/pesquisa', parametrosPesquisaPa).then(function (resultado) {
            if (resultado[0]) {
              _this.set('_resumo', resultado[0].resumo);
              _this.set('_situacaoAtualDoProcesso', resultado[0].situacaoAtualDoProcesso);
            } else {
              _this.set('_resumo', '');
              _this.set('_mensagemErro', 'Processo administrativo não encontrado no sistema PROAD. Insira um número de PROAD válido.');
            }
          });
        } else {
          this.set('_resumo', '');
          this.set('_mensagemErro', 'Processo administrativo não encontrado no sistema PROAD. Insira um número de PROAD válido.');
          this.set('_situacaoAtualDoProcesso', '');
        }
      } else {
        this.set('_resumo', '');
        this.set('_situacaoAtualDoProcesso', '');
      }
    }).observes('atributoDestino'),

    mostrarCampos: (function () {
      return this.mostrarDetalhes && !this._mensagemErro;
    }).property('mostrarDetalhes', '_mensagemErro'),

    labelAcessivelCampo: (function () {
      return this.labelAcessivel;
    }).property('labelAcessivel'),

    instrucoesAdicionaisCampo: (function () {
      return this.get('desabilitarBotoes') ? 'Botões para alteração desabilitados.' : 'Use os botões que vêm a seguir para alterar o Processo Administrativo.';
    }).property('desabilitarBotoes'),

    labelAcessivelBotaoPesquisar: (function () {
      return 'Iniciar busca de ' + (this.labelAcessivel ? this.labelAcessivel : this.texto ? this.texto : 'Processo Administrativo.');
    }).property('labelAcessivel'),

    labelAcessivelBotaoExcluir: (function () {
      return 'Limpar ' + (this.labelAcessivel ? this.labelAcessivel : 'Processo Administrativo') + ' selecionado';
    }).property('labelAcessivel'),

    _idResumo: (function () {
      if (!this.id) {
        return '';
      };

      var porcaoAdicionalIdComponente = UtilID['default'].getPorcaoOpcionalId(this.id);
      return porcaoAdicionalIdComponente ? UtilID['default'].prefixaSeccao('resumo', 'opcional', this.id) : UtilID['default'].incluiSeccaoOpcional(this.id, 'resumo');
    }).property('id'),

    _idSituacaoAtualDoProcesso: (function () {
      if (!this.id) {
        return '';
      };

      var porcaoAdicionalIdComponente = UtilID['default'].getPorcaoOpcionalId(this.id);
      return porcaoAdicionalIdComponente ? UtilID['default'].prefixaSeccao('situacaoAtualDoProcesso', 'opcional', this.id) : UtilID['default'].incluiSeccaoOpcional(this.id, 'situacaoAtualDoProcesso');
    }).property('id'),

    // insereAriaLabel: function() {
    //   if(this.apenasBotao) {
    //     return;
    //   }
    //
    //   var campo = this.$('input')[0];
    //   var identificacaoInicial = 'Componente para busca de Processos Administrativos, ';
    //   var instrucoesFinais = ', use o botão ao lado para selecionar';
    //   campo.setAttribute('aria-label', identificacaoInicial + this.get('_ariaLabel') + instrucoesFinais);
    // }.on('willInsertElement'),
    //

    controllerDoContexto: (function () {
      if (typeof this.contexto === 'string') {
        return this.container.lookup("controller:" + this.contexto);
      }

      return this.contexto;
    }).property('contexto'),

    actions: {

      acaoMouseClick: function acaoMouseClick() {
        var _this2 = this;

        if (!this.atributoDestino) {
          return;
        }
        // this.atributoDestino = (typeof this.atributoDestino === 'string' ? this.atributoDestino.trim() : this.atributoDestino);
        this.get('comunicador').servico('get', 'parametroExibicao').then(function (resultado) {
          var acaoUrlProcessoEletronico = Util['default'].converteParametrosDeExibicao(resultado).ACAO_URL_PROCESSO_ELETRONICO;
          var saveData = (function () {
            var a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display: none";
            return function (data, fileName) {
              var blob = new Blob([data], { type: "application/pdf" }),
                  url = window.URL.createObjectURL(blob);
              a.href = url;
              a.download = fileName;
              a.click();
              window.URL.revokeObjectURL(url);
            };
          })();

          var xhttp = new XMLHttpRequest();
          xhttp.onloadend = function () {
            if (xhttp.readyState === 4) {
              if (xhttp.status === 200) {
                if (acaoUrlProcessoEletronico === "BAIXAR_ARQUIVO") {
                  saveData(xhttp.response, 'processo_' + _this2.atributoDestino + '.pdf');
                } else {
                  var url = xhttp.response.includes("http://") || xhttp.response.includes("https://") ? xhttp.response : "http://" + xhttp.response;
                  window.open(url, '_blank');
                }
              } else {
                Util['default'].alertaErro("Erro: processo não encontrado");
              }
            }
          };
          xhttp.open('get', Defs['default'].enderecoServer + "pa/download/" + encodeURI(_this2.atributoDestino), true);
          xhttp.setRequestHeader('authToken', _this2.get('comunicador').get('usuarioLogado').estaLogado() ? _this2.get('comunicador').get('usuarioLogado').info.authToken : '');
          xhttp.setRequestHeader('TimeZone', _this2.get('comunicador').get('usuarioLogado').estaLogado() ? JSON.stringify(new Date()).substr(24, 5) : '');
          xhttp.responseType = acaoUrlProcessoEletronico === "BAIXAR_ARQUIVO" ? "arraybuffer" : "text";
          xhttp.send();
        });
      },

      excluir: function excluir() {
        this.set('paSelecionado', null);
        this.set('atributoDestino', '');
      },

      iniciarBusca: function iniciarBusca() {
        this.set('numeroAPesquisar', undefined);
        this.set('resumoAPesquisar', undefined);
        this.set('anoAPesquisar', undefined);

        this.set('mostrarBuscaPas', true);
      },

      pesquisarPa: function pesquisarPa() {
        var _this3 = this;

        if (!this.numeroAPesquisar && !this.resumoAPesquisar && !this.anoAPesquisar) {
          return;
        }

        var parametrosPesquisaPa = {
          numero: this.numeroAPesquisar,
          resumo: this.resumoAPesquisar,
          ano: this.anoAPesquisar
        };

        this.set('mostrarResultados', false);

        this.get('comunicador').servico('post', 'pa/pesquisa', parametrosPesquisaPa).then(function (resultado) {
          _this3.set('pasPesquisados', resultado);
          _this3.get('pasPesquisados').forEach(function (pa) {
            pa.nroProcAdm = Util['default'].compoeProcAdm(pa.numero, pa.ano);
          });
          _this3.set('mostrarResultados', true);
        });
      },

      selecionarPa: function selecionarPa(pa) {
        this.set('_resumo', pa.resumo);
        this.set('_situacaoAtualDoProcesso', pa.situacaoAtualDoProcesso);
        this.set('atributoDestino', Util['default'].compoeProcAdm(pa.numero, pa.ano));
        this.set('paSelecionado', Util['default'].compoeProcAdm(pa.numero, pa.ano));
        this.set('mostrarBuscaPas', false);
      },

      cancelarBusca: function cancelarBusca() {
        this.set('mostrarBuscaPas', false);
      }

    }

  });

});
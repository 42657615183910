define('webapp/models/contato', ['exports', 'ember'], function (exports, Ember) {

	'use strict';

	exports['default'] = Ember['default'].Object.extend({
		id: null,
		nome: '',
		telefone: '',
		email: '',
		observacao: ''
	});

});